import { trackEvent } from '@phntms/next-gtm';
import { captureException } from '@sentry/nextjs';
import { disconnect } from '@wagmi/core';
import clsx from 'clsx';
import Image from 'next/image';
import { type ReactNode } from 'react';
import type { Connector } from 'wagmi';
import { ConnectorAlreadyConnectedError, useConnect } from 'wagmi';

import { InfoIcon, LogoIcon, ProfileIcon, WalletIcon } from '@endaoment-frontend/ui/icons';
import { ActionButton, Button, Pill, Tooltip } from '@endaoment-frontend/ui/shared';
import { capitalize } from '@endaoment-frontend/utils';

import { isSocialConnector } from '../socialConnectors';

import styles from './ChooseTypeView.module.scss';
import coinbaseLogo from './connector-logos/coinbase.png';
import discordLogo from './connector-logos/discord.png';
import facebookLogo from './connector-logos/facebook.png';
import googleLogo from './connector-logos/google.png';
import injectedLogo from './connector-logos/injected.png';
import ledgerLogo from './connector-logos/ledger.png';
import metamaskLogo from './connector-logos/metamask.png';
import safeLogo from './connector-logos/safe.png';
import walletConnectLogo from './connector-logos/wallet-connect.png';

const connectorToIcon: Record<string, ReactNode> = {
  'injected': <Image width='48' height='48' src={injectedLogo} alt='wallet' />,
  'fake-social-connector': <Image width='48' height='48' src={injectedLogo} alt='wallet' />,
  'google': <Image width='48' height='48' src={googleLogo} alt='google logo' />,
  'facebook': <Image width='48' height='48' src={facebookLogo} alt='facebook logo' />,
  'discord': <Image width='48' height='48' src={discordLogo} alt='discord logo' />,
  'walletConnect': <Image width='48' height='48' src={walletConnectLogo} alt='wallet connect logo' />,
  'walletConnectLegacy': <Image width='48' height='48' src={walletConnectLogo} alt='wallet connect logo' />,
  'metamask': <Image width='48' height='48' src={metamaskLogo} alt='metamask logo' />,
  'coinbaseWallet': <Image width='48' height='48' src={coinbaseLogo} alt='coinbase wallet logo' />,
  'safe': <Image width='48' height='48' src={safeLogo} alt='safe wallet logo' />,
  'ledger': <Image width='48' height='48' src={ledgerLogo} alt='ledger logo' />,
};

const ConnectorButton = ({
  connector,
  prefixName = false,
  className,
}: {
  connector: Connector;
  prefixName?: boolean;
  className?: string;
}) => {
  const { connect, status } = useConnect({
    connector,
    onError: e => {
      if (e instanceof ConnectorAlreadyConnectedError) disconnect();
      captureException(e, {
        tags: {
          type: 'connector_error',
          connector: connector.name,
        },
        extra: {
          connector,
        },
      });
    },
    onSuccess: ({ account }) => {
      // Signal GTM for user wallet connected
      trackEvent({
        event: 'si_connect',
        data: {
          wallet_id: account,
          sign_in_method: connector.name,
        },
      });
    },
  });
  return (
    <Button
      onClick={() => connect()}
      className={clsx(
        styles.button,
        styles.connector,
        connector.name === 'MetaMask' ? styles['connector__metamask'] : styles[`connector__${connector.id}`],
        styles[`connector--${status}`],
        className,
      )}>
      <div className={styles['connector-icon']}>
        {connector.name === 'MetaMask' ? connectorToIcon['metamask'] : connectorToIcon[connector.id]}
      </div>
      <div className={styles['connector-text']}>
        <h5>
          {!!prefixName && 'Sign in via '}
          <b>{connector.name}</b>
        </h5>
        <Pill size='tiny' className={styles['connector-status']} variation='purple'>
          {status === 'idle' ? 'Connect' : capitalize(status)}
        </Pill>
      </div>
    </Button>
  );
};

export const ChooseTypeView = ({
  onChoose,
}: {
  onChoose: (view: 'social' | 'wallet') => void;
  onClose: () => void;
}) => {
  return (
    <>
      <LogoIcon width={48} color='currentColor' className={clsx(styles.logo, styles['header-fix'])} />
      <h2 className={styles.title}>Sign in to Endaoment</h2>
      <div className={styles.buttons}>
        <ActionButton
          onClick={() => onChoose('social')}
          color='fund'
          text='Sign in with Social'
          subtext='Use Google, Facebook or Discord'
          className={styles.button}>
          <ProfileIcon width={28} color='currentColor' />
        </ActionButton>
        <hr />
        <ActionButton
          onClick={() => onChoose('wallet')}
          text='Sign in with Ethereum'
          subtext='Connect using a variety of wallets'
          color='org'
          className={styles.button}>
          <WalletIcon width={24} color='currentColor' strokeWidth={2} />
        </ActionButton>
      </div>
      <br />
      <Tooltip
        content='Use a social account to sign in if you do not already have your own onchain wallet. Sign in with Ethereum if you wish to use an existing wallet.'
        className={styles.subtext}
        as='p'>
        What is the difference? <InfoIcon width={16} color='currentColor' />
      </Tooltip>
    </>
  );
};

export const ChooseSocialView = () => {
  const { connectors } = useConnect();
  // Do not create new connectors, only use existing ones
  // otherwise, the login will not get saved to local storage
  const socialConnectors = connectors.filter(isSocialConnector);

  return (
    <>
      <h2 className={clsx(styles.title, styles['header-fix'])}>Sign in to Endaoment</h2>
      <div className={styles.buttons}>
        {socialConnectors.map(connector => (
          <ConnectorButton key={connector.id} connector={connector} prefixName />
        ))}
      </div>
    </>
  );
};

export const ChooseWalletView = () => {
  const { connectors } = useConnect();
  // Do not create new connectors, only use existing ones
  // otherwise, the login will not get saved to local storage
  const walletConnectors = connectors.filter(v => !isSocialConnector(v));

  return (
    <>
      <h2 className={clsx(styles.title, styles['header-fix'])}>Sign in to Endaoment</h2>
      <div className={styles.buttons}>
        {walletConnectors
          .filter(connector => connector.ready)
          .map(connector => (
            <ConnectorButton key={connector.id} connector={connector} />
          ))}
      </div>
    </>
  );
};
