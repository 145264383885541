/** This file is autogenerated using the production environment */

import { mainnet, base, optimism } from 'wagmi/chains';

import type { ConfigEntry } from './config.types';
import { BASE_RPC_URLS, DOT_ORG_TEAM, NDAO_WALLETS } from './helpers';

export const config: ConfigEntry = {
  environmentName: 'production',
  chains: [mainnet, { ...base, rpcUrls: BASE_RPC_URLS }, { ...optimism, name: 'Optimism' }],
  providers: {
    alchemy: 'ao2D0tkhGEKpa2l-kyzmBhxyXm1Md9my',
    infura: '510c5afa18eb4eb9b611f010b805c393',
    tenderly: '4zmtwDeAz3MODjVA9Lj2Lz',
  },
  baseUrls: {
    app: 'https://app.endaoment.org',
    marketing: 'https://endaoment.org',
    api: 'https://api.endaoment.org',
    admin: 'https://admin.endaoment.org',
    developers: 'https://developers.endaoment.org',
    docs: 'https://docs.endaoment.org',
  },
  endaoment: {
    accounts: {
      accountant: ['0x9D5025B327E6B863E5050141C987d988c07fd8B2'],
      maintainer: [NDAO_WALLETS.ZACH, NDAO_WALLETS.ROBBIE, NDAO_WALLETS.RICARDO, NDAO_WALLETS.PEDRO],
      reviewer: ['0xBE21e4cf884c8B2517E4E199487f8b505841cB36'],
      keeper: DOT_ORG_TEAM,
    },
  },
  socialLoginChainId: base.id,
  zeroDevProjectIds: ['46197405-dbfa-4bfd-8fed-359696a1d0c5'],
  web3AuthClientId: 'BBeKn3j4I-WxP2o7xeWOaf9dOYPjcKvyMLgLow6s_mqsUeeZQsCnbW0YRV6IqKFrg0kSxlpsl5RmU9RALHgDvcU',
  circleAddress: '0xf0bfff7045dfeb4f6e69350c1cf8ff1a7de0d7da',
  googleMapsApiKey: 'AIzaSyBNX_ixICIxKEUHNiWFGdBBbi42OD4GN7k',
  // Need real API key for mapbox
  mapboxApiKey: 'pk.eyJ1IjoiZW5kYW9tZW50IiwiYSI6ImNsaGo5MDRmdzBmZXozZnBiMHV1NWRjcGIifQ.HfvViRsi4sKeYxFYt7-BQw',
  identifiersSecret: 'f33f3c5a-a716-4a5a-94bf-6cf7d86199a83a4cfa0a-1a52-4c30-98c9-7bc38ab6bbb3',
};

export const defaults = {
  "confirmations": {
    "donation": 2,
    "wirePayout": 7,
    "walletPayout": 5,
    "portfolioTrade": 2,
    "transfer": 2,
    "setManager": 2,
    "orgDeployment": 2,
    "fundDeployment": 2
  },
  "fees": {
    "donationBps": 150,
    "transferBps": 100,
    "stockDonationFeeBps": 396.25,
    "tgbEntityStockDonationFeeBps": 150
  },
  "network": {
    "defaultChainId": 1,
    "supportedNetworkSettings": [
      {
        "chainId": 1,
        "baseToken": {
          "id": 18,
          "symbol": "USDC",
          "name": "USD//C",
          "decimals": 6,
          "logoUrl": "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
          "type": "EvmToken",
          "chainId": 1,
          "contractAddress": "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48"
        },
        "nativeToken": {
          "id": 8,
          "symbol": "ETH",
          "name": "Ether",
          "decimals": 18,
          "logoUrl": "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
          "type": "EvmToken",
          "chainId": 1,
          "contractAddress": "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
        },
        "contracts": {
          "registry": "0x94106ca9c7e567109a1d39413052887d1f412183",
          "orgFundFactory": "0x10fd9348136dcea154f752fe0b6db45fc298a589",
          "batchOrgDeployer": "0x5012cb8a192db05260673a795b18aa5329d3d4c2",
          "transferDeployer": "0x4d91aad0deb494c71d538039c8c5d689faffa5fc",
          "entityMulticall": "0xf15c683ffba50254bab340efe49fa2038ee9354c"
        }
      },
      {
        "chainId": 10,
        "baseToken": {
          "id": 1034,
          "symbol": "USDC",
          "name": "USD Coin",
          "decimals": 6,
          "logoUrl": "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
          "type": "EvmToken",
          "chainId": 10,
          "contractAddress": "0x0b2c639c533813f4aa9d7837caf62653d097ff85"
        },
        "nativeToken": {
          "id": 1033,
          "symbol": "ETH",
          "name": "Ether",
          "decimals": 18,
          "logoUrl": "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
          "type": "EvmToken",
          "chainId": 10,
          "contractAddress": "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
        },
        "contracts": {
          "registry": "0x5d216bb28852f98ceaa29180670397ab01774ea6",
          "orgFundFactory": "0x10fd9348136dcea154f752fe0b6db45fc298a589",
          "batchOrgDeployer": "0x51aa0ad438b1efea69dbfd64bc15b6937edea504",
          "transferDeployer": "0x1d6834f708780dec04a7c33decb3bfe2397aefb9",
          "entityMulticall": "0x9abd2f47a1a1661adf9170048c6ba6f3943ab13e"
        }
      },
      {
        "chainId": 8453,
        "baseToken": {
          "id": 1027,
          "symbol": "USDbC",
          "name": "USD Base Coin",
          "decimals": 6,
          "logoUrl": "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
          "type": "EvmToken",
          "chainId": 8453,
          "contractAddress": "0xd9aaec86b65d86f6a7b5b1b0c42ffa531710b6ca"
        },
        "nativeToken": {
          "id": 1026,
          "symbol": "ETH",
          "name": "Ether",
          "decimals": 18,
          "logoUrl": "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
          "type": "EvmToken",
          "chainId": 8453,
          "contractAddress": "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
        },
        "contracts": {
          "registry": "0x713023b628cc1a7eb5b9dec2b58127909a7c9760",
          "orgFundFactory": "0x3d7bba3aee1cfadc730f42ca716172f94bbba488",
          "batchOrgDeployer": "0x0000000000000000000000000000000000000000",
          "transferDeployer": "0x5ebc7b328af86f456fa92c1ef4c2593253971e85",
          "entityMulticall": "0xef58a604bd63038d4a04db1fce3e70a662620b0a"
        }
      }
    ]
  },
  "stripePublishableKey": "pk_live_51NlG4xJjtfgbRoqpxUU4fFOwZYalkcVt9rCrvASS9zOrtgLvVq2lIOrmirw0Qyc7sRcILgsjyYCIOzt0WBYR9KCL004tbrOKdt"
} as const;
