import {
  DiscordSocialWalletConnector,
  FacebookSocialWalletConnector,
  GoogleSocialWalletConnector,
} from '@zerodev/wagmi';
import type { AbstractWeb3AuthWalletConnectorOptions } from '@zerodev/wagmi/dist/types/connectors/AbstractWeb3AuthWalletConnector';
import { match } from 'ts-pattern';
import type { Connector } from 'wagmi';
import { InjectedConnector } from 'wagmi/connectors/injected';

import { config } from '@endaoment-frontend/config';
import { getChainForChainId } from '@endaoment-frontend/multichain';
import { formatShortAddress } from '@endaoment-frontend/utils';

// TODO: Dedupe with `Web3Provider`
const APP_NAME = 'app.endaoment';
const APP_URL = 'https://app.endaoment.org';
const APP_LOGO = 'https://app.endaoment.org/images/favicon.ico';

// Used to stub out a social connector locally for metamask
export class FakeSocialConnector extends InjectedConnector {
  override id = 'fake-social-connector';
  override name = 'Fake Social';
  web3Auth = {
    getUserInfo: async (): ReturnType<NonNullable<GoogleSocialWalletConnector['web3Auth']>['getUserInfo']> => {
      const account = await this.getAccount();
      return {
        name: 'Social ' + formatShortAddress(account, 4),
        email: account.slice(0, 8) + '@social.com',
      };
    },
  };
}

// Remember to update the type when adding new social connectors
const socialConnectors = [
  { connector: DiscordSocialWalletConnector, isFake: false },
  { connector: FacebookSocialWalletConnector, isFake: false },
  { connector: GoogleSocialWalletConnector, isFake: false },
  { connector: FakeSocialConnector, isFake: true },
] as const;
export type SocialConnector = InstanceType<(typeof socialConnectors)[number]['connector']>;

export const isSocialConnector = (connector?: Connector): connector is SocialConnector => {
  if (!connector) return false;
  return socialConnectors.some(socialConnector => connector instanceof socialConnector.connector);
};

const web3AuthNetwork = match<
  (typeof config)['environmentName'],
  NonNullable<AbstractWeb3AuthWalletConnectorOptions['web3authOptions']>['web3AuthNetwork']
>(config.environmentName)
  .with('production', () => 'sapphire_mainnet')
  .otherwise(() => 'sapphire_devnet');

const zeroDevOptions = (): AbstractWeb3AuthWalletConnectorOptions => ({
  onlySendSponsoredTransaction: true,
  projectIds: config.zeroDevProjectIds,
  adapterSettings: {
    uxMode: 'redirect',
    clientId: config.web3AuthClientId,
    whiteLabel: {
      appName: APP_NAME,
      appUrl: APP_URL,
      logoLight: APP_LOGO,
      logoDark: APP_LOGO,
    },
    // TODO: Add custom OAuth verification configs
    loginConfig: {
      // google: {
      //   clientId: '895705015571-ouohcmao3bnctit621i85m7ivme65hpu.apps.googleusercontent.com',
      //   name: 'Google',
      //   typeOfLogin: 'google',
      //   verifier: 'endaoment-dev-google',
      // },
    },
  },
  web3authOptions: {
    clientId: config.web3AuthClientId,
    web3AuthNetwork,
    sessionTime: 7 * 86400, // 7 days
  },
  shimDisconnect: true,
});

export const getSocialConnectors = (): Array<SocialConnector> => {
  const chains = [getChainForChainId(config.socialLoginChainId)];
  const options = zeroDevOptions();

  if (typeof window === 'undefined' || process.env.NODE_ENV === 'test') return [];

  const connectors =
    config.environmentName === 'local' ? socialConnectors : socialConnectors.filter(({ isFake }) => !isFake);
  return connectors.map(v => new v.connector({ chains, options }));
};
