import { useEffect, useState } from 'react';
import { useEnsName } from 'wagmi';

import { defaults } from '@endaoment-frontend/config';
import { TIME_ONE_HOUR_IN_SECONDS } from '@endaoment-frontend/constants';

type EnsNameArgs = Parameters<typeof useEnsName>[0];

export const useEnsNameWithFallback = (args: EnsNameArgs): ReturnType<typeof useEnsName> => {
  const [usedChainId, setUsedChainId] = useState<number | undefined>(args?.chainId);
  const ensNameResponse = useEnsName({
    staleTime: TIME_ONE_HOUR_IN_SECONDS * 1000,
    ...args,
    chainId: usedChainId,
  });
  const { error } = ensNameResponse;

  useEffect(() => {
    if (!error) return;

    setUsedChainId(defaults.network.defaultChainId);
  }, [error]);

  return ensNameResponse;
};
