import { createRef, useEffect, useRef, useState } from 'react';

import type { SegmentedControlOption } from './SegmentedControl.types';

export const useSegmentedControlSelection = (
  options: Array<SegmentedControlOption>,
  defaultIndex: number,
  onSelect: (option: SegmentedControlOption) => void,
) => {
  const [selectedIndex, setSelectedIndex] = useState(defaultIndex);
  const optionRefs = useRef(options.map(() => createRef<HTMLDivElement>()));

  useEffect(() => {
    const activeSegmentRef = optionRefs.current[selectedIndex];

    if (activeSegmentRef.current) {
      const { offsetWidth, offsetLeft } = activeSegmentRef.current;
      const { style } = document.documentElement;

      style.setProperty('--selected-segment-width', `${offsetWidth}px`);
      style.setProperty('--selected-segment-x-pos', `${offsetLeft}px`);
    }
  }, [selectedIndex, onSelect, options, optionRefs]);

  const onOptionChange = (option: SegmentedControlOption, index: number) => {
    setSelectedIndex(index);
    onSelect(option);
  };

  return { selectedIndex, optionRefs, onOptionChange };
};
