import clsx from 'clsx';
import { Children } from 'react';

import styles from './Carousel.module.scss';
import type { CarouselProps } from './Carousel.types';
import { useCarouselScroll } from './useCarouselScroll';

const RightArrow = () => {
  return (
    <svg width='44' height='44' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='22' cy='22' r='21.5' stroke='#EFEFEF' />
      <path
        d='M15 21C14.4477 21 14 21.4477 14 22C14 22.5523 14.4477 23 15 23V21ZM29.7071 22.7071C30.0976 22.3166 30.0976 21.6834 29.7071 21.2929L23.3431 14.9289C22.9526 14.5384 22.3195 14.5384 21.9289 14.9289C21.5384 15.3195 21.5384 15.9526 21.9289 16.3431L27.5858 22L21.9289 27.6569C21.5384 28.0474 21.5384 28.6805 21.9289 29.0711C22.3195 29.4616 22.9526 29.4616 23.3431 29.0711L29.7071 22.7071ZM15 23H29V21H15V23Z'
        fill='black'
      />
    </svg>
  );
};

const LeftArrow = () => {
  return (
    <svg width='44' height='44' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='22' cy='22' r='21.5' transform='rotate(180 22 22)' stroke='#EFEFEF' />
      <path
        d='M29 23C29.5523 23 30 22.5523 30 22C30 21.4477 29.5523 21 29 21L29 23ZM14.2929 21.2929C13.9024 21.6834 13.9024 22.3166 14.2929 22.7071L20.6569 29.0711C21.0474 29.4616 21.6805 29.4616 22.0711 29.0711C22.4616 28.6805 22.4616 28.0474 22.0711 27.6569L16.4142 22L22.0711 16.3431C22.4616 15.9526 22.4616 15.3195 22.0711 14.9289C21.6805 14.5384 21.0474 14.5384 20.6569 14.9289L14.2929 21.2929ZM29 21L15 21L15 23L29 23L29 21Z'
        fill='black'
      />
    </svg>
  );
};

export const Carousel = ({ className, children }: CarouselProps) => {
  const { listRef, scrollPos, currentIndex, maxIndex, incrementIndex, decrementIndex, handleKeyDown } =
    useCarouselScroll(Children.count(children));

  // TODO: `handleKeyDown` always decrements index? Also check if arrows are correctly tab-able

  return (
    <div className={clsx(styles['carousel'], className)}>
      <div
        className={clsx(
          styles['carousel__button'],
          styles['carousel__button--prev'],
          currentIndex === 0 && styles['carousel__button--inactive'],
        )}
        onClick={decrementIndex}
        onKeyDown={handleKeyDown}
        tabIndex={0}
        role='button'>
        <LeftArrow />
      </div>
      <div className={styles['carousel__container']}>
        <div
          className={styles['carousel__list']}
          ref={listRef}
          style={{ transform: `translateX(calc(${scrollPos}px))` }}
          data-testid='carousel-list'>
          {children}
        </div>
      </div>
      <div
        className={clsx(
          styles['carousel__button'],
          styles['carousel__button--next'],
          currentIndex === maxIndex && styles['carousel__button--inactive'],
        )}
        onClick={incrementIndex}
        onKeyDown={handleKeyDown}
        tabIndex={0}
        role='button'>
        <RightArrow />
      </div>
    </div>
  );
};
