import { getAccount, signMessage } from '@wagmi/core';
import { useAtomValue, useSetAtom } from 'jotai';
import type { ReactNode } from 'react';
import { useCallback, useEffect } from 'react';
import { P, match } from 'ts-pattern';
import { useAccount, useConnect } from 'wagmi';
import { useQueryClient } from '@tanstack/react-query';

import { WhoAmI, SignIn, SignOut } from '@endaoment-frontend/api';
import type { Address } from '@endaoment-frontend/types';
import { equalAddress, makeValidLoginSignature } from '@endaoment-frontend/utils';

import { WalletModal } from './WalletModal';
import { getUserEmail } from './socialAuth';
import { isSocialConnector } from './socialConnectors';
import { siweActionsAtom, siweSessionResultAtom, siweStatusAtom, useAuth } from './useAuth';
import { useWalletModal } from './useWalletModal';

type Web3AuthProviderProps = {
  children: Array<ReactNode> | ReactNode;
  onSignIn?: (authAddress: Address) => void;
  onSignOut?: () => void;
};

export const isRejectedError = (e: unknown): boolean => {
  return match(e)
    .with(
      {
        code: P.union(
          -3200, // WalletConnect: user rejected
          4001, // MetaMask: user rejected
          'ACTION_REJECTED', // MetaMask: user rejected
        ),
      },
      () => true,
    )
    .otherwise(() => false);
};

// We eagerly try to connect/show the modal for the `redirect` uxMode cases so user has a clear next
// step after being redirected to the app
const EagerSIWEReconnector = () => {
  const { isDisconnected, connector } = useAccount();
  const { isReady, isLoading, isSignedIn } = useAuth();
  const { showWallet } = useWalletModal();

  useEffect(() => {
    // Do not attempt if still resolving connector or actually disconnected
    if (!connector || isDisconnected || !isReady || isLoading || isSignedIn) return;

    // Do not attempt this for wallet connections
    if (!isSocialConnector(connector)) return;
    showWallet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connector, isDisconnected, isLoading, isReady, isSignedIn]);

  return <></>;
};

// Required since we were not able to recover connector after a page refresh
// See https://web3auth.io/community/t/keep-web3auth-session-persistent-after-page-refresh/3262
const EagerConnectionWrapper = () => {
  const { connect, connectors, pendingConnector } = useConnect();
  const { isDisconnected } = useAccount();

  useEffect(() => {
    if (!isDisconnected || pendingConnector) return;

    // if wagmi.connected set to true, then wagmi will not show modal
    // to reconnect user wallet, but instead will use prev connection
    // I found this example in this public repo: https://github.com/sumicet/web3auth-modal-wagmi
    let isWagmiConnected = false;
    let connectorId = '';
    try {
      // Pull wagmi internal variables from local storage
      const wagmiConnected = localStorage.getItem('wagmi.connected');
      isWagmiConnected = wagmiConnected ? JSON.parse(wagmiConnected) : false;
      const connectorIdRaw = localStorage.getItem('wagmi.wallet');
      connectorId = connectorIdRaw ? JSON.parse(connectorIdRaw) : null;
    } catch {
      /* empty */
    }

    if (!isWagmiConnected || !connectorId) return;

    const connector = connectors.find(c => c.id === connectorId);

    // Do not need to attempt this for wallet connections
    if (!isSocialConnector(connector)) return;

    connect({ connector });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectors, pendingConnector, isDisconnected]);

  return <></>;
};

export const Web3AuthProvider = ({ children, onSignIn, onSignOut }: Web3AuthProviderProps) => {
  const setStatus = useSetAtom(siweStatusAtom);
  const { data: authAddress, refetch: refetchSession } = useAtomValue(siweSessionResultAtom);
  const setSIWEActions = useSetAtom(siweActionsAtom);
  const queryClient = useQueryClient();

  const signOut: () => Promise<boolean> = useCallback(
    async () => {
      if (!authAddress) return false; // No session to sign out of

      setStatus('loading');
      if (!(await SignOut.execute())) {
        throw new Error('Failed to sign out.');
      }

      // Delete cached whoami state
      WhoAmI.invalidateQuery(queryClient);

      // Refetch session state to ensure it's cleared and perform side effects
      await refetchSession();
      setStatus('ready');
      onSignOut?.();
      return true;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authAddress],
  );

  const signIn: () => Promise<`0x${string}` | false> = useCallback(
    async () => {
      try {
        const { address: connectedAddress, connector } = getAccount();
        if (!connectedAddress || !connector) throw new Error('Cannot sign in without a connected wallet');

        setStatus('loading');

        // Ask user to sign message with their wallet
        const loginSignature = makeValidLoginSignature(connectedAddress);
        const signature = await signMessage({
          message: loginSignature.message,
        });

        // Verify signature
        if (
          !(await SignIn.execute({
            signature,
            walletAddress: loginSignature.address,
            signatureDateUtc: loginSignature.date,
            email: await getUserEmail(connector),
          }))
        ) {
          throw new Error('Error verifying SIWE signature');
        }

        // Refetch session state to populate global state and perform side effects
        const { data: refetchedSession } = await refetchSession();
        if (!refetchedSession) {
          throw new Error('Failed to get session after signing in');
        }

        onSignIn?.(refetchedSession);
        setStatus('ready');
        return refetchedSession;
      } catch (e) {
        if (isRejectedError(e)) {
          setStatus('rejected');
        } else {
          setStatus('error');
        }
        return false;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useAccount({
    onDisconnect: () => {
      // Sign out the user when a wallet disconnects.
      signOut();
    },
  });
  useEffect(() => {
    // Skip if we're still fetching session state from backend
    if (!authAddress) return;

    const { address: connectedAddress } = getAccount();
    // Skip if wallet isn't connected (i.e. initial page load)
    if (!connectedAddress) return;

    // If SIWE session no longer matches connected account, sign out
    if (!equalAddress(authAddress, connectedAddress)) {
      console.warn('Wallet account changed, signing out of SIWE session');
      signOut();
    }
  }, [authAddress, signOut]);

  useEffect(() => {
    setSIWEActions({ signIn, signOut });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signIn, signOut]);

  return (
    <>
      <WalletModal />
      <EagerConnectionWrapper />
      <EagerSIWEReconnector />
      {children}
    </>
  );
};
