import { Link } from '@chakra-ui/next-js';
import { Tooltip } from '@chakra-ui/react';
import clsx from 'clsx';
import { type ComponentProps } from 'react';
import { P, match } from 'ts-pattern';

import { GetFundPositions, GetPortfolio } from '@endaoment-frontend/api';
import { routes } from '@endaoment-frontend/routes';
import type { EntityPosition, EntityPositionSummary, FundDetails } from '@endaoment-frontend/types';
import { AllocationIcon, ArrowIcon, InfoIcon, TargetAllocationIcon } from '@endaoment-frontend/ui/icons';
import { Button, Card, EmptyStateBlock } from '@endaoment-frontend/ui/shared';
import { MiniLoadingDetails, MiniPortfolioDetails } from '@endaoment-frontend/ui/smart';
import { formatCurrency, formatNumber, formatUsdc } from '@endaoment-frontend/utils';

import { GrantableBalanceCard, OtherBalanceCard } from './GrantableBalanceCard';
import { RebalanceButton } from './RebalanceButton';
import { TargetAllocationOverview } from './TargetAllocationOverview';
import styles from './TargetAllocationSection.module.scss';
import {
  computeDeviationFromTarget,
  computeIsNearTargetAllocations,
  formatPercentageOfFundRebalancable,
} from './computeValues';
import { CalculateEntityRebalance, GetTargetAllocations } from './requests';
import type { TargetAllocation } from './types';
import { useOpenTargetAllocationModal } from './useTargetAllocationModal';

export const TargetAllocationSectionContent = ({
  fund,
  positionSummary,
}: {
  fund: Pick<FundDetails, 'id' | 'investedUsdc' | 'usdcBalance'>;
  positionSummary: EntityPositionSummary;
}) => {
  const { data: targetAllocations } = GetTargetAllocations.useQuery(['fund', fund.id]);
  const hasTargetAllocations = targetAllocations && targetAllocations.length > 0;
  const { data: rebalanceOps } = CalculateEntityRebalance.useQuery(['fund', fund.id, false], {
    enabled: hasTargetAllocations,
  });

  const openModal = useOpenTargetAllocationModal();

  const hasPositions = positionSummary.positions.length > 0;

  if (!hasTargetAllocations) {
    if (!hasPositions) {
      return (
        <EmptyStateBlock
          variation='allocation'
          title='Maximize your impact!'
          description='There is no Target Allocation on this fund. Set a target allocation to start allocating to available onchain, traditional, and impact-oriented portfolios.'
          vertical
          spread
          className={styles['empty']}>
          <Button
            onClick={() => openModal(fund.id)}
            filled
            variation='allocation'
            gradient
            size='medium'
            className={styles['allocation-button']}>
            <TargetAllocationIcon color='currentColor' />
            Set a Target Allocation
          </Button>
        </EmptyStateBlock>
      );
    }
    return (
      <EmptyStateBlock
        variation='allocation'
        title='Maximize your impact!'
        description='Assign a target allocation to match your positions to a set mix of portfolios.'
        vertical
        spread
        className={styles['empty']}>
        <div className={styles['allocation-buttons']}>
          <Button
            onClick={() => openModal(fund.id)}
            filled
            variation='allocation'
            gradient
            size='medium'
            className={styles['allocation-button']}
            float={false}>
            <TargetAllocationIcon color='currentColor' />
            Set a Target Allocation
          </Button>
          <Button
            as={Link}
            // TODO: add fund id to the route and scroll to that fund
            href={routes.app.portfolios({ view: 'positions' })}
            variation='portfolio'
            filled
            float={false}
            size='medium'
            className={styles['positions-button']}>
            Go To Positions ({positionSummary.positions.length})
            <ArrowIcon color='currentColor' />
          </Button>
        </div>
      </EmptyStateBlock>
    );
  }

  const allocationPortfolioIds = targetAllocations.map(t => t.portfolioId);
  const sumOfOtherPositions = positionSummary.positions
    .filter(p => !allocationPortfolioIds.includes(p.portfolio.id))
    .reduce((acc, v) => acc + v.currentMarketValue, 0n);

  const grantablePercentage = targetAllocations.reduce((acc, v) => acc - v.percentage, 1);

  const { isWithinTarget: isGrantableBalanceWithinTarget } = computeDeviationFromTarget(fund.usdcBalance, fund, {
    percentage: grantablePercentage ?? 0,
  });

  /* Assess if there are any positions that are individually outside of target but there are no rebalance ops for the fund */
  const isNearTargetAllocations =
    !rebalanceOps?.length &&
    isGrantableBalanceWithinTarget &&
    computeIsNearTargetAllocations(fund, positionSummary.positions, targetAllocations);

  return (
    <div className={styles['allocations__container']}>
      <TargetAllocationOverview fundId={fund.id} />
      <div className={styles.allocations}>
        {targetAllocations.map((allocation, index) => {
          const currentPosition = positionSummary?.positions.find(p => p.portfolio.id === allocation.portfolioId);
          return (
            <TargetAllocationItem
              allocation={allocation}
              currentPosition={currentPosition}
              fund={fund}
              key={allocation.portfolioId}
              index={index}
              noRebalanceOps={!rebalanceOps?.length}
            />
          );
        })}
        <div className={clsx(styles['allocation__listing'], styles['allocation__listing--grantable'])}>
          <GrantableBalanceCard
            fund={fund}
            grantablePercentage={grantablePercentage}
            noRebalanceOps={!rebalanceOps?.length}
          />
          <span>
            {formatNumber(grantablePercentage * 100, {
              digits: 4,
              fractionDigits: 2,
              stripZeros: true,
            })}
            %
          </span>
        </div>
        {sumOfOtherPositions > 0 && (
          <div className={clsx(styles['allocation__listing'], styles['allocation__listing--other'])}>
            <OtherBalanceCard balance={sumOfOtherPositions} />
          </div>
        )}
      </div>
      <div className={styles['allocation-button__container']}>
        <RebalanceButton fundId={fund.id} isNearTargetAllocations={isNearTargetAllocations} />
      </div>
    </div>
  );
};
export const TargetAllocationSectionContentFromFund = ({
  fund,
}: Pick<ComponentProps<typeof TargetAllocationSectionContent>, 'fund'>) => {
  const { data: positionsSummary } = GetFundPositions.useQuery([fund.id]);

  if (!positionsSummary) return <></>;

  return <TargetAllocationSectionContent fund={fund} positionSummary={positionsSummary} />;
};

export const TargetAllocationItem = ({
  allocation,
  currentPosition,
  fund,
  hidePercent = false,
  index,
  noRebalanceOps = false,
}: {
  allocation: TargetAllocation;
  currentPosition?: EntityPosition;
  fund: Pick<FundDetails, 'investedUsdc' | 'usdcBalance'>;
  hidePercent?: boolean;
  index: number;
  noRebalanceOps?: boolean;
}) => {
  const portfolioQuery = GetPortfolio.useQuery([allocation.portfolioId], {
    enabled: !currentPosition,
  });
  const portfolio = currentPosition ? currentPosition.portfolio : portfolioQuery.data;

  const { deviationPercentFromTarget, deviationAmountFromTarget, isWithinTarget } = computeDeviationFromTarget(
    currentPosition?.currentMarketValue,
    fund,
    allocation,
  );

  return (
    <div className={styles['allocation__listing']} data-index={index}>
      <Card className={styles['allocation']} noPadding>
        <Link href={routes.app.portfolio({ id: allocation.portfolioId })}>
          {portfolio ? <MiniPortfolioDetails portfolio={portfolio} padding={false} /> : <MiniLoadingDetails />}
        </Link>
        <hr />
        <div>
          <span>
            <AllocationIcon color='currentColor' width={18} height={18} />
            <b>{formatCurrency(formatUsdc(currentPosition?.currentMarketValue))}</b> position
            {fund.usdcBalance > 0 && (
              <b>({formatPercentageOfFundRebalancable(currentPosition?.currentMarketValue, fund)})</b>
            )}
          </span>
          <span>
            {match({ hidePercent, isWithinTarget, doesFundHaveMoney: fund.usdcBalance > 0, noRebalanceOps })
              .with(P.union({ hidePercent: true }, { doesFundHaveMoney: false }), () => <></>)
              .with({ isWithinTarget: true }, () => (
                <Tooltip label='This position is considered within the range of its target position.'>
                  <b className={styles['within-target']}>
                    Within target <InfoIcon color='currentColor' width={14} height={14} />
                  </b>
                </Tooltip>
              ))
              .with({ noRebalanceOps: true, isWithinTarget: false }, () => (
                <Tooltip label='The deviation of this position does not warrant a rebalance.'>
                  <b className={styles['near-target']}>
                    Near target <InfoIcon color='currentColor' width={14} height={14} />
                  </b>
                </Tooltip>
              ))
              .with({ isWithinTarget: false }, () => (
                <>
                  <b className={styles['off-target']}>
                    {deviationPercentFromTarget >= 0 ? '-' : '+'}
                    {formatCurrency(formatUsdc(deviationAmountFromTarget)).replace('-', '')}
                  </b>
                  from target
                  <b className={styles['off-target']}>
                    ({deviationPercentFromTarget >= 0 ? '-' : '+'}
                    {formatNumber(Math.abs(deviationPercentFromTarget) * 100, {
                      digits: 3,
                      fractionDigits: 2,
                      stripZeros: true,
                    })}
                    %)
                  </b>
                </>
              ))
              .exhaustive()}
          </span>
        </div>
      </Card>
      {!hidePercent && (
        <span>
          {formatNumber(allocation.percentage * 100, {
            digits: 2,
          })}
          %
        </span>
      )}
    </div>
  );
};
