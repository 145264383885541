import { Flex } from '@chakra-ui/react';
import { useQueryClient, type QueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import type { ReactNode } from 'react';
import { useState } from 'react';
import { useAsync, useMount } from 'react-use';
import { P, match } from 'ts-pattern';

import { GetDonationPledge, GetSubproject } from '@endaoment-frontend/api';
import { isFetchError } from '@endaoment-frontend/data-fetching';
import type {
  DonationRecipient,
  EntityLabel,
  StripeDonationPledgeDetails,
  StripePaymentType,
  UUID,
} from '@endaoment-frontend/types';
import { uuidSchema } from '@endaoment-frontend/types';
import {
  AwaitingIcon,
  BankIcon,
  CardIcon,
  CheckmarkIcon,
  CloseIcon,
  GlobeAltIcon,
  RoutingIcon,
} from '@endaoment-frontend/ui/icons';
import { Button, Loader, Pill, StepModal } from '@endaoment-frontend/ui/shared';
import { EntityCardWithLabel } from '@endaoment-frontend/ui/smart';
import { convertUsdcToNumber, formatCurrency } from '@endaoment-frontend/utils';

import styles from '../DonationWizard.module.scss';

import creditStyles from './CreditDonation.module.scss';

type ViewCreditDonationViewProps = Partial<Pick<StripeDonationPledgeDetails, 'id' | 'outcome' | 'stripeFeesCents'>> & {
  pledgedAmountCents: number;
  destination: DonationRecipient;
  paymentType?: StripePaymentType;
  netDonation?: number;
  protocolFees?: number;
  children?: ReactNode;
};

export const estimateCreditFees = ({
  pledgedAmountCents,
  paymentType,
  destinationType,
}: {
  pledgedAmountCents: number;
  paymentType?: StripePaymentType;
  destinationType: DonationRecipient['type'];
}) => {
  const pledgedAmount = pledgedAmountCents / 100;
  const stripeFee = match(paymentType)
    .with(P.union('Card', 'Apple Pay', 'Cash App', 'Google Pay'), () => {
      // 2.9% + $0.30
      return pledgedAmount * 0.029 + 0.3;
    })
    .with('Bank Transfer', () => {
      // 0.8%, with a $5 cap
      return Math.min(pledgedAmount * 0.008, 5);
    })
    .with('Other', () => {
      // 3% or $15, whichever is greater
      return Math.max(pledgedAmount * 0.03, 15);
    })
    .otherwise(() => undefined);
  const endaomentFee = match(destinationType)
    .with('fund', () => pledgedAmount * 0.005)
    .with('org', () => pledgedAmount * 0.005)
    .exhaustive();
  const estimatedProceeds = pledgedAmount - (stripeFee ?? 0) - endaomentFee;

  return {
    pledgedAmount,
    estimatedStripeFee: stripeFee,
    estimatedEndaomentFee: endaomentFee,
    estimatedProceeds,
  };
};

const paymentTypeThemeIconMap = (paymentType: StripePaymentType) =>
  match(paymentType)
    .with('Card', () => ({ icon: <CardIcon color='currentColor' />, color: 'fund' }))
    .with('Bank Transfer', () => ({ icon: <BankIcon color='currentColor' />, color: 'blue' }))
    .otherwise(() => ({ icon: <GlobeAltIcon color='currentColor' />, color: 'pink' }));

const PaymentTypeSpan = ({ paymentType }: { paymentType: StripePaymentType }) => (
  <div
    className={clsx(
      creditStyles['payment-type'],
      creditStyles[`payment-type--${paymentTypeThemeIconMap(paymentType).color}`],
    )}>
    {paymentTypeThemeIconMap(paymentType).icon}
    {paymentType}
  </div>
);

export const ViewCreditDonation = ({
  id,
  destination,
  paymentType,
  pledgedAmountCents,
  stripeFeesCents,
  netDonation,
  protocolFees,
  outcome,
  onClose,
  children,
}: ViewCreditDonationViewProps & { onClose: () => void }) => {
  const { pledgedAmount, estimatedProceeds, estimatedEndaomentFee, estimatedStripeFee } = estimateCreditFees({
    pledgedAmountCents,
    paymentType,
    destinationType: destination.type,
  });

  const registered = netDonation !== undefined;

  return (
    <>
      <EntityCardWithLabel label='Donating to' entity={destination} />
      {children}
      <div className={styles['donation-info']}>
        <div>
          <h4>Donation</h4>
          <h4>{formatCurrency(pledgedAmount)}</h4>
        </div>
        {registered ? (
          !!stripeFeesCents && (
            <div>
              <h4>Proceeds</h4>
              <h4>{formatCurrency(netDonation)}</h4>
            </div>
          )
        ) : (
          <div>
            <h4>Estimated Proceeds</h4>
            <h4>{formatCurrency(estimatedProceeds)}</h4>
          </div>
        )}
        <div>
          <h4>Method</h4>
          <h4>
            <PaymentTypeSpan paymentType={paymentType ?? 'Other'} />
          </h4>
        </div>
        <hr />
        {((!!registered && !!stripeFeesCents) || !registered) && (
          <>
            <div>
              {stripeFeesCents ? (
                <>
                  <h6>Processing Fee</h6>
                  <h6>{formatCurrency(stripeFeesCents / 100)}</h6>
                </>
              ) : (
                <>
                  <h6>Estimated Processing Fee</h6>
                  <h6>{formatCurrency(estimatedStripeFee)}</h6>
                </>
              )}
            </div>
            {(estimatedEndaomentFee > 0 || protocolFees !== undefined) && (
              <div>
                <h6>Endaoment Fee</h6>
                <h6>
                  {protocolFees !== undefined ? formatCurrency(protocolFees) : formatCurrency(estimatedEndaomentFee)}
                </h6>
              </div>
            )}
            <hr />
          </>
        )}

        {!!registered && (
          <div className={styles['info']}>
            {match(outcome)
              .with('Success', () => (
                <Pill variation='green' size='tiny'>
                  <CheckmarkIcon strokeWidth={5} />
                  Completed
                </Pill>
              ))
              .with('Failure', () => (
                <>
                  <Pill variation='red' size='tiny'>
                    <CloseIcon />
                    Transfer Failed
                  </Pill>
                  <p>
                    Please{' '}
                    <a href='mailto:help@endaoment.org' className={styles['mailto']}>
                      contact our team
                    </a>{' '}
                    for more information.
                  </p>
                </>
              ))
              .with('Pending', () => (
                <Flex alignItems='center' justifyContent='center' gap='0.75rem' w='100%'>
                  <Loader />
                  Processing Donation
                </Flex>
              ))
              .with('AwaitingAssets', () => (
                <>
                  <Pill variation='purple' className={clsx(styles['disclaimer'], styles['awaiting__disclaimer'])}>
                    We are awaiting assets from our payment processor. We'll email you once your donation arrives safely
                    at Endaoment shortly.
                  </Pill>
                  <div className={styles['awaiting']}>
                    <AwaitingIcon />
                    Awaiting Donation...
                  </div>
                  <div className={styles.info}>
                    <hr />
                    You can safely close this modal while the operation occurs. Check your email inbox for more details,
                    or{' '}
                    <a href='mailto:help@endaoment.org' className={styles['mailto']}>
                      contact our team
                    </a>
                    .
                  </div>

                  <Flex flexDirection='row' className={styles['modal-actions']}>
                    <Button onClick={onClose} size='medium' float={false}>
                      Close
                    </Button>
                    <Button
                      as='a'
                      href={`mailto:admin@endaoment.org?subject=Endaoment: Cash Donation Cancel Request - ID ${id}`}
                      variation='red'
                      size='medium'
                      float={false}>
                      Cancel Donation
                    </Button>
                  </Flex>
                </>
              ))
              .otherwise(() => (
                <>
                  <Pill variation='green' className={clsx(styles['disclaimer'], styles['awaiting__disclaimer'])}>
                    We have received your donation and are migrating it onchain. You will receive an email when this is
                    completed, usually within one business day.
                  </Pill>
                  <div className={styles['routing']}>
                    <RoutingIcon />
                    Routing Donation...
                  </div>
                  {!!onClose && (
                    <>
                      <div className={styles.info}>
                        <hr />
                        You can safely close this modal while the operation occurs. Check your email inbox for more
                        details, or <a href='mailto:help@endaoment.org'>contact our team</a>.
                      </div>

                      <Flex flexDirection='row' className={styles['modal-actions']}>
                        <Button onClick={onClose} size='medium' float={false}>
                          Close
                        </Button>
                        <Button
                          as='a'
                          href={`mailto:admin@endaoment.org?subject=Endaoment: Cash Donation Cancel Request - ID ${id}`}
                          variation='red'
                          size='medium'
                          float={false}>
                          Cancel Donation
                        </Button>
                      </Flex>
                    </>
                  )}
                </>
              ))}
          </div>
        )}
      </div>
    </>
  );
};

const convertEntityLabelToDonationRecipient = async (
  entityLabel: EntityLabel,
  queryClient?: QueryClient,
): Promise<DonationRecipient> => {
  return match(entityLabel)
    .with({ type: 'fund' }, entityLabel => ({ type: 'fund', id: entityLabel.id }) as const)
    .with({ type: 'org' }, entityLabel => ({ type: 'org', einOrId: entityLabel.id }) as const)
    .with({ type: 'subproject' }, async entityLabel => {
      if (!queryClient) throw new Error('Fetching the org for a subproject requires a queryClient');

      const subproject = await GetSubproject.fetchFromQueryClient(queryClient, [entityLabel.id]);
      return {
        type: 'org',
        einOrId: subproject.sponsorOrgEin ?? subproject.sponsorOrgId,
        subprojectId: entityLabel.id,
      } as const;
    })
    .exhaustive();
};

const MAX_PENDING_RETRIES = 20;

/**
 * Used for when a User is redirected back from Stripe after completing a donation
 * shows when the user has a pledgeId in the URL
 */
export const ViewCreditDonationModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [pledgeId, setPledgeId] = useState<UUID | undefined>();
  const [pendingRetries, setPendingRetries] = useState(0);

  useMount(() => {
    const params = new URLSearchParams(window.location.search);
    const pledgeIdParse = uuidSchema.safeParse(params.get('pledgeId'));
    if (!pledgeIdParse.success) return;

    setPledgeId(pledgeIdParse.data);
    setIsOpen(true);
  });

  const queryClient = useQueryClient();
  const { data } = GetDonationPledge.useQuery([pledgeId as UUID], {
    enabled: !!pledgeId,
    onSuccess: data => {
      // If the donation is pending, we want to poll the donation until it is no longer pending
      if (data.outcome === 'Pending' && pendingRetries < MAX_PENDING_RETRIES) {
        setPendingRetries(prev => {
          setTimeout(() => {
            if (!pledgeId) return;
            GetDonationPledge.invalidateQuery(queryClient, [pledgeId]);
          }, 5000);
          return prev + 1;
        });
      }
    },
    retry: (n, e) => {
      // Retry logic needs to be special due to this call needing the 409 code to trigger a retry
      if (
        isFetchError(e) &&
        e.statusCode &&
        // We do not want to retry on 4xx errors
        e.statusCode >= 400 &&
        e.statusCode < 500 &&
        // The exception is 408, which is a timeout
        e.statusCode !== 408 &&
        // Additional case of 409, which is only for this call
        e.statusCode !== 409
      )
        return false;
      // Max 20 retries
      return n < 20;
    },
    // Retry every 3 seconds
    retryDelay: 3000,
    staleTime: 20 * 1000, // Refreshed cached value every 20 seconds
  });

  const [viewProps, setViewProps] = useState<ViewCreditDonationViewProps>();

  useAsync(async () => {
    if (!data || data.type !== 'StripeDonationPledge') return;

    const destination = await convertEntityLabelToDonationRecipient(data.destinationEntity, queryClient);

    setViewProps({
      id: data.id,
      destination,
      paymentType: data.selectedPaymentMethod ?? undefined,
      outcome: data.outcome === 'Pending' && pendingRetries >= MAX_PENDING_RETRIES ? 'Failure' : data.outcome,
      pledgedAmountCents: data.pledgedAmountCents,
      stripeFeesCents: data.stripeFeesCents,
      netDonation: convertUsdcToNumber(data.netDonationUsdc),
      protocolFees: convertUsdcToNumber(data.protocolFeesUsdc),
    });
  }, [data, pendingRetries]);

  const onClose = () => setIsOpen(false);

  return (
    <StepModal isOpen={isOpen} onClose={onClose}>
      <StepModal.StepsWrapper>
        <StepModal.Step onClose={onClose} header='Cash Donation'>
          {viewProps ? (
            <ViewCreditDonation
              {...viewProps}
              pledgedAmountCents={viewProps.pledgedAmountCents ?? 0}
              onClose={onClose}
            />
          ) : (
            <Loader size='l' />
          )}
        </StepModal.Step>
      </StepModal.StepsWrapper>
    </StepModal>
  );
};
