import clsx from 'clsx';

import { StarIcon } from '@endaoment-frontend/ui/icons';
import { formatCurrency, formatUsdc, getFundTotalValues, parseHtml, stringToColor } from '@endaoment-frontend/utils';

import { Avatar } from '../avatar';
import { Card } from '../card';
import { Loader } from '../loader';

import styles from './FundCard.module.scss';
import type { FundCardProps } from './FundCard.types';

export const FundCard = ({ fund, variation = 'default', className }: FundCardProps) => {
  const { totalImpact } = getFundTotalValues(fund);

  return (
    <Card className={clsx(styles['fund-card'], variation === 'compact' && styles['fund-card--compact'], className)}>
      <header>
        {typeof fund.v2ContractAddress === 'string' && (
          <Avatar className={styles['fund-card__avatar']}>
            {fund.logo ? (
              <img src={fund.logo} alt={`${fund.name} logo`} />
            ) : (
              <StarIcon
                startColor={stringToColor(fund.name)}
                endColor={stringToColor(fund.name.split('').reverse().join(''))}
              />
            )}
          </Avatar>
        )}
        {typeof fund.v2ContractAddress !== 'string' && <Loader size='l' />}

        <h3>{fund.name}</h3>
        <span>
          Managed by {fund.advisor.firstName} {fund.advisor.lastName}
        </span>
      </header>
      <article>{variation === 'default' && parseHtml(fund.description)}</article>
      <footer>
        {variation === 'default' && <span>Total impact</span>}
        {!fund.id && <strong>Creating...</strong>}
        {!!fund.id && typeof fund.v2ContractAddress !== 'string' && <strong>Deploying...</strong>}
        {!!fund.id && typeof fund.v2ContractAddress === 'string' && (
          <strong>{formatCurrency(formatUsdc(totalImpact))}</strong>
        )}
      </footer>
    </Card>
  );
};
