import { getAccount } from '@wagmi/core';
import { atom, useAtomValue } from 'jotai';
import { atomsWithQuery } from 'jotai-tanstack-query';
import { match } from 'ts-pattern';
import { useAccount } from 'wagmi';

import { WhoAmI } from '@endaoment-frontend/api';
import { TIME_ONE_MINUTE_IN_SECONDS } from '@endaoment-frontend/constants';
import { isFetchError } from '@endaoment-frontend/data-fetching';
import { equalAddress } from '@endaoment-frontend/utils';

type SIWEStatus = 'error' | 'loading' | 'ready' | 'rejected' | 'success';
export const siweStatusAtom = atom<SIWEStatus>('ready');
export const [, siweSessionResultAtom] = atomsWithQuery(get => ({
  queryKey: ['SIWESession'],
  queryFn: async () => {
    const connectedAddress = getAccount()?.address;
    if (!connectedAddress) return null;

    let authInfo: Awaited<ReturnType<typeof WhoAmI.execute>>;
    try {
      authInfo = await WhoAmI.execute();
    } catch (e) {
      // Case for no auth token
      if (isFetchError(e) && e.statusCode === 401) {
        console.warn('User does not have Auth token\n', e);
        return null;
      }
      throw e;
    }

    if (
      !equalAddress(authInfo.wallet, connectedAddress) || // Ensure connected wallet is the same as the one authenticated
      Date.now() / 1000 > authInfo.exp // Compare expiration date (in seconds) with current timestamp
    )
      return null;

    // TODO: Refactor this ATOM so it returns the entire user object instead of just the wallet address.
    return authInfo.wallet;
  },
  refetchOnMount: true,
  refetchOnWindowFocus: false,
  staleTime: 10 * TIME_ONE_MINUTE_IN_SECONDS * 1000,
  keepPreviousData: true,
}));
export const siweActionsAtom = atom<{ signIn: () => void; signOut: () => void }>({
  signIn: () => {
    throw new Error('Web3Provider has not been initialized');
  },
  signOut: () => {
    throw new Error('Web3Provider has not been initialized');
  },
});

export const useAuth = () => {
  const stateStatus = useAtomValue(siweStatusAtom);
  const { data: authAddress, isError } = useAtomValue(siweSessionResultAtom);
  const { signIn, signOut } = useAtomValue(siweActionsAtom);

  const { address: connectedAddress, isConnected, connector } = useAccount();

  const isSignedIn = !!authAddress && equalAddress(authAddress, connectedAddress);
  const computedStatus = match({ stateStatus, isSignedIn, isError })
    .returnType<SIWEStatus>()
    .with({ isSignedIn: true }, () => 'success')
    .with({ isError: true }, () => 'error')
    .otherwise(() => stateStatus);

  const handleSignIn = () => {
    if (isSignedIn) return;
    signIn();
  };
  const handleSignOut = () => {
    if (!isSignedIn) return;
    signOut();
  };

  return {
    signIn: handleSignIn,
    signOut: handleSignOut,
    authAddress: authAddress ?? undefined,
    connectedAddress,
    connector,
    isSignedIn,
    isConnected,
    isSuccess: computedStatus === 'success',
    isReady: computedStatus === 'ready',
    isLoading: computedStatus === 'loading',
    isRejected: computedStatus === 'rejected',
    isError: computedStatus === 'error',
  } as const;
};
