import type { As } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import clsx from 'clsx';

import styles from './Pill.module.scss';
import type { PillProps } from './Pill.types';

export const Pill = <Tag extends As>({
  children,
  className,
  rectangle,
  outline,
  variation,
  fill,
  size = 'small',
  onClick,
  as,
  pad,
  ...props
}: PillProps<Tag>) => {
  return (
    <Box
      as={as ?? 'span'}
      className={clsx(
        styles['pill'],
        className,
        onClick && styles['selectable'],
        outline && styles['outline'],
        rectangle && styles['rectangle'],
        pad && styles['pad'],
        fill && styles['fill'],
        styles[`size-${size}`],
        variation && styles[`color-${variation}`],
      )}
      onClick={onClick}
      {...props}>
      {children}
    </Box>
  );
};
