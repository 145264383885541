import clsx from 'clsx';

import styles from './Paginator.module.scss';
import type { PaginatorPageProps, PaginatorProps } from './Paginator.types';

const Page = ({ isCurrent, variation, onSelect }: PaginatorPageProps) => (
  <div
    className={clsx(
      styles['paginator__page'],
      isCurrent && styles['paginator__page--current'],
      variation === 'fund' && styles['paginator__page--fund-style'],
    )}
    onClick={onSelect}
    role='button'
    onKeyDown={e => e.key === 'Enter' && onSelect()}
    tabIndex={0}
  />
);

export const Paginator = ({ pages, current, variation = 'org', className, onPageSelect }: PaginatorProps) => {
  return (
    <div className={clsx(styles['paginator'], className)}>
      {Array.from({ length: pages }).map((_, page) => (
        <Page key={page} isCurrent={current === page} variation={variation} onSelect={() => onPageSelect(page)} />
      ))}
    </div>
  );
};
