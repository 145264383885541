import type { QueryClient } from '@tanstack/react-query';
import { P, match } from 'ts-pattern';
import { z } from 'zod';

import { RequestHandler } from '@endaoment-frontend/data-fetching';
import type {
  Address,
  AdminDonation,
  CreateDonationInput,
  Donation,
  DonationPledgeDetails,
  DonationRecipient,
  LiquidateOtcDonationInput,
  NecDonationInput,
  StockTicker,
  SupportedBroker,
  UUID,
  UserIdentityInfo,
} from '@endaoment-frontend/types';
import {
  adminDonationSchema,
  donationPledgeDetailsSchema,
  donationSchema,
  stockTickerSchema,
  supportedBrokerSchema,
  uuidSchema,
} from '@endaoment-frontend/types';

import { GetFund } from './funds';
import { GetOrg } from './orgs';

export const GetTotalDonations = new RequestHandler('GetTotalDonations', fetch => async () => {
  try {
    return z.string().parse(await fetch('/v1/donations/total'));
  } catch {
    return '0';
  }
});

export const GetDonationsCount = new RequestHandler('GetDonationsCount', fetch => async () => {
  try {
    return z.number({ coerce: true }).parse(await fetch('/v1/donations/count'));
  } catch {
    return 0;
  }
});

export const GetDestinationDonations = new RequestHandler(
  'GetDestinationDonations',
  fetch =>
    async (id: UUID): Promise<Array<Donation>> => {
      const res = await fetch(`/v1/donations/destination/${id}`);
      return z.array(donationSchema).parse(res);
    },
);

export const RegisterDonation = new RequestHandler(
  'RegisterDonation',
  fetch =>
    async (donationTransactionHash: Address, chainId: number, args: CreateDonationInput): Promise<Donation> => {
      const { donorIdentity, subprojectId, taxReceipt, shareMyEmail } = args;
      const res = await fetch('/v1/donations', {
        method: 'POST',
        body: {
          donationTransactionHash,
          donorIdentity,
          subprojectId,
          taxReceipt,
          shareMyEmail,
          chainId,
        },
      });
      return donationSchema.parse(res);
    },
  { isUserSpecificRequest: true },
);

export const GetAdminDonations = new RequestHandler(
  'GetAdminDonations',
  fetch =>
    async (count?: number, offset?: number, entityName?: string): Promise<Array<AdminDonation>> => {
      const res = await fetch('/v1/donations/all', {
        params: {
          count,
          offset,
          entityName,
        },
      });
      return z.array(adminDonationSchema).parse(res);
    },
);

export const PledgeOTCDonation = new RequestHandler('PledgeOTCDonation', fetch => async (args: NecDonationInput) => {
  const res = await fetch('/v1/donation-pledges/crypto', {
    method: 'POST',
    body: args satisfies NecDonationInput,
  });
  return donationSchema.parse(res);
});

export const AdminLiquidateOtcDonation = new RequestHandler(
  'AdminLiquidateOtcDonation',
  fetch => async (donationId: UUID, args: LiquidateOtcDonationInput) => {
    return fetch(`/v1/donation-pledges/${donationId}/liquidate`, {
      method: 'POST',
      body: args satisfies LiquidateOtcDonationInput,
    });
  },
);

export const GetStockTickers = new RequestHandler(
  'GetStockTickers',
  fetch =>
    async (search?: string): Promise<Array<StockTicker>> => {
      const res = await fetch('/v1/stocks/tickers', {
        query: {
          ticker: search,
        },
      });

      return z.object({ tickers: z.array(stockTickerSchema) }).parse(res).tickers;
    },
  {
    makeMockEndpoints: ({ baseURL }) => ({
      default: `${baseURL}/v1/stocks/tickers`,
    }),
  },
);

export const GetStockTickerPrice = new RequestHandler(
  'GetStockTickerPrice',
  fetch =>
    async (ticker?: string): Promise<number> => {
      if (!ticker) throw new Error('Ticker is required');

      const res = await fetch('/v1/stocks/price', {
        query: {
          ticker,
        },
      });
      return z.object({ price: z.number() }).parse(res).price;
    },
  {
    makeMockEndpoints: ({ baseURL }) => ({
      default: `${baseURL}/v1/stocks/price`,
    }),
  },
);

export const GetSupportedBrokers = new RequestHandler(
  'GetSupportedBrokers',
  fetch =>
    async (search?: string): Promise<Array<SupportedBroker>> => {
      const res = await fetch('/v1/stocks/tgb-brokers');
      const brokers = z.object({ brokers: z.array(supportedBrokerSchema) }).parse(res).brokers;

      if (!search) return brokers;

      return brokers.filter(
        broker =>
          broker.name.toLowerCase().includes(search.toLowerCase()) ||
          broker.label.toLowerCase().includes(search.toLowerCase()),
      );
    },
  {
    makeMockEndpoints: ({ baseURL }) => ({
      default: `${baseURL}/v1/stocks/tgb-brokers`,
    }),
  },
);

type StockPledgeDonorData = {
  receiptEmail: string;
  firstName: string;
  lastName: string;
  addressLine1: string;
  addressLine2?: string;
  country: string;
  state: string;
  city: string;
  zipcode: string;
  phoneNumber: string;
};
type StockPledgeBrokerData = {
  brokerName: string;
  customBrokerName?: string;
  brokerageAccountNumber: string;
  brokerContactName: string;
  brokerEmail: string;
  brokerPhone: string;
};
type StockPledgeInput = {
  idempotencyKey: string;
  updateIdentity: boolean;
  shareMyEmail: boolean;
  tgbPledgeData: {
    shares: number;
    ticker: string;
    donorData: StockPledgeDonorData;
    brokerData: StockPledgeBrokerData;
    signature: string;
  };
  receivingEntityType: 'fund' | 'org' | 'subproject';
  receivingEntityId: UUID;
  isRebalanceRequested: boolean;
};

export const CreateStockDonationPledge = new RequestHandler(
  'CreateStockDonationPledge',
  fetch =>
    async ({
      idempotencyKey,
      recipient,
      queryClient,
      brokerData,
      donorData,
      shares,
      ticker,
      signature,
      shareMyEmail,
      updateIdentity,
      isRebalanceRequested,
    }: Pick<StockPledgeInput, 'idempotencyKey' | 'isRebalanceRequested' | 'shareMyEmail' | 'updateIdentity'> &
      StockPledgeInput['tgbPledgeData'] & {
        recipient: DonationRecipient;
        queryClient: QueryClient;
      }) => {
      const { receivingEntityType, receivingEntityId } = await match<
        DonationRecipient,
        Promise<Pick<StockPledgeInput, 'receivingEntityId' | 'receivingEntityType'>>
      >(recipient)
        .with({ type: 'org', subprojectId: P.not(P.nullish) }, async recipient => ({
          receivingEntityType: 'subproject',
          receivingEntityId: recipient.subprojectId,
        }))
        .with({ type: 'org' }, async recipient => ({
          receivingEntityType: 'org',
          receivingEntityId: (await GetOrg.fetchFromQueryClient(queryClient, [recipient.einOrId])).id,
        }))
        .with({ type: 'fund' }, async recipient => ({
          receivingEntityType: 'fund',
          receivingEntityId: recipient.id,
        }))
        .exhaustive();

      const tgbPledgeData: StockPledgeInput['tgbPledgeData'] = {
        brokerData,
        donorData,
        shares,
        ticker,
        signature,
      };

      const res = await fetch('v1/donation-pledges/tgb-stock', {
        method: 'POST',
        body: {
          receivingEntityId,
          receivingEntityType,
          tgbPledgeData,
          shareMyEmail,
          updateIdentity,
          idempotencyKey,
          isRebalanceRequested,
        } satisfies StockPledgeInput,
      });
      return z
        .object({
          id: uuidSchema,
        })
        .parse(res).id;
    },
  {
    augmentArgs: ([input]) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { queryClient, ...inputWithoutQueryClient } = input;
      return [inputWithoutQueryClient];
    },
  },
);

type CryptoDonationPledgeInput = {
  cryptoGiven: {
    inputAmount: string;
    tokenId: number;
  };
  otcDonationTransactionHash: string;
  receivingEntityType: 'fund' | 'org' | 'subproject';
  receivingEntityId: UUID;
  donorIdentity?: UserIdentityInfo;
  updateIdentity?: boolean;
  shareMyEmail?: boolean;
  isRebalanceRequested: boolean;
};

export const CreateCryptoDonationPledge = new RequestHandler(
  'CreateCryptoDonationPledge',
  fetch =>
    async ({
      cryptoGiven,
      otcDonationTransactionHash,
      donorIdentity,
      shareMyEmail,
      updateIdentity,
      recipient,
      queryClient,
      isRebalanceRequested,
    }: Omit<CryptoDonationPledgeInput, 'receivingEntityId' | 'receivingEntityType'> & {
      recipient: DonationRecipient;
      queryClient: QueryClient;
    }) => {
      const { receivingEntityType, receivingEntityId } = await match<
        DonationRecipient,
        Promise<Pick<CryptoDonationPledgeInput, 'receivingEntityId' | 'receivingEntityType'>>
      >(recipient)
        .with({ type: 'org', subprojectId: P.not(P.nullish) }, async recipient => ({
          receivingEntityType: 'subproject',
          receivingEntityId: recipient.subprojectId,
        }))
        .with({ type: 'org' }, async recipient => ({
          receivingEntityType: 'org',
          receivingEntityId: (await GetOrg.fetchFromQueryClient(queryClient, [recipient.einOrId])).id,
        }))
        .with({ type: 'fund' }, async recipient => ({
          receivingEntityType: 'fund',
          receivingEntityId: recipient.id,
        }))
        .otherwise(() => {
          throw new Error('Invalid recipient');
        });

      const res = await fetch('v1/donation-pledges/crypto', {
        method: 'POST',
        body: {
          cryptoGiven,
          otcDonationTransactionHash,
          donorIdentity,
          shareMyEmail,
          updateIdentity,
          receivingEntityId,
          receivingEntityType,
          isRebalanceRequested,
        } satisfies CryptoDonationPledgeInput,
      });
      return z
        .object({
          id: uuidSchema,
        })
        .parse(res).id;
    },
  {
    augmentArgs: ([input]) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { queryClient, ...inputWithoutQueryClient } = input;
      return [inputWithoutQueryClient];
    },
  },
);

type DafMigrationPledgeInput = {
  pledgedAmountMicroDollars: string;
  receivingFundId: string;
  idempotencyKey: string;
};

export const CreateDafMigrationPledge = new RequestHandler(
  'CreateDafMigrationPledge',
  fetch =>
    async ({ receivingFundId, pledgedAmountMicroDollars, idempotencyKey }: DafMigrationPledgeInput) => {
      const res = await fetch('v1/donation-pledges/daf-migration', {
        method: 'POST',
        body: {
          idempotencyKey,
          pledgedAmountMicroDollars,
          receivingFundId,
        } satisfies DafMigrationPledgeInput,
      });
      return z
        .object({
          id: uuidSchema,
        })
        .parse(res).id;
    },
);

type StripeDonationInput = {
  paymentMethod: 'Card' | 'Custom' | 'UsBankTransfer';
  pledgedAmountCents: number;
  donorIdentity?: UserIdentityInfo;
  updateIdentity?: boolean;
  shareMyEmail?: boolean;
  receivingEntityType: 'fund' | 'org' | 'subproject';
  receivingEntityId: UUID;
  isRebalanceRequested: boolean;
};
const stripeDonationResponseSchema = z.object({
  id: uuidSchema,
  clientSecret: z.string(),
});
export const StartCashDonation = new RequestHandler(
  'StartCashDonation',
  fetch =>
    async ({
      pledgedAmountCents,
      recipient,
      queryClient,
      donorIdentity,
      shareMyEmail,
      updateIdentity,
      isRebalanceRequested,
    }: Pick<
      StripeDonationInput,
      'donorIdentity' | 'isRebalanceRequested' | 'pledgedAmountCents' | 'shareMyEmail' | 'updateIdentity'
    > & {
      recipient: DonationRecipient;
      queryClient: QueryClient;
    }): Promise<z.infer<typeof stripeDonationResponseSchema>> => {
      const { receivingEntityType, receivingEntityId } = await match<
        DonationRecipient,
        Promise<Pick<StripeDonationInput, 'receivingEntityId' | 'receivingEntityType'>>
      >(recipient)
        .with({ type: 'org', subprojectId: P.not(P.nullish) }, async recipient => ({
          receivingEntityType: 'subproject',
          receivingEntityId: recipient.subprojectId,
        }))
        .with({ type: 'org' }, async recipient => ({
          receivingEntityType: 'org',
          receivingEntityId: (await GetOrg.fetchFromQueryClient(queryClient, [recipient.einOrId])).id,
        }))
        .with({ type: 'fund' }, async recipient => ({
          receivingEntityType: 'fund',
          receivingEntityId: recipient.id,
        }))
        .exhaustive();

      const res = await fetch('v1/donation-pledges/stripe', {
        method: 'POST',
        body: {
          paymentMethod: 'Custom',
          pledgedAmountCents,
          donorIdentity,
          shareMyEmail,
          updateIdentity,
          receivingEntityType,
          receivingEntityId,
          isRebalanceRequested,
        } satisfies StripeDonationInput,
      });
      return stripeDonationResponseSchema.parse(res);
    },
  {
    augmentArgs: ([input]) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { queryClient, ...inputWithoutQueryClient } = input;
      return [inputWithoutQueryClient];
    },
  },
);

export const GetDonationPledge = new RequestHandler(
  'GetDonationPledge',
  fetch =>
    async (id: UUID): Promise<DonationPledgeDetails> => {
      const res = await fetch(`/v1/donation-pledges/${id}`);
      return donationPledgeDetailsSchema.parse(res);
    },
);

export const GetRecipient = new RequestHandler(
  'GetRecipient',
  () => async (queryClient: QueryClient, recipient?: DonationRecipient) => {
    if (!recipient) throw new Error('Recipient must be provided');
    return match(recipient)
      .with(
        { type: 'org' },
        async recipient =>
          ({
            ...recipient,
            type: 'org',
            entity: await GetOrg.fetchFromQueryClient(queryClient, [recipient.einOrId]),
          }) as const,
      )
      .with(
        { type: 'fund' },
        async recipient =>
          ({
            ...recipient,
            type: 'fund',
            entity: await GetFund.fetchFromQueryClient(queryClient, [recipient.id]),
          }) as const,
      )
      .exhaustive();
  },
  {
    augmentArgs: ([, recipient]) => [recipient],
  },
);
