import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Flex } from '@chakra-ui/react';
import clsx from 'clsx';
import { match } from 'ts-pattern';

import { defaults } from '@endaoment-frontend/config';
import type { FundListing, PledgeOutcome, UUID } from '@endaoment-frontend/types';
import { AwaitingIcon, CheckmarkIcon, CloseIcon, InfoIcon, MinusIcon, PlusIcon } from '@endaoment-frontend/ui/icons';
import { Button, Pill, Tooltip } from '@endaoment-frontend/ui/shared';
import { EntityCardWithLabel } from '@endaoment-frontend/ui/smart';
import { formatCurrency } from '@endaoment-frontend/utils';

import styles from './MigrateFund.module.scss';
import { MigrateFundInstructions } from './MigrateFundInstructions';

export const MigrateFundView = ({
  fund,
  amount,
  fundMigrationId,
  outcome,
  onClose,
}: {
  fund: FundListing;
  amount: number;
  fundMigrationId: UUID;
  outcome?: PledgeOutcome;
  onClose: () => void;
}) => {
  const endaomentFee = (amount * defaults.fees.donationBps) / 10000;

  return (
    <>
      <EntityCardWithLabel label='Migrating to' entity={{ type: 'fund', id: fund.id }} />

      <div className={styles['donation-info']}>
        <div>
          <h4>Amount</h4>
          <h4>{formatCurrency(amount)}</h4>
        </div>
        <div>
          <h6>
            <div>
              Endaoment Fee
              <Tooltip content='Fee charged for processing DAF migration'>
                <InfoIcon color='currentColor' width='14px' />
              </Tooltip>
            </div>
          </h6>
          <h6>{formatCurrency(endaomentFee)}</h6>
        </div>
      </div>
      <Accordion allowToggle>
        <AccordionItem className={styles['instruction-accordion']}>
          {({ isExpanded }) => (
            <>
              <AccordionButton>
                {isExpanded ? 'Hide' : 'Show'} Instructions
                {isExpanded ? <MinusIcon /> : <PlusIcon />}
              </AccordionButton>
              <AccordionPanel>
                <MigrateFundInstructions fund={fund} amount={amount} />
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
      {match(outcome)
        .with('Success', () => (
          <div className={styles.info}>
            <Pill variation='green' size='tiny'>
              <CheckmarkIcon strokeWidth={5} />
              Completed
            </Pill>
            {/* TODO: Collect transaction activity from resulting donation and provide transaction link */}
          </div>
        ))
        .with('Failure', () => (
          <div className={styles.info}>
            <Pill variation='red' size='tiny'>
              <CloseIcon />
              Migration Failed
            </Pill>
            <Box mt='1rem'>
              Please{' '}
              <a href='mailto:help@endaoment.org' className={styles['mailto']}>
                contact our team
              </a>{' '}
              for more information.
            </Box>
          </div>
        ))
        .otherwise(() => (
          <>
            <div className={styles.awaiting}>
              <Pill variation='purple' className={clsx(styles.notice, styles.disclaimer, styles.awaiting__disclaimer)}>
                Thank you for submitting your grant. We will credit this fund and email you once the grant arrives.
              </Pill>
            </div>
            <div className={styles.awaiting}>
              <AwaitingIcon /> Awaiting Grant...
            </div>
            <hr />
            <div className={styles.info}>
              <p>
                You can safely close this modal while the operation occurs. Check your email inbox for more details, or{' '}
                <a href='mailto:help@endaoment.org' className={styles['mailto']}>
                  contact our team
                </a>
                .
              </p>
              <Flex justifyContent='center' gap='1rem' mt='1rem' w='100%' className={styles['modal-actions']}>
                <Button onClick={onClose}>Close</Button>
                <Button
                  as='a'
                  href={`mailto:admin@endaoment.org?subject=Endaoment: DAF Migration Cancel Request - ID ${fundMigrationId}`}
                  variation='red'>
                  Cancel Migration
                </Button>
              </Flex>
            </div>
          </>
        ))}
    </>
  );
};
