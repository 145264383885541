import { addBreadcrumb, captureException, startTransaction } from '@sentry/nextjs';
import type { WriteContractResult } from '@wagmi/core';
import { getWalletClient, waitForTransaction } from '@wagmi/core';
import { useCallback, useState } from 'react';
import type { TransactionReceipt, WalletClient } from 'viem';

import { isRejectedError, useAuth, useWalletModal } from '@endaoment-frontend/authentication';
import type { Address, TransactionStatus } from '@endaoment-frontend/types';

import type { TransactionActionKey } from './transactions';
import { TransactionListUpdatedEvent, useManageTransactionList } from './useTransactionList';

type PreTransactionCallback<T, Args> = (params: {
  args: Args;
  /** Address that executed the transaction */
  address: Address;
  walletClient: WalletClient;
  chainId: number;
  sentryTransaction: ReturnType<typeof startTransaction>;
}) => Promise<T>;
type PostTransactionCallback<T, Args> = (params: {
  args: Args;
  /** Address that executed the transaction */
  address: Address;
  transaction: WriteContractResult;
  chainId: number;
  sentryTransaction: ReturnType<typeof startTransaction>;
}) => T;

export type ReactionHookOptions<Args> = {
  actionName: TransactionActionKey;
  createTransaction: PreTransactionCallback<WriteContractResult, Args>;
  createDescription: PostTransactionCallback<string, Args>;
  createExtra?: PostTransactionCallback<unknown, Args>;
  handleError?: (params: { error: unknown; transaction?: WriteContractResult }) => void;
  confirmations?: number;
};

type ReactionHookResult<Args> = {
  status: TransactionStatus;
  execute: (
    args: Args,
  ) => Promise<{ status: 'error'; error: unknown } | { status: 'success'; receipt: TransactionReceipt }>;
  reset: () => void;
  transactionHash?: Address;
  transactionChainId?: number;
};
type ReactionHookDisplayInfo = { transactionHash: Address; transactionChainId: number };

type CreateExecuteParams = {
  showWallet: () => void;
  status: TransactionStatus;
  onStatusChange: (status: TransactionStatus, info?: ReactionHookDisplayInfo) => void;
  authAddress?: Address;
  addTransaction: ReturnType<typeof useManageTransactionList>['addTransaction'];
};
/**
 * Create a function that executes a transaction and handles all the necessary state changes
 * Only exported for testing, do not use directly
 **/
export const createExecute =
  <Args>({
    actionName,
    createDescription,
    createTransaction,
    createExtra,
    confirmations,
    handleError,
    status,
    onStatusChange,
    showWallet,
    authAddress,
    addTransaction,
  }: CreateExecuteParams & ReactionHookOptions<Args>) =>
  async (args: Args) => {
    if (status === 'waiting' || status === 'pending')
      return {
        status: 'error',
        error: new Error('Transaction already in progress'),
      } as const;

    const walletClient = await getWalletClient();
    if (!authAddress || !walletClient) {
      showWallet();
      onStatusChange('rejected');
      return { status: 'error', error: new Error('User must be authenticated') } as const;
    }

    const sentryTransaction = startTransaction({
      name: `${actionName.toLowerCase().replace(/ |_/g, '-')} (creation)`,
      op: 'blockchain-interaction',
    });

    let transaction: WriteContractResult | undefined = undefined;
    onStatusChange('waiting');

    try {
      const sendingSpan = sentryTransaction.startChild({
        op: 'create-transaction',
        description: 'Waiting for transaction to be created',
      });

      const transactionPromise = createTransaction({
        args,
        address: authAddress,
        walletClient,
        chainId: await walletClient.getChainId(),
        sentryTransaction,
      });
      addBreadcrumb({
        level: 'log',
        type: 'transaction',
        category: 'transaction.init',
        message: `Initiated transaction for ${actionName}`,
      });
      transaction = await transactionPromise;

      // Create variable for chainId after transaction creation since the process may have forced a chain change
      const chainId = await walletClient.getChainId();

      sendingSpan.setStatus('ok').setData('transaction', transaction).finish();
      sentryTransaction.traceId = transaction.hash;
      addBreadcrumb({
        category: 'transaction.sent',
        type: 'transaction',
        level: 'log',
        data: { transaction },
      });

      const storingSpan = sentryTransaction.startChild({
        op: 'store-transaction',
        description: 'Storing transaction in browser storage',
      });

      const description = createDescription({
        args,
        address: authAddress,
        transaction,
        chainId,
        sentryTransaction,
      });
      const extra = createExtra?.({ args, address: authAddress, transaction, chainId, sentryTransaction });
      addTransaction(actionName, transaction, description, chainId, extra);

      onStatusChange('pending', { transactionHash: transaction.hash, transactionChainId: chainId });

      // Let Sentry know that the transaction was properly sent to the blockchain
      storingSpan.setStatus('ok').finish();
      sentryTransaction.setStatus('ok').finish();

      const receipt = await waitForTransaction({ hash: transaction.hash, confirmations, chainId });
      onStatusChange('success');
      return { status: 'success', receipt } as const;
    } catch (e) {
      console.warn(e);

      // Log errors that aren't actual rejections
      if (isRejectedError(e)) {
        sentryTransaction.setStatus('cancelled');
        onStatusChange('rejected');
      } else {
        // Send unknown error details to Sentry
        sentryTransaction.setData('transaction', transaction);
        sentryTransaction.setData('error', e);
        sentryTransaction.setStatus('unknown_error');
        captureException(e, {
          tags: {
            transactions: actionName.toLowerCase().replace(/ |_/, '-'),
          },
          level: 'error',
          extra: { transaction },
        });
        onStatusChange('error');
      }
      handleError?.({ error: e, transaction });
      sentryTransaction.finish();
      return { status: 'error', error: e } as const;
    }
  };

export const generateReactionHook = <Args>({
  actionName,
  createTransaction,
  createDescription,
  createExtra,
  handleError,
  confirmations = 2,
}: ReactionHookOptions<Args>): ReactionHookResult<Args> => {
  const { authAddress } = useAuth();
  const { showWallet } = useWalletModal();

  const [status, setStatus] = useState<TransactionStatus>('none');
  const [displayInfo, setDisplayInfo] = useState<ReactionHookDisplayInfo>();
  const onStatusChange = useCallback((status: TransactionStatus, info?: ReactionHookDisplayInfo) => {
    setStatus(status);
    // Only update display info if it's not already set, we don't want to overwrite an existing transaction with a undefined one
    if (info) setDisplayInfo(info);
  }, []);
  const reset = useCallback(() => {
    setStatus('none');
    setDisplayInfo(undefined);
  }, []);

  const { addTransaction } = useManageTransactionList();
  TransactionListUpdatedEvent.useEventListener(({ detail }) => {
    if (
      detail.transaction.type !== actionName ||
      !displayInfo ||
      detail.transaction.hash !== displayInfo.transactionHash
    )
      return;
    setStatus(detail.transaction.status);
  });

  const execute = useCallback(
    createExecute({
      actionName,
      createDescription,
      createTransaction,
      createExtra,
      confirmations,
      handleError,
      showWallet,
      authAddress,
      addTransaction,
      status,
      onStatusChange,
    }),
    [authAddress, actionName],
  );

  return {
    ...displayInfo,
    status,
    execute,
    reset,
  } as const;
};
