import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { P, match } from 'ts-pattern';
import { createWalletClient, http } from 'viem';
import { generatePrivateKey, privateKeyToAccount } from 'viem/accounts';
import type { Chain, ChainProviderFn, Connector } from 'wagmi';
import { WagmiConfig, configureChains, createConfig } from 'wagmi';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { MockConnector } from 'wagmi/connectors/mock';
import { SafeConnector } from 'wagmi/connectors/safe';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { infuraProvider } from 'wagmi/providers/infura';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
import { publicProvider } from 'wagmi/providers/public';

import { config, defaults } from '@endaoment-frontend/config';
import { WALLET_CONNECT_PROJECT_ID } from '@endaoment-frontend/constants';

import { getChainForChainId } from './getDefaults';

const APP_NAME = 'app.endaoment';
const APP_DESCRIPTION = 'Endaoment';
const APP_URL = 'https://app.endaoment.org';
const APP_LOGO = 'https://app.endaoment.org/images/favicon.ico';

export const getDefaultConnectors = (chains: Array<Chain>): Array<Connector> => {
  if (process.env.NODE_ENV === 'test')
    return [
      new MockConnector({
        chains,
        options: {
          walletClient: createWalletClient({
            transport: http(),
            account: privateKeyToAccount(generatePrivateKey()),
            chain: getChainForChainId(defaults.network.defaultChainId),
          }),
          flags: {
            isAuthorized: true,
          },
        },
      }),
    ];

  return [
    new InjectedConnector({
      chains,
      options: {
        shimDisconnect: true,
        name: detectedName =>
          match(detectedName)
            .with('Coinbase Wallet', () => 'Injected Wallet')
            .with(P.array(P.string), arr => {
              const filteredArr = arr.filter(name => name !== 'Coinbase Wallet');
              if (filteredArr.length === 0) return 'Injected Wallet';
              return filteredArr[0];
            })
            .otherwise(name => name),
      },
    }),
    new SafeConnector({
      chains,
      options: {
        allowedDomains: [/gnosis-safe.io$/, /app.safe.global$/],
        debug: false,
        shimDisconnect: true,
      },
    }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: APP_NAME,
        headlessMode: true,
        appLogoUrl: APP_LOGO,
      },
    }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: WALLET_CONNECT_PROJECT_ID,
        qrModalOptions: {
          themeVariables: {
            '--w3m-z-index': '2000',
          },
        },
        metadata: {
          name: APP_NAME,
          description: APP_DESCRIPTION,
          url: APP_URL,
          icons: [APP_LOGO],
        },
      },
    }),
    // new LedgerConnector({
    //   chains,
    //   options: {
    //     walletConnectVersion: 2,
    //     projectId: WALLET_CONNECT_PROJECT_ID,
    //     // Makes it so that the ledger connector only requires the default chain, not all chains
    //     requiredChains: [defaults.network.defaultChainId],
    //   },
    // }),
  ];
};

const tenderlyProvider = ({ apiKey }: { apiKey: string }) =>
  jsonRpcProvider({
    rpc: chain => {
      const baseHttpUrl = chain.rpcUrls.tenderly?.http[0];
      const baseWsUrl = chain.rpcUrls.tenderly?.webSocket?.[0];
      if (!baseHttpUrl) return null;
      return {
        http: `${baseHttpUrl}/${apiKey}`,
        webSocket: baseWsUrl ? `${baseWsUrl}/${apiKey}` : undefined,
      };
    },
  });
const getProviders = () => {
  return [
    infuraProvider({ apiKey: config.providers.infura }),
    alchemyProvider({ apiKey: config.providers.alchemy }),
    tenderlyProvider({ apiKey: config.providers.tenderly }),
    publicProvider(),
  ];
};

/** Wrapper for WAGMI with our custom configs */
export const Web3Provider = ({
  children,
  chains = config.chains,
  getConnectors = getDefaultConnectors,
}: {
  children: Array<ReactNode> | ReactNode;
  /** Defaults to chains matching config, only neccesary for some tests */
  chains?: Array<Chain>;
  /**
   * Used to create list of connectors that will be used throughout the app
   * ⚠️ This function is only called once, on initial render
   *
   * Do not create new connectors outside this function, only use existing ones or fetch them using `useConnect`
   *
   * ⚠️ If you create new connectors, their state will not be persisted to local storage
   * @link https://github.com/wevm/wagmi/issues/2511
   */
  getConnectors?: (chains: Array<Chain>, providers: Array<ChainProviderFn<Chain>>) => Array<Connector>;
}) => {
  const wagmiConfig = useMemo(() => {
    const providers = getProviders();
    const { publicClient, webSocketPublicClient } = configureChains(chains, providers);
    const connectors = getConnectors(chains, providers);

    return createConfig({
      publicClient,
      webSocketPublicClient,
      connectors,
      autoConnect: true,
    });

    // ⚠️ This list MUST be empty or guarantee all references are stable
    // The inital Social Login bug was caused by this list having unstable references
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <WagmiConfig config={wagmiConfig}>{children}</WagmiConfig>;
};
