import { Link } from '@chakra-ui/next-js';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { P, match } from 'ts-pattern';

import { GetRecipient } from '@endaoment-frontend/api';
import { useAuth } from '@endaoment-frontend/authentication';
import { routes } from '@endaoment-frontend/routes';
import type { DonationRecipient } from '@endaoment-frontend/types';
import { CloseIcon } from '@endaoment-frontend/ui/icons';
import { Button, Card } from '@endaoment-frontend/ui/shared';

import styles from './EntityCard.module.scss';
import { MiniFundDetails, MiniLoadingDetails, MiniOrgDetails, MiniWalletDetails } from './MiniEntityDetails';

type EntityCardProps = { entity?: DonationRecipient | { type: 'user' }; onRemove?: () => void; link?: boolean };

export const EntityCard = ({ entity, onRemove, link }: EntityCardProps) => {
  const { authAddress } = useAuth();
  const queryClient = useQueryClient();

  const shouldFetchRecipient = entity?.type === 'fund' || entity?.type === 'org';
  const { data, isLoading } = useQuery({
    queryKey: shouldFetchRecipient ? GetRecipient.getQueryKeyForArgs([queryClient, entity]) : GetRecipient.prefixKeys,
    queryFn: () => {
      if (!shouldFetchRecipient) throw new Error('Invalid entity type');
      return GetRecipient.fetchFromQueryClient(queryClient, [queryClient, entity]);
    },
    enabled: shouldFetchRecipient,
  });

  const loadingCard = (
    <Card className={styles[`destination-card--simple`]}>
      <MiniLoadingDetails />
    </Card>
  );

  if (shouldFetchRecipient && isLoading) return loadingCard;

  const removeButton = !!onRemove && (
    <Button
      size='small'
      onClick={onRemove}
      filled
      variation='faded'
      float={false}
      title='Remove Destination Selection'
      className={clsx(styles['header-button'], styles['remove-button'])}>
      <CloseIcon width={18} color={entity?.type === 'fund' ? '#EA6B0E' : '#53ACDE'} strokeWidth={0.75} />
    </Button>
  );

  const entityCard = match({ type: entity?.type, data })
    .with({ type: 'fund', data: P.select({ type: 'fund', entity: P.not(P.nullish) }) }, ({ entity }) => (
      <div className={clsx(styles['destination-card'], styles[`destination-card--fund`])}>
        <MiniFundDetails fund={entity} extraDetails showBalance />
        {removeButton}
      </div>
    ))
    .with({ type: 'org', data: P.select({ type: 'org', entity: P.not(P.nullish) }) }, ({ entity }) => (
      <div className={clsx(styles['destination-card'], styles[`destination-card--org`])}>
        <MiniOrgDetails org={entity} extraDetails hideTooltips />
        {removeButton}
      </div>
    ))
    .with({ type: 'user' }, () => {
      if (!authAddress) return loadingCard;

      return (
        <Card className={styles[`destination-card--simple`]}>
          <MiniWalletDetails address={authAddress} />
        </Card>
      );
    })
    .otherwise(() => loadingCard);

  if (!link || !entity || entity.type === 'user') return entityCard;

  return <Link href={routeForDestination(entity)}>{entityCard}</Link>;
};

export const EntityCardWithLabel = ({ entity, label, onRemove, link }: EntityCardProps & { label: string }) => {
  return (
    <div className={styles['entity-with-label']}>
      <label>{label}</label>
      <EntityCard entity={entity} onRemove={onRemove} link={link} />
    </div>
  );
};

const routeForDestination = (entity: DonationRecipient) => {
  return match(entity)
    .with({ type: 'fund' }, entity => routes.app.fund({ id: entity.id }))
    .with({ type: 'org' }, entity => routes.app.org({ einOrId: entity.einOrId }))
    .exhaustive();
};
