import * as app from './lib/app-routes';
import * as developers from './lib/developers-routes';
import * as marketing from './lib/marketing-routes';

export { formatEinOrUuid } from './lib/utils';
export const routes = { app, marketing, developers };

export {
  donationWizardParsers,
  fundWizardParsers,
  portfolioWizardParsers,
  targetAllocationModalParsers,
} from './lib/wizardUtils';
