import { Link } from '@chakra-ui/next-js';

import { routes } from '@endaoment-frontend/routes';
import { LogoTextIcon } from '@endaoment-frontend/ui/icons';

import styles from './Footer.module.scss';

export const Footer = () => {
  return (
    <footer className={styles['footer-wrapper']}>
      <div className={styles['links-container']}>
        <div className={styles['links-logo']}>
          <LogoTextIcon color='currentColor' />
          <span>Onchain Community Foundation</span>
        </div>
        <div>
          <h6>Information</h6>
          {/* <Link href="https://endaoment.org/ndao" target="_blank" rel="noreferrer">
            NDAO Token
          </Link> */}
          <Link href={routes.marketing.about()} target='_blank' rel='noreferrer'>
            About Endaoment
          </Link>
          <Link href={routes.marketing.otc()} target='_blank' rel='noreferrer'>
            Over-the-Counter Donations
          </Link>
          <Link href={routes.marketing.learn()} target='_blank' rel='noreferrer'>
            Learn
          </Link>
          <Link href={routes.developers.home()} target='_blank' rel='noreferrer'>
            Developers
          </Link>
          <Link href='https://docs.endaoment.org' target='_blank' rel='noreferrer'>
            Documentation
          </Link>
          <Link href={routes.marketing.donationCalculator()}>Donation Calculator</Link>
        </div>
        <div>
          <h6>Endaoment App</h6>
          <Link href={routes.app.home()}>App Home</Link>
          <Link href={routes.app.explore()}>Search Organizations</Link>
        </div>
        <div>
          <h6>Resources for Nonprofits</h6>
          <Link href='https://docs.endaoment.org/organizations' target='_blank' rel='noreferrer'>
            Nonprofit Documentation
          </Link>
          <Link href={routes.marketing.orgs()} target='_blank' rel='noreferrer'>
            Info for Nonprofits
          </Link>
        </div>
        <div>
          <h6>Resources for Donors</h6>
          <Link href='https://docs.endaoment.org/donors' target='_blank' rel='noreferrer'>
            Donor Documentation
          </Link>
          <Link
            href='https://endaoment.typeform.com/to/IWxBhuMP?typeform-source=endaoment.org'
            target='_blank'
            rel='noreferrer'>
            OTC Donation Form
          </Link>
          <Link href={routes.marketing.advisors()}>Info for Financial Advisors</Link>
        </div>
      </div>
      <hr />
      <div className={styles['extra-container']}>
        <div>
          <p className={styles['ndao-ein']}>Endaoment is a registered United States 501(c)(3) with EIN 84-4661797</p>
          <span className={styles['ndao-copyright']}>© 2023 Endaoment</span>
        </div>
      </div>
    </footer>
  );
};
