import { Input, InputGroup, InputLeftElement, InputRightAddon } from '@chakra-ui/input';
import { Box } from '@chakra-ui/layout';
import { Link } from '@chakra-ui/next-js';
import { Popover, PopoverAnchor, PopoverBody, PopoverContent } from '@chakra-ui/popover';
import { Fade } from '@chakra-ui/transition';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import { match } from 'ts-pattern';
import { useDebounce } from 'use-debounce';

import { routes } from '@endaoment-frontend/routes';
import { ArrowIcon, CircleIcon, SearchIcon, StarIcon } from '@endaoment-frontend/ui/icons';
import { Button, Loader } from '@endaoment-frontend/ui/shared';

import type { SearchSelection } from './SearchResults';
import { SearchResults } from './SearchResults';
import styles from './SimpleSearch.module.scss';
import { useSearch } from './useSearch';

export type SimpleSearchProps = {
  className?: string;
  compressed?: boolean;
  includeLink?: boolean;
  onSelect?: (selection?: SearchSelection) => void;
};

const PAGE_SIZE = 10;

export const SimpleSearch = ({ className, compressed = false, includeLink = true, onSelect }: SimpleSearchProps) => {
  const router = useRouter();

  const [searchTerm, setSearchTerm] = useState('');
  const [displayResults, setDisplayResults] = useState(false);
  const cleanSearchTerm = searchTerm.trim().toLowerCase();
  const { data, isLoading } = useSearch(cleanSearchTerm, { pageSize: PAGE_SIZE });

  const [isInputFocused, setIsInputFocused] = useState(false);
  const [isPopoverFocused, setIsPopoverFocused] = useState(false);

  // Ghost focus is used to keep the popover open when the user clicks on an item in the popover
  const [isGhostFocused, setIsGhostFocused] = useState(false);
  const inputRef = useRef<HTMLDivElement>(null);
  useClickAway(inputRef, () => setIsGhostFocused(false));

  useEffect(() => {
    setDisplayResults(cleanSearchTerm.length > 0);
  }, [cleanSearchTerm]);

  const handleSelect = (selection?: SearchSelection) => {
    setDisplayResults(false);
    setIsGhostFocused(false);

    // Navigate
    if (selection?.type === 'org') {
      router.push(routes.app.org({ einOrId: selection.org.ein ?? selection.org.id }));
    } else if (selection?.type === 'fund') {
      router.push(routes.app.fund({ id: selection.fund.id }));
    } else {
      router.push(routes.app.explore({ searchTerm }));
    }

    onSelect?.(selection);
  };

  const shouldOpen = isInputFocused || isPopoverFocused || isGhostFocused;
  const isOpen = useDebounce(shouldOpen, 800)[0] && shouldOpen;

  const [isOrgMode, setIsOrgMode] = useState(true);

  const inputState = isOpen && searchTerm.length === 0 ? 'empty' : isOpen ? 'open' : 'closed';

  if (!isLoading && data?.orgs.length === 0 && data?.funds.length > 0 && isOrgMode) {
    setIsOrgMode(false);
  }

  if (!isLoading && data?.funds.length === 0 && data?.orgs.length > 0 && !isOrgMode) {
    setIsOrgMode(true);
  }

  return (
    <Popover
      isOpen={!!isOpen && displayResults}
      gutter={0}
      trigger='click'
      matchWidth
      autoFocus={false}
      closeOnBlur={false}>
      <PopoverAnchor>
        <InputGroup
          className={clsx(
            styles.search,
            compressed && styles['search--compressed'],
            shouldOpen && styles['search--focused'],

            className,
          )}
          ref={inputRef}>
          <InputLeftElement>
            {isLoading ? (
              <Loader className={styles['search-icon']} />
            ) : (
              <SearchIcon
                className={clsx(styles['search-icon'], styles['search-icon--glass'])}
                width={36}
                color='currentColor'
              />
            )}
          </InputLeftElement>
          <Input
            placeholder={compressed ? 'Search...' : 'Search 1.5 million organizations and community funds...'}
            onFocus={() => {
              setIsInputFocused(true);
              setIsGhostFocused(true);
            }}
            onBlur={() => setIsInputFocused(false)}
            onChange={e => setSearchTerm(e.currentTarget.value)}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                e.preventDefault();

                // If there is only one result, select it
                // If not the case, send a generic selection to be used by callback
                match({ isOrgMode, orgCount: data.orgs.length, fundCount: data.funds.length })
                  .with({ isOrgMode: true, orgCount: 1 }, () => handleSelect({ type: 'org', org: data.orgs[0] }))
                  .with({ isOrgMode: false, fundCount: 1 }, () => handleSelect({ type: 'fund', fund: data.funds[0] }))
                  .otherwise(() => handleSelect());
              }
            }}
            value={searchTerm}
            className={styles['search-input']}
            as={motion.input}
            initial='closed'
            data-state={inputState}
            animate={inputState}
            exit='closed'
            variants={{
              open: {
                borderRadius: '1.5rem 0 0 0',
                transition: {
                  duration: 0,
                },
              },
              empty: {
                borderRadius: '1.5rem 0 0 1.5rem',
              },
              closed: {
                borderRadius: '1.5rem',
                transition: {
                  delay: 0.15,
                },
              },
            }}
          />
          <Fade
            in={isOpen}
            delay={{ enter: 0.5 }}
            unmountOnExit
            className={clsx(
              styles['toggle-overlay'],
              compressed && styles['toggle-overlay--compressed'],
              styles['filter-buttons'],
            )}>
            <Button
              variation='org'
              size='small'
              filled={isOrgMode}
              onClick={() => setIsOrgMode(true)}
              disabled={data?.orgs.length === 0}
              float={false}
              className={styles['toggle-button']}>
              <CircleIcon />
              Orgs&nbsp;
              <b>{data?.orgs.length >= PAGE_SIZE ? `${PAGE_SIZE}+` : data?.orgs.length ?? 0}</b>
            </Button>

            <Button
              variation='fund'
              size='small'
              filled={!isOrgMode}
              onClick={() => setIsOrgMode(false)}
              disabled={data?.funds.length === 0}
              float={false}
              className={styles['toggle-button']}>
              <StarIcon />
              Funds&nbsp;
              <b>{data?.funds.length >= PAGE_SIZE ? `${PAGE_SIZE}+` : data?.funds.length ?? 0}</b>
            </Button>
          </Fade>
          <Fade
            in={!!includeLink && isOpen}
            transition={{
              enter: { duration: 0.5 },
              exit: { duration: 0.5 },
            }}>
            <InputRightAddon
              as={motion.div}
              className={clsx(styles['search-icon__container'], isOpen && styles['search-icon__container--focused'])}
              initial='closed'
              animate={inputState}
              exit='closed'
              variants={{
                open: {
                  borderBottomRightRadius: '0rem',
                },
                empty: {
                  borderBottomRightRadius: '1rem',
                },
                closed: {
                  borderBottomRightRadius: '1rem',
                  transition: {
                    delay: 0.15,
                  },
                },
              }}>
              <Link href={routes.app.explore({ searchTerm })}>
                <ArrowIcon className={clsx(styles['search-icon'], styles['search-icon--arrow'])} />
              </Link>
            </InputRightAddon>
          </Fade>
        </InputGroup>
      </PopoverAnchor>
      <PopoverContent
        borderRadius='0 0 1.5rem 1.5rem'
        width='100%'
        border='1px solid var(--highlight-color)'
        borderTop='none'
        onHoverStart={() => setIsPopoverFocused(true)}
        onHoverEnd={() => setIsPopoverFocused(false)}>
        <PopoverBody className={clsx(styles['result-list'], isLoading && styles['result-list--loading'])}>
          <Box
            className={clsx(
              styles['mobile-toggle'],
              compressed && styles['mobile-toggle--compressed'],
              styles['filter-buttons'],
            )}>
            <Button
              variation='org'
              size='small'
              filled={isOrgMode}
              onClick={() => setIsOrgMode(true)}
              disabled={data?.orgs.length === 0}
              float={false}
              className={styles['toggle-button']}>
              <CircleIcon />
              Orgs&nbsp;
              <b>{data?.orgs.length >= PAGE_SIZE ? `${PAGE_SIZE}+` : data?.orgs.length ?? 0}</b>
            </Button>
            <Button
              variation='fund'
              size='small'
              filled={!isOrgMode}
              onClick={() => setIsOrgMode(false)}
              disabled={data?.funds.length === 0}
              float={false}
              className={styles['toggle-button']}>
              <StarIcon />
              Funds&nbsp;
              <b>{data?.funds.length >= PAGE_SIZE ? `${PAGE_SIZE}+` : data?.funds.length ?? 0}</b>
            </Button>
          </Box>

          <SearchResults data={data} isLoading={isLoading} mode={isOrgMode ? 'org' : 'fund'} onSelect={handleSelect} />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
