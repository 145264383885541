import { Link } from '@chakra-ui/next-js';
import clsx from 'clsx';
import Image from 'next/image';

import type { TransactionToast } from '@endaoment-frontend/blockchain-interactions';
import { useTransactionToast } from '@endaoment-frontend/blockchain-interactions';
import { getTransactionLink } from '@endaoment-frontend/multichain';
import type { BlockchainTransactionStatus } from '@endaoment-frontend/types';
import { Button, Loader, Modal } from '@endaoment-frontend/ui/shared';

import transactionToastIcon from '../../images/transaction-toast-icon.svg';

import styles from './TransactionToastController.module.scss';

const toastTypeTextMap: { [key in BlockchainTransactionStatus]: { displayTitle?: string; blurb: string } } = {
  success: {
    displayTitle: 'Transaction Complete',
    blurb: 'Your transaction has been successfully completed.',
  },
  pending: {
    blurb: 'Waiting for the blockchain to confirm your transaction.',
  },
  error: {
    displayTitle: 'Transaction Error',
    blurb: 'There was an error processing your transaction.',
  },
} as const;

// Top-level component that renders all transaction toasts
export const TransactionToastController = () => {
  const { toast, removeToast } = useTransactionToast();

  return (
    <Modal
      className={clsx(styles['transaction-toast'], toast && styles[`toast--${toast.type}`])}
      data-type={toast ? toast.type : 'none'}
      onClose={removeToast}
      isOpen={typeof toast !== 'undefined'}
      title={toast?.title}
      branded={toast?.type === 'pending' || toast?.type === 'error'}
      closeOnOverlayClick={false}
      size='sm'>
      {!!toast && <DisplayToast close={removeToast} {...toast} />}
    </Modal>
  );
};

const DisplayToast = ({
  display,
  type,
  link,
  transactionHash,
  transactionChainId,
  blurbOverride,
  close,
}: TransactionToast & { close: () => void }) => {
  return (
    <>
      {!!display && (
        <>
          <h4>{toastTypeTextMap[type].displayTitle}</h4>
          {display}
        </>
      )}
      {type !== 'pending' && type !== 'error' && <Image src={transactionToastIcon} alt='' width={100} height={100} />}
      <p>{blurbOverride ?? toastTypeTextMap[type].blurb}</p>
      {type === 'pending' ? (
        <>
          <Loader size='l' />
          {!!transactionHash && !!transactionChainId && (
            <Button
              as='a'
              href={getTransactionLink(transactionHash, transactionChainId)}
              className={styles['etherscan-link']}
              size='small'
              variation='purple'
              filled
              target='_blank'>
              View on Explorer
            </Button>
          )}
        </>
      ) : link ? (
        <Button as={Link} href={link.href} variation='purple' filled onClick={close}>
          {link.label}
        </Button>
      ) : (
        <></>
      )}
    </>
  );
};
