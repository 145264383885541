import type { QueryClient } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';

import { writeEntityMulticall } from '@endaoment-frontend/abis';
import { GetFund } from '@endaoment-frontend/api';
import type { ReactionHookOptions } from '@endaoment-frontend/blockchain-interactions';
import { generateReactionHook } from '@endaoment-frontend/blockchain-interactions';
import { defaults } from '@endaoment-frontend/config';
import { getContractAddressForChain } from '@endaoment-frontend/multichain';
import type { UUID } from '@endaoment-frontend/types';

import { CalculateEntityRebalance } from './requests';

type RebalanceFundTransactionArgs = {
  fundId: UUID;
};

const createRebalanceFundTransaction =
  (queryClient: QueryClient): ReactionHookOptions<RebalanceFundTransactionArgs>['createTransaction'] =>
  async ({ args, chainId }) => {
    const { fundId } = args;

    const fund = await GetFund.fetchFromQueryClient(queryClient, [fundId]);

    if (typeof fund.v2ContractAddress !== 'string') {
      throw new Error('Attempting to rebalance a fund that has not been deployed');
    }

    CalculateEntityRebalance.invalidateQuery(queryClient, ['fund', fund.id, true]);
    const rebalanceCalculation = await CalculateEntityRebalance.fetchFromQueryClient(queryClient, [
      'fund',
      fund.id,
      true,
    ]);
    const validOperations = rebalanceCalculation.filter(v => !!v.readyForExecution);

    if (validOperations.length === 0) {
      throw new Error('No valid rebalance operations to execute');
    }

    return writeEntityMulticall({
      address: getContractAddressForChain(chainId, 'entityMulticall'),
      functionName: 'multicall',
      args: [
        fund.v2ContractAddress,
        validOperations.map(v => {
          if (!v.calldata) throw new Error('No calldata for rebalance operation');
          return { value: 0n, data: v.calldata };
        }),
      ],
    });
  };

export const useRebalanceFund = () => {
  const queryClient = useQueryClient();
  return generateReactionHook({
    actionName: 'REBALANCE_FUND',
    createTransaction: createRebalanceFundTransaction(queryClient),
    createDescription: () => 'Rebalance Fund',
    confirmations: defaults.confirmations.portfolioTrade,
  });
};
