import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu';
import clsx from 'clsx';
import type { MouseEvent, ReactNode } from 'react';

import { CloseIcon, DownCaretIcon } from '@endaoment-frontend/ui/icons';

import { Button } from '../button';

import styles from './Select.module.scss';

type SelectProps = {
  name?: string;
  value?: string;
  renderValue?: ReactNode;
  onValueChange?: (value: string) => void;
  onClick?: () => void;
  onRemoveClick?: (e: MouseEvent) => void;
  placeholder?: ReactNode;
  options?: Array<{
    key?: string;
    value: string;
    display: ReactNode;
  }>;
  disabled?: boolean;
  className?: string;
};

export const Select = ({
  name,
  value,
  renderValue,
  onValueChange,
  placeholder,
  options = [],
  className,
  onRemoveClick,
  onClick,
  disabled,
}: SelectProps) => {
  return (
    <div className={styles['select-container']}>
      <Menu matchWidth>
        {({ isOpen }) => (
          <>
            <MenuButton
              disabled={disabled}
              name={name}
              type='button'
              className={clsx(styles.dropdown, className)}
              onClick={onClick}
              data-state={isOpen ? 'open' : 'closed'}>
              {value ? renderValue ?? value : <span className={styles.placeholder}>{placeholder}</span>}
              {!(onRemoveClick && value) && <DownCaretIcon className={styles['down-icon']} width={20} height={10} />}
            </MenuButton>
            <MenuList className={styles.list}>
              {options.map((option, i) => (
                <MenuItem key={option.key ?? i} onClick={() => onValueChange?.(option.value)}>
                  {option.display}
                </MenuItem>
              ))}
            </MenuList>
          </>
        )}
      </Menu>
      {!!onRemoveClick && !!value && (
        <Button
          className={styles['remove-icon']}
          as='span'
          variation='faded'
          size='small'
          float={false}
          filled
          minimal
          onClick={onRemoveClick}>
          <CloseIcon width={16} height={16} />
        </Button>
      )}
    </div>
  );
};
