import type { Chain } from 'wagmi/chains';
import { z } from 'zod';

import type { Address, AdminAccountName } from '@endaoment-frontend/types';

export const environmentSchema = z.enum(['local', 'dev', 'production', 'staging']);
export type Environment = z.infer<typeof environmentSchema>;

export type ConfigEntry = {
  environmentName: Environment;
  chains: Array<Chain>;
  providers: {
    alchemy: string;
    infura: string;
    tenderly: string;
  };
  baseUrls: {
    app: string;
    marketing: string;
    developers: string;
    admin: string;
    api: string;
    docs: string;
  };
  endaoment: {
    accounts: Record<AdminAccountName, Array<Address>>;
  };
  socialLoginChainId: Chain['id'];
  circleAddress: Address;
  googleMapsApiKey: string;
  mapboxApiKey: string;
  zeroDevProjectIds: Array<string>;
  web3AuthClientId?: string;
  identifiersSecret: string;
};

export type App = keyof Pick<ConfigEntry['baseUrls'], 'admin' | 'app' | 'developers' | 'marketing'>;
