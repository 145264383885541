import clsx from 'clsx';
import { motion, useAnimation } from 'framer-motion';
import { useEffect, useState } from 'react';

import styles from './Accordion.module.scss';
import type { AccordionProps } from './Accordion.types';

export const Accordion = ({ outerContent, children, initiallyOpen = false, className }: AccordionProps) => {
  const [isOpen, setOpen] = useState(() => initiallyOpen);

  const controls = useAnimation();

  useEffect(() => {
    const handleAnimations = async () => {
      if (isOpen) {
        await controls.start({ height: 'auto', marginTop: 16, display: 'flex' });
        controls.start({ opacity: 1 });
      } else {
        await controls.start({ height: '0', marginTop: 0, opacity: 0 });
        controls.start({ display: 'none' });
      }
    };

    controls && handleAnimations();
  }, [isOpen, controls]);

  return (
    <div className={clsx(styles['accordion'], className)}>
      <div className={styles['accordion-outer']}>{outerContent}</div>
      <div onClick={() => setOpen(o => !o)} className={styles['accordion-icon']}>
        <svg height='75%' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <line x1='11' y1='0' x2='11' y2='21' strokeLinecap='round' className={clsx(isOpen && styles['open'])} />
          <line x1='0' y1='11' x2='21' y2='11' strokeLinecap='round' />
        </svg>
      </div>
      <motion.div
        animate={controls}
        initial={{ height: 0 }}
        transition={{ duration: 0.75, ease: 'linear' }}
        className={styles['accordion-inner']}>
        {children}
      </motion.div>
    </div>
  );
};
