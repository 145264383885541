import { useMutation } from '@tanstack/react-query';
import type { Hash } from '@wagmi/core';
import { randomBytes } from 'crypto';

import { CreateFund } from '@endaoment-frontend/api';
import { useAuth, useAuthType } from '@endaoment-frontend/authentication';
import { config } from '@endaoment-frontend/config';
import type { Address, CreateFundAdvisorInput, CreateFundDetailsInput } from '@endaoment-frontend/types';

import { useDeployFund } from './useDeployFund';

export const useDeployAndCreateFund = ({ onSuccess }: { onSuccess: () => void }) => {
  const { authAddress } = useAuth();
  const { isSocialAuth } = useAuthType();

  const { execute: deployFund } = useDeployFund();

  const { mutate, status, data, reset } = useMutation({
    mutationKey: CreateFund.prefixKeys,
    mutationFn: async ({
      fundDetails,
      advisor,
    }: {
      fundDetails: CreateFundDetailsInput;
      advisor: CreateFundAdvisorInput;
    }) => {
      if (!authAddress) return;

      // For social users, we predeploy their funds
      let deploymentTransactionHash: Address | undefined;
      let chainId = fundDetails.chainId;

      if (isSocialAuth) {
        chainId = config.socialLoginChainId;
        const salt = ('0x' + randomBytes(32).toString('hex')) as Hash;
        const receipt = await deployFund({ manager: authAddress, salt });
        if (receipt.status === 'success') {
          deploymentTransactionHash = receipt.receipt.transactionHash;
        }
      }

      const referralSource = fundDetails.referralSource;

      // Delete chainId and referralSource from the fundDetails object via a clone to not affect the original object
      const clone = { ...fundDetails } as Partial<CreateFundDetailsInput>;
      delete clone.chainId;
      delete clone.referralSource;

      return CreateFund.execute({
        fundInput: {
          ...(clone as CreateFundDetailsInput),
          advisor,
        },
        referralSource,
        deploymentTransactionHash,
        chainId,
      });
    },
    onSuccess,
    retryDelay: 5000,
  });

  return {
    createFund: mutate,
    status,
    fund: data,
    reset,
  } as const;
};
