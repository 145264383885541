var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/sentry/tunnel";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"0ee4a26f62a536bd3538e4ff5f8b913a77ec5e97"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { Replay, init } from '@sentry/nextjs';

import { config } from '@endaoment-frontend/config';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

init({
  dsn: SENTRY_DSN || 'https://7e9718877ac84d38aba202cc09ec0971@o1285899.ingest.sentry.io/6528438',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,

  integrations: [
    new Replay({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [config.baseUrls.api],
    }),
  ],

  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  environment: process.env.NEXT_PUBLIC_ENV,
  tracePropagationTargets: [config.baseUrls.api],

  ignoreErrors: [
    /Loading chunk \d+ failed/,
    /Loading CSS chunk \d+ failed/,
    'Invariant: attempted to hard navigate to the same URL',
  ],
});
