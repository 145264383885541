import { useField } from 'formik';
import { useCallback } from 'react';

import { defaults } from '@endaoment-frontend/config';

import { NetworkSelector } from './NetworkSwitcher';

type FormNetworkSelectorProps = { className?: string; name: string };

export const FormNetworkSelector = ({ name, className }: FormNetworkSelectorProps) => {
  const [{ value }, , { setValue, setTouched }] = useField<number>(name);

  const chains = defaults.network.supportedNetworkSettings.map(chain => ({
    id: chain.chainId,
  }));

  const handleClick = useCallback(
    (chainId: number) => {
      setValue(chainId, true);
      setTouched(true);
    },
    [setTouched, setValue],
  );

  return (
    <NetworkSelector
      currentChain={{ id: value }}
      chains={chains}
      onClick={handleClick}
      showLabel
      className={className}
    />
  );
};
