import type { IconProps } from './Icon.types';

export const AdviseIcon = ({ width = 36, height, color, ...props }: IconProps) => (
  <svg
    width={width}
    height={height ?? width}
    viewBox='0 0 36 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5 9C5 7.34315 6.34315 6 8 6H28C29.6569 6 31 7.34315 31 9V23C31 24.6569 29.6569 26 28 26H16L7 32L8.125 26H8C6.34315 26 5 24.6569 5 23V9ZM18.8507 12.4947L18 9L17.143 12.421C16.8073 13.761 15.761 14.8073 14.421 15.143L11 16L14.421 16.857C15.761 17.1927 16.8073 18.239 17.143 19.579L18 23L18.857 19.579C19.1927 18.239 20.239 17.1927 21.579 16.857L25 16L21.6791 15.2647C20.2853 14.9561 19.1883 13.8817 18.8507 12.4947Z'
      fill={color ?? '#EA6B0E'}
    />
  </svg>
);
