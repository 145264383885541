import { TrackingHeadScript } from '@phntms/next-gtm';
import { Hydrate, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider as JotaiProvider } from 'jotai';
import type { ComponentPropsWithoutRef, ReactNode } from 'react';

import { Web3AuthProvider, getSocialConnectors } from '@endaoment-frontend/authentication';
import { TransactionListProvider } from '@endaoment-frontend/blockchain-interactions';
import { config, featureFlags } from '@endaoment-frontend/config';
import { convertDehydratedStringToState, defaultQueryClient } from '@endaoment-frontend/data-fetching';
import { Web3Provider, getDefaultConnectors } from '@endaoment-frontend/multichain';
import { StylesProvider } from '@endaoment-frontend/ui/shared';

import { AdminFunctions } from './AdminFunctions';
import { Web3ReactionProvider } from './Web3ReactionProvider';

const getConnectors: ComponentPropsWithoutRef<typeof Web3Provider>['getConnectors'] = chains => [
  ...getDefaultConnectors(chains),
  ...getSocialConnectors(),
];

/**
 * This component is used to wrap all providers the app
 *
 * Do not use this in testing as you will be using adding a lot of potential bugs and unnecessary bloat
 **/
export const AppProvider = ({
  dehydratedState,
  children,
}: {
  dehydratedState?: string;
  children: Array<ReactNode> | ReactNode;
}) => {
  return (
    <>
      <TrackingHeadScript id={featureFlags.gaMeasurementId} isGTM />
      <StylesProvider>
        <Web3Provider chains={config.chains} getConnectors={getConnectors}>
          {/* IMPORTANT: QueryClient must be a subcomponent of Web3Provider, otherwise WAGMI overrides SSR */}
          <QueryClientProvider client={defaultQueryClient}>
            <JotaiProvider>
              <Hydrate state={convertDehydratedStringToState(dehydratedState)} />
              <Web3AuthProvider>
                {children}
                <TransactionListProvider />
                <Web3ReactionProvider />
                <AdminFunctions />
              </Web3AuthProvider>
            </JotaiProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </Web3Provider>
      </StylesProvider>
    </>
  );
};
