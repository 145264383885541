import { Modal, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import { Text } from '@chakra-ui/react';
import { captureException } from '@sentry/nextjs';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import type { ReactElement, ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { useIsMobile } from '@endaoment-frontend/hooks';
import { BackIcon, CloseIcon } from '@endaoment-frontend/ui/icons';

import { Button } from '../button';
import { ProgressBar } from '../progressBar';

import styles from './StepModal.module.scss';

type ModalWrapperProps = {
  isOpen: boolean;
  onClose: () => void;
  children: ReactElement | null;
  extraLarge?: boolean;
  className?: string;
};

/**
 * @description This is the modal and state management for any wizard-like flow.\
 *
 * Please ensure that the components are architected in the following way:
 * @example
 * Component1 = <StepModal><Component2 /></StepModal>
 * Component2 = <StepModal.StepsWrapper><StepModal.Step>{children}</StepModal.Step></StepModal.StepsWrapper>
 */
export const StepModal = ({ children, isOpen, onClose, extraLarge = false, className }: ModalWrapperProps) => {
  const { isMobile } = useIsMobile();
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={{ sm: '2xl' }} isCentered={!isMobile}>
      <ModalOverlay />
      <ModalContent
        data-testid='step-modal'
        className={clsx(styles['modal-content'], extraLarge && styles['modal-content__xl'], className)}
        motionProps={{
          layout: true,
          initial: 'closed',
          animate: 'open',
          exit: 'closed',
          variants: {
            open: { opacity: 1 },
            closed: { opacity: 0 },
          },
        }}>
        <ErrorBoundary
          onError={e =>
            captureException(e, {
              tags: {
                component: 'StepModal',
              },
            })
          }
          fallback={
            <ModalStepsWrapper>
              <StepModal.Step key='error' onClose={onClose} header='Error'>
                <Text alignSelf='center'>Something went wrong. Please close the modal and try again.</Text>
              </StepModal.Step>
            </ModalStepsWrapper>
          }>
          {children}
        </ErrorBoundary>
      </ModalContent>
    </Modal>
  );
};

type ModalStepProps = {
  header?: ReactNode;
  children: Array<ReactElement> | ReactElement | null;
  progress?: { current: number; pages: number };
  onClose: () => void;
  onBack?: () => void;
  className?: string;
};

export const ModalStep = ({ children, header, progress, onClose, onBack, className }: ModalStepProps) => {
  return (
    <motion.div
      initial='hidden'
      animate='visible'
      exit='hidden'
      variants={{
        hidden: {
          // Static height to minimize to
          height: window.innerHeight * 0.3,
          opacity: 0,
          overflow: 'hidden',
          transition: {
            duration: 0.5,
            opacity: {
              duration: 0.2,
            },
          },
        },
        visible: {
          height: 'auto',
          opacity: 1,
          overflow: 'scroll',
          transition: {
            duration: 0.5,
            opacity: {
              delay: 0.2,
            },
          },
        },
      }}
      className={className}>
      {!!header && (
        <ModalHeader className={styles['header']}>
          {!!onBack && (
            <Button
              size='small'
              onClick={onBack}
              variation='faded'
              filled
              float={false}
              title='Go Back Modal'
              className={styles['header-button']}>
              <BackIcon width={6} color='#ADBACC' />
            </Button>
          )}
          <h3 className={styles['header-title']}>{header}</h3>
          <Button
            size='small'
            onClick={() => onClose()}
            filled
            variation='faded'
            float={false}
            title='Close'
            className={styles['header-button']}>
            <CloseIcon width={12} color='#ADBACC' />
          </Button>
        </ModalHeader>
      )}
      {children}
      {progress ? (
        <ProgressBar progress={(progress.current / progress.pages) * 100} className={styles['donation-progress']}>
          Step {progress.current} / {progress.pages}
        </ProgressBar>
      ) : (
        <span className={styles['bottom-space']} />
      )}
    </motion.div>
  );
};
StepModal.Step = ModalStep;

type ModalStepsWrapperProps = {
  children: ReactElement | null;
};
/**
 * Use this to wrap the actual steps. This will handle the animation between steps.
 *
 * It is required to wrap the steps as a separate component, otherwise the animations and ErrorBoundary will not work.
 */
const ModalStepsWrapper = ({ children }: ModalStepsWrapperProps) => {
  return <AnimatePresence mode='wait'>{children}</AnimatePresence>;
};
StepModal.StepsWrapper = ModalStepsWrapper;
