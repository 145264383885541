import { Flex, Skeleton } from '@chakra-ui/react';
import clsx from 'clsx';
import Image from 'next/image';
import type { ComponentProps, ReactNode } from 'react';
import { P, match } from 'ts-pattern';

import { GetFund, GetFundPositions } from '@endaoment-frontend/api';
import type { UUID } from '@endaoment-frontend/types';
import { AllocationIcon, ClaimedIcon, InTransitIcon, StarIcon } from '@endaoment-frontend/ui/icons';
import { MultiProgressBar } from '@endaoment-frontend/ui/shared';
import { convertUsdcToNumber, formatCurrency, formatStringSize, formatUsdc } from '@endaoment-frontend/utils';

import styles from './FundAllocationBar.module.scss';

export const AggregatedFundAllocationBar = ({
  invested,
  grantable,
  completed,
  inTransit,
}: {
  invested: bigint;
  grantable: bigint;
  completed: bigint;
  inTransit: bigint;
}) => {
  const progressParts: ComponentProps<typeof MultiProgressBar>['parts'] = [
    {
      color: '#9d93e6',
      progress: convertUsdcToNumber(invested),
    },
    {
      color: '#BFA9D110',
      secondColor: '#BFA9D1',
      progress: convertUsdcToNumber(inTransit),
    },
    {
      color: '#EA6B0E80',
      progress: convertUsdcToNumber(grantable),
    },
    {
      color: '#53ACDE80',
      progress: convertUsdcToNumber(completed),
    },
  ];

  return (
    <div>
      <MultiProgressBar parts={progressParts} breakdown />
      <Flex
        flexDirection='row'
        justifyContent='space-between'
        alignItems='flex-start'
        className={styles['text-container']}
        gap='1rem'>
        <Flex gap='0.25rem' as='span' data-relation='allocations' align='center'>
          <AllocationIcon width={18} height={18} color='currentColor' />
          <b>{formatCurrency(formatUsdc(invested))}</b>
          {' Invested'}
        </Flex>
        <Flex gap={[0, '0.5rem']} alignItems={['flex-end', 'center']} flexDirection={['column', 'row']}>
          <Flex gap='0.25rem' as='span' data-relation='grantable' align='center'>
            <StarIcon width={18} height={18} color='currentColor' />
            <b>{formatCurrency(formatUsdc(grantable))}</b>
            {' Grantable'}
          </Flex>
          <Flex gap='0.25rem' as='span' data-relation='granted' align='center'>
            <ClaimedIcon width={18} height={18} color='currentColor' />
            <b>{formatCurrency(formatUsdc(completed))}</b>
            {' Granted'}
          </Flex>
        </Flex>
      </Flex>
      {!!inTransit && (
        <Flex
          justifyContent='space-between'
          alignItems='center'
          className={clsx(styles['text-container'], styles['text-container--secondary'])}
          pt='0.5rem'
          pl='0.125rem'>
          <Flex flexDir='row' gap='0.5rem'>
            <Flex gap='0.25rem' as='span' data-relation='in-transit' align='center'>
              <InTransitIcon width={18} height={18} color='currentColor' />
              <b>{formatCurrency(formatUsdc(inTransit))}</b> In Transit
            </Flex>
          </Flex>
        </Flex>
      )}
    </div>
  );
};

export const FundAllocationBar = ({
  fundId,
  portfolioId,
  positionChange,
  isDeposit,
  estimatedNewGrantable,
  showGranted,
  showPositionFirst,
  actionButton,
}: {
  fundId: UUID;
  portfolioId?: UUID;
  positionChange?: bigint;
  estimatedNewGrantable?: bigint;
  isDeposit?: boolean;
  showGranted?: boolean;
  showPositionFirst?: boolean;
  actionButton?: ReactNode;
}) => {
  const { data: fund } = GetFund.useQuery([fundId]);
  const position = GetFundPositions.useQuery([fundId], { enabled: !!portfolioId }).data?.positions.find(
    p => p.portfolio.id === portfolioId,
  );

  const totalPositionsAmount = fund ? fund.investedUsdc : 0n;
  const otherAllocationsAmount = position ? totalPositionsAmount - position.currentMarketValue : totalPositionsAmount;
  const inTransitUsdc = match({ position, fund })
    .returnType<bigint>()
    .with(
      { position: P.not(P.nullish) },
      ({ position }) => position.inTransitBuyUsdcAmount + position?.inTransitSellUsdcAmount,
    )
    .with({ fund: P.not(P.nullish) }, ({ fund }) => fund.inTransitBuyUsdcAmount + fund.inTransitSellUsdcAmount)
    .otherwise(() => 0n);

  const progressParts: ComponentProps<typeof MultiProgressBar>['parts'] = [];
  progressParts.push({
    color: '#9d93e6',
    progress: convertUsdcToNumber(otherAllocationsAmount),
  });
  if (position) {
    const positionPart = {
      color: '#2F3479',
      progress: convertUsdcToNumber(position.currentMarketValue - (positionChange && !isDeposit ? positionChange : 0n)),
    };
    if (showPositionFirst) {
      progressParts.unshift(positionPart);
    } else {
      progressParts.push(positionPart);
    }
  }
  if (positionChange) {
    progressParts.push({
      color: isDeposit ? '#1d1db7' : '#e05606',
      secondColor: isDeposit ? '#1d1db780' : '#e0560680',
      progress: convertUsdcToNumber(positionChange ?? 0n),
    });
  }
  if (inTransitUsdc) {
    const positionPart = {
      color: '#BFA9D110',
      secondColor: '#BFA9D1',
      progress: convertUsdcToNumber(inTransitUsdc),
    };
    if (showPositionFirst) {
      progressParts.push(positionPart);
    } else {
      progressParts.splice(1, 0, positionPart);
    }
  }
  if (fund) {
    progressParts.push({
      color: '#EA6B0E80',
      progress: convertUsdcToNumber(fund.usdcBalance - (positionChange && isDeposit ? positionChange : 0n)),
    });
    if (showGranted) {
      progressParts.push({
        color: '#53ACDE80',
        progress: convertUsdcToNumber(fund.totalGrantedUsdc),
      });
    }
  }

  const positionChangeDescription = !!positionChange && (
    <span data-relation={isDeposit ? 'buy-allocations' : 'sell-allocations'}>
      {isDeposit ? ' +' : ' -'} <b>{formatCurrency(formatUsdc(positionChange))}</b>
      {isDeposit ? ' Buy' : ' Sell'}
    </span>
  );

  return (
    <div>
      <MultiProgressBar parts={progressParts} breakdown />
      <Flex
        flexDirection='row'
        justifyContent='space-between'
        alignItems='flex-start'
        className={styles['text-container']}
        gap={['0.125rem 1rem', '1rem']}
        flexWrap={['wrap', 'nowrap']}>
        <Flex flexDirection='column' flexShrink={0}>
          {position ? (
            <Flex gap={[0, '0.25rem']} alignItems={['flex-start', 'center']} flexDirection={['column', 'row']}>
              <Flex alignItems='center' as='span' flex='1' gap='0.25rem' data-relation='position'>
                <AllocationIcon width={18} height={18} color='currentColor' />
                <b>{formatCurrency(formatUsdc(position.currentMarketValue))}</b>
                <Flex alignItems='center'>
                  {!!position.portfolio.logoUrl && (
                    <Image src={position.portfolio.logoUrl} alt={position.portfolio.name} width={20} height={20} />
                  )}
                  <b>{formatStringSize(position.portfolio.underlyingStockTicker ?? position.portfolio.name, 20)}</b>
                </Flex>
                Allocation
              </Flex>
            </Flex>
          ) : (
            <></>
          )}
          {!!otherAllocationsAmount && !position && (
            <>
              <Flex alignItems='center' as='span' flex='1' gap='0.25rem' data-relation='other-allocations'>
                <AllocationIcon width={18} height={18} color='currentColor' />
                <b>{formatCurrency(formatUsdc(otherAllocationsAmount))}</b>
                {positionChange ? ' Other Allocations' : ' Allocated'}
              </Flex>
            </>
          )}
          {!otherAllocationsAmount && !!positionChange && !position && (
            <Flex alignItems='center' as='span' flex='1' gap='0.25rem' data-relation='other-allocations'>
              <AllocationIcon width={18} height={18} color='currentColor' />
              <b>$0</b>
              {' Allocated'}
            </Flex>
          )}
          {positionChangeDescription}
        </Flex>
        <Flex gap={[0, '0.5rem']} alignItems={['flex-end', 'center']} flexDirection={['column', 'row']}>
          <Skeleton
            textAlign='right'
            isLoaded={!positionChange || estimatedNewGrantable !== undefined}
            alignSelf='flex-end'
            as='span'
            data-relation='grantable'>
            <Flex alignItems='center' as='span' flex='1' gap='0.25rem'>
              <StarIcon width={18} height={18} color='currentColor' />
              <b>{formatCurrency(formatUsdc(estimatedNewGrantable ?? fund?.usdcBalance))}</b>
              {estimatedNewGrantable ? ' Est. New Grantable' : ' Grantable'}
            </Flex>
          </Skeleton>
          {!!showGranted && (
            <Skeleton
              textAlign='right'
              isLoaded={fund?.totalGrantedUsdc !== undefined}
              alignSelf={['flex-start', 'flex-end']}
              as='span'
              data-relation='granted'>
              <Flex alignItems='center' as='span' flex='1' gap='0.25rem'>
                <ClaimedIcon width={18} height={18} color='currentColor' />
                <b>{formatCurrency(formatUsdc(fund?.totalGrantedUsdc))}</b>
                {' Granted'}
              </Flex>
            </Skeleton>
          )}
        </Flex>
      </Flex>
      {!!(!!inTransitUsdc || (!!position && !!otherAllocationsAmount) || actionButton) && (
        <Flex
          justifyContent='space-between'
          alignItems='center'
          className={clsx(styles['text-container'], styles['text-container--secondary'])}
          pt='0.5rem'
          pl='0.125rem'>
          <Flex flexDir={actionButton ? 'column' : 'row'} gap={actionButton ? '0.125rem' : '0.5rem'}>
            {!!position && !!otherAllocationsAmount && (
              <Flex gap='0.25rem' as='span' data-relation='other-allocations' align='center'>
                <AllocationIcon width={16} height={16} color='currentColor' />
                <b data-relation='allocations'>{formatCurrency(formatUsdc(otherAllocationsAmount))}</b>
                {' Other Allocations'}
              </Flex>
            )}
            {!!inTransitUsdc && (
              <Flex gap='0.25rem' as='span' data-relation='in-transit' align='center'>
                <InTransitIcon width={16} height={16} color='currentColor' />
                <b>{formatCurrency(formatUsdc(inTransitUsdc))}</b> In Transit
              </Flex>
            )}
          </Flex>
          {actionButton}
        </Flex>
      )}
    </div>
  );
};
