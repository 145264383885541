import clsx from 'clsx';
import type { ReactElement } from 'react';

import type { ImageButtonProps } from './ImageButton';
import { ImageButton } from './ImageButton';
import styles from './ImageButtonRow.module.scss';

type AllowedChildren = ReactElement<HTMLHRElement | ImageButtonProps>;

export const ImageButtonRow = ({
  title,
  children,
  className,
}: {
  title?: string;
  children: AllowedChildren | Array<AllowedChildren>;
  className?: string;
}) => {
  return (
    <section className={clsx(styles['image-button-row'], className)}>
      {!!title && <h3>{title}</h3>}
      <div>{children}</div>
    </section>
  );
};

ImageButtonRow.Button = ImageButton;
