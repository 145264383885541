import type { Chain } from '@wagmi/core';
import { getPublicClient, switchNetwork } from '@wagmi/core';

import { getChainForChainId } from './getDefaults';

type EnsureUserChainOptions = {
  force?: boolean;
};

export const ensureUserChain = async (chainId: number, options?: EnsureUserChainOptions): Promise<Chain> => {
  const currentChainId = getPublicClient().chain.id;

  if (currentChainId === chainId) return getChainForChainId(chainId);

  try {
    return await switchNetwork({ chainId });
  } catch (e) {
    if (e instanceof Error && e.message.includes(`Request of type 'wallet_switchEthereumChain' already pending`)) {
      return getChainForChainId(chainId);
    }
    if (e instanceof Error && e.message.includes(`User rejected the request.`) && options?.force) {
      return ensureUserChain(chainId);
    }

    throw e;
  }
};
