import type { KeyboardEventHandler } from 'react';
import { useEffect, useRef, useState } from 'react';

export const useCarouselScroll = (carouselItemCount: number) => {
  const listRef = useRef<HTMLDivElement>(null);

  const [currentIndex, setCurrentIndex] = useState<number>(carouselItemCount > 1 ? 1 : 0);
  const [scrollPos, setScrollPos] = useState<number>(0);

  const maxIndex = carouselItemCount - 1;

  useEffect(() => {
    if (listRef?.current) {
      const child = listRef.current.children[currentIndex] as HTMLElement;
      setScrollPos(child ? -child.offsetLeft : 0);
    }
  }, [currentIndex]);

  useEffect(() => {
    setCurrentIndex(carouselItemCount > 1 ? 1 : 0);
  }, [carouselItemCount]);

  const incrementIndex = () => {
    if (currentIndex < maxIndex) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const decrementIndex = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = e => {
    if (currentIndex > 0 && e.key === 'Enter') {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return { listRef, scrollPos, currentIndex, maxIndex, incrementIndex, decrementIndex, handleKeyDown };
};
