import { writeEntity } from '@endaoment-frontend/abis';
import { ensureUserChain } from '@endaoment-frontend/multichain';
import type { AssembledTradeResponse } from '@endaoment-frontend/types';

import { generateReactionHook } from './generateReactionHook';

export const useEditPosition = () =>
  generateReactionHook<Pick<AssembledTradeResponse, 'entityAddress' | 'tradeData'>>({
    actionName: 'EDIT_POSITION',
    createTransaction: async ({ args, walletClient }) => {
      const { tradeData, entityAddress } = args;
      const { portfolioAddress, tradeType, amount, callData, chainId } = tradeData;

      // Ensure that the user is on the same chain as the portfolio
      await ensureUserChain(chainId);

      if (tradeType === 'Buy') {
        return writeEntity({
          address: entityAddress,
          functionName: 'portfolioDeposit',
          args: [portfolioAddress, amount, callData],
          account: walletClient.account,
          chainId,
        });
      }

      // On a sell, amount is the number of shares to sell
      return writeEntity({
        address: entityAddress,
        functionName: 'portfolioRedeem',
        args: [portfolioAddress, amount, callData],
        account: walletClient.account,
        chainId,
      });
    },
    createDescription: ({ args }) => `Portfolio ${args.tradeData.tradeType === 'Buy' ? 'Depositing' : 'Redeeming'}`,
  });
