import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { AnimatePresence } from 'framer-motion';
import type { AppProps as NextAppProps } from 'next/app';
import Head from 'next/head';
import type { NextRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { useAsync } from 'react-use';

import { WhoAmI, GetUserIdentity } from '@endaoment-frontend/api';
import { useAuth } from '@endaoment-frontend/authentication';
import { config, featureFlags } from '@endaoment-frontend/config';
import { DEFAULT_META_DESCRIPTION, DEFAULT_META_IMAGE, INTERCOM_APP_ID } from '@endaoment-frontend/constants';
import { DonationWizard, ViewCreditDonationModal } from '@endaoment-frontend/donation-wizard';
import { FundWizard } from '@endaoment-frontend/fund-wizard';
import { PortfolioWizard } from '@endaoment-frontend/portfolio-wizard';
import { TargetAllocationModal } from '@endaoment-frontend/target-allocations';
import type { MetaData } from '@endaoment-frontend/types';
import { Announcement, CookieModal, Loader, StructuredDataScript } from '@endaoment-frontend/ui/shared';
import { IntercomLoader } from '@endaoment-frontend/ui/smart';

import { AppProvider } from '../components/AppProvider';
import { Footer } from '../components/footer';
import { NavBar } from '../components/navBar';
import { TransactionToastController } from '../components/transactionToastController';
import { UnderMaintenance } from '../components/underMaintenance';

import 'swiper/css';
import 'swiper/css/pagination';
import '../styles/autocomplete.scss';
import '../styles/globals.scss';

// Required for proper stringification of BigInts, specially for cacheing and (de)hydration
// This is inserted here so both client and server pick up the polyfil
// @ts-expect-error Override
// eslint-disable-next-line no-extend-native
BigInt.prototype.toJSON = function () {
  return { value: this.toString(), _datatype: 'bigint' };
};

export type AppProps = {
  dehydratedState?: string;
  seo?: Partial<MetaData>;
};

const noOverlayWorkaroundScript = `
  window.addEventListener('error', event => {
    event.stopImmediatePropagation()
  })

  window.addEventListener('unhandledrejection', event => {
    event.stopImmediatePropagation()
  })
`;

const App = ({ Component, pageProps, router }: NextAppProps<AppProps>) => {
  const isRouting = useIsRouting(router);

  /**
   * Have to accept meta tags as a prop due to bug with `next/head`
   * that prevents other components from updating the meta tags
   */
  const seo: MetaData = {
    title: 'Endaoment — Modern Giving for Everyone',
    url: router.pathname,
    description: DEFAULT_META_DESCRIPTION,
    image: DEFAULT_META_IMAGE,
    ...pageProps.seo,
  };
  if (featureFlags.isMaintenanceMode) seo.title = 'Endaoment - Under Maintenance';

  const head = (
    <>
      <Head>
        <link rel='icon' href='/images/favicon.ico' />
        <meta name='viewport' content='width=device-width, initial-scale=1, maximum-scale=1' />

        <title>{seo.title}</title>
        <meta name='description' content={seo.description} />

        <meta property='og:url' content={`${config.baseUrls.app}${seo.url}`} />
        <meta property='og:type' content='website' />
        <meta property='og:title' content={seo.title} />
        <meta property='og:description' content={seo.description} />
        <meta property='og:image' content={seo.image} />

        <meta name='twitter:card' content='summary_large_image' />
        <meta property='twitter:domain' content='app.endaoment.org' />
        <meta property='twitter:url' content={`${config.baseUrls.app}${seo.url}`} />
        <meta name='twitter:title' content={seo.title} />
        <meta name='twitter:description' content={seo.description} />
        <meta name='twitter:image' content={seo.image} />

        {/* Disable indexing in non-production environments */}
        {config.environmentName !== 'production' && <meta name='robots' content='noindex' />}

        {/* Disable Next's error overlay in local development */}
        {!!featureFlags.disableNextErrorOverlay && (
          <script dangerouslySetInnerHTML={{ __html: noOverlayWorkaroundScript }} />
        )}
      </Head>
      <StructuredDataScript
        structuredData={{
          '@type': 'WebApplication',
          'name': 'Endaoment',
          'description': DEFAULT_META_DESCRIPTION,
          'applicationCategory': 'Finance',
          'offers': {
            '@type': 'Offer',
            'price': '0',
            'priceCurrency': 'USD',
          },
          'operatingSystem': 'Browser',
          'url': `${config.baseUrls.app}${router.pathname}`,
          'image': DEFAULT_META_IMAGE,
        }}
      />
    </>
  );

  // Render under maintenance page if environment variable is set
  if (featureFlags.isMaintenanceMode)
    return (
      <>
        {head}
        <UnderMaintenance />
      </>
    );

  return (
    <>
      {head}
      <AppProvider dehydratedState={pageProps.dehydratedState}>
        {/* Needs to be inside of AppProvider to use authentication state */}
        <IntercomLoaderWithAuth />

        <Announcement>
          <Announcement.Item icon='shield'>
            Be sure you&apos;re visiting <b>{config.baseUrls.app}</b> to donate.
          </Announcement.Item>
        </Announcement>
        <NavBar />
        <main className={clsx('app', isRouting && 'app--routing')}>
          <AnimatePresence mode='wait'>
            {isRouting ? <Loader size='l' /> : <Component {...pageProps} />}
          </AnimatePresence>
        </main>
        <Footer />
        <CookieModal />

        <DonationWizard />
        <FundWizard />
        <PortfolioWizard />
        <TargetAllocationModal />
        <ViewCreditDonationModal />
        <TransactionToastController />
      </AppProvider>
    </>
  );
};

const useIsRouting = (router: NextRouter) => {
  const [isRouting, setIsRouting] = useState(false);
  const pathRef = useRef(router.pathname);

  useEffect(() => {
    const handleChangeStart = () => {
      // Prevent setting state if the path hasn't changed
      if (router.pathname === pathRef.current) return;

      pathRef.current = router.pathname;
      setIsRouting(true);

      // TODO: Decide if we want to scroll to top on route change
      // if (typeof window === 'undefined') return;
      // window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const handleChangeComplete = () => {
      setIsRouting(false);
    };

    router.events.on('routeChangeStart', handleChangeStart);
    router.events.on('routeChangeComplete', handleChangeComplete);

    return () => {
      router.events.off('routeChangeStart', handleChangeStart);
      router.events.off('routeChangeComplete', handleChangeComplete);
    };
  }, [router]);

  return isRouting;
};

const IntercomLoaderWithAuth = () => {
  const { isSignedIn } = useAuth();
  const queryClient = useQueryClient();
  useAsync(async () => {
    if (typeof window === 'undefined' || !window.Intercom) return;

    if (!isSignedIn) {
      // Clear user identifiers from Intercom when user signs out
      window.Intercom('update', {
        app_id: INTERCOM_APP_ID,
        name: undefined,
        email: undefined,
        phone: undefined,
        user_id: undefined,
        user_hash: undefined,
      });
      return;
    }

    // When a user signs in, update their info in Intercom
    const user = await WhoAmI.fetchFromQueryClient(queryClient, []);
    const userIdentity = await GetUserIdentity.fetchFromQueryClient(queryClient, []);
    window.Intercom('update', {
      app_id: INTERCOM_APP_ID,
      user_id: user.userId,
      name: `${userIdentity.firstName} ${userIdentity.lastName}`,
      email: userIdentity.email,
    });
  }, [isSignedIn]);

  return <IntercomLoader />;
};

export default App;
