import {
  createParser,
  createSerializer,
  parseAsBoolean,
  parseAsFloat,
  parseAsInteger,
  parseAsJson,
  parseAsStringEnum,
} from 'nuqs';
import { z } from 'zod';

import {
  bigIntSchema,
  donationRecipientSchema,
  evmTokenSchema,
  stockTickerSchema,
  uuidSchema,
} from '@endaoment-frontend/types';

const parseAsUuid = createParser({
  parse: v => {
    try {
      return uuidSchema.parse(v);
    } catch {
      return null;
    }
  },
  serialize: v => v,
});
const parseAsBigInt = createParser({
  parse: v => {
    try {
      return bigIntSchema.parse(v);
    } catch {
      return null;
    }
  },
  serialize: v => v.toString(),
});

export const targetAllocationModalParsers = {
  isTargetAllocationModalOpen: parseAsBoolean.withDefault(false).withOptions({
    clearOnDefault: true,
  }),
  targetAllocationFundId: parseAsUuid,
} as const;

export const donationWizardParsers = {
  isDonationWizardOpen: parseAsBoolean.withDefault(false).withOptions({
    clearOnDefault: true,
  }),
  dwMode: parseAsStringEnum([
    'bank-donation',
    'brokerage-donation',
    'credit-donation',
    'erc-donation',
    'grant',
    'nft-donation',
    'otc-donation',
  ]),
  dwRecipient: parseAsJson(donationRecipientSchema.parse),
  dwAmount: parseAsBigInt.withDefault(0n).withOptions({
    clearOnDefault: true,
  }),
  creditPledgeAmount: parseAsInteger.withDefault(0).withOptions({
    clearOnDefault: true,
  }),
  dwIncludeTaxReceipt: parseAsBoolean.withDefault(true).withOptions({
    clearOnDefault: true,
  }),
  ercToken: parseAsJson(evmTokenSchema.parse),
  grantOriginId: parseAsUuid,
  otcTokenId: parseAsInteger,
  brokerageTicker: parseAsJson(stockTickerSchema.parse),
  brokerageShares: parseAsFloat.withDefault(0).withOptions({
    clearOnDefault: true,
  }),
  isRebalanceRequested: parseAsBoolean.withDefault(true).withOptions({
    clearOnDefault: true,
  }),
} as const;

export const fundWizardParsers = {
  isFundWizardOpen: parseAsBoolean.withDefault(false).withOptions({
    clearOnDefault: true,
  }),
  fundWizardMode: parseAsStringEnum(['create', 'migrate']),
  fundWizardInitial: parseAsJson(
    z.object({
      initialDestinationFundId: uuidSchema.nullish(),
    }).parse,
  ),
} as const;

export const portfolioWizardParsers = {
  isPortfolioWizardOpen: parseAsBoolean.withDefault(false).withOptions({
    clearOnDefault: true,
  }),
  portfolioWizardFundId: parseAsUuid,
  portfolioWizardPortfolioId: parseAsUuid,
  portfolioWizardAmount: parseAsBigInt.withDefault(0n).withOptions({
    clearOnDefault: true,
  }),
  portfolioWizardIsDeposit: parseAsBoolean.withDefault(true).withOptions({
    clearOnDefault: true,
  }),
} as const;

export const assembleSearchParamsForWizards = createSerializer({
  ...donationWizardParsers,
  ...fundWizardParsers,
  ...portfolioWizardParsers,
  ...targetAllocationModalParsers,
});
export type SearchParamsUsedByWizards = Parameters<typeof assembleSearchParamsForWizards>[1];
