import { useRouter } from 'next/router';
import { useDisconnect } from 'wagmi';

import { PRIVACY_POLICY_URL, TERMS_AND_CONDITIONS_URL } from '@endaoment-frontend/constants';
import { UserAvatar } from '@endaoment-frontend/multichain';
import { routes } from '@endaoment-frontend/routes';
import { InfoIcon } from '@endaoment-frontend/ui/icons';
import { Button, Loader, Tooltip } from '@endaoment-frontend/ui/shared';
import { formatShortAddress } from '@endaoment-frontend/utils';

import { useAccountDisplayName, useAuthType } from '../socialAuth';
import { useAuth } from '../useAuth';

import styles from './ProfileView.module.scss';

export const ProfileView = ({ onClose }: { onClose: () => void }) => {
  const router = useRouter();
  const { isWalletAuth } = useAuthType();
  const { connectedAddress, connector } = useAuth();
  const accountName = useAccountDisplayName();

  const { signIn, isSignedIn, isLoading, signOut } = useAuth();
  const { disconnect } = useDisconnect();

  if (!connectedAddress || !connector) return <></>;

  const handleSignOut = () => {
    if (isSignedIn) {
      signOut();
    } else {
      disconnect();
    }
    onClose();
  };

  const handleRouteToDashboard = () => {
    router.push(routes.app.dashboard());
    onClose();
  };

  const shortAddress = formatShortAddress(connectedAddress);

  return (
    <>
      <div className={styles.header}>
        <UserAvatar address={connectedAddress} />
        <h3 className={styles.email}>{accountName}</h3>
        {isWalletAuth ? (
          accountName !== shortAddress && <span className={styles['address']}>{shortAddress}</span>
        ) : (
          <Tooltip
            content='This is an onchain identifier created for your connected social account.'
            className={styles['address']}>
            {formatShortAddress(connectedAddress)} <InfoIcon width={16} color='currentColor' />
          </Tooltip>
        )}
      </div>
      {!isSignedIn ? (
        <div className={styles.info}>
          <p>
            {'Please confirm you agree to our '}
            <a href={TERMS_AND_CONDITIONS_URL} target='_blank' rel='noreferrer'>
              terms and conditions
            </a>
            {' and '}
            <a href={PRIVACY_POLICY_URL} target='_blank' rel='noreferrer'>
              privacy policy
            </a>
            .
          </p>
          <div className={styles.buttons}>
            <Button onClick={signIn} size='small' float={false} filled variation='purple' disabled={isLoading}>
              {!!isLoading && <Loader size='s' loading white />}I agree
            </Button>
            <Button onClick={handleSignOut} size='small' float={false}>
              Disconnect
            </Button>
          </div>
        </div>
      ) : (
        <>
          <div className={styles.info}>
            Authenticated via <strong>{connector.name}</strong>
          </div>
          <hr />
          <div className={styles.buttons}>
            <Button onClick={handleRouteToDashboard} size='small' float={false} filled variation='purple'>
              Go to Dashboard
            </Button>
            <Button onClick={handleSignOut} size='small' float={false}>
              Sign out
            </Button>
          </div>
        </>
      )}
    </>
  );
};
