import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import type { Connector } from 'wagmi';
import { z } from 'zod';

import { TIME_ONE_DAY_IN_SECONDS } from '@endaoment-frontend/constants';
import { useEnsNameWithFallback } from '@endaoment-frontend/multichain';
import { formatShortAddress } from '@endaoment-frontend/utils';

import type { SocialConnector } from './socialConnectors';
import { isSocialConnector } from './socialConnectors';
import { useAuth } from './useAuth';

const getSocialConnectorUser = async (connector: SocialConnector) => connector.web3Auth?.getUserInfo();

export const getUserEmail = async (connector: Connector) => {
  if (!isSocialConnector(connector)) return undefined;

  const user = await getSocialConnectorUser(connector);
  const email = user?.email;
  if (!email) return undefined;

  const emailParse = z.string().email().safeParse(email);
  if (!emailParse.success) return undefined;
  return emailParse.data;
};

export const getSocialUsername = async (connector: SocialConnector) => {
  const user = await getSocialConnectorUser(connector);
  const displayName = user?.email ?? user?.name ?? user?.idToken;
  if (!displayName) throw new Error('No name found for social user');
  return displayName;
};

const getAuthType = (connector?: Connector) => {
  if (!connector) return undefined;
  return isSocialConnector(connector) ? 'social' : 'wallet';
};

/**
 * Gets whether the currently logged-in user is logged in via social or wallet
 */
export const useAuthType = () => {
  try {
    const { isConnected, connector } = useAuth();
    const authType = getAuthType(connector);

    // TODO: Cleanup this return type so it is in line with the "useAuthType" function label and description. In this
    //  case we want to remove "connector" and "isConnected" from the return type, as they are not related to what this
    //  function is supposed to return.
    return {
      connector,
      authType,
      isConnected,
      isWalletAuth: authType === 'wallet',
      isSocialAuth: authType === 'social',
    } as const;
  } catch (e) {
    // Allow use of this hook outside of a blockchain connected context, defaulting to an unconnected state
    if (e instanceof Error && e.message.includes('`useConfig` must be used within `WagmiConfig`.'))
      return {
        connector: undefined,
        authType: undefined,
        isConnected: false,
        isWalletAuth: false,
        isSocialAuth: false,
      } as const;
    throw e;
  }
};

export const useAccountDisplayName = () => {
  const { connectedAddress, connector } = useAuth();
  const { authType } = useAuthType();

  const { data: ensName } = useEnsNameWithFallback({
    address: connectedAddress,
    cacheTime: 7 * TIME_ONE_DAY_IN_SECONDS * 1000,
    enabled: !!connector && authType === 'wallet',
  });
  const { data: socialName } = useQuery({
    queryKey: ['SocialAccountName', connectedAddress],
    queryFn: async () => {
      if (!connector || !isSocialConnector(connector)) return undefined;
      return getSocialUsername(connector);
    },
    enabled: !!connector && authType === 'social',
  });

  const simpleName = connectedAddress ? formatShortAddress(connectedAddress) : '';
  const displayName = useMemo(() => {
    if (!connector) return simpleName;
    const isSocial = isSocialConnector(connector);
    if (!isSocial) return ensName ?? simpleName;
    return socialName ?? simpleName;
  }, [connector, connectedAddress, ensName, socialName, simpleName]);

  return displayName;
};
