import { z } from 'zod';

import { COUNTRY_ISO3166_ALPHA3, COUNTRY_ISO3166_ALPHA3_NOT_US, US_STATES } from '@endaoment-frontend/constants';

import type { Address } from '../web3';

const genericPhysicalAddressSchema = z.object({
  line1: z.string().trim().min(1, 'Please enter an address'),
  line2: z.string().nullish(),
  city: z.string().trim().min(1, 'Please enter a city'),
});
const physicalAddressUSSchema = genericPhysicalAddressSchema.extend({
  state: US_STATES,
  zip: z.string().refine(val => /\d{5}/.test(val)),
  country: z.literal(COUNTRY_ISO3166_ALPHA3.enum.USA),
});
const physicalAddressGlobalSchema = genericPhysicalAddressSchema.extend({
  state: z.string().nullish(),
  zip: z.string().nullish(),
  // Removing USA from the list of countries because we already have a schema for it above
  country: COUNTRY_ISO3166_ALPHA3_NOT_US,
});
export const physicalAddressSchema = z.discriminatedUnion('country', [
  physicalAddressUSSchema,
  physicalAddressGlobalSchema,
]);

export type PhysicalAddress = z.infer<typeof physicalAddressSchema>;

export const userIdentityInfoSchema = z.object({
  firstName: z.string().trim().min(1),
  lastName: z.string().trim().min(1),
  email: z.string().email(),
  address: physicalAddressSchema,
});
export type UserIdentityInfo = z.infer<typeof userIdentityInfoSchema>;

export type LoginDTO = {
  signature: string;
  walletAddress: Address;
  signatureDateUtc: string;
  email?: string;
  userSalutation?: string;
};
