import { Link } from '@chakra-ui/next-js';
import { Tooltip } from '@chakra-ui/react';
import clsx from 'clsx';
import type { ReactNode } from 'react';
import { P, match } from 'ts-pattern';

import { GetFund, GetFundPositions } from '@endaoment-frontend/api';
import { routes } from '@endaoment-frontend/routes';
import type { EntityPositionSummary, FundListing, TransactionStatus, UUID } from '@endaoment-frontend/types';
import { LoadingIcon, TargetAllocationIcon } from '@endaoment-frontend/ui/icons';
import { Button } from '@endaoment-frontend/ui/shared';

import styles from './TargetAllocationSection.module.scss';
import { CalculateEntityRebalance } from './requests';
import type { TargetAllocationRebalanceOperation } from './types';
import { useRebalanceFund } from './useRebalanceFund';

/**
 * Determine the ability to rebalance a fund based on the current state of the fund and its positions.
 *
 * Possible states:
 * - balanced
 * - balancing transaction in progress
 * - rebalance available
 * - rebalance in progress
 * - rebalance failed
 * - empty fund
 * - fund has portfolio trades in transit
 */
const computeRebalancability = (
  fund: FundListing | undefined,
  positionSummary: EntityPositionSummary | undefined,
  operations: Array<TargetAllocationRebalanceOperation> | undefined,
  rebalanceTransactionStatus: TransactionStatus,
) => {
  const canRebalanceFromOps = !!operations && operations.length > 0 && operations.some(op => op.readyForExecution);

  const isEmptyFund = !fund || fund.usdcBalance === 0n;
  const isBalancing =
    !!operations && (rebalanceTransactionStatus === 'pending' || rebalanceTransactionStatus === 'waiting');
  const isRebalanceTransactionSuccessful = !!operations && rebalanceTransactionStatus === 'success';
  const isBalanced = !canRebalanceFromOps && !isBalancing && !isEmptyFund;
  const fundHasPortfolioTradesInTransit =
    !!fund && positionSummary?.positions.some(p => p.inTransitBuyUsdcAmount > 0 || p.inTransitSellUsdcAmount > 0);
  const canRebalance = canRebalanceFromOps && !isBalancing && !fundHasPortfolioTradesInTransit && !isEmptyFund;

  return {
    canRebalance,
    isEmptyFund,
    isBalancing,
    isRebalanceTransactionSuccessful,
    isBalanced,
    fundHasPortfolioTradesInTransit,
  };
};

export const RebalanceButton = ({
  fundId,
  isNearTargetAllocations = false,
  children,
}: {
  fundId: UUID;
  isNearTargetAllocations?: boolean;
  children?: ReactNode;
}) => {
  const { execute, status } = useRebalanceFund();
  const { data: rebalanceOps } = CalculateEntityRebalance.useQuery(['fund', fundId, false]);
  const { data: fund } = GetFund.useQuery([fundId]);
  const { data: fundPositions } = GetFundPositions.useQuery([fundId]);

  const currentRebalanceStatus = computeRebalancability(fund, fundPositions, rebalanceOps, status);
  const tooltipText = match({ ...currentRebalanceStatus, isNearTargetAllocations })
    .returnType<string>()
    .with({ isEmptyFund: true }, () => `This fund is empty`)
    .with({ isBalancing: true }, () => `Rebalancing in progress`)
    .with({ isRebalanceTransactionSuccessful: true }, () => `Rebalance successful!`)
    .with({ fundHasPortfolioTradesInTransit: true }, () => `This fund has portfolio trades in transit`)
    .with(
      { isNearTargetAllocations: true, isBalanced: true },
      () =>
        `The positions within this fund are not within the qualified target allocation range for a collective rebalance.`,
    )
    .with({ isBalanced: true }, () => `All positions are within target. No rebalance needed!`)
    .otherwise(() => '');
  const { canRebalance, isBalancing, isBalanced, isEmptyFund } = currentRebalanceStatus;

  const handleClick = () => {
    if (!canRebalance) return;
    execute({
      fundId,
    });
  };

  if (isEmptyFund) {
    return (
      <div className={styles['empty-fund-warning']}>
        <h6>This fund is empty!</h6>
        <p>Add assets to distribute to your target allocation.</p>
        <Button
          as={Link}
          href={routes.app.fund({
            id: fundId,
            wizardParams: { isDonationWizardOpen: true, dwRecipient: { id: fundId, type: 'fund' } },
            useFullUrl: false,
          })}
          shallow
          filled
          variation='fund'
          gradient
          size='medium'
          className={styles['donate-button']}>
          Add Assets to Fund
        </Button>
      </div>
    );
  }

  return (
    <Tooltip label={tooltipText} placement='top'>
      <Button
        onClick={handleClick}
        filled
        variation='allocation'
        gradient
        className={clsx(styles['allocation-button'], isBalanced && styles['allocation-button--balanced'])}
        float={canRebalance}
        disabled={!canRebalance}>
        {match({ children, status, isBalancing, isBalanced, isNearTargetAllocations })
          .with({ children: P.not(P.nullish) }, () => children)
          .with({ status: 'waiting', isBalancing: true }, () => (
            <>
              <LoadingIcon color='currentColor' spinning />
              Waiting for signature
            </>
          ))
          .with({ isBalancing: true }, () => (
            <>
              <LoadingIcon color='currentColor' spinning />
              Balancing...
            </>
          ))
          .with({ isNearTargetAllocations: true, isBalanced: true }, () => (
            <>
              <TargetAllocationIcon color='currentColor' />
              No Rebalance Available
            </>
          ))
          .with({ isBalanced: true }, () => (
            <>
              <TargetAllocationIcon color='currentColor' />
              Balanced
            </>
          ))
          .otherwise(() => (
            <>
              <TargetAllocationIcon color='currentColor' />
              Balance Positions
            </>
          ))}
      </Button>
    </Tooltip>
  );
};
