import clsx from 'clsx';
import type { ChangeEvent, ComponentPropsWithoutRef } from 'react';
import { useEffect, useState } from 'react';
import { formatUnits, parseUnits } from 'viem';

import { formatCurrency } from '@endaoment-frontend/utils';

import styles from './BigNumberInput.module.scss';
import { Input } from './Input';

const inputRegEx = RegExp(`^\\d*(?:\\\\[.])?\\d*$`);
// Convert any characters that could be used to form a RegExp into string literals (removing this messes with input for the '.' character)
const escapeRegEx = (str: string) => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string

export type BigNumberInputProps = Omit<ComponentPropsWithoutRef<typeof Input>, 'onBlur' | 'onChange' | 'value'> & {
  value?: bigint;
  onChange?: (num: bigint) => void;
  onBlur?: () => void;
  units: number;
  isDollars?: boolean;
};

const formatInternal = (num: bigint, options: { units: number; isDollars: boolean }): string => {
  if (num === 0n) return '';

  const formatted = formatUnits(num, options.units);
  if (options.isDollars) {
    return formatCurrency(formatted, { fraction: 2 }).slice(1).replace(/,/g, '');
  }

  return formatted;
};
const parseInternal = (str: string, units: number): bigint => {
  if (str === '' || str === '.') return 0n;

  // Must escape any invalid characters
  if (!inputRegEx.test(escapeRegEx(str))) throw new Error('Invalid input');

  // Do not allow underflow
  const decimalIndex = str.indexOf('.');
  if (decimalIndex !== -1 && str.slice(decimalIndex + 1).length > units) throw new Error('Invalid input');

  return parseUnits(str, units);
};
const shouldSetInternal = (str: string, units: number) => {
  // Must escape any invalid characters
  if (!inputRegEx.test(escapeRegEx(str))) return false;

  // Do not allow underflow or input to exceed max units
  const decimalIndex = str.indexOf('.');
  if (decimalIndex !== -1 && str.slice(decimalIndex + 1).length > units) return false;

  return true;
};

export const BigNumberInput = ({
  value = 0n,
  onChange,
  onBlur,
  units,
  isDollars = false,
  ...props
}: BigNumberInputProps) => {
  const [internalValue, setInternalValue] = useState(() => formatInternal(value, { units, isDollars }));

  useEffect(() => {
    if (parseInternal(internalValue, units) !== value) {
      setInternalValue(formatInternal(value, { units, isDollars }));
    }
  }, [value, units, isDollars, internalValue]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const raw = e.currentTarget.value;

    if (shouldSetInternal(raw, units)) {
      setInternalValue(raw);

      const parsed = parseInternal(raw, units);
      if (parsed !== value) onChange?.(parsed);
    }
  };
  const handleBlur = () => {
    // Reformats user input to be valid once they are finished typing
    if (isDollars) {
      setInternalValue(prev => formatCurrency(prev, { fraction: 2 }).slice(1).replace(/,/g, ''));
    }

    onBlur?.();
  };

  return (
    <Input
      value={internalValue}
      onBlur={handleBlur}
      onChange={handleChange}
      placeholder='0'
      inputMode='decimal'
      autoComplete='off'
      autoCorrect='off'
      type='text'
      pattern='^[0-9]*[.,]?[0-9]*$'
      minLength={1}
      maxLength={16}
      spellCheck='false'
      leftElements={isDollars ? <span>$</span> : false}
      inputClassName={clsx(styles['right-align'], props.inputClassName)}
      {...props}
    />
  );
};
