import { ButtonGroup, Tooltip as ChakraTooltip, Skeleton, Tooltip } from '@chakra-ui/react';
import type { QueryClient } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { Form, Formik, useFormikContext } from 'formik';
import Image from 'next/image';
import { useDebounce } from 'use-debounce';
import { parseUnits } from 'viem';
import { z } from 'zod';

import { AssembleTrade, GetFund, GetFundPositions, GetPortfolio } from '@endaoment-frontend/api';
import { PRICE_IMPACT_WARNING_THRESHOLD, STABLECOIN_DECIMALS } from '@endaoment-frontend/constants';
import { getFetchErrorMessage } from '@endaoment-frontend/data-fetching';
import type { EntityPositionSummary, UUID } from '@endaoment-frontend/types';
import { bigIntSchema } from '@endaoment-frontend/types';
import { BigNumberInput, ErrorDisplay, ProceedButton, validateWithZod } from '@endaoment-frontend/ui/forms';
import { InfoIcon } from '@endaoment-frontend/ui/icons';
import { Button, Card, Pill } from '@endaoment-frontend/ui/shared';
import {
  FundAllocationBar,
  MiniFundDetailsWithQuery,
  MiniLoadingDetails,
  MiniPortfolioDetails,
} from '@endaoment-frontend/ui/smart';
import { convertUsdcToNumber, formatCurrency, formatUsdc } from '@endaoment-frontend/utils';

import wizardStyles from '../PortfolioWizard.module.scss';

import styles from './AmountStep.module.scss';

const MINIMUM_USDC = parseUnits(1n.toString(), STABLECOIN_DECIMALS);

type AmountStepFormValues = {
  amount: bigint;
  isDeposit: boolean;
};

// Validate that amount user is trying to sell is less than or equal to their position
const checkIfSellIsValid = async (
  values: AmountStepFormValues,
  portfolioId: UUID,
  queryClient: QueryClient,
  positionSummary?: EntityPositionSummary,
): Promise<void> => {
  if (!positionSummary) throw new Error('Fetching positions, please try again in a few seconds');
  const position = positionSummary.positions.find(p => p.portfolio.id === portfolioId);
  if (!position) throw new Error("You don't have a position in this fund");

  if (values.amount > position.currentMarketValue) {
    throw new Error('Amount must be less than or equal to your position');
  }

  const MIN_REDEEMABLE_USDC = 100_000_000n;
  if (position.portfolio.type === 'TPlusN' && values.amount < MIN_REDEEMABLE_USDC) {
    throw new Error(
      `Amount must be greater than or equal to ${formatCurrency(
        formatUsdc(MIN_REDEEMABLE_USDC),
      )} in order to redeem from the selected portfolio`,
    );
  }
};

export const AmountStepForm = ({
  fundId,
  portfolioId,
  canBuy,
  canSell,
}: {
  fundId: UUID;
  portfolioId: UUID;
  canBuy: boolean;
  canSell: boolean;
}) => {
  const { values, setFieldValue, errors, handleSubmit: submitForm } = useFormikContext<AmountStepFormValues>();

  const queryClient = useQueryClient();
  const { data: portfolio } = GetPortfolio.useQuery([portfolioId]);
  const { data: fund } = GetFund.useQuery([fundId]);
  const { data: positionSummary } = GetFundPositions.useQuery([fundId]);
  const currentPosition = positionSummary
    ? positionSummary.positions.find(p => p.portfolio.id === portfolioId)
    : undefined;
  const hasPosition = !!currentPosition;

  const [debouncedAmount, { isPending }] = useDebounce(values.amount, 1000);
  const {
    data: assembledTrade,
    isLoading,
    error: assembleTradeError,
  } = AssembleTrade.useQuery(
    [
      queryClient,
      {
        issuerEntityId: fundId,
        issuerEntityType: 'fund',
        portfolioId: portfolioId,
        amountUsdc: debouncedAmount,
        tradeType: values.isDeposit ? 'Buy' : 'Sell',
      },
    ],
    {
      enabled: !!debouncedAmount,
    },
  );

  const isAssemblingTrade = isLoading || isPending();
  const isTPlusNSelected = portfolio?.type === 'TPlusN';
  const shouldShowMinDeposit = isTPlusNSelected && values.isDeposit && !!portfolio?.minDeposit;
  const priceImpact = assembledTrade?.tradeData.priceImpact;
  const minimumNewGrantableBalance = assembledTrade?.estimations.minimumNewGrantableBalanceUsdc;
  const minimumNewEstimatedPosition = assembledTrade?.estimations.minimumNewEstimatedPositionUsdc;
  const showMinimumNewGrantableBalance =
    priceImpact && priceImpact > PRICE_IMPACT_WARNING_THRESHOLD && minimumNewGrantableBalance;
  const showMinimumNewPosition =
    priceImpact && priceImpact > PRICE_IMPACT_WARNING_THRESHOLD && !!minimumNewEstimatedPosition;
  const showPriceImpact = priceImpact && priceImpact > PRICE_IMPACT_WARNING_THRESHOLD;
  const formattedPriceImpact = priceImpact ? Math.round(priceImpact * 100) / 100 : 0;
  const showDepositFee = !!assembledTrade && values.isDeposit && assembledTrade.estimations.estimatedFee > 0n;
  const showSellFee = !!assembledTrade && !values.isDeposit && assembledTrade.estimations.estimatedFee > 0n;

  /**
   * @param percent A 0n - 100n value representing the percentage of the user's balance to allocate
   */
  const handlePercentButtonClick = (percent: bigint) => () => {
    if (values.isDeposit && fund) setFieldValue('amount', (fund.usdcBalance * percent) / 100n);
    if (!values.isDeposit && currentPosition)
      setFieldValue('amount', (currentPosition.currentMarketValue * percent) / 100n);
  };

  const detectPercent = (percent: bigint) => {
    if (values.isDeposit && fund)
      return Math.abs(convertUsdcToNumber((fund.usdcBalance * percent) / 100n - values.amount)) < 0.01;
    if (!values.isDeposit && currentPosition)
      return (
        Math.abs(convertUsdcToNumber((currentPosition.currentMarketValue * percent) / 100n - values.amount)) < 0.01
      );
  };

  const handleSubmit = async () => {
    // Make sure we have the latest assembled trade before submitting
    await AssembleTrade.fetchFromQueryClient(queryClient, [
      queryClient,
      {
        issuerEntityId: fundId,
        issuerEntityType: 'fund',
        portfolioId: portfolioId,
        amountUsdc: values.amount,
        tradeType: values.isDeposit ? 'Buy' : 'Sell',
      },
    ]);
    submitForm();
  };

  return (
    <Form>
      <Card noPadding noShadow>
        {portfolio ? <MiniPortfolioDetails portfolio={portfolio} /> : <MiniLoadingDetails />}
      </Card>
      <hr />
      <h4>
        {!!canBuy && !!canSell && 'What change do you want to your allocation'}
        {!!canBuy && !canSell && 'How much do you want to allocate'}
        {!!canSell && !canBuy && 'How much do you want to sell'}
      </h4>
      <Card noPadding noShadow className={clsx(wizardStyles['extended-card'], styles['input-card'])}>
        <MiniFundDetailsWithQuery fundId={fundId} />
        <FundAllocationBar
          fundId={fundId}
          portfolioId={portfolioId}
          positionChange={values.amount}
          isDeposit={values.isDeposit}
          estimatedNewGrantable={assembledTrade?.estimations.estimatedNewGrantableBalanceUsdc}
        />
        {portfolio ? (
          <>
            <hr />
            <p className={styles['position-change-notification']}>
              {hasPosition ? 'Change' : 'New'}
              {!!portfolio.logoUrl && <Image src={portfolio.logoUrl} alt={portfolio.name} width={20} height={20} />}
              <b>{portfolio.underlyingStockTicker ?? portfolio.name}</b> Position
            </p>
          </>
        ) : (
          <></>
        )}
        <AmountInput
          amount={values.amount}
          isDeposit={values.isDeposit}
          onAmountChange={v => setFieldValue('amount', v)}
          onAmountBlur={() => {
            if (values.isDeposit && fund && values.amount > fund.usdcBalance) setFieldValue('amount', fund.usdcBalance);
            if (!values.isDeposit && currentPosition && values.amount > currentPosition.currentMarketValue)
              setFieldValue('amount', currentPosition.currentMarketValue);
          }}
          onIsDepositChange={v => setFieldValue('isDeposit', v)}
          canBuy={canBuy}
          canSell={canSell}
        />
        <Skeleton isLoaded={!!fund} className={styles['sub-input']}>
          {!!fund && (
            <>
              <span>{formatCurrency(formatUsdc(fund.usdcBalance))} available to allocate</span>
              <div>
                <Button
                  size='small'
                  variation='org'
                  filled
                  float={false}
                  onClick={handlePercentButtonClick(25n)}
                  className={detectPercent(25n) ? styles['filled'] : undefined}>
                  25%
                </Button>
                <Button
                  size='small'
                  variation='org'
                  filled
                  float={false}
                  onClick={handlePercentButtonClick(50n)}
                  className={detectPercent(50n) ? styles['filled'] : undefined}>
                  50%
                </Button>
                <Button
                  size='small'
                  variation='org'
                  filled
                  float={false}
                  onClick={handlePercentButtonClick(75n)}
                  className={detectPercent(75n) ? styles['filled'] : undefined}>
                  75%
                </Button>
                <Button
                  size='small'
                  variation='org'
                  filled
                  float={false}
                  onClick={handlePercentButtonClick(100n)}
                  className={detectPercent(100n) ? styles['filled'] : undefined}>
                  Max
                </Button>
              </div>
            </>
          )}
        </Skeleton>
      </Card>
      <ErrorDisplay error={errors.amount ?? errors.isDeposit ?? getFetchErrorMessage(assembleTradeError)} />
      <div
        className={clsx(
          wizardStyles['estimation-container'],
          values.amount > MINIMUM_USDC && wizardStyles['estimation-container--hidden'],
        )}>
        <DashedBorder loading={!!isAssemblingTrade && values.amount !== 0n} />
        <div data-estimation-type='grantable-balance'>
          <span>Estimated New Grantable Balance</span>
          <Skeleton as='b' isLoaded={!isAssemblingTrade || values.amount === 0n} data-testid='estimations-new-balance'>
            {!!assembledTrade &&
              formatCurrency(formatUsdc(assembledTrade.estimations.estimatedNewGrantableBalanceUsdc))}
            {!!showSellFee && (
              <span className={styles['fee']}>
                ({formatCurrency(formatUsdc(assembledTrade.estimations.estimatedFee))} fee)
              </span>
            )}
          </Skeleton>
        </div>
        {!!showMinimumNewGrantableBalance && (
          <>
            <hr />
            <div data-estimation-type='min-grantable-balance'>
              <span>Minimum New Grantable Balance</span>
              <b>{formatCurrency(formatUsdc(minimumNewGrantableBalance))}</b>
            </div>
          </>
        )}
        <hr />
        <div data-estimation-type='allocation'>
          <span>Estimated New Position </span>
          <Skeleton as='b' isLoaded={!isAssemblingTrade || values.amount === 0n} data-testid='estimations-new-position'>
            {!!assembledTrade &&
              formatCurrency(
                formatUsdc(
                  assembledTrade.estimations.newEstimatedPositionUsdc > 0n
                    ? assembledTrade.estimations.newEstimatedPositionUsdc
                    : 0n,
                ),
              )}
            {!!showDepositFee && (
              <span className={styles['fee']}>
                ({formatCurrency(formatUsdc(assembledTrade.estimations.estimatedFee))} fee)
              </span>
            )}
          </Skeleton>
        </div>
        {!!showMinimumNewPosition && (
          <>
            <hr />
            <div data-estimation-type='min-allocation'>
              <span>Minimum New Position</span>
              <b>{formatCurrency(formatUsdc(minimumNewEstimatedPosition))}</b>
            </div>
          </>
        )}
        {!!showPriceImpact && (
          <>
            <hr />
            <div data-estimation-type='price-impact'>
              <span>Price Impact</span>
              <b>{formattedPriceImpact}%</b>
            </div>
          </>
        )}
      </div>
      {!!showPriceImpact && (
        <p className={wizardStyles['price-impact']}>
          Due to price impact and slippage impact total portfolio values might decrease upon allocation changes.
        </p>
      )}
      {!!isTPlusNSelected && (
        <p className={wizardStyles['t-n-warning']}>
          <Pill size='tiny' variation='orange' className={wizardStyles['t-n-warning__pill']}>
            Settlement Notice
            {/* TODO: replace copy @RobbieHeeger */}
            <Tooltip label='Settlement time may vary' placement='top'>
              <InfoIcon color='currentColor' />
            </Tooltip>
          </Pill>
          Please note that as you have selected a portfolio which contains assets that require extra time to process.
          The operation may take up to two business days.
        </p>
      )}
      {!!shouldShowMinDeposit && (
        <p className={wizardStyles['price-impact']}>
          {`The portfolio you have selected requires a minimum deposit of ${formatCurrency(
            formatUsdc(portfolio.minDeposit),
          )}.`}
        </p>
      )}
      <ProceedButton className={wizardStyles['proceed']} onClick={handleSubmit} />
    </Form>
  );
};

// The below background image as an actual SVG
export const DashedBorder = ({ loading, strokeOpacity }: { loading?: boolean; strokeOpacity?: number }) => (
  <svg
    className={clsx(wizardStyles['dashed-border'], loading && wizardStyles['dashed-border--loading'])}
    width='100%'
    height='100%'
    xmlns='http://www.w3.org/2000/svg'>
    <rect
      width='100%'
      height='100%'
      fill='none'
      rx='18'
      ry='18'
      stroke='#8478E0'
      strokeOpacity={strokeOpacity ?? 0.2}
      strokeWidth='3'
      strokeDasharray='10, 10'
      strokeDashoffset='0'
      strokeLinecap='round'
    />
  </svg>
);

const AmountInput = ({
  amount,
  isDeposit,
  canBuy,
  canSell,
  onAmountChange,
  onAmountBlur,
  onIsDepositChange,
}: {
  amount?: bigint;
  isDeposit: boolean;
  canBuy: boolean;
  canSell: boolean;
  onAmountChange: (amount: bigint) => void;
  onAmountBlur?: () => void;
  onIsDepositChange: (isDeposit: boolean) => void;
}) => {
  return (
    <>
      <BigNumberInput
        data-testid='portfolio-wizard-amount-input'
        value={amount}
        onChange={onAmountChange}
        onBlur={onAmountBlur}
        isDollars
        units={STABLECOIN_DECIMALS}
        plain
        className={styles['amount-input']}
        rightElements={
          <div className={styles['amount-input__switch']}>
            <ButtonGroup isAttached>
              <ChakraTooltip label='This fund has no balance to allocate' isDisabled={canBuy} placement='top-end'>
                <Button
                  size='small'
                  filled={isDeposit}
                  float={false}
                  onClick={() => {
                    if (canBuy) onIsDepositChange(true);
                  }}
                  disabled={!canBuy}
                  className={clsx(styles['switch-button'], isDeposit && styles['switch-button--active'])}>
                  Buy
                </Button>
              </ChakraTooltip>
              <ChakraTooltip
                label='This fund has no money allocated to this portfolio'
                isDisabled={canSell}
                placement='top-end'>
                <Button
                  size='small'
                  filled={!isDeposit}
                  float={false}
                  onClick={() => {
                    if (canSell) onIsDepositChange(false);
                  }}
                  disabled={!canSell}
                  className={clsx(styles['switch-button'], !isDeposit && styles['switch-button--active'])}>
                  Sell
                </Button>
              </ChakraTooltip>
            </ButtonGroup>
          </div>
        }
      />
    </>
  );
};

export const AmountStep = ({
  fundId,
  portfolioId,
  initialValues,
  onIsDepositChange,
  onSubmit,
}: {
  fundId: UUID;
  portfolioId: UUID;
  initialValues: AmountStepFormValues;
  onIsDepositChange: (v: boolean) => void;
  onSubmit: (v: AmountStepFormValues) => void;
}) => {
  const queryClient = useQueryClient();
  const { data: fund } = GetFund.useQuery([fundId]);
  const { data: positionSummary } = GetFundPositions.useQuery([fundId], {
    onSuccess: async summary => {
      // If the user is trying to sell, check that they are able to
      if (!initialValues.isDeposit) {
        try {
          await checkIfSellIsValid(initialValues, portfolioId, queryClient, summary);
        } catch {
          onIsDepositChange(true);
        }
      }
    },
  });
  const canBuy = fund ? fund.usdcBalance > MINIMUM_USDC : false;
  const canSell = positionSummary
    ? positionSummary.positions.some(p => p.portfolio.id === portfolioId && p.currentMarketValue > 0n)
    : false;

  const handleValidate = async (values: AmountStepFormValues) => {
    const errors = validateWithZod(
      z.object({
        amount: bigIntSchema.gt(MINIMUM_USDC, 'Amount must be greater than $1'),
        isDeposit: z.boolean(),
      }),
    )(values);

    if (values.isDeposit) {
      if (!fund) errors.amount = 'Fetching fund, please try again in a few seconds';
      if (fund && values.amount > fund.usdcBalance) {
        errors.amount = `Amount cannot be greater than the fund's balance (${formatCurrency(
          formatUsdc(fund.usdcBalance),
        )})`;
      }

      const portfolio = await GetPortfolio.fetchFromQueryClient(queryClient, [portfolioId]);
      if (typeof portfolio.cap === 'bigint' && portfolio.totalInvestedInPortfolio + values.amount > portfolio.cap) {
        errors.amount = `Trade cannot be completed because it would exceed the portfolio's cap (${formatCurrency(
          formatUsdc(portfolio.cap),
        )})`;
      }
      if (
        portfolio.type === 'TPlusN' &&
        typeof portfolio.minDeposit === 'bigint' &&
        values.amount < portfolio.minDeposit
      ) {
        errors.amount = `The portfolio you have selected requires a minimum deposit of ${formatCurrency(
          formatUsdc(portfolio.minDeposit),
        )}`;
      }
    } else {
      try {
        await checkIfSellIsValid(values, portfolioId, queryClient, positionSummary);
      } catch (e) {
        if (e instanceof Error) errors.amount = e.message;
      }
    }
    return errors;
  };

  return (
    <Formik initialValues={initialValues as AmountStepFormValues} onSubmit={onSubmit} validate={handleValidate}>
      <AmountStepForm fundId={fundId} portfolioId={portfolioId} canBuy={canBuy} canSell={canSell} />
    </Formik>
  );
};
