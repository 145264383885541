import { Link } from '@chakra-ui/next-js';
import { Tooltip } from '@chakra-ui/react';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import Image from 'next/image';
import { useState } from 'react';
import { P, match } from 'ts-pattern';

import { useAuthType } from '@endaoment-frontend/authentication';
import { useIsMobile } from '@endaoment-frontend/hooks';
import { getChainForChainId } from '@endaoment-frontend/multichain';
import { routes } from '@endaoment-frontend/routes';
import type { PortfolioCategory, PortfolioFinancial } from '@endaoment-frontend/types';
import { ChainIcon, QuestionIcon } from '@endaoment-frontend/ui/icons';
import { Button, Card, Pill } from '@endaoment-frontend/ui/shared';
import {
  calculateExpenseRatio,
  formatCurrency,
  formatNumber,
  formatUsdc,
  getDateStringForPortfolioPerformance,
} from '@endaoment-frontend/utils';

import styles from './PortfolioCard.module.scss';

const MotionCard = motion(Card);

type PortfolioCardProps = {
  portfolio: PortfolioFinancial;
  onAllocate?: () => void;
  className?: string;
};

export const PortfolioCard = ({ portfolio, onAllocate, className }: PortfolioCardProps) => {
  const {
    name,
    type,
    logoUrl,
    id,
    chainId,
    categories,
    description,
    aipPerSecondFeesWad,
    depositFeeBps,
    totalInvestedInPortfolio,
    cap,
    providerFeeBps,
  } = portfolio;

  const { isMobile } = useIsMobile();
  const { isSocialAuth } = useAuthType();
  const [isExpanded, setIsExpanded] = useState(false);
  const chainName = getChainForChainId(chainId)?.name;
  const isPositive = portfolio.quarterlyPerformanceBps && portfolio.quarterlyPerformanceBps > 0;

  const dateString = getDateStringForPortfolioPerformance(portfolio);

  const renderCurrentPerformance = match(portfolio)
    .with({ quarterlyPerformanceBps: P.not(P.nullish) }, ({ quarterlyPerformanceBps, updatedAt }) => (
      <div>
        <span>
          <em className={isPositive ? styles['positive'] : styles['negative']}>
            {!!isPositive && '+'}
            {formatNumber((quarterlyPerformanceBps / 100).toFixed(2), {
              digits: 5,
              fractionDigits: 2,
              stripZeros: true,
            })}
            %
          </em>
        </span>
        <span>{dateString}</span>
      </div>
    ))
    .with({ currentApyBps: P.not(P.nullish) }, ({ currentApyBps, updatedAt }) => (
      <div>
        <span>
          <em>{formatNumber(currentApyBps / 100, { digits: 5, fractionDigits: 2, stripZeros: true })}%</em> APY
        </span>
        <span>{dateString}</span>
      </div>
    ))
    .otherwise(() => <div />);

  return (
    <MotionCard
      noPadding
      as={Link}
      href={routes.app.portfolio({ id })}
      className={clsx(styles['container'], isExpanded && styles['container--expanded'], className)}
      onHoverStart={() => !isMobile && setIsExpanded(true)}
      onHoverEnd={() => setIsExpanded(false)}>
      <div className={styles['icon']}>
        <Image src={logoUrl} alt='' width={100} height={100} priority />
        {!isSocialAuth && (
          <Tooltip label={`This portfolio is deployed on ${chainName}`} placement='top'>
            <span className={styles['chain-icon']}>
              <ChainIcon chainId={chainId} filled light />
            </span>
          </Tooltip>
        )}
      </div>
      <div className={styles['title']}>
        <h6>
          <b>{name}</b>
        </h6>
      </div>
      <div className={styles['categories']}>
        {categories.map(category => (
          <PortfolioCategoryPill key={category.id} category={category} />
        ))}
      </div>
      <div className={styles['performance']}>{renderCurrentPerformance}</div>
      <AnimatePresence>
        {!!isExpanded && (
          <motion.div
            className={styles['details']}
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            exit={{ height: 0 }}
            style={{ overflow: 'hidden' }}>
            <p>{description}</p>
            <div className={styles['info-table']}>
              {(!!aipPerSecondFeesWad || !!providerFeeBps) && (
                <span>
                  Expense Ratio:{' '}
                  <em>
                    {formatNumber(
                      calculateExpenseRatio({
                        aipPerSecondFeesWad,
                        providerFeeBps,
                      }),
                      { digits: 5, fractionDigits: 4, stripZeros: true },
                    )}
                    %
                  </em>
                </span>
              )}
              <span>
                Entry Fee:{' '}
                <em>{formatNumber(depositFeeBps / 100, { digits: 5, fractionDigits: 4, stripZeros: true })}%</em>
              </span>
              <span>
                Settlement Time: <em>{type === 'TPlusN' ? '24 - 48 hours' : 'Instant'}</em>
              </span>
            </div>
            <div className={styles['info-footer']}>
              <span>
                <em>{formatCurrency(formatUsdc(totalInvestedInPortfolio))} allocated</em>
                {cap ? <em>{formatCurrency(formatUsdc(cap))} cap</em> : <p>No deposit cap</p>}
              </span>
              <Button
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  onAllocate?.();
                }}
                filled
                variation='portfolio'
                size='small'
                float={false}>
                New Position
              </Button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </MotionCard>
  );
};

export const PortfolioCategoryPill = ({ category }: { category: PortfolioCategory }) => {
  const style = {
    '--card-color': category.colorRgb,
    '--card-color-half': `${category.colorRgb}7F`,
    '--card-color-quarter': `${category.colorRgb}3F`,
    '--card-color-tenth': `${category.colorRgb}19`,
    '--card-color-eight': `${category.colorRgb}23`,
    '--shadow-color': `0px 18px 26px 0px var(--card-color-tenth)`,
  };
  return (
    <Pill size='tiny' className={clsx(styles['category-pill'])} style={style}>
      {category.name}&nbsp;
      <Tooltip label={category.description} placement='top'>
        <span>
          <QuestionIcon />
        </span>
      </Tooltip>
    </Pill>
  );
};
