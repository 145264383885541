import { Text } from '@chakra-ui/react';
import { trackEvent } from '@phntms/next-gtm';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AnimatePresence } from 'framer-motion';
import { useEffect, useMemo, useReducer, useState } from 'react';
import { P, match } from 'ts-pattern';

import { CreateDafMigrationPledge, GetFund, GetUserActivity, GetUserFunds, WhoAmI } from '@endaoment-frontend/api';
import { useDeployAndCreateFund } from '@endaoment-frontend/blockchain-interactions';
import { STABLECOIN_DECIMALS } from '@endaoment-frontend/constants';
import type { CreateFundDetailsInput, FundListing, UUID } from '@endaoment-frontend/types';
import { Loader, StepModal } from '@endaoment-frontend/ui/shared';

import CreateFundAdvisor from '../common/CreateFundAdvisor';
import CreateFundDetails from '../common/CreateFundDetails';

import { MigrateFundAmount } from './MigrateFundAmount';
import { MigrateFundDestination } from './MigrateFundDestination';
import { MigrateFundInstructions } from './MigrateFundInstructions';
import { MigrateFundView } from './MigrateFundView';

type FundMigrateStep = 'amount' | 'create-advisor' | 'create-details' | 'destination' | 'instructions' | 'view';

const useMigrateFundFlowState = () => {
  const [step, setStep] = useReducer((_prev: FundMigrateStep, next: FundMigrateStep) => {
    trackEvent({ event: 'fw_wizard_progress', data: { fw_wizard_step: next } });
    return next;
  }, 'destination');

  const [selectedFundId, setSelectedFundId] = useState<UUID | undefined>();
  const [fundDetails, setFundDetails] = useState<CreateFundDetailsInput>();
  const [amount, setAmount] = useState<number>();

  const { data: selectedFund } = GetFund.useQuery([selectedFundId as UUID], { enabled: !!selectedFundId });
  const { data: funds } = GetUserFunds.useQuery([]);

  return {
    wizard: {
      step,
      funds,
      fundDetails,
      selectedFund: selectedFund as FundListing | undefined,
      amount,
    } as const,
    setStep,
    setFundDetails,
    setSelectedFundId,
    setAmount,
  } as const;
};

export const MigrateFundFlow = ({
  initialDestinationFundId,
  onClose,
}: {
  initialDestinationFundId?: UUID;
  onClose: () => void;
}) => {
  const queryClient = useQueryClient();

  const { wizard, setStep, setSelectedFundId, setFundDetails, setAmount } = useMigrateFundFlowState();
  const [usedInitialDestination, setUsedInitialDestination] = useState(false);

  // Key used to prevent duplicate pledges, generate once per flow
  const idempotencyKey = useMemo(() => crypto.randomUUID(), []);

  // If an initial destination fund is provided, skip the destination step
  useEffect(() => {
    if (!!initialDestinationFundId && wizard.step === 'destination' && !usedInitialDestination) {
      setUsedInitialDestination(true);
      setSelectedFundId(initialDestinationFundId);
      setStep('amount');
    }
  }, [initialDestinationFundId, usedInitialDestination, setSelectedFundId, setStep, wizard.step]);

  const {
    createFund,
    status: creationStatus,
    fund: createdFund,
    reset: resetFundCreate,
  } = useDeployAndCreateFund({
    onSuccess: () => {
      GetUserFunds.invalidateQuery(queryClient, []);
      WhoAmI.invalidateQuery(queryClient);
      setFundDetails(undefined);
      setStep('amount');
    },
  });

  const destinationFund = createdFund || wizard.selectedFund;

  const {
    mutate: handleMigrate,
    status: migrationStatus,
    data: migrationPledgeId,
  } = useMutation({
    mutationKey: CreateDafMigrationPledge.prefixKeys,
    mutationFn: async () => {
      const { amount } = wizard;

      if (!destinationFund || !amount) {
        console.error('Invalid state - missing `destinationFund` or `amount`');
        return;
      }

      return CreateDafMigrationPledge.execute({
        idempotencyKey,
        pledgedAmountMicroDollars: (amount * 10 ** STABLECOIN_DECIMALS).toString(),
        receivingFundId: destinationFund.id,
      });
    },
    onSuccess: () => {
      GetUserActivity.invalidateQuery(queryClient, []);
      setStep('view');

      // Clear dataLayer before sending ecommerce values, moved within onSuccess to ensure pledgeId is available
      trackEvent({ data: { ecommerce: null } });
      trackEvent({
        event: 'fw_migrate',
        data: {
          destination_id: destinationFund?.id,
          ecommerce: {
            currency: 'USD',
            value: wizard.amount,
            transaction_id: migrationPledgeId,
          },
        },
      });
    },
    retryDelay: 5000,
  });

  const handleReset = () => {
    setFundDetails(undefined);
    setSelectedFundId(undefined);
    resetFundCreate();
    setStep('destination');
  };

  const stepsIncludeCreate = wizard.step === 'create-details' || !!createdFund;
  const stepTotal = stepsIncludeCreate ? 5 : 3;

  const steps = match({ step: wizard.step, destinationFund, amount: wizard.amount, migrationPledgeId })
    .with({ step: 'destination' }, () => (
      <StepModal.Step key='destination' header='DAF Migration' onClose={onClose} progress={{ current: 1, pages: 3 }}>
        <MigrateFundDestination
          funds={wizard.funds}
          onCreate={() => {
            setStep('create-details');
            trackEvent({ event: 'fw_start_create_fund', data: { is_migration: true } });
          }}
          onSelect={fund => {
            setSelectedFundId(fund.id);
            setStep('amount');
          }}
        />
      </StepModal.Step>
    ))
    .with({ step: 'create-details' }, () => (
      <StepModal.Step
        key='create-details'
        header='New Fund Details'
        onBack={() => setStep('destination')}
        onClose={onClose}
        progress={{ current: 2, pages: 5 }}>
        <CreateFundDetails
          initialValues={wizard.fundDetails}
          onSubmit={details => {
            setFundDetails(details);
            setStep('create-advisor');
          }}
        />
      </StepModal.Step>
    ))
    .with({ step: 'create-advisor' }, ({ step }) => (
      <StepModal.Step
        key='create-advisor'
        header='DAF Migration'
        onBack={() => setStep('create-details')}
        onClose={onClose}
        progress={{ current: 3, pages: 5 }}>
        <CreateFundAdvisor
          createButtonText='Create Fund & Proceed'
          isCreating={creationStatus === 'loading'}
          onSubmit={advisor => {
            if (!wizard.fundDetails) throw new Error(`Fund details are missing from Create Fund ${step} step`);
            createFund({ fundDetails: wizard.fundDetails, advisor });
          }}
        />
      </StepModal.Step>
    ))
    .with({ step: 'amount' }, ({ destinationFund }) => {
      if (!destinationFund) {
        return (
          <StepModal.Step
            key='amount'
            header='DAF Migration'
            onBack={() => setStep('destination')}
            onClose={onClose}
            progress={{ current: stepsIncludeCreate ? 4 : 2, pages: stepTotal }}>
            <Loader size='l' />
          </StepModal.Step>
        );
      }
      return (
        <StepModal.Step
          key='amount'
          header='DAF Migration'
          onBack={() => setStep('destination')}
          onClose={onClose}
          progress={{ current: stepsIncludeCreate ? 4 : 2, pages: stepTotal }}>
          <MigrateFundAmount
            initialValue={wizard.amount}
            fund={destinationFund}
            onSubmit={amount => {
              setAmount(amount);
              setStep('instructions');
            }}
            onRemove={handleReset}
          />
        </StepModal.Step>
      );
    })
    .with(
      { step: 'instructions', destinationFund: P.not(P.nullish), amount: P.not(P.nullish) },
      ({ destinationFund, amount }) => (
        <StepModal.Step
          key='instructions'
          header='Migration Instruction'
          onBack={migrationStatus === 'loading' ? undefined : () => setStep('amount')}
          onClose={onClose}
          progress={{ current: stepsIncludeCreate ? 5 : 3, pages: stepTotal }}>
          <MigrateFundInstructions
            fund={destinationFund}
            amount={amount}
            processing={migrationStatus === 'loading'}
            onInitiate={handleMigrate}
          />
        </StepModal.Step>
      ),
    )
    .with(
      {
        step: 'view',
        destinationFund: P.not(P.nullish),
        amount: P.not(P.nullish),
        migrationPledgeId: P.not(P.nullish),
      },
      ({ destinationFund, amount, migrationPledgeId }) => (
        <StepModal.Step key='view' header='Migration Summary' onClose={onClose}>
          <MigrateFundView
            fund={destinationFund}
            amount={amount}
            fundMigrationId={migrationPledgeId}
            onClose={onClose}
          />
        </StepModal.Step>
      ),
    )
    .otherwise(wizard => {
      console.error('Unhandled fund wizard state', wizard);
      return (
        <StepModal.Step key='error' onClose={onClose} header='Error'>
          <Text alignSelf='center'>Something went wrong</Text>
        </StepModal.Step>
      );
    });

  return <AnimatePresence mode='wait'>{steps}</AnimatePresence>;
};
