import type { ImageProps } from '@chakra-ui/next-js';
import Image from 'next/image';
import { useState } from 'react';
import type { Address } from 'wagmi';

import { FilledCircleIcon } from '@endaoment-frontend/ui/icons';
import { stringToColor } from '@endaoment-frontend/utils';

import { useEnsAvatarWithFallback } from '../useEnsAvatarWithFallback';

import styles from './UserAvatar.module.scss';

type UserAvatarProps = Omit<ImageProps, 'alt' | 'src'> & {
  address: Address;
  hideFallback?: boolean;
};

export const UserAvatar = ({ address, className, hideFallback, onError, ...props }: UserAvatarProps) => {
  const [logoFailed, setLogoFailed] = useState(false);
  const { data: avatarUrl } = useEnsAvatarWithFallback({
    address,
  });

  if (!avatarUrl || logoFailed) {
    if (hideFallback) return <></>;
    return (
      <FilledCircleIcon
        startColor={stringToColor(address)}
        endColor={stringToColor(address.split('').reverse().join(''))}
        className={className}
      />
    );
  }

  const handleError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    setLogoFailed(true);
    onError?.(e);
  };

  return (
    <Image
      src={avatarUrl}
      alt={`${address} avatar`}
      className={styles['avatar']}
      width={96}
      height={96}
      {...props}
      onError={handleError}
    />
  );
};
