import type { ReactNode } from 'react';
import { Fragment } from 'react';

import { ShieldIcon, InfoIcon } from '@endaoment-frontend/ui/icons';

import styles from './Announcement.module.scss';
import type { AnnouncementProps } from './Announcement.types';

export const Announcement = ({ children }: AnnouncementProps) => {
  return (
    <div className={styles['announcement']} role='note'>
      {children}
    </div>
  );
};

const iconOptions = {
  shield: ShieldIcon,
  info: InfoIcon,
} as const;

Announcement.Item = ({
  children,
  icon,
}: {
  icon?: keyof typeof iconOptions;
  children: Array<ReactNode> | ReactNode;
}) => {
  const Icon = icon ? iconOptions[icon] : Fragment;
  return (
    <div>
      <Icon />
      <span>{children}</span>
    </div>
  );
};
