import type { As } from '@chakra-ui/react';
import clsx from 'clsx';
import type { ComponentProps } from 'react';

import { GetFund } from '@endaoment-frontend/api';
import type { UUID } from '@endaoment-frontend/types';
import { Card } from '@endaoment-frontend/ui/shared';
import { FundAllocationBar, MiniFundDetailsWithQuery } from '@endaoment-frontend/ui/smart';
import { getFundTotalValues } from '@endaoment-frontend/utils';

import wizardStyles from '../PortfolioWizard.module.scss';

export const FundDetailsCardWithBar = <Tag extends As>({
  fundId,
  as,
  selectable,
  ...cardProps
}: Omit<ComponentProps<typeof Card<Tag>>, 'noPadding'> & { fundId: UUID; selectable?: boolean }) => {
  const { data: fund } = GetFund.useQuery([fundId]);

  const { investedPercent, invested, grantable }: Partial<ReturnType<typeof getFundTotalValues>> = fund
    ? getFundTotalValues(fund)
    : {};

  return (
    <Card
      noPadding
      noShadow
      className={clsx(wizardStyles['extended-card'], selectable && wizardStyles['selectable'])}
      as={as ? as : cardProps.onClick ? 'button' : undefined}
      {...cardProps}>
      <MiniFundDetailsWithQuery fundId={fundId} extraDetails showBalance />
      {fund && invested && invested > 0 ? <FundAllocationBar fundId={fund.id} /> : <></>}
    </Card>
  );
};
