import type { As } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import clsx from 'clsx';
import { forwardRef } from 'react';

import type { PolymorphicRef } from '@endaoment-frontend/types';

import styles from './Button.module.scss';
import type { ButtonProps } from './Button.types';

declare module 'react' {
  /** Fixed Ref typings (They suck) */
  function forwardRef<T, P = Record<string, never>>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null,
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

const ButtonWithRef = <Tag extends As>(
  {
    variation = 'default',
    size = 'medium',
    fontSize,
    minimal = false,
    filled = false,
    float = true,
    shadowed = false,
    gradient = false,
    card = false,
    faded = false,
    cardIcon,
    className,
    children,
    as,
    ...restProps
  }: ButtonProps<Tag>,
  ref: PolymorphicRef<Tag>,
) => {
  const Component = as ?? 'button';

  return (
    <Box
      as={Component}
      ref={ref}
      {...restProps}
      type={Component === 'button' ? restProps.type ?? 'button' : undefined}
      data-filled={filled}
      className={clsx(
        styles.button,
        styles[`variation--${variation}`],
        styles[`size--${size}`],
        styles[`font-size--${fontSize ?? size}`],
        minimal && styles['minimal'],
        float && styles.float,
        gradient && styles.gradient,
        filled && styles.filled,
        faded && styles.faded,
        shadowed && styles['shadowed'],
        cardIcon && styles['card--icon'],
        (card || cardIcon) && styles.card,
        className,
      )}>
      {!!cardIcon && <div className={styles['icon-div']}>{cardIcon}</div>}
      {children}
    </Box>
  );
};

export const Button = forwardRef(ButtonWithRef);
