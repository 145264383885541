import { atom, useAtom } from 'jotai';

export const walletModalOpenAtom = atom(false);

export const useWalletModal = () => {
  const [isWalletModalOpen, setIsOpen] = useAtom(walletModalOpenAtom);
  const showWallet = () => setIsOpen(true);

  return { showWallet, isWalletModalOpen } as const;
};
