import { getPublicClient, waitForTransaction } from '@wagmi/core';

import { RequestHandler } from '@endaoment-frontend/data-fetching';
import type { Address } from '@endaoment-frontend/types';

const GetTransactionWithTimestamp = new RequestHandler(
  'GetTransactionWithTimestamp',
  () => async (hash: Address | undefined, chainId: number | undefined, confirmations: number) => {
    if (!hash) throw new Error('No hash provided');
    if (!chainId) throw new Error('No chainId provided');

    const publicClient = getPublicClient();
    const transactionReceipt = await waitForTransaction({
      hash,
      chainId,
      confirmations,
    });
    const block = await publicClient.getBlock({
      blockHash: transactionReceipt.blockHash,
      includeTransactions: false,
    });
    const timestamp = Number(block.timestamp);

    return { transactionReceipt, timestamp } as const;
  },
);

export const useTransactionWithTimestamp = (
  hash: Address | undefined,
  chainId: number | undefined,
  confirmations: number,
) => {
  const { data, error } = GetTransactionWithTimestamp.useQuery<Error>([hash, chainId, confirmations], {
    retry: 3,
    enabled: !!hash && !!chainId,
  });
  const { timestamp, transactionReceipt } = data ?? {};
  return { timestamp, transactionReceipt, error } as const;
};
