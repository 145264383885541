import { Input, InputGroup, InputLeftElement } from '@chakra-ui/input';
import clsx from 'clsx';
import { useState } from 'react';
import { useMedia } from 'react-use';

import { GetFeaturedFunds, GetFeaturedOrgs } from '@endaoment-frontend/api';
import type { DonationRecipient } from '@endaoment-frontend/types';
import { CircleIcon, SearchIcon, StarIcon } from '@endaoment-frontend/ui/icons';
import { Button, Loader } from '@endaoment-frontend/ui/shared';

import styles from './DestinationSearch.module.scss';
import { SearchResults } from './SearchResults';
import { useSearch } from './useSearch';

export type SimpleSearchProps = {
  className?: string;
  includeLink?: boolean;
  flow: 'donation' | 'grant';
  onSubmit: (recipient: DonationRecipient, chainId: number) => void;
};

export const DestinationSearch = ({ className, flow, onSubmit }: SimpleSearchProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isOrgMode, setIsOrgMode] = useState(true);
  const isMobile = useMedia('(max-width: 40rem)', false);

  const featured = {
    orgs:
      GetFeaturedOrgs.useQuery([], {
        enabled: isOrgMode,
      }).data ?? [],
    funds:
      GetFeaturedFunds.useQuery([], {
        enabled: !isOrgMode,
      }).data ?? [],
  };
  const { data, isLoading, hasNextPage, fetchNextPage, shouldDisplay } = useSearch(searchTerm);

  return (
    <>
      <InputGroup className={clsx(styles.search, className)}>
        <InputLeftElement>
          {isLoading ? (
            <Loader className={styles['search-icon']} />
          ) : (
            <SearchIcon
              className={clsx(styles['search-icon'], styles['search-icon--glass'])}
              width={36}
              color='currentColor'
            />
          )}
        </InputLeftElement>
        <Input
          placeholder={isMobile ? 'Search...' : 'Search all funds and organizations...'}
          onChange={e => setSearchTerm(e.currentTarget.value)}
          value={searchTerm}
          className={styles['search-input']}
        />
        {flow === 'donation' && (
          <div
            className={clsx(
              styles['toggle-overlay'],
              isMobile && styles['toggle-overlay--compressed'],
              styles['filter-buttons'],
            )}>
            <Button
              variation='org'
              size='small'
              filled={isOrgMode}
              onClick={() => setIsOrgMode(true)}
              disabled={data?.orgs.length === 0}
              float={false}
              className={styles['toggle-button']}>
              <CircleIcon />
              Orgs&nbsp;
            </Button>
            <Button
              variation='fund'
              size='small'
              filled={!isOrgMode}
              onClick={() => setIsOrgMode(false)}
              disabled={data?.funds.length === 0}
              float={false}
              className={styles['toggle-button']}>
              <StarIcon />
              Funds&nbsp;
            </Button>
          </div>
        )}
      </InputGroup>
      {shouldDisplay ? (
        <SearchResults
          flow={flow}
          data={data}
          isLoading={isLoading}
          mode={isOrgMode ? 'org' : 'fund'}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          onSubmit={onSubmit}
          searchTerm={searchTerm}
        />
      ) : (
        <SearchResults
          flow={flow}
          data={featured}
          isLoading={false}
          mode={isOrgMode ? 'org' : 'fund'}
          hasNextPage={false}
          fetchNextPage={() => {
            return;
          }}
          onSubmit={onSubmit}
          searchTerm={searchTerm}
        />
      )}
    </>
  );
};
