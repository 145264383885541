import { formatUnits, stringToHex } from 'viem';

import { STABLECOIN_DECIMALS } from '@endaoment-frontend/constants';
import type { Address, EIN, UUID } from '@endaoment-frontend/types';
import { isUuid } from '@endaoment-frontend/types';

import { normalizeEin, roundDown } from './formatters';

export const equalAddress = (addr1?: Address | '' | null, addr2?: Address | '' | null): boolean => {
  if (!addr1 || !addr2) return false;

  return addr1.toLowerCase() === addr2.toLowerCase();
};

export type LoginSignature = {
  message: string;
  address: Address;
  date: string;
};

export const makeValidLoginSignature = (address: Address, date = new Date().toISOString()) => {
  return {
    message: `I agree to the Terms & Conditions described at https://docs.endaoment.org/governance/terms-and-conditions ** ACCOUNT: ${address} ** SIGNED DATE UTC: ${date}`,
    address,
    date,
  };
};

export const formatUsdc = (usdcAmount?: bigint | number | string | null) => {
  if (!usdcAmount || (typeof usdcAmount === 'string' && parseFloat(usdcAmount) === 0)) {
    return '0.0';
  }
  return formatUnits(BigInt(usdcAmount), STABLECOIN_DECIMALS);
};
export const convertUsdcToNumber = (usdcAmount?: bigint | number | string | null, limitDecimals?: boolean) =>
  limitDecimals ? roundDown(Number(formatUsdc(usdcAmount)), 2) : Number(formatUsdc(usdcAmount));

/**
 * Convert a UUID to a 0x-prefixed hex string of length 66 so that it can be used as an address in contracts
 */
export const encodeUuidForContracts = (id: UUID): Address => {
  if (id.length !== 36 || !isUuid(id)) throw new Error('Input is not a UUID');

  return `0x${id.replace(/-/g, '').padEnd(64, '0')}`;
};

export const getHexForOrgDeployment = (einOrId: EIN | UUID): Address =>
  isUuid(einOrId) ? encodeUuidForContracts(einOrId) : stringToHex(normalizeEin(einOrId), { size: 32 });
