import { z } from 'zod';

import { addressSchema } from '../web3';

export const genericTokenSchema = z.object({
  id: z.number(),
  symbol: z.string(),
  name: z.string(),
  decimals: z.number(),
  logoUrl: z.string(),
  type: z.string(),
});
export const evmTokenSchema = genericTokenSchema.extend({
  type: z.literal('EvmToken'),
  chainId: z.number(),
  contractAddress: addressSchema,
});
export const otcTokenSchema = genericTokenSchema.extend({
  type: z.literal('OtcToken'),
  otcAddress: z.string(),
  memo: z.string().nullish(),
});
export type GenericToken = z.infer<typeof genericTokenSchema>;
export type EVMToken = z.infer<typeof evmTokenSchema>;
export type OTCToken = z.infer<typeof otcTokenSchema>;

export type TokenBalance<T extends Pick<EVMToken, 'contractAddress' | 'decimals' | 'symbol'> = EVMToken> = {
  token: T;
  balance: bigint;
};
