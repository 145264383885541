import NTEE_A from './images/A.png';
import NTEE_B from './images/B.png';
import NTEE_C from './images/C.png';
import NTEE_D from './images/D.png';
import NTEE_E from './images/E.png';
import NTEE_F from './images/F.png';
import NTEE_G from './images/G.png';
import NTEE_H from './images/H.png';
import NTEE_I from './images/I.png';
import NTEE_J from './images/J.png';
import NTEE_K from './images/K.png';
import NTEE_L from './images/L.png';
import NTEE_M from './images/M.png';
import NTEE_N from './images/N.png';
import NTEE_O from './images/O.png';
import NTEE_P from './images/P.png';
import NTEE_Q from './images/Q.png';
import NTEE_R from './images/R.png';
import NTEE_S from './images/S.png';
import NTEE_T from './images/T.png';
import NTEE_U from './images/U.png';
import NTEE_V from './images/V.png';
import NTEE_W from './images/W.png';
import NTEE_X from './images/X.png';
import NTEE_Y from './images/Y.png';
import NTEE_Z from './images/Z.png';

export const NTEECodes = {
  A: NTEE_A,
  B: NTEE_B,
  C: NTEE_C,
  D: NTEE_D,
  E: NTEE_E,
  F: NTEE_F,
  G: NTEE_G,
  H: NTEE_H,
  I: NTEE_I,
  J: NTEE_J,
  K: NTEE_K,
  L: NTEE_L,
  M: NTEE_M,
  N: NTEE_N,
  O: NTEE_O,
  P: NTEE_P,
  Q: NTEE_Q,
  R: NTEE_R,
  S: NTEE_S,
  T: NTEE_T,
  U: NTEE_U,
  V: NTEE_V,
  W: NTEE_W,
  X: NTEE_X,
  Y: NTEE_Y,
  Z: NTEE_Z,
};
