import { Modal, ModalContent, VStack } from '@chakra-ui/react';
import { trackEvent } from '@phntms/next-gtm';
import { useQueryClient } from '@tanstack/react-query';
import { Widget as TypeformWidget } from '@typeform/embed-react';
import dynamic from 'next/dynamic';
import { useEffect, useReducer, useRef } from 'react';
import { useAsync } from 'react-use';
import { P, match } from 'ts-pattern';
import { useChainId } from 'wagmi';

import { GetFund } from '@endaoment-frontend/api';
import { useAuth, useAuthType, useWalletModal } from '@endaoment-frontend/authentication';
import { defaults } from '@endaoment-frontend/config';
import { ensureUserChain, getNativeTokenForChain, isSupportedChain } from '@endaoment-frontend/multichain';
import { BankIcon, BitcoinIcon, NFTIcon, StarIcon, StockIcon, WalletIcon } from '@endaoment-frontend/ui/icons';
import { ActionButton, Loader, StepModal } from '@endaoment-frontend/ui/shared';
import { EntityCardWithLabel } from '@endaoment-frontend/ui/smart';

import { DestinationSearch } from './DestinationSearch';
import styles from './DonationWizard.module.scss';
import { useDonationWizardState } from './useDonationWizardState';

type WizardStep = 'crypto-type' | 'destination' | 'subform' | 'type';

// Load Subforms dynamically to avoid loading all dependencies at once
const ErcDonationFlow = dynamic(() => import('./erc-donation-flow').then(mod => mod.ErcDonationFlow), { ssr: false });
const GrantFlow = dynamic(() => import('./grant-flow').then(mod => mod.GrantFlow), { ssr: false });
const OtcDonationFlow = dynamic(() => import('./otc-donation-flow').then(mod => mod.OtcDonationFlow), { ssr: false });
const BrokerageDonationFlow = dynamic(
  () => import('./brokerage-donation-flow').then(mod => mod.BrokerageDonationFlow),
  { ssr: false },
);
const CreditDonationFlow = dynamic(() => import('./credit-donation-flow').then(mod => mod.CreditDonationFlow), {
  ssr: false,
});

const DonationWizardSteps = ({
  onClose,
  onReset,
  step,
  onStepChange,
  state,
  setters,
}: {
  onClose: () => void;
  onReset: () => void;
  step: WizardStep;
  onStepChange: (newStep: WizardStep) => void;
  state: ReturnType<typeof useDonationWizardState>['state'];
  setters: ReturnType<typeof useDonationWizardState>['setters'];
}) => {
  const { isSignedIn } = useAuth();
  const { authType } = useAuthType();
  const { showWallet } = useWalletModal();
  const chainId = useChainId();

  const { setMode, setRecipient } = setters;

  const selectCryptoTypeActionButton = (
    <ActionButton
      onClick={() => {
        onStepChange('crypto-type');
      }}
      color='purple'
      text='Crypto Wallet or Exchange'
      subtext='Donate any crypto asset'>
      <WalletIcon color='currentColor' />
    </ActionButton>
  );
  const selectGrantActionButton = (!state.recipient || state.recipient?.type !== 'fund') && (
    <ActionButton
      onClick={() => {
        if (!isSignedIn) {
          showWallet();
          return;
        }
        trackEvent({
          event: 'dw_start_grant',
        });
        setMode('grant');
        onStepChange('subform');
      }}
      color='fund'
      text='Donor-Advised Fund'
      subtext='Make a new grant recommendation'>
      <StarIcon width={24} color='currentColor' />
    </ActionButton>
  );
  const selectCreditActionButton = (
    <ActionButton
      onClick={() => {
        trackEvent({
          event: 'dw_start_donation_pledge',
          data: {
            pledge_type: 'cash',
          },
        });
        setMode('credit-donation');
        onStepChange('subform');
      }}
      color='org'
      text='Cash or Card'
      subtext='Give from your bank account, credit, or debit card'>
      <BankIcon color='currentColor' />
    </ActionButton>
  );
  const selectBrokerageActionButton = (
    <ActionButton
      onClick={() => {
        trackEvent({
          event: 'dw_start_donation_pledge',
          data: {
            pledge_type: 'stock',
          },
        });
        setMode('brokerage-donation');
        onStepChange('subform');
      }}
      color='green'
      text='Stocks or Securities'
      subtext='Give stock from your brokerage account'>
      <StockIcon color='currentColor' width={32} />
    </ActionButton>
  );
  // ERC button is hidden for social users and a sign-in button for those not signed in
  const selectERCActionButton = match(authType)
    .with('wallet', () => (
      <ActionButton
        onClick={() => {
          trackEvent({
            event: 'dw_start_wallet_donation',
          });
          setMode('erc-donation');
          onStepChange('subform');
        }}
        color='purple'
        text='EVM Wallet'
        subtext={`Best for ${getNativeTokenForChain(chainId).symbol} or ERC-20`}>
        <WalletIcon color='currentColor' />
      </ActionButton>
    ))
    .with('social', () => <></>)
    .with(undefined, () => (
      <ActionButton
        onClick={() => {
          showWallet();
        }}
        color='purple'
        text='EVM Wallet'
        subtext={`Best for ${getNativeTokenForChain(chainId).symbol} or ERC-20`}>
        <WalletIcon color='currentColor' />
      </ActionButton>
    ))
    .exhaustive();
  const selectOTCActionButton = (
    <ActionButton
      onClick={() => {
        trackEvent({
          event: 'dw_start_donation_pledge',
          data: {
            pledge_type: 'otc_crypto',
          },
        });
        setMode('otc-donation');
        onStepChange('subform');
      }}
      color='org'
      text='Exchange or Cold Storage'
      subtext='Give Bitcoin, Solana, NFTs and more'>
      <BitcoinIcon width={20} color='currentColor' />
    </ActionButton>
  );
  const selectNFTActionButton = (
    <ActionButton
      onClick={() => {
        setMode('nft-donation');
        onStepChange('subform');
      }}
      color='violet'
      text='NFT'
      subtext='Get a tax-deduction for an NFT'>
      <NFTIcon width={24} color='currentColor' />
    </ActionButton>
  );

  useEffect(() => {
    if (step === 'destination' && !!state.recipient) {
      onStepChange(state.mode ? 'subform' : 'type');
    }
  }, [onStepChange, state.mode, state.recipient, step]);
  const steps = match({ step, ...state })
    .with({ step: 'destination' }, ({ mode, recipient }) => {
      if (recipient) {
        return (
          <StepModal.Step key='destination' onClose={onClose} header='New Donation or Grant'>
            <Loader size='l' />
          </StepModal.Step>
        );
      }
      return (
        <StepModal.Step key='destination' onClose={onClose} header='New Donation or Grant'>
          <h2 className={styles['step-header']}>Where would you like to donate?</h2>
          <DestinationSearch
            flow={mode === 'grant' ? 'grant' : 'donation'}
            onSubmit={recipient => {
              setRecipient(recipient);
              onStepChange(mode ? 'subform' : 'type');
            }}
          />
        </StepModal.Step>
      );
    })
    .with(P.union({ step: 'type' }, { step: 'subform', mode: P.nullish }), ({ recipient }) => {
      const handleBack = () => {
        onStepChange('destination');
        setRecipient(undefined);
      };
      return (
        <StepModal.Step key='type' onBack={handleBack} onClose={onClose} header='New Donation or Grant'>
          <EntityCardWithLabel label='Donating to' entity={recipient} onRemove={handleBack} />
          <hr />
          <h2 className={styles['step-header']}>How will you be giving?</h2>
          <VStack mt='1rem' gap='1rem' align='stretch'>
            {selectCreditActionButton}
            {selectCryptoTypeActionButton}
            {selectBrokerageActionButton}
            {selectGrantActionButton}
          </VStack>
        </StepModal.Step>
      );
    })
    .with({ step: 'crypto-type' }, ({ step, recipient }) => {
      if (!recipient) throw new Error(`Recipient is missing from Donation Wizard ${step} step`);
      return (
        <StepModal.Step
          key='crypto-type'
          onBack={() => {
            onStepChange('type');
          }}
          onClose={onClose}
          header='New Donation or Grant'>
          <EntityCardWithLabel
            label='Donating to'
            entity={recipient}
            onRemove={() => {
              onStepChange('destination');
              setRecipient(undefined);
            }}
          />
          <hr />
          <h2 className={styles['step-header']}>How will you be giving?</h2>
          <VStack mt='1rem' gap='1rem' align='stretch'>
            {selectERCActionButton}
            {selectOTCActionButton}
            {selectNFTActionButton}
          </VStack>
        </StepModal.Step>
      );
    })
    .with({ step: 'subform', mode: 'erc-donation' }, ({ step, recipient }) => {
      if (!recipient) throw new Error(`Recipient is missing from Donation Wizard ${step} step`);
      return <ErcDonationFlow onClose={onClose} onReset={onReset} rawState={state} rawStateSetters={setters} />;
    })
    .with({ step: 'subform', mode: 'otc-donation' }, ({ step, recipient }) => {
      if (!recipient) throw new Error(`Recipient is missing from Donation Wizard ${step} step`);
      return <OtcDonationFlow onClose={onClose} onReset={onReset} rawState={state} rawStateSetters={setters} />;
    })
    .with({ step: 'subform', mode: 'nft-donation' }, ({ step, recipient }) => {
      if (!recipient) throw new Error(`Recipient is missing from Donation Wizard ${step} step`);
      return (
        // @TODO: Refactor out the modal-inside-modal logic when possible
        <Modal isOpen isCentered onClose={onClose} size='6xl'>
          <ModalContent className={styles['typeform-modal']}>
            <TypeformWidget id='BE4L04bI' inlineOnMobile className={styles['typeform-iframe']} />
          </ModalContent>
        </Modal>
      );
    })
    .with({ step: 'subform', mode: 'grant' }, ({ step, recipient }) => {
      if (!recipient) throw new Error(`Recipient is missing from Donation Wizard ${step} step`);
      return <GrantFlow onClose={onClose} onReset={onReset} rawState={state} rawStateSetters={setters} />;
    })
    .with({ step: 'subform', mode: 'credit-donation' }, ({ step, recipient }) => {
      if (!recipient) throw new Error(`Recipient is missing from Donation Wizard ${step} step`);
      return <CreditDonationFlow onClose={onClose} onReset={onReset} rawState={state} rawStateSetters={setters} />;
    })
    .with({ step: 'subform', mode: 'bank-donation' }, () => <></>)
    .with({ step: 'subform', mode: 'brokerage-donation' }, ({ step, recipient }) => {
      if (!recipient) throw new Error(`Recipient is missing from Donation Wizard ${step} step`);
      return <BrokerageDonationFlow onClose={onClose} onReset={onReset} rawState={state} rawStateSetters={setters} />;
    })
    .exhaustive();
  return <StepModal.StepsWrapper>{steps}</StepModal.StepsWrapper>;
};

export const DonationWizard = () => {
  const { isSignedIn } = useAuth();
  const { showWallet, isWalletModalOpen } = useWalletModal();
  const chainId = useChainId();
  const queryClient = useQueryClient();

  const { isDonationWizardOpen, resetDonationWizard, state, setters } = useDonationWizardState();
  const [step, setStep] = useReducer((_prev: WizardStep, next: WizardStep) => {
    if (isDonationWizardOpen) {
      trackEvent({ event: 'dw_wizard_progress', data: { dw_wizard_step: next } });
    }
    return next;
  }, 'destination');

  const isOpen = isDonationWizardOpen && !isWalletModalOpen;
  const isBlockchainFlow = state.mode === 'erc-donation' || state.mode === 'grant';
  const shouldShowWallet = isBlockchainFlow && !isSignedIn && !isWalletModalOpen;

  const handleClose = () => {
    setStep('destination');
    resetDonationWizard();
  };
  const handleReset = () => {
    setStep('type');
    resetDonationWizard(true);
  };

  const hasAlreadyReroutedToWallet = useRef(false);
  useAsync(
    async () => {
      // Guard against rendering if the modal is not open
      if (!isDonationWizardOpen) return;

      // If the user has already been rerouted to the wallet, close the modal
      if (shouldShowWallet && hasAlreadyReroutedToWallet.current) {
        handleClose();
        hasAlreadyReroutedToWallet.current = false;
        return;
      }

      // Guard against rendering if the user is not signed in
      if (shouldShowWallet) {
        showWallet();
        hasAlreadyReroutedToWallet.current = true;
        return;
      }

      if (isBlockchainFlow) {
        try {
          if (state.recipient && state.recipient.type === 'fund') {
            // If the initial recipient is a fund, ensure the user is connected to the fund's chain
            const fund = await GetFund.fetchFromQueryClient(queryClient, [state.recipient.id]);
            await ensureUserChain(fund.chainId);
          } else if (!isSupportedChain(chainId)) {
            // Ask user to swap to a supported chain if they are not on one
            await ensureUserChain(defaults.network.defaultChainId);
          }
        } catch {
          handleClose();
          return;
        }
      }

      match({ step, ...state })
        .with({ mode: P.not(P.nullish), recipient: P.not(P.nullish) }, () => {
          setStep('subform');
        })
        .with({ step: 'crypto-type' }, () => {
          // Do not change the step if we're coming back to the wizard from the wallet
          return;
        })
        .with({ recipient: P.not(P.nullish) }, () => {
          setStep('type');
        })
        .otherwise(() => {
          setStep('destination');
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSignedIn, isDonationWizardOpen],
  );

  return (
    <StepModal isOpen={isOpen} onClose={handleClose}>
      <DonationWizardSteps
        onClose={handleClose}
        onReset={handleReset}
        step={step}
        onStepChange={setStep}
        state={state}
        setters={setters}
      />
    </StepModal>
  );
};
