import { Link } from '@chakra-ui/next-js';
import { Popover, PopoverContent, PopoverTrigger } from '@chakra-ui/react';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useRef, useState } from 'react';

import { ScrollDirection, useIsMobile, useScroll } from '@endaoment-frontend/hooks';
import { routes } from '@endaoment-frontend/routes';
import { SimpleSearch } from '@endaoment-frontend/search';
import { CircleIcon, ClaimedIcon, DownCaretIcon, HamburgerIcon, LogoIcon } from '@endaoment-frontend/ui/icons';
import { Button } from '@endaoment-frontend/ui/shared';
import { retry } from '@endaoment-frontend/utils';

import styles from './NavBar.module.scss';
import type { NavBarProps } from './NavBar.types';

const NAVBAR_HEIGHT = 96;

const ClientNavItems = dynamic(
  retry(() => import('./ClientNavItems')),
  { ssr: false },
);
const MobileMenu = dynamic(
  retry(() => import('./MobileMenu')),
  { ssr: false },
);

export const NavBar = ({ className }: NavBarProps) => {
  // Get which href is currently active
  const { asPath } = useRouter();

  const [animated, setAnimated] = useState(false);
  const headerRef = useRef(null);

  const { isMobile } = useIsMobile();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  // Navbar disappears on scroll
  const { y, scrollDirection } = useScroll(50);
  const navbarIsHidden = y >= NAVBAR_HEIGHT && scrollDirection === ScrollDirection.Down;
  const navbarIsScrolled = y >= NAVBAR_HEIGHT && scrollDirection === ScrollDirection.Up;

  return (
    <>
      <motion.header
        className={clsx(styles.navbar, className)}
        initial='top'
        animate={navbarIsHidden ? 'hide' : navbarIsScrolled ? 'scroll' : 'top'}
        variants={{
          top: { visibility: 'visible', opacity: 1, background: 'transparent' },
          hide: { visibility: 'hidden', opacity: 0 },
          scroll: {
            visibility: 'visible',
            opacity: 1,
            background: 'rgba(255, 255, 255, 0.72)',
          },
        }}
        transition={{ while: 'beforeChildren', duration: 0.75 }}
        ref={headerRef}>
        <div className={styles.navbar__inner}>
          <Link
            href={routes.app.home()}
            title='Endaoment'
            className={animated ? styles['mouse-enter'] : ''}
            id='logo'
            onMouseEnter={() => setAnimated(true)}
            onAnimationEnd={() => setAnimated(false)}>
            <LogoIcon width={45} />
          </Link>

          <div className={styles['search']}>
            {/* Set key to current path to reset component on route change */}
            <SimpleSearch key={asPath} className={styles['search-input']} compressed />
          </div>

          <nav className={styles['nav-menu']}>
            <ClientNavItems promptContainerRef={headerRef} />
            {!!isMobile && (
              <Button
                float={false}
                onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
                className={styles['mobile-toggle']}>
                <HamburgerIcon />
              </Button>
            )}
          </nav>

          {!isMobile && (
            <>
              <hr />
              <Popover trigger='hover' matchWidth placement='bottom-end' gutter={2}>
                <PopoverTrigger>
                  <Button className={styles['org-menu']} float={false}>
                    <span>For Orgs</span>
                    <div className={styles['org-menu-arrow']}>
                      <DownCaretIcon width={16} height={12} />
                    </div>
                  </Button>
                </PopoverTrigger>
                <PopoverContent className={styles['org-menu__list']}>
                  <div className={styles['org-menu__item']}>
                    <Link href={routes.app.orgClaim()}>
                      <ClaimedIcon width={22} height={22} color='currentColor' />
                      Claim Org
                    </Link>
                  </div>
                  <div className={styles['org-menu__item']}>
                    <Link as={Link} href='https://docs.endaoment.org/organizations' target='_blank'>
                      <CircleIcon width={22} height={18} /> Org Resources
                    </Link>
                  </div>
                </PopoverContent>
              </Popover>
            </>
          )}
        </div>
      </motion.header>
      <AnimatePresence>{!!isMobileMenuOpen && <MobileMenu onClose={() => setMobileMenuOpen(false)} />}</AnimatePresence>
    </>
  );
};
