import { Link } from '@chakra-ui/next-js';
import { Tooltip } from '@chakra-ui/react';
import { match, P } from 'ts-pattern';

import { routes } from '@endaoment-frontend/routes';
import type { FundListing } from '@endaoment-frontend/types';
import { ArrowIcon, InfoIcon, QuestionIcon } from '@endaoment-frontend/ui/icons';
import { Button, Card } from '@endaoment-frontend/ui/shared';
import { formatCurrency, formatNumber, formatUsdc } from '@endaoment-frontend/utils';

import { computeDeviationFromTarget, formatPercentageOfFundRebalancable } from './computeValues';
import styles from './TargetAllocationSection.module.scss';

export const OtherBalanceCard = ({ balance }: { balance: bigint }) => {
  return (
    <>
      <Card className={styles['other-allocations']}>
        <Tooltip label='The amount of money in portfolios not within the target allocation.' placement='top-start'>
          <h6>
            Other Positions <QuestionIcon width={15} height={16} color='currentColor' />
          </h6>
        </Tooltip>
        <hr />
        <div>
          <span>{formatCurrency(formatUsdc(balance))}</span>
          <Button
            size='small'
            variation='portfolio'
            as={Link}
            href={routes.app.portfolios({ view: 'positions' })}
            filled
            float={false}
            className={styles['other-positions-button']}>
            See Positions
            <ArrowIcon width={16} />
          </Button>
        </div>
      </Card>
      <b></b>
    </>
  );
};

export const GrantableBalanceCard = ({
  fund,
  grantablePercentage,
  noRebalanceOps = false,
}: {
  fund: Pick<FundListing, 'investedUsdc' | 'usdcBalance'>;
  grantablePercentage?: number;
  noRebalanceOps?: boolean;
}) => {
  const { deviationPercentFromTarget, deviationAmountFromTarget, isWithinTarget } = computeDeviationFromTarget(
    fund.usdcBalance,
    fund,
    { percentage: grantablePercentage ?? 0 },
  );

  return (
    <Card className={styles['grantable-allocation']}>
      <Tooltip label='The amount of grantable money that is allocated within the target mix.' placement='top-start'>
        <h6>
          Grantable Balance <QuestionIcon width={15} height={16} color='currentColor' />
        </h6>
      </Tooltip>
      {fund.usdcBalance > 0 && (
        <>
          <hr />
          <div>
            <span>
              {formatCurrency(formatUsdc(fund.usdcBalance))}
              <span>
                (<b>{formatPercentageOfFundRebalancable(fund.usdcBalance, fund)}</b>)
              </span>
            </span>
            <span>
              {match({ grantablePercentage, isWithinTarget, noRebalanceOps })
                .with({ grantablePercentage: P.nullish }, () => <></>)
                .with({ isWithinTarget: true }, () => (
                  <Tooltip label='This position is considered within the range of its target position.'>
                    <b className={styles['within-target']}>
                      Within target <InfoIcon color='currentColor' width={14} height={14} />
                    </b>
                  </Tooltip>
                ))
                .with({ noRebalanceOps: true, grantablePercentage: P.not(1) }, () => (
                  <Tooltip label='The deviation of this position does not warrant a collective rebalance.'>
                    <b className={styles['near-target']}>
                      Near target <InfoIcon color='currentColor' width={14} height={14} />
                    </b>
                  </Tooltip>
                ))
                .with({ isWithinTarget: false }, () => (
                  <>
                    <b className={styles['off-target']}>
                      {deviationPercentFromTarget >= 0 ? '-' : '+'}
                      {formatCurrency(formatUsdc(deviationAmountFromTarget)).replace('-', '')}
                    </b>
                    from target
                    <b className={styles['off-target']}>
                      ({deviationPercentFromTarget >= 0 ? '-' : '+'}
                      {formatNumber(Math.abs(deviationPercentFromTarget) * 100, {
                        digits: 3,
                        fractionDigits: 2,
                        stripZeros: true,
                      })}
                      %)
                    </b>
                  </>
                ))
                .exhaustive()}
            </span>
          </div>
        </>
      )}
    </Card>
  );
};
