import { useField } from 'formik';
import type { ComponentProps } from 'react';

import { COUNTRY_GEODATA, COUNTRY_ISO3166_ALPHA3 } from '@endaoment-frontend/constants';
import type { CountryCode } from '@endaoment-frontend/types';

import { Select } from '../Inputs';
import type { DropDownItem } from '../Inputs/Select';

type CountryFormInputProps = Omit<
  ComponentProps<typeof Select>,
  'label' | 'name' | 'onChange' | 'options' | 'value'
> & {
  countryFieldName: string;
  stateFieldName: string;
};

export const CountryFormInput = ({ countryFieldName, stateFieldName, ...props }: CountryFormInputProps) => {
  const defaultCountry = 'USA';
  const [{ value: countryValue }, , { setValue: setCountry }] = useField<CountryCode>(countryFieldName);
  const [, , { setValue: setState }] = useField(stateFieldName);

  return (
    <Select
      name={countryFieldName}
      label='Country'
      value={countryValue ?? defaultCountry}
      options={COUNTRY_ISO3166_ALPHA3.options
        .sort((a, b) => COUNTRY_GEODATA[a].name.localeCompare(COUNTRY_GEODATA[b].name))
        .map(
          (code): DropDownItem => ({
            value: code,
            display: COUNTRY_GEODATA[code].name,
          }),
        )}
      onChange={e => {
        const newCountry = COUNTRY_ISO3166_ALPHA3.safeParse(e.currentTarget.value);
        if (!newCountry.success) return;

        setCountry(newCountry.data, true);
        // Reset state if switching to/from USA
        if (
          (countryValue === 'USA' && newCountry.data !== 'USA') ||
          (countryValue !== 'USA' && newCountry.data === 'USA')
        ) {
          setState('', false);
        }
      }}
      {...props}
    />
  );
};
