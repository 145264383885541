import { z } from 'zod';

import { uuidSchema } from '../general';
import { bigIntSchema } from '../web3';

import { entityLabelSchema, portfolioTradeTypeSchema, stockTickerSchema } from './misc';
import { genericTokenSchema } from './tokens';

export const genericRecommendationSchema = z.object({
  id: uuidSchema,
  advisedFund: z.object({
    id: uuidSchema,
    name: z.string().trim().min(1),
  }),
  advisor: z.object({
    id: uuidSchema,
    firstName: z.string().trim().min(1),
    lastName: z.string().trim().min(1),
    email: z.string().email(),
  }),
  createdAt: z.string(),
  type: z.string(),
});

export const grantRecommendationSchema = genericRecommendationSchema.extend({
  type: z.literal('grant'),
  amountUsdc: bigIntSchema,
  destinationEntity: entityLabelSchema,
});
export type GrantRecommendation = z.infer<typeof grantRecommendationSchema>;

export const portfolioTradeRecommendationSchema = genericRecommendationSchema.extend({
  type: z.literal('trade'),
  inputAmountUsdc: bigIntSchema,
  portfolio: z.object({
    id: uuidSchema,
    name: z.string(),
    ticker: z.string(),
  }),
  tradeType: portfolioTradeTypeSchema,
});
export type PortfolioTradeRecommendation = z.infer<typeof portfolioTradeRecommendationSchema>;

// TODO: Both types are nearly identical - join or specialize once we start using their differences for recommendation execution
export const cryptoDonationPledgeRecommendationSchema = genericRecommendationSchema.extend({
  type: z.literal('nec_crypto_donation'),
  inputAmount: bigIntSchema,
  token: genericTokenSchema.pick({ id: true, name: true, symbol: true, decimals: true, logoUrl: true }),
});
export type CryptoDonationPledgeRecommendation = z.infer<typeof cryptoDonationPledgeRecommendationSchema>;

export const donationRecommendationSchema = cryptoDonationPledgeRecommendationSchema.omit({ type: true }).extend({
  type: z.literal('donation'),
});
export type DonationRecommendation = z.infer<typeof donationRecommendationSchema>;

export const stockDonationPledgeRecommendationSchema = genericRecommendationSchema.extend({
  type: z.literal('stock_donation_pledge'),
  stock: stockTickerSchema,
  shares: z.number({ coerce: true }),
});
export type StockDonationPledgeRecommendation = z.infer<typeof stockDonationPledgeRecommendationSchema>;

export const recommendationSchema = z.discriminatedUnion('type', [
  grantRecommendationSchema,
  portfolioTradeRecommendationSchema,
  donationRecommendationSchema,
  cryptoDonationPledgeRecommendationSchema,
  stockDonationPledgeRecommendationSchema,
]);
export type Recommendation = z.infer<typeof recommendationSchema>;
