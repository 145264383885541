import { useId } from 'react';

export const CardDotsBackground = ({ className }: { className?: string }) => {
  const id = useId();
  const gradientId = useId();
  return (
    <svg
      className={className}
      width='250'
      height='200'
      viewBox='0 0 250 200'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <g style={{ mixBlendMode: 'multiply' }} clipPath={`url(#${id})`}>
        <rect width='250' height='200' transform='matrix(-1 0 0 1 250 0)' fill='white' />
        <rect width='250' height='200' transform='matrix(-1 0 0 -1 246 196)' fill='white' />
        <circle cx='233.5' cy='194.5' r='1.5' transform='rotate(180 233.5 194.5)' fill='#0083CF' />
        <circle cx='222.5' cy='194.5' r='1.5' transform='rotate(180 222.5 194.5)' fill='#0083CF' />
        <circle cx='211.5' cy='194.5' r='1.5' transform='rotate(180 211.5 194.5)' fill='#0083CF' />
        <circle cx='200.5' cy='194.5' r='1.5' transform='rotate(180 200.5 194.5)' fill='#0083CF' />
        <circle cx='189.5' cy='194.5' r='1.5' transform='rotate(180 189.5 194.5)' fill='#0083CF' />
        <circle cx='178.5' cy='194.5' r='1.5' transform='rotate(180 178.5 194.5)' fill='#0083CF' />
        <circle cx='167.5' cy='194.5' r='1.5' transform='rotate(180 167.5 194.5)' fill='#0083CF' />
        <circle cx='156.5' cy='194.5' r='1.5' transform='rotate(180 156.5 194.5)' fill='#0083CF' />
        <circle cx='145.5' cy='194.5' r='1.5' transform='rotate(180 145.5 194.5)' fill='#0083CF' />
        <circle cx='134.5' cy='194.5' r='1.5' transform='rotate(180 134.5 194.5)' fill='#0083CF' />
        <circle cx='123.5' cy='194.5' r='1.5' transform='rotate(180 123.5 194.5)' fill='#0083CF' />
        <circle cx='112.5' cy='194.5' r='1.5' transform='rotate(180 112.5 194.5)' fill='#0083CF' />
        <circle cx='101.5' cy='194.5' r='1.5' transform='rotate(180 101.5 194.5)' fill='#0083CF' />
        <circle cx='90.5' cy='194.5' r='1.5' transform='rotate(180 90.5 194.5)' fill='#0083CF' />
        <circle cx='79.5' cy='194.5' r='1.5' transform='rotate(180 79.5 194.5)' fill='#0083CF' />
        <circle cx='68.5' cy='194.5' r='1.5' transform='rotate(180 68.5 194.5)' fill='#0083CF' />
        <circle cx='57.5' cy='194.5' r='1.5' transform='rotate(180 57.5 194.5)' fill='#0083CF' />
        <circle cx='46.5' cy='194.5' r='1.5' transform='rotate(180 46.5 194.5)' fill='#0083CF' />
        <circle cx='35.5' cy='194.5' r='1.5' transform='rotate(180 35.5 194.5)' fill='#0083CF' />
        <circle cx='24.5' cy='194.5' r='1.5' transform='rotate(180 24.5 194.5)' fill='#0083CF' />
        <circle cx='13.5' cy='194.5' r='1.5' transform='rotate(180 13.5 194.5)' fill='#0083CF' />
        <circle cx='2.5' cy='194.5' r='1.5' transform='rotate(180 2.5 194.5)' fill='#0083CF' />
        <circle cx='244.5' cy='183.5' r='1.5' transform='rotate(180 244.5 183.5)' fill='#0083CF' />
        <circle cx='233.5' cy='183.5' r='1.5' transform='rotate(180 233.5 183.5)' fill='#0083CF' />
        <circle cx='222.5' cy='183.5' r='1.5' transform='rotate(180 222.5 183.5)' fill='#0083CF' />
        <circle cx='211.5' cy='183.5' r='1.5' transform='rotate(180 211.5 183.5)' fill='#0083CF' />
        <circle cx='200.5' cy='183.5' r='1.5' transform='rotate(180 200.5 183.5)' fill='#0083CF' />
        <circle cx='189.5' cy='183.5' r='1.5' transform='rotate(180 189.5 183.5)' fill='#0083CF' />
        <circle cx='178.5' cy='183.5' r='1.5' transform='rotate(180 178.5 183.5)' fill='#0083CF' />
        <circle cx='167.5' cy='183.5' r='1.5' transform='rotate(180 167.5 183.5)' fill='#0083CF' />
        <circle cx='156.5' cy='183.5' r='1.5' transform='rotate(180 156.5 183.5)' fill='#0083CF' />
        <circle cx='145.5' cy='183.5' r='1.5' transform='rotate(180 145.5 183.5)' fill='#0083CF' />
        <circle cx='134.5' cy='183.5' r='1.5' transform='rotate(180 134.5 183.5)' fill='#0083CF' />
        <circle cx='123.5' cy='183.5' r='1.5' transform='rotate(180 123.5 183.5)' fill='#0083CF' />
        <circle cx='112.5' cy='183.5' r='1.5' transform='rotate(180 112.5 183.5)' fill='#0083CF' />
        <circle cx='101.5' cy='183.5' r='1.5' transform='rotate(180 101.5 183.5)' fill='#0083CF' />
        <circle cx='90.5' cy='183.5' r='1.5' transform='rotate(180 90.5 183.5)' fill='#0083CF' />
        <circle cx='79.5' cy='183.5' r='1.5' transform='rotate(180 79.5 183.5)' fill='#0083CF' />
        <circle cx='68.5' cy='183.5' r='1.5' transform='rotate(180 68.5 183.5)' fill='#0083CF' />
        <circle cx='57.5' cy='183.5' r='1.5' transform='rotate(180 57.5 183.5)' fill='#0083CF' />
        <circle cx='46.5' cy='183.5' r='1.5' transform='rotate(180 46.5 183.5)' fill='#0083CF' />
        <circle cx='35.5' cy='183.5' r='1.5' transform='rotate(180 35.5 183.5)' fill='#0083CF' />
        <circle cx='24.5' cy='183.5' r='1.5' transform='rotate(180 24.5 183.5)' fill='#0083CF' />
        <circle cx='13.5' cy='183.5' r='1.5' transform='rotate(180 13.5 183.5)' fill='#0083CF' />
        <circle cx='2.5' cy='183.5' r='1.5' transform='rotate(180 2.5 183.5)' fill='#0083CF' />
        <circle cx='244.5' cy='172.5' r='1.5' transform='rotate(180 244.5 172.5)' fill='#0083CF' />
        <circle cx='233.5' cy='172.5' r='1.5' transform='rotate(180 233.5 172.5)' fill='#0083CF' />
        <circle cx='222.5' cy='172.5' r='1.5' transform='rotate(180 222.5 172.5)' fill='#0083CF' />
        <circle cx='211.5' cy='172.5' r='1.5' transform='rotate(180 211.5 172.5)' fill='#0083CF' />
        <circle cx='200.5' cy='172.5' r='1.5' transform='rotate(180 200.5 172.5)' fill='#0083CF' />
        <circle cx='189.5' cy='172.5' r='1.5' transform='rotate(180 189.5 172.5)' fill='#0083CF' />
        <circle cx='178.5' cy='172.5' r='1.5' transform='rotate(180 178.5 172.5)' fill='#0083CF' />
        <circle cx='167.5' cy='172.5' r='1.5' transform='rotate(180 167.5 172.5)' fill='#0083CF' />
        <circle cx='156.5' cy='172.5' r='1.5' transform='rotate(180 156.5 172.5)' fill='#0083CF' />
        <circle cx='145.5' cy='172.5' r='1.5' transform='rotate(180 145.5 172.5)' fill='#0083CF' />
        <circle cx='134.5' cy='172.5' r='1.5' transform='rotate(180 134.5 172.5)' fill='#0083CF' />
        <circle cx='123.5' cy='172.5' r='1.5' transform='rotate(180 123.5 172.5)' fill='#0083CF' />
        <circle cx='112.5' cy='172.5' r='1.5' transform='rotate(180 112.5 172.5)' fill='#0083CF' />
        <circle cx='101.5' cy='172.5' r='1.5' transform='rotate(180 101.5 172.5)' fill='#0083CF' />
        <circle cx='90.5' cy='172.5' r='1.5' transform='rotate(180 90.5 172.5)' fill='#0083CF' />
        <circle cx='79.5' cy='172.5' r='1.5' transform='rotate(180 79.5 172.5)' fill='#0083CF' />
        <circle cx='68.5' cy='172.5' r='1.5' transform='rotate(180 68.5 172.5)' fill='#0083CF' />
        <circle cx='57.5' cy='172.5' r='1.5' transform='rotate(180 57.5 172.5)' fill='#0083CF' />
        <circle cx='46.5' cy='172.5' r='1.5' transform='rotate(180 46.5 172.5)' fill='#0083CF' />
        <circle cx='35.5' cy='172.5' r='1.5' transform='rotate(180 35.5 172.5)' fill='#0083CF' />
        <circle cx='24.5' cy='172.5' r='1.5' transform='rotate(180 24.5 172.5)' fill='#0083CF' />
        <circle cx='13.5' cy='172.5' r='1.5' transform='rotate(180 13.5 172.5)' fill='#0083CF' />
        <circle cx='2.5' cy='172.5' r='1.5' transform='rotate(180 2.5 172.5)' fill='#0083CF' />
        <circle cx='244.5' cy='161.5' r='1.5' transform='rotate(180 244.5 161.5)' fill='#0083CF' />
        <circle cx='233.5' cy='161.5' r='1.5' transform='rotate(180 233.5 161.5)' fill='#0083CF' />
        <circle cx='222.5' cy='161.5' r='1.5' transform='rotate(180 222.5 161.5)' fill='#0083CF' />
        <circle cx='211.5' cy='161.5' r='1.5' transform='rotate(180 211.5 161.5)' fill='#0083CF' />
        <circle cx='200.5' cy='161.5' r='1.5' transform='rotate(180 200.5 161.5)' fill='#0083CF' />
        <circle cx='189.5' cy='161.5' r='1.5' transform='rotate(180 189.5 161.5)' fill='#0083CF' />
        <circle cx='178.5' cy='161.5' r='1.5' transform='rotate(180 178.5 161.5)' fill='#0083CF' />
        <circle cx='167.5' cy='161.5' r='1.5' transform='rotate(180 167.5 161.5)' fill='#0083CF' />
        <circle cx='156.5' cy='161.5' r='1.5' transform='rotate(180 156.5 161.5)' fill='#0083CF' />
        <circle cx='145.5' cy='161.5' r='1.5' transform='rotate(180 145.5 161.5)' fill='#0083CF' />
        <circle cx='134.5' cy='161.5' r='1.5' transform='rotate(180 134.5 161.5)' fill='#0083CF' />
        <circle cx='123.5' cy='161.5' r='1.5' transform='rotate(180 123.5 161.5)' fill='#0083CF' />
        <circle cx='112.5' cy='161.5' r='1.5' transform='rotate(180 112.5 161.5)' fill='#0083CF' />
        <circle cx='101.5' cy='161.5' r='1.5' transform='rotate(180 101.5 161.5)' fill='#0083CF' />
        <circle cx='90.5' cy='161.5' r='1.5' transform='rotate(180 90.5 161.5)' fill='#0083CF' />
        <circle cx='79.5' cy='161.5' r='1.5' transform='rotate(180 79.5 161.5)' fill='#0083CF' />
        <circle cx='68.5' cy='161.5' r='1.5' transform='rotate(180 68.5 161.5)' fill='#0083CF' />
        <circle cx='57.5' cy='161.5' r='1.5' transform='rotate(180 57.5 161.5)' fill='#0083CF' />
        <circle cx='46.5' cy='161.5' r='1.5' transform='rotate(180 46.5 161.5)' fill='#0083CF' />
        <circle cx='35.5' cy='161.5' r='1.5' transform='rotate(180 35.5 161.5)' fill='#0083CF' />
        <circle cx='24.5' cy='161.5' r='1.5' transform='rotate(180 24.5 161.5)' fill='#0083CF' />
        <circle cx='13.5' cy='161.5' r='1.5' transform='rotate(180 13.5 161.5)' fill='#0083CF' />
        <circle cx='2.5' cy='161.5' r='1.5' transform='rotate(180 2.5 161.5)' fill='#0083CF' />
        <circle cx='244.5' cy='150.5' r='1.5' transform='rotate(180 244.5 150.5)' fill='#0083CF' />
        <circle cx='233.5' cy='150.5' r='1.5' transform='rotate(180 233.5 150.5)' fill='#0083CF' />
        <circle cx='222.5' cy='150.5' r='1.5' transform='rotate(180 222.5 150.5)' fill='#0083CF' />
        <circle cx='211.5' cy='150.5' r='1.5' transform='rotate(180 211.5 150.5)' fill='#0083CF' />
        <circle cx='200.5' cy='150.5' r='1.5' transform='rotate(180 200.5 150.5)' fill='#0083CF' />
        <circle cx='189.5' cy='150.5' r='1.5' transform='rotate(180 189.5 150.5)' fill='#0083CF' />
        <circle cx='178.5' cy='150.5' r='1.5' transform='rotate(180 178.5 150.5)' fill='#0083CF' />
        <circle cx='167.5' cy='150.5' r='1.5' transform='rotate(180 167.5 150.5)' fill='#0083CF' />
        <circle cx='156.5' cy='150.5' r='1.5' transform='rotate(180 156.5 150.5)' fill='#0083CF' />
        <circle cx='145.5' cy='150.5' r='1.5' transform='rotate(180 145.5 150.5)' fill='#0083CF' />
        <circle cx='134.5' cy='150.5' r='1.5' transform='rotate(180 134.5 150.5)' fill='#0083CF' />
        <circle cx='123.5' cy='150.5' r='1.5' transform='rotate(180 123.5 150.5)' fill='#0083CF' />
        <circle cx='112.5' cy='150.5' r='1.5' transform='rotate(180 112.5 150.5)' fill='#0083CF' />
        <circle cx='101.5' cy='150.5' r='1.5' transform='rotate(180 101.5 150.5)' fill='#0083CF' />
        <circle cx='90.5' cy='150.5' r='1.5' transform='rotate(180 90.5 150.5)' fill='#0083CF' />
        <circle cx='79.5' cy='150.5' r='1.5' transform='rotate(180 79.5 150.5)' fill='#0083CF' />
        <circle cx='68.5' cy='150.5' r='1.5' transform='rotate(180 68.5 150.5)' fill='#0083CF' />
        <circle cx='57.5' cy='150.5' r='1.5' transform='rotate(180 57.5 150.5)' fill='#0083CF' />
        <circle cx='46.5' cy='150.5' r='1.5' transform='rotate(180 46.5 150.5)' fill='#0083CF' />
        <circle cx='35.5' cy='150.5' r='1.5' transform='rotate(180 35.5 150.5)' fill='#0083CF' />
        <circle cx='24.5' cy='150.5' r='1.5' transform='rotate(180 24.5 150.5)' fill='#0083CF' />
        <circle cx='13.5' cy='150.5' r='1.5' transform='rotate(180 13.5 150.5)' fill='#0083CF' />
        <circle cx='2.5' cy='150.5' r='1.5' transform='rotate(180 2.5 150.5)' fill='#0083CF' />
        <circle cx='244.5' cy='139.5' r='1.5' transform='rotate(180 244.5 139.5)' fill='#0083CF' />
        <circle cx='233.5' cy='139.5' r='1.5' transform='rotate(180 233.5 139.5)' fill='#0083CF' />
        <circle cx='222.5' cy='139.5' r='1.5' transform='rotate(180 222.5 139.5)' fill='#0083CF' />
        <circle cx='211.5' cy='139.5' r='1.5' transform='rotate(180 211.5 139.5)' fill='#0083CF' />
        <circle cx='200.5' cy='139.5' r='1.5' transform='rotate(180 200.5 139.5)' fill='#0083CF' />
        <circle cx='189.5' cy='139.5' r='1.5' transform='rotate(180 189.5 139.5)' fill='#0083CF' />
        <circle cx='178.5' cy='139.5' r='1.5' transform='rotate(180 178.5 139.5)' fill='#0083CF' />
        <circle cx='167.5' cy='139.5' r='1.5' transform='rotate(180 167.5 139.5)' fill='#0083CF' />
        <circle cx='156.5' cy='139.5' r='1.5' transform='rotate(180 156.5 139.5)' fill='#0083CF' />
        <circle cx='145.5' cy='139.5' r='1.5' transform='rotate(180 145.5 139.5)' fill='#0083CF' />
        <circle cx='134.5' cy='139.5' r='1.5' transform='rotate(180 134.5 139.5)' fill='#0083CF' />
        <circle cx='123.5' cy='139.5' r='1.5' transform='rotate(180 123.5 139.5)' fill='#0083CF' />
        <circle cx='112.5' cy='139.5' r='1.5' transform='rotate(180 112.5 139.5)' fill='#0083CF' />
        <circle cx='101.5' cy='139.5' r='1.5' transform='rotate(180 101.5 139.5)' fill='#0083CF' />
        <circle cx='90.5' cy='139.5' r='1.5' transform='rotate(180 90.5 139.5)' fill='#0083CF' />
        <circle cx='79.5' cy='139.5' r='1.5' transform='rotate(180 79.5 139.5)' fill='#0083CF' />
        <circle cx='68.5' cy='139.5' r='1.5' transform='rotate(180 68.5 139.5)' fill='#0083CF' />
        <circle cx='57.5' cy='139.5' r='1.5' transform='rotate(180 57.5 139.5)' fill='#0083CF' />
        <circle cx='46.5' cy='139.5' r='1.5' transform='rotate(180 46.5 139.5)' fill='#0083CF' />
        <circle cx='35.5' cy='139.5' r='1.5' transform='rotate(180 35.5 139.5)' fill='#0083CF' />
        <circle cx='24.5' cy='139.5' r='1.5' transform='rotate(180 24.5 139.5)' fill='#0083CF' />
        <circle cx='13.5' cy='139.5' r='1.5' transform='rotate(180 13.5 139.5)' fill='#0083CF' />
        <circle cx='2.5' cy='139.5' r='1.5' transform='rotate(180 2.5 139.5)' fill='#0083CF' />
        <circle cx='244.5' cy='128.5' r='1.5' transform='rotate(180 244.5 128.5)' fill='#0083CF' />
        <circle cx='233.5' cy='128.5' r='1.5' transform='rotate(180 233.5 128.5)' fill='#0083CF' />
        <circle cx='222.5' cy='128.5' r='1.5' transform='rotate(180 222.5 128.5)' fill='#0083CF' />
        <circle cx='211.5' cy='128.5' r='1.5' transform='rotate(180 211.5 128.5)' fill='#0083CF' />
        <circle cx='200.5' cy='128.5' r='1.5' transform='rotate(180 200.5 128.5)' fill='#0083CF' />
        <circle cx='189.5' cy='128.5' r='1.5' transform='rotate(180 189.5 128.5)' fill='#0083CF' />
        <circle cx='178.5' cy='128.5' r='1.5' transform='rotate(180 178.5 128.5)' fill='#0083CF' />
        <circle cx='167.5' cy='128.5' r='1.5' transform='rotate(180 167.5 128.5)' fill='#0083CF' />
        <circle cx='156.5' cy='128.5' r='1.5' transform='rotate(180 156.5 128.5)' fill='#0083CF' />
        <circle cx='145.5' cy='128.5' r='1.5' transform='rotate(180 145.5 128.5)' fill='#0083CF' />
        <circle cx='134.5' cy='128.5' r='1.5' transform='rotate(180 134.5 128.5)' fill='#0083CF' />
        <circle cx='123.5' cy='128.5' r='1.5' transform='rotate(180 123.5 128.5)' fill='#0083CF' />
        <circle cx='112.5' cy='128.5' r='1.5' transform='rotate(180 112.5 128.5)' fill='#0083CF' />
        <circle cx='101.5' cy='128.5' r='1.5' transform='rotate(180 101.5 128.5)' fill='#0083CF' />
        <circle cx='90.5' cy='128.5' r='1.5' transform='rotate(180 90.5 128.5)' fill='#0083CF' />
        <circle cx='79.5' cy='128.5' r='1.5' transform='rotate(180 79.5 128.5)' fill='#0083CF' />
        <circle cx='68.5' cy='128.5' r='1.5' transform='rotate(180 68.5 128.5)' fill='#0083CF' />
        <circle cx='57.5' cy='128.5' r='1.5' transform='rotate(180 57.5 128.5)' fill='#0083CF' />
        <circle cx='46.5' cy='128.5' r='1.5' transform='rotate(180 46.5 128.5)' fill='#0083CF' />
        <circle cx='35.5' cy='128.5' r='1.5' transform='rotate(180 35.5 128.5)' fill='#0083CF' />
        <circle cx='24.5' cy='128.5' r='1.5' transform='rotate(180 24.5 128.5)' fill='#0083CF' />
        <circle cx='13.5' cy='128.5' r='1.5' transform='rotate(180 13.5 128.5)' fill='#0083CF' />
        <circle cx='2.5' cy='128.5' r='1.5' transform='rotate(180 2.5 128.5)' fill='#0083CF' />
        <circle cx='244.5' cy='117.5' r='1.5' transform='rotate(180 244.5 117.5)' fill='#0083CF' />
        <circle cx='233.5' cy='117.5' r='1.5' transform='rotate(180 233.5 117.5)' fill='#0083CF' />
        <circle cx='222.5' cy='117.5' r='1.5' transform='rotate(180 222.5 117.5)' fill='#0083CF' />
        <circle cx='211.5' cy='117.5' r='1.5' transform='rotate(180 211.5 117.5)' fill='#0083CF' />
        <circle cx='200.5' cy='117.5' r='1.5' transform='rotate(180 200.5 117.5)' fill='#0083CF' />
        <circle cx='189.5' cy='117.5' r='1.5' transform='rotate(180 189.5 117.5)' fill='#0083CF' />
        <circle cx='178.5' cy='117.5' r='1.5' transform='rotate(180 178.5 117.5)' fill='#0083CF' />
        <circle cx='167.5' cy='117.5' r='1.5' transform='rotate(180 167.5 117.5)' fill='#0083CF' />
        <circle cx='156.5' cy='117.5' r='1.5' transform='rotate(180 156.5 117.5)' fill='#0083CF' />
        <circle cx='145.5' cy='117.5' r='1.5' transform='rotate(180 145.5 117.5)' fill='#0083CF' />
        <circle cx='134.5' cy='117.5' r='1.5' transform='rotate(180 134.5 117.5)' fill='#0083CF' />
        <circle cx='123.5' cy='117.5' r='1.5' transform='rotate(180 123.5 117.5)' fill='#0083CF' />
        <circle cx='112.5' cy='117.5' r='1.5' transform='rotate(180 112.5 117.5)' fill='#0083CF' />
        <circle cx='101.5' cy='117.5' r='1.5' transform='rotate(180 101.5 117.5)' fill='#0083CF' />
        <circle cx='90.5' cy='117.5' r='1.5' transform='rotate(180 90.5 117.5)' fill='#0083CF' />
        <circle cx='79.5' cy='117.5' r='1.5' transform='rotate(180 79.5 117.5)' fill='#0083CF' />
        <circle cx='68.5' cy='117.5' r='1.5' transform='rotate(180 68.5 117.5)' fill='#0083CF' />
        <circle cx='57.5' cy='117.5' r='1.5' transform='rotate(180 57.5 117.5)' fill='#0083CF' />
        <circle cx='46.5' cy='117.5' r='1.5' transform='rotate(180 46.5 117.5)' fill='#0083CF' />
        <circle cx='35.5' cy='117.5' r='1.5' transform='rotate(180 35.5 117.5)' fill='#0083CF' />
        <circle cx='24.5' cy='117.5' r='1.5' transform='rotate(180 24.5 117.5)' fill='#0083CF' />
        <circle cx='13.5' cy='117.5' r='1.5' transform='rotate(180 13.5 117.5)' fill='#0083CF' />
        <circle cx='2.5' cy='117.5' r='1.5' transform='rotate(180 2.5 117.5)' fill='#0083CF' />
        <circle cx='244.5' cy='106.5' r='1.5' transform='rotate(180 244.5 106.5)' fill='#0083CF' />
        <circle cx='233.5' cy='106.5' r='1.5' transform='rotate(180 233.5 106.5)' fill='#0083CF' />
        <circle cx='222.5' cy='106.5' r='1.5' transform='rotate(180 222.5 106.5)' fill='#0083CF' />
        <circle cx='211.5' cy='106.5' r='1.5' transform='rotate(180 211.5 106.5)' fill='#0083CF' />
        <circle cx='200.5' cy='106.5' r='1.5' transform='rotate(180 200.5 106.5)' fill='#0083CF' />
        <circle cx='189.5' cy='106.5' r='1.5' transform='rotate(180 189.5 106.5)' fill='#0083CF' />
        <circle cx='178.5' cy='106.5' r='1.5' transform='rotate(180 178.5 106.5)' fill='#0083CF' />
        <circle cx='167.5' cy='106.5' r='1.5' transform='rotate(180 167.5 106.5)' fill='#0083CF' />
        <circle cx='156.5' cy='106.5' r='1.5' transform='rotate(180 156.5 106.5)' fill='#0083CF' />
        <circle cx='145.5' cy='106.5' r='1.5' transform='rotate(180 145.5 106.5)' fill='#0083CF' />
        <circle cx='134.5' cy='106.5' r='1.5' transform='rotate(180 134.5 106.5)' fill='#0083CF' />
        <circle cx='123.5' cy='106.5' r='1.5' transform='rotate(180 123.5 106.5)' fill='#0083CF' />
        <circle cx='112.5' cy='106.5' r='1.5' transform='rotate(180 112.5 106.5)' fill='#0083CF' />
        <circle cx='101.5' cy='106.5' r='1.5' transform='rotate(180 101.5 106.5)' fill='#0083CF' />
        <circle cx='90.5' cy='106.5' r='1.5' transform='rotate(180 90.5 106.5)' fill='#0083CF' />
        <circle cx='79.5' cy='106.5' r='1.5' transform='rotate(180 79.5 106.5)' fill='#0083CF' />
        <circle cx='68.5' cy='106.5' r='1.5' transform='rotate(180 68.5 106.5)' fill='#0083CF' />
        <circle cx='57.5' cy='106.5' r='1.5' transform='rotate(180 57.5 106.5)' fill='#0083CF' />
        <circle cx='46.5' cy='106.5' r='1.5' transform='rotate(180 46.5 106.5)' fill='#0083CF' />
        <circle cx='35.5' cy='106.5' r='1.5' transform='rotate(180 35.5 106.5)' fill='#0083CF' />
        <circle cx='24.5' cy='106.5' r='1.5' transform='rotate(180 24.5 106.5)' fill='#0083CF' />
        <circle cx='13.5' cy='106.5' r='1.5' transform='rotate(180 13.5 106.5)' fill='#0083CF' />
        <circle cx='2.5' cy='106.5' r='1.5' transform='rotate(180 2.5 106.5)' fill='#0083CF' />
        <circle cx='244.5' cy='95.5' r='1.5' transform='rotate(180 244.5 95.5)' fill='#0083CF' />
        <circle cx='233.5' cy='95.5' r='1.5' transform='rotate(180 233.5 95.5)' fill='#0083CF' />
        <circle cx='222.5' cy='95.5' r='1.5' transform='rotate(180 222.5 95.5)' fill='#0083CF' />
        <circle cx='211.5' cy='95.5' r='1.5' transform='rotate(180 211.5 95.5)' fill='#0083CF' />
        <circle cx='200.5' cy='95.5' r='1.5' transform='rotate(180 200.5 95.5)' fill='#0083CF' />
        <circle cx='189.5' cy='95.5' r='1.5' transform='rotate(180 189.5 95.5)' fill='#0083CF' />
        <circle cx='178.5' cy='95.5' r='1.5' transform='rotate(180 178.5 95.5)' fill='#0083CF' />
        <circle cx='167.5' cy='95.5' r='1.5' transform='rotate(180 167.5 95.5)' fill='#0083CF' />
        <circle cx='156.5' cy='95.5' r='1.5' transform='rotate(180 156.5 95.5)' fill='#0083CF' />
        <circle cx='145.5' cy='95.5' r='1.5' transform='rotate(180 145.5 95.5)' fill='#0083CF' />
        <circle cx='134.5' cy='95.5' r='1.5' transform='rotate(180 134.5 95.5)' fill='#0083CF' />
        <circle cx='123.5' cy='95.5' r='1.5' transform='rotate(180 123.5 95.5)' fill='#0083CF' />
        <circle cx='112.5' cy='95.5' r='1.5' transform='rotate(180 112.5 95.5)' fill='#0083CF' />
        <circle cx='101.5' cy='95.5' r='1.5' transform='rotate(180 101.5 95.5)' fill='#0083CF' />
        <circle cx='90.5' cy='95.5' r='1.5' transform='rotate(180 90.5 95.5)' fill='#0083CF' />
        <circle cx='79.5' cy='95.5' r='1.5' transform='rotate(180 79.5 95.5)' fill='#0083CF' />
        <circle cx='68.5' cy='95.5' r='1.5' transform='rotate(180 68.5 95.5)' fill='#0083CF' />
        <circle cx='57.5' cy='95.5' r='1.5' transform='rotate(180 57.5 95.5)' fill='#0083CF' />
        <circle cx='46.5' cy='95.5' r='1.5' transform='rotate(180 46.5 95.5)' fill='#0083CF' />
        <circle cx='35.5' cy='95.5' r='1.5' transform='rotate(180 35.5 95.5)' fill='#0083CF' />
        <circle cx='24.5' cy='95.5' r='1.5' transform='rotate(180 24.5 95.5)' fill='#0083CF' />
        <circle cx='13.5' cy='95.5' r='1.5' transform='rotate(180 13.5 95.5)' fill='#0083CF' />
        <circle cx='2.5' cy='95.5' r='1.5' transform='rotate(180 2.5 95.5)' fill='#0083CF' />
        <circle cx='244.5' cy='84.5' r='1.5' transform='rotate(180 244.5 84.5)' fill='#0083CF' />
        <circle cx='233.5' cy='84.5' r='1.5' transform='rotate(180 233.5 84.5)' fill='#0083CF' />
        <circle cx='222.5' cy='84.5' r='1.5' transform='rotate(180 222.5 84.5)' fill='#0083CF' />
        <circle cx='211.5' cy='84.5' r='1.5' transform='rotate(180 211.5 84.5)' fill='#0083CF' />
        <circle cx='200.5' cy='84.5' r='1.5' transform='rotate(180 200.5 84.5)' fill='#0083CF' />
        <circle cx='189.5' cy='84.5' r='1.5' transform='rotate(180 189.5 84.5)' fill='#0083CF' />
        <circle cx='178.5' cy='84.5' r='1.5' transform='rotate(180 178.5 84.5)' fill='#0083CF' />
        <circle cx='167.5' cy='84.5' r='1.5' transform='rotate(180 167.5 84.5)' fill='#0083CF' />
        <circle cx='156.5' cy='84.5' r='1.5' transform='rotate(180 156.5 84.5)' fill='#0083CF' />
        <circle cx='145.5' cy='84.5' r='1.5' transform='rotate(180 145.5 84.5)' fill='#0083CF' />
        <circle cx='134.5' cy='84.5' r='1.5' transform='rotate(180 134.5 84.5)' fill='#0083CF' />
        <circle cx='123.5' cy='84.5' r='1.5' transform='rotate(180 123.5 84.5)' fill='#0083CF' />
        <circle cx='112.5' cy='84.5' r='1.5' transform='rotate(180 112.5 84.5)' fill='#0083CF' />
        <circle cx='101.5' cy='84.5' r='1.5' transform='rotate(180 101.5 84.5)' fill='#0083CF' />
        <circle cx='90.5' cy='84.5' r='1.5' transform='rotate(180 90.5 84.5)' fill='#0083CF' />
        <circle cx='79.5' cy='84.5' r='1.5' transform='rotate(180 79.5 84.5)' fill='#0083CF' />
        <circle cx='68.5' cy='84.5' r='1.5' transform='rotate(180 68.5 84.5)' fill='#0083CF' />
        <circle cx='57.5' cy='84.5' r='1.5' transform='rotate(180 57.5 84.5)' fill='#0083CF' />
        <circle cx='46.5' cy='84.5' r='1.5' transform='rotate(180 46.5 84.5)' fill='#0083CF' />
        <circle cx='35.5' cy='84.5' r='1.5' transform='rotate(180 35.5 84.5)' fill='#0083CF' />
        <circle cx='24.5' cy='84.5' r='1.5' transform='rotate(180 24.5 84.5)' fill='#0083CF' />
        <circle cx='13.5' cy='84.5' r='1.5' transform='rotate(180 13.5 84.5)' fill='#0083CF' />
        <circle cx='2.5' cy='84.5' r='1.5' transform='rotate(180 2.5 84.5)' fill='#0083CF' />
        <circle cx='244.5' cy='73.5' r='1.5' transform='rotate(180 244.5 73.5)' fill='#0083CF' />
        <circle cx='233.5' cy='73.5' r='1.5' transform='rotate(180 233.5 73.5)' fill='#0083CF' />
        <circle cx='222.5' cy='73.5' r='1.5' transform='rotate(180 222.5 73.5)' fill='#0083CF' />
        <circle cx='211.5' cy='73.5' r='1.5' transform='rotate(180 211.5 73.5)' fill='#0083CF' />
        <circle cx='200.5' cy='73.5' r='1.5' transform='rotate(180 200.5 73.5)' fill='#0083CF' />
        <circle cx='189.5' cy='73.5' r='1.5' transform='rotate(180 189.5 73.5)' fill='#0083CF' />
        <circle cx='178.5' cy='73.5' r='1.5' transform='rotate(180 178.5 73.5)' fill='#0083CF' />
        <circle cx='167.5' cy='73.5' r='1.5' transform='rotate(180 167.5 73.5)' fill='#0083CF' />
        <circle cx='156.5' cy='73.5' r='1.5' transform='rotate(180 156.5 73.5)' fill='#0083CF' />
        <circle cx='145.5' cy='73.5' r='1.5' transform='rotate(180 145.5 73.5)' fill='#0083CF' />
        <circle cx='134.5' cy='73.5' r='1.5' transform='rotate(180 134.5 73.5)' fill='#0083CF' />
        <circle cx='123.5' cy='73.5' r='1.5' transform='rotate(180 123.5 73.5)' fill='#0083CF' />
        <circle cx='112.5' cy='73.5' r='1.5' transform='rotate(180 112.5 73.5)' fill='#0083CF' />
        <circle cx='101.5' cy='73.5' r='1.5' transform='rotate(180 101.5 73.5)' fill='#0083CF' />
        <circle cx='90.5' cy='73.5' r='1.5' transform='rotate(180 90.5 73.5)' fill='#0083CF' />
        <circle cx='79.5' cy='73.5' r='1.5' transform='rotate(180 79.5 73.5)' fill='#0083CF' />
        <circle cx='68.5' cy='73.5' r='1.5' transform='rotate(180 68.5 73.5)' fill='#0083CF' />
        <circle cx='57.5' cy='73.5' r='1.5' transform='rotate(180 57.5 73.5)' fill='#0083CF' />
        <circle cx='46.5' cy='73.5' r='1.5' transform='rotate(180 46.5 73.5)' fill='#0083CF' />
        <circle cx='35.5' cy='73.5' r='1.5' transform='rotate(180 35.5 73.5)' fill='#0083CF' />
        <circle cx='24.5' cy='73.5' r='1.5' transform='rotate(180 24.5 73.5)' fill='#0083CF' />
        <circle cx='13.5' cy='73.5' r='1.5' transform='rotate(180 13.5 73.5)' fill='#0083CF' />
        <circle cx='2.5' cy='73.5' r='1.5' transform='rotate(180 2.5 73.5)' fill='#0083CF' />
        <circle cx='244.5' cy='62.5' r='1.5' transform='rotate(180 244.5 62.5)' fill='#0083CF' />
        <circle cx='233.5' cy='62.5' r='1.5' transform='rotate(180 233.5 62.5)' fill='#0083CF' />
        <circle cx='222.5' cy='62.5' r='1.5' transform='rotate(180 222.5 62.5)' fill='#0083CF' />
        <circle cx='211.5' cy='62.5' r='1.5' transform='rotate(180 211.5 62.5)' fill='#0083CF' />
        <circle cx='200.5' cy='62.5' r='1.5' transform='rotate(180 200.5 62.5)' fill='#0083CF' />
        <circle cx='189.5' cy='62.5' r='1.5' transform='rotate(180 189.5 62.5)' fill='#0083CF' />
        <circle cx='178.5' cy='62.5' r='1.5' transform='rotate(180 178.5 62.5)' fill='#0083CF' />
        <circle cx='167.5' cy='62.5' r='1.5' transform='rotate(180 167.5 62.5)' fill='#0083CF' />
        <circle cx='156.5' cy='62.5' r='1.5' transform='rotate(180 156.5 62.5)' fill='#0083CF' />
        <circle cx='145.5' cy='62.5' r='1.5' transform='rotate(180 145.5 62.5)' fill='#0083CF' />
        <circle cx='134.5' cy='62.5' r='1.5' transform='rotate(180 134.5 62.5)' fill='#0083CF' />
        <circle cx='123.5' cy='62.5' r='1.5' transform='rotate(180 123.5 62.5)' fill='#0083CF' />
        <circle cx='112.5' cy='62.5' r='1.5' transform='rotate(180 112.5 62.5)' fill='#0083CF' />
        <circle cx='101.5' cy='62.5' r='1.5' transform='rotate(180 101.5 62.5)' fill='#0083CF' />
        <circle cx='90.5' cy='62.5' r='1.5' transform='rotate(180 90.5 62.5)' fill='#0083CF' />
        <circle cx='79.5' cy='62.5' r='1.5' transform='rotate(180 79.5 62.5)' fill='#0083CF' />
        <circle cx='68.5' cy='62.5' r='1.5' transform='rotate(180 68.5 62.5)' fill='#0083CF' />
        <circle cx='57.5' cy='62.5' r='1.5' transform='rotate(180 57.5 62.5)' fill='#0083CF' />
        <circle cx='46.5' cy='62.5' r='1.5' transform='rotate(180 46.5 62.5)' fill='#0083CF' />
        <circle cx='35.5' cy='62.5' r='1.5' transform='rotate(180 35.5 62.5)' fill='#0083CF' />
        <circle cx='24.5' cy='62.5' r='1.5' transform='rotate(180 24.5 62.5)' fill='#0083CF' />
        <circle cx='13.5' cy='62.5' r='1.5' transform='rotate(180 13.5 62.5)' fill='#0083CF' />
        <circle cx='2.5' cy='62.5' r='1.5' transform='rotate(180 2.5 62.5)' fill='#0083CF' />
        <circle cx='244.5' cy='51.5' r='1.5' transform='rotate(180 244.5 51.5)' fill='#0083CF' />
        <circle cx='233.5' cy='51.5' r='1.5' transform='rotate(180 233.5 51.5)' fill='#0083CF' />
        <circle cx='222.5' cy='51.5' r='1.5' transform='rotate(180 222.5 51.5)' fill='#0083CF' />
        <circle cx='211.5' cy='51.5' r='1.5' transform='rotate(180 211.5 51.5)' fill='#0083CF' />
        <circle cx='200.5' cy='51.5' r='1.5' transform='rotate(180 200.5 51.5)' fill='#0083CF' />
        <circle cx='189.5' cy='51.5' r='1.5' transform='rotate(180 189.5 51.5)' fill='#0083CF' />
        <circle cx='178.5' cy='51.5' r='1.5' transform='rotate(180 178.5 51.5)' fill='#0083CF' />
        <circle cx='167.5' cy='51.5' r='1.5' transform='rotate(180 167.5 51.5)' fill='#0083CF' />
        <circle cx='156.5' cy='51.5' r='1.5' transform='rotate(180 156.5 51.5)' fill='#0083CF' />
        <circle cx='145.5' cy='51.5' r='1.5' transform='rotate(180 145.5 51.5)' fill='#0083CF' />
        <circle cx='134.5' cy='51.5' r='1.5' transform='rotate(180 134.5 51.5)' fill='#0083CF' />
        <circle cx='123.5' cy='51.5' r='1.5' transform='rotate(180 123.5 51.5)' fill='#0083CF' />
        <circle cx='112.5' cy='51.5' r='1.5' transform='rotate(180 112.5 51.5)' fill='#0083CF' />
        <circle cx='101.5' cy='51.5' r='1.5' transform='rotate(180 101.5 51.5)' fill='#0083CF' />
        <circle cx='90.5' cy='51.5' r='1.5' transform='rotate(180 90.5 51.5)' fill='#0083CF' />
        <circle cx='79.5' cy='51.5' r='1.5' transform='rotate(180 79.5 51.5)' fill='#0083CF' />
        <circle cx='68.5' cy='51.5' r='1.5' transform='rotate(180 68.5 51.5)' fill='#0083CF' />
        <circle cx='57.5' cy='51.5' r='1.5' transform='rotate(180 57.5 51.5)' fill='#0083CF' />
        <circle cx='46.5' cy='51.5' r='1.5' transform='rotate(180 46.5 51.5)' fill='#0083CF' />
        <circle cx='35.5' cy='51.5' r='1.5' transform='rotate(180 35.5 51.5)' fill='#0083CF' />
        <circle cx='24.5' cy='51.5' r='1.5' transform='rotate(180 24.5 51.5)' fill='#0083CF' />
        <circle cx='13.5' cy='51.5' r='1.5' transform='rotate(180 13.5 51.5)' fill='#0083CF' />
        <circle cx='2.5' cy='51.5' r='1.5' transform='rotate(180 2.5 51.5)' fill='#0083CF' />
        <circle cx='244.5' cy='40.5' r='1.5' transform='rotate(180 244.5 40.5)' fill='#0083CF' />
        <circle cx='233.5' cy='40.5' r='1.5' transform='rotate(180 233.5 40.5)' fill='#0083CF' />
        <circle cx='222.5' cy='40.5' r='1.5' transform='rotate(180 222.5 40.5)' fill='#0083CF' />
        <circle cx='211.5' cy='40.5' r='1.5' transform='rotate(180 211.5 40.5)' fill='#0083CF' />
        <circle cx='200.5' cy='40.5' r='1.5' transform='rotate(180 200.5 40.5)' fill='#0083CF' />
        <circle cx='189.5' cy='40.5' r='1.5' transform='rotate(180 189.5 40.5)' fill='#0083CF' />
        <circle cx='178.5' cy='40.5' r='1.5' transform='rotate(180 178.5 40.5)' fill='#0083CF' />
        <circle cx='167.5' cy='40.5' r='1.5' transform='rotate(180 167.5 40.5)' fill='#0083CF' />
        <circle cx='156.5' cy='40.5' r='1.5' transform='rotate(180 156.5 40.5)' fill='#0083CF' />
        <circle cx='145.5' cy='40.5' r='1.5' transform='rotate(180 145.5 40.5)' fill='#0083CF' />
        <circle cx='134.5' cy='40.5' r='1.5' transform='rotate(180 134.5 40.5)' fill='#0083CF' />
        <circle cx='123.5' cy='40.5' r='1.5' transform='rotate(180 123.5 40.5)' fill='#0083CF' />
        <circle cx='112.5' cy='40.5' r='1.5' transform='rotate(180 112.5 40.5)' fill='#0083CF' />
        <circle cx='101.5' cy='40.5' r='1.5' transform='rotate(180 101.5 40.5)' fill='#0083CF' />
        <circle cx='90.5' cy='40.5' r='1.5' transform='rotate(180 90.5 40.5)' fill='#0083CF' />
        <circle cx='79.5' cy='40.5' r='1.5' transform='rotate(180 79.5 40.5)' fill='#0083CF' />
        <circle cx='68.5' cy='40.5' r='1.5' transform='rotate(180 68.5 40.5)' fill='#0083CF' />
        <circle cx='57.5' cy='40.5' r='1.5' transform='rotate(180 57.5 40.5)' fill='#0083CF' />
        <circle cx='46.5' cy='40.5' r='1.5' transform='rotate(180 46.5 40.5)' fill='#0083CF' />
        <circle cx='35.5' cy='40.5' r='1.5' transform='rotate(180 35.5 40.5)' fill='#0083CF' />
        <circle cx='24.5' cy='40.5' r='1.5' transform='rotate(180 24.5 40.5)' fill='#0083CF' />
        <circle cx='13.5' cy='40.5' r='1.5' transform='rotate(180 13.5 40.5)' fill='#0083CF' />
        <circle cx='2.5' cy='40.5' r='1.5' transform='rotate(180 2.5 40.5)' fill='#0083CF' />
        <circle cx='244.5' cy='29.5' r='1.5' transform='rotate(180 244.5 29.5)' fill='#0083CF' />
        <circle cx='233.5' cy='29.5' r='1.5' transform='rotate(180 233.5 29.5)' fill='#0083CF' />
        <circle cx='222.5' cy='29.5' r='1.5' transform='rotate(180 222.5 29.5)' fill='#0083CF' />
        <circle cx='211.5' cy='29.5' r='1.5' transform='rotate(180 211.5 29.5)' fill='#0083CF' />
        <circle cx='200.5' cy='29.5' r='1.5' transform='rotate(180 200.5 29.5)' fill='#0083CF' />
        <circle cx='189.5' cy='29.5' r='1.5' transform='rotate(180 189.5 29.5)' fill='#0083CF' />
        <circle cx='178.5' cy='29.5' r='1.5' transform='rotate(180 178.5 29.5)' fill='#0083CF' />
        <circle cx='167.5' cy='29.5' r='1.5' transform='rotate(180 167.5 29.5)' fill='#0083CF' />
        <circle cx='156.5' cy='29.5' r='1.5' transform='rotate(180 156.5 29.5)' fill='#0083CF' />
        <circle cx='145.5' cy='29.5' r='1.5' transform='rotate(180 145.5 29.5)' fill='#0083CF' />
        <circle cx='134.5' cy='29.5' r='1.5' transform='rotate(180 134.5 29.5)' fill='#0083CF' />
        <circle cx='123.5' cy='29.5' r='1.5' transform='rotate(180 123.5 29.5)' fill='#0083CF' />
        <circle cx='112.5' cy='29.5' r='1.5' transform='rotate(180 112.5 29.5)' fill='#0083CF' />
        <circle cx='101.5' cy='29.5' r='1.5' transform='rotate(180 101.5 29.5)' fill='#0083CF' />
        <circle cx='90.5' cy='29.5' r='1.5' transform='rotate(180 90.5 29.5)' fill='#0083CF' />
        <circle cx='79.5' cy='29.5' r='1.5' transform='rotate(180 79.5 29.5)' fill='#0083CF' />
        <circle cx='68.5' cy='29.5' r='1.5' transform='rotate(180 68.5 29.5)' fill='#0083CF' />
        <circle cx='57.5' cy='29.5' r='1.5' transform='rotate(180 57.5 29.5)' fill='#0083CF' />
        <circle cx='46.5' cy='29.5' r='1.5' transform='rotate(180 46.5 29.5)' fill='#0083CF' />
        <circle cx='35.5' cy='29.5' r='1.5' transform='rotate(180 35.5 29.5)' fill='#0083CF' />
        <circle cx='24.5' cy='29.5' r='1.5' transform='rotate(180 24.5 29.5)' fill='#0083CF' />
        <circle cx='13.5' cy='29.5' r='1.5' transform='rotate(180 13.5 29.5)' fill='#0083CF' />
        <circle cx='2.5' cy='29.5' r='1.5' transform='rotate(180 2.5 29.5)' fill='#0083CF' />
        <circle cx='244.5' cy='18.5' r='1.5' transform='rotate(180 244.5 18.5)' fill='#0083CF' />
        <circle cx='233.5' cy='18.5' r='1.5' transform='rotate(180 233.5 18.5)' fill='#0083CF' />
        <circle cx='222.5' cy='18.5' r='1.5' transform='rotate(180 222.5 18.5)' fill='#0083CF' />
        <circle cx='211.5' cy='18.5' r='1.5' transform='rotate(180 211.5 18.5)' fill='#0083CF' />
        <circle cx='200.5' cy='18.5' r='1.5' transform='rotate(180 200.5 18.5)' fill='#0083CF' />
        <circle cx='189.5' cy='18.5' r='1.5' transform='rotate(180 189.5 18.5)' fill='#0083CF' />
        <circle cx='178.5' cy='18.5' r='1.5' transform='rotate(180 178.5 18.5)' fill='#0083CF' />
        <circle cx='167.5' cy='18.5' r='1.5' transform='rotate(180 167.5 18.5)' fill='#0083CF' />
        <circle cx='156.5' cy='18.5' r='1.5' transform='rotate(180 156.5 18.5)' fill='#0083CF' />
        <circle cx='145.5' cy='18.5' r='1.5' transform='rotate(180 145.5 18.5)' fill='#0083CF' />
        <circle cx='134.5' cy='18.5' r='1.5' transform='rotate(180 134.5 18.5)' fill='#0083CF' />
        <circle cx='123.5' cy='18.5' r='1.5' transform='rotate(180 123.5 18.5)' fill='#0083CF' />
        <circle cx='112.5' cy='18.5' r='1.5' transform='rotate(180 112.5 18.5)' fill='#0083CF' />
        <circle cx='101.5' cy='18.5' r='1.5' transform='rotate(180 101.5 18.5)' fill='#0083CF' />
        <circle cx='90.5' cy='18.5' r='1.5' transform='rotate(180 90.5 18.5)' fill='#0083CF' />
        <circle cx='79.5' cy='18.5' r='1.5' transform='rotate(180 79.5 18.5)' fill='#0083CF' />
        <circle cx='68.5' cy='18.5' r='1.5' transform='rotate(180 68.5 18.5)' fill='#0083CF' />
        <circle cx='57.5' cy='18.5' r='1.5' transform='rotate(180 57.5 18.5)' fill='#0083CF' />
        <circle cx='46.5' cy='18.5' r='1.5' transform='rotate(180 46.5 18.5)' fill='#0083CF' />
        <circle cx='35.5' cy='18.5' r='1.5' transform='rotate(180 35.5 18.5)' fill='#0083CF' />
        <circle cx='24.5' cy='18.5' r='1.5' transform='rotate(180 24.5 18.5)' fill='#0083CF' />
        <circle cx='13.5' cy='18.5' r='1.5' transform='rotate(180 13.5 18.5)' fill='#0083CF' />
        <circle cx='2.5' cy='18.5' r='1.5' transform='rotate(180 2.5 18.5)' fill='#0083CF' />
        <circle cx='244.5' cy='7.5' r='1.5' transform='rotate(180 244.5 7.5)' fill='#0083CF' />
        <circle cx='233.5' cy='7.5' r='1.5' transform='rotate(180 233.5 7.5)' fill='#0083CF' />
        <circle cx='222.5' cy='7.5' r='1.5' transform='rotate(180 222.5 7.5)' fill='#0083CF' />
        <circle cx='211.5' cy='7.5' r='1.5' transform='rotate(180 211.5 7.5)' fill='#0083CF' />
        <circle cx='200.5' cy='7.5' r='1.5' transform='rotate(180 200.5 7.5)' fill='#0083CF' />
        <circle cx='189.5' cy='7.5' r='1.5' transform='rotate(180 189.5 7.5)' fill='#0083CF' />
        <circle cx='178.5' cy='7.5' r='1.5' transform='rotate(180 178.5 7.5)' fill='#0083CF' />
        <circle cx='167.5' cy='7.5' r='1.5' transform='rotate(180 167.5 7.5)' fill='#0083CF' />
        <circle cx='156.5' cy='7.5' r='1.5' transform='rotate(180 156.5 7.5)' fill='#0083CF' />
        <circle cx='145.5' cy='7.5' r='1.5' transform='rotate(180 145.5 7.5)' fill='#0083CF' />
        <circle cx='134.5' cy='7.5' r='1.5' transform='rotate(180 134.5 7.5)' fill='#0083CF' />
        <circle cx='123.5' cy='7.5' r='1.5' transform='rotate(180 123.5 7.5)' fill='#0083CF' />
        <circle cx='112.5' cy='7.5' r='1.5' transform='rotate(180 112.5 7.5)' fill='#0083CF' />
        <circle cx='101.5' cy='7.5' r='1.5' transform='rotate(180 101.5 7.5)' fill='#0083CF' />
        <circle cx='90.5' cy='7.5' r='1.5' transform='rotate(180 90.5 7.5)' fill='#0083CF' />
        <circle cx='79.5' cy='7.5' r='1.5' transform='rotate(180 79.5 7.5)' fill='#0083CF' />
        <circle cx='68.5' cy='7.5' r='1.5' transform='rotate(180 68.5 7.5)' fill='#0083CF' />
        <circle cx='57.5' cy='7.5' r='1.5' transform='rotate(180 57.5 7.5)' fill='#0083CF' />
        <circle cx='46.5' cy='7.5' r='1.5' transform='rotate(180 46.5 7.5)' fill='#0083CF' />
        <circle cx='35.5' cy='7.5' r='1.5' transform='rotate(180 35.5 7.5)' fill='#0083CF' />
        <circle cx='24.5' cy='7.5' r='1.5' transform='rotate(180 24.5 7.5)' fill='#0083CF' />
        <circle cx='13.5' cy='7.5' r='1.5' transform='rotate(180 13.5 7.5)' fill='#0083CF' />
        <circle cx='2.5' cy='7.5' r='1.5' transform='rotate(180 2.5 7.5)' fill='#0083CF' />
        <rect width='250' height='200' transform='matrix(-1 0 0 1 250 0)' fill={`url(#${gradientId})`} />
      </g>
      <defs>
        <radialGradient
          id={gradientId}
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(-9.0301e-06 200) rotate(-90) scale(200 250)'>
          <stop stopColor='white' stopOpacity='0.5' />
          <stop offset='0.317708' stopColor='white' stopOpacity='0.9' />
          <stop offset='0.807292' stopColor='white' />
        </radialGradient>
        <clipPath id={id}>
          <rect width='250' height='200' fill='white' transform='matrix(-1 0 0 1 250 0)' />
        </clipPath>
      </defs>
    </svg>
  );
};
