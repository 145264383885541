import { Link } from '@chakra-ui/next-js';
import clsx from 'clsx';
import type { ComponentProps, ReactNode } from 'react';

import { Button } from '../button';

import styles from './ImageButtonRow.module.scss';

export type ImageButtonProps = Omit<ComponentProps<typeof Button<'a'>>, 'as' | 'children'> & {
  label: string;
  href?: string;
  paragraph?: string;
  icon?: ReactNode;
};

export const ImageButton = ({ label, href, paragraph, icon, className, ...props }: ImageButtonProps) => {
  const external = href ? href.charAt(0) !== '/' : false;
  return (
    <Button
      as={Link}
      href={href ?? ''}
      target={external ? '_blank' : undefined}
      rel={external ? 'noreferrer' : undefined}
      cardIcon={icon}
      className={clsx(className, styles['image-button'])}
      {...props}>
      <div>
        <h4>{label}</h4>
        {!!paragraph && <p>{paragraph}</p>}
      </div>
    </Button>
  );
};
