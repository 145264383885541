import { useEffect } from 'react';
import { match } from 'ts-pattern';
import { useAccount } from 'wagmi';
import { z } from 'zod';

import { useAuth, useWalletModal } from '@endaoment-frontend/authentication';
import { StepModal } from '@endaoment-frontend/ui/shared';

import { CreateFundFlow } from './create-fund-flow/CreateFundFlow';
import styles from './FundWizard.module.scss';
import { MigrateFundFlow } from './migrate-fund-flow/MigrateFundFlow';
import { useFundWizardState } from './useFundWizardState';

export const fundWizardModes = z.enum(['create', 'migrate']);

export const FundWizard = () => {
  const { isConnecting, isReconnecting } = useAccount();
  const { isSignedIn } = useAuth();
  const { showWallet } = useWalletModal();

  const { isFundWizardOpen, initialValues, __querySetters } = useFundWizardState();
  const { setInitials, setIsFundWizardOpen, setMode } = __querySetters;
  const onClose = () => {
    setInitials();
    setIsFundWizardOpen();
    setMode();
  };

  // Force open wallet modal if user attempts to open fund wizard while not signed in
  useEffect(() => {
    if (!isFundWizardOpen || isConnecting || isReconnecting || isSignedIn) return;

    onClose();
    showWallet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFundWizardOpen, isSignedIn, isConnecting]);

  const steps = match({ isFundWizardOpen, ...initialValues })
    .with({ isFundWizardOpen: false }, () => <></>)
    .with({ isFundWizardOpen: true, mode: undefined }, () => <></>)
    .with({ mode: 'create' }, () => <CreateFundFlow onClose={onClose} />)
    .with({ mode: 'migrate' }, ({ initialDestinationFundId }) => (
      <MigrateFundFlow initialDestinationFundId={initialDestinationFundId} onClose={onClose} />
    ))
    .with({ isFundWizardOpen: true }, () => <></>)
    .exhaustive();

  return (
    <StepModal isOpen={!!isFundWizardOpen && isSignedIn} onClose={onClose} className={styles['fund-wizard-modal']}>
      <StepModal.StepsWrapper>{steps}</StepModal.StepsWrapper>
    </StepModal>
  );
};
