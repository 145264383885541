import { Form, Formik } from 'formik';
import { z } from 'zod';

import type { FundListing } from '@endaoment-frontend/types';
import { BigNumberInput, ProceedButton, validateWithZod } from '@endaoment-frontend/ui/forms';
import { EntityCardWithLabel } from '@endaoment-frontend/ui/smart';
import { formatCurrency } from '@endaoment-frontend/utils';

import styles from './MigrateFund.module.scss';

const DAF_MIGRATION_MINIMUM_AMOUNT_DOLLARS = 25_00n as const;

export const MigrateFundAmount = ({
  initialValue = 0,
  fund,
  onSubmit,
  onRemove,
}: {
  initialValue?: number;
  fund: FundListing;
  onSubmit: (amount: number) => void;
  onRemove: () => void;
}) => {
  const amountInputFormSchema = z.object({
    amount: z.bigint().gte(DAF_MIGRATION_MINIMUM_AMOUNT_DOLLARS, {
      message: `Amount must be equal or greater than ${formatCurrency(
        Number(DAF_MIGRATION_MINIMUM_AMOUNT_DOLLARS) / 1e2,
      )}`,
    }),
  });

  return (
    <>
      <EntityCardWithLabel label='Migrating to' entity={{ type: 'fund', id: fund.id }} onRemove={onRemove} />

      <h4 className={styles['step-header']}>How much will you be migrating?</h4>

      <Formik
        initialValues={{ amount: BigInt(initialValue * 1e2) }}
        onSubmit={v => onSubmit(Number(v.amount) / 1e2)}
        validate={validateWithZod(amountInputFormSchema)}>
        {({ errors, values, setFieldValue, setFieldTouched }) => (
          <Form className={styles['amount-form']}>
            <BigNumberInput
              name='amount'
              units={2}
              value={values.amount}
              onChange={v => setFieldValue('amount', v, true)}
              onBlur={() => setFieldTouched('amount', true)}
              error={errors.amount}
              placeholder='0.00'
              leftElements={<h6 className={styles['dollar']}>$</h6>}
            />

            <ProceedButton type='submit' />
          </Form>
        )}
      </Formik>
    </>
  );
};
