import { Box } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useChainId } from 'wagmi';
import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { useAuth, useAuthType } from '@endaoment-frontend/authentication';
import { config } from '@endaoment-frontend/config';
import { FUND_AGREEMENT_URL, TERMS_AND_CONDITIONS_URL } from '@endaoment-frontend/constants';
import { FormNetworkSelector } from '@endaoment-frontend/multichain';
import type { CreateFundDetailsInput } from '@endaoment-frontend/types';
import { createFundDetailsInputSchema } from '@endaoment-frontend/types';
import {
  FormInput,
  FormTextArea,
  ProceedButton,
  validateWithZod,
  ReferralFormInput,
} from '@endaoment-frontend/ui/forms';
import { WhoAmI } from '@endaoment-frontend/api';

import styles from './CreateFund.module.scss';

export const CreateFundDetails = ({
  initialValues,
  onSubmit,
}: {
  initialValues?: CreateFundDetailsInput;
  onSubmit: (details: CreateFundDetailsInput) => void;
}) => {
  const { isSocialAuth, isWalletAuth } = useAuthType();
  const { isConnected } = useAuth();
  const currentChainId = useChainId();
  const chainId = isSocialAuth ? config.socialLoginChainId : currentChainId;
  const authInfo = WhoAmI.useQuery([], { enabled: isConnected });

  return (
    <div>
      <h4 className={styles['step-header']}>What's the name of this new fund?</h4>
      <Formik
        validate={validateWithZod(createFundDetailsInputSchema)}
        validateOnBlur
        initialValues={initialValues ?? { name: '', description: '', chainId, referralSource: undefined }}
        onSubmit={onSubmit}>
        {({ values }) => (
          <Form className={styles['create-fund']}>
            <FormInput name='name' label='Fund Name' placeholder="New Fund's Name" />
            <FormTextArea
              name='description'
              rows={5}
              label='Fund Description'
              placeholder={`Describe a goal, purpose or hope for this new fund's impact on the world`}
            />

            {!authInfo?.data?.hasInformedReferralSource && <ReferralFormInput referralFieldName='referralSource' />}

            {!!isWalletAuth && (
              <div className={styles['chain-selector']}>
                <span>Select a network to use for this fund:</span>
                <FormNetworkSelector name='chainId' />
              </div>
            )}

            <ProceedButton type='submit' />
            <Box className={styles['terms']}>
              By continuing you agree to our{' '}
              <a href={TERMS_AND_CONDITIONS_URL} target='_blank' rel='noopener noreferrer'>
                terms and conditions
              </a>{' '}
              and{' '}
              <a href={FUND_AGREEMENT_URL} target='_blank' rel='noopener noreferrer'>
                donor-advised fund agreement
              </a>
              .
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateFundDetails;
