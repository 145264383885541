import type { As } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import clsx from 'clsx';
import { forwardRef } from 'react';

import type { PolymorphicRef } from '@endaoment-frontend/types';

import styles from './Card.module.scss';
import type { CardProps } from './Card.types';

declare module 'react' {
  /** Fixed Ref typings (They suck) */
  function forwardRef<T, P = Record<string, never>>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null,
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

const CardWithRef = <Tag extends As>(
  { children, noPadding, noShadow, className, onSelect, rainbow, mobileFullWidth, role, as, ...props }: CardProps<Tag>,
  ref: PolymorphicRef<Tag>,
) => {
  return (
    <Box
      as={as}
      ref={ref}
      className={clsx(
        styles['card'],
        className,
        onSelect && styles['card--clickable'],
        rainbow && styles['card--rainbow'],
        noPadding && styles['card--no-padding'],
        noShadow && styles['card--no-shadow'],
        mobileFullWidth && styles['card--mobile-fw'],
      )}
      onClick={onSelect}
      role={onSelect ? 'button' : role}
      tabIndex={onSelect ? 0 : undefined}
      onKeyDown={e => e.key === 'Enter' && onSelect?.()}
      {...props}>
      {rainbow ? <div className={styles['content']}>{children}</div> : children}
    </Box>
  );
};

export const Card = forwardRef(CardWithRef);
