import type { RefObject } from 'react';

import type { InputWrapperProps } from './InputWrapper';
import { InputWrapper } from './InputWrapper';

type InputProps = JSX.IntrinsicElements['input'] &
  Omit<InputWrapperProps, 'children'> & {
    innerRef?: RefObject<HTMLInputElement>;
    inputClassName?: string;
  };

export const Input = ({
  name,
  label,
  leftElements,
  rightElements,
  error,
  className,
  inputClassName,
  innerRef,
  plain,
  ...restProps
}: InputProps) => {
  return (
    <InputWrapper
      className={className}
      name={name}
      label={label}
      error={error}
      leftElements={leftElements}
      rightElements={rightElements}
      plain={plain}>
      <input
        name={name}
        id={name}
        className={inputClassName}
        ref={innerRef}
        aria-invalid={error ? 'true' : 'false'}
        {...restProps}
      />
    </InputWrapper>
  );
};
