import type { QueryClientConfig } from '@tanstack/react-query';
import { dehydrate, QueryClient } from '@tanstack/react-query';

import { TIME_ONE_DAY_IN_SECONDS, TIME_ONE_MINUTE_IN_SECONDS } from '@endaoment-frontend/constants';

import { isFetchError } from './index';

// Note this parsing format MUST match what was defined for the `toJSON` - see polyfill in _app.tsx
const jsonParseWithBigInt = (value: string): unknown =>
  JSON.parse(value, (_key, value) =>
    value?._datatype === 'bigint' && typeof value?.value === 'string' ? BigInt(value.value) : value,
  );

// Creates new instance of QueryClient - useful to repeat to avoid caching between SSG/server requests
export const newQueryClient = (additionalConfig: QueryClientConfig = {}) =>
  new QueryClient({
    ...additionalConfig,
    defaultOptions: {
      ...additionalConfig.defaultOptions,
      queries: {
        // Store in offline cache for 3 days
        cacheTime: 3 * TIME_ONE_DAY_IN_SECONDS * 1000,
        // Make available for refetching after 5 minutes
        staleTime: 5 * TIME_ONE_MINUTE_IN_SECONDS * 1000,
        retry: (n, e) => {
          if (
            isFetchError(e) &&
            e.statusCode &&
            // We do not want to retry on 4xx errors
            e.statusCode >= 400 &&
            e.statusCode < 500 &&
            // The exception is 408, which is a timeout
            e.statusCode !== 408
          )
            return false;
          return n < 3;
        },
        retryDelay: n => 1000 * Math.pow(10, n),
        refetchOnWindowFocus: false,
        ...additionalConfig.defaultOptions?.queries,
      },
      mutations: {
        retry: (n, e) => {
          if (
            isFetchError(e) &&
            e.statusCode &&
            // We do not want to retry on 4xx errors
            e.statusCode >= 400 &&
            e.statusCode < 500 &&
            // The exception is 408, which is a timeout
            e.statusCode !== 408
          )
            return false;
          return n < 3;
        },
        retryDelay: n => 1000 * Math.pow(5, n),
        ...additionalConfig.defaultOptions?.mutations,
      },
    },
  });

// QueryClient instance for use only on the server-side
export const queryClientForSSR = new Proxy(
  (function () {
    const queryClient = newQueryClient({
      defaultOptions: {
        queries: {
          retry: false,
        },
      },
    });
    // TODO: implement persister
    // const persister: Persister = {
    //   persistClient: async (client: PersistedClient) => {
    //   },
    //   restoreClient: async () => {
    //   },
    //   removeClient: async () => {
    //   },
    // };
    // persistQueryClient({
    //   queryClient: queryClient,
    //   persister,
    // });
    return queryClient;
  })(),
  {
    get: (target, prop) => {
      if (process.env.NODE_ENV === 'test') return Reflect.get(target, prop);

      if (typeof window !== 'undefined') throw new Error('Cannot access server-side query client on client-side');

      return Reflect.get(target, prop);
    },
  },
);

export const defaultQueryClient = newQueryClient();

// Execute a series of queries, used in getStaticProps to make a dehydrated QueryClient
export const makeDehydratedQueriesString = async (...queries: Array<Parameters<QueryClient['prefetchQuery']>>) => {
  const queryClient = newQueryClient();
  await Promise.all(
    queries.map(queryArgs => {
      try {
        return queryClient.prefetchQuery(...queryArgs);
      } catch (e) {
        throw new Error(`Error prefetching query: ${queryArgs[0]}`);
      }
    }),
  );
  // We can stringify directly since we have overridden the toJSON function for BigInts
  return JSON.stringify(dehydrate(queryClient));
};

/**
 * Convert a dehydrated string to a state object
 * This is neccesary in order to support BigInts
 */
export const convertDehydratedStringToState = (dehydratedString?: string | null) => {
  if (!dehydratedString) return undefined;
  return jsonParseWithBigInt(dehydratedString);
};
