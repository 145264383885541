import clsx from 'clsx';

import { ErrorIcon } from '@endaoment-frontend/ui/icons';

import { Loader } from '../loader/Loader';
import { Pill } from '../pill/Pill';

import styles from './StatusPill.module.scss';
import type { StatusPillProps } from './StatusPill.types';

export const StatusPill = ({ status = 'failed' }: StatusPillProps) => {
  return (
    <Pill className={clsx(styles['status__pill'], styles[`status__pill--${status}`])}>
      {status === 'failed' && (
        <>
          <span role='img' aria-label='Failed transaction'>
            <ErrorIcon />
          </span>
          Failed
        </>
      )}
      {status === 'processing' && (
        <>
          <Loader />
          Processing
        </>
      )}
      {status === 'pending' && <>Pending</>}
    </Pill>
  );
};
