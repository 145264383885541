import { z } from 'zod';

import { RequestHandler } from '@endaoment-frontend/data-fetching';
import type {
  Activity,
  AdminFund,
  CreateFundInput,
  EntityPositionSummary,
  FundAdvisor,
  FundDetails,
  FundDistributionRecipient,
  FundDistributionRoundSummary,
  FundListing,
  FundSettingsInput,
  Letter,
  UUID,
} from '@endaoment-frontend/types';
import {
  activitySchema,
  adminFundSchema,
  arraySchemaInvalidsFiltered,
  entityPositionSummarySchema,
  fundAdvisorSchema,
  fundDetailsSchema,
  fundDistributionRecipientSchema,
  fundDistributionRoundSchema,
  fundDistributionRoundSummarySchema,
  fundListingSchema,
  isUuid,
  uuidSchema,
} from '@endaoment-frontend/types';

type FundIdentifier = Pick<FundListing, 'id' | 'vanityUrl'>;

/**
 * Fetch an array of fund identifiers for pregenerating pages
 */
export const PrerenderFunds = new RequestHandler(
  'PrerenderFunds',
  fetch => async (): Promise<Array<FundIdentifier>> => {
    try {
      const res = await fetch(`/v1/funds/pregen/62f09133-9719-4cef-b79d-7b0528ded191`);
      return z.array(z.object({ id: uuidSchema, vanityUrl: z.string().nullish() })).parse(res);
    } catch {
      // Prerendering is not critical, so we can just return an empty array if it fails
      return [];
    }
  },
);

/**
 * Fetch the total number of community funds
 */
export const GetCommunityFundsCount = new RequestHandler(
  'GetCommunityFundsCount',
  fetch => async (): Promise<number> => {
    const res = await fetch(`/v1/funds/community/count`);
    return z.number({ coerce: true }).parse(res);
  },
);

/**
 * Fetch the total number of funds
 */
export const GetFundsCount = new RequestHandler('GetFundsCount', fetch => async (): Promise<number> => {
  const res = await fetch(`/v1/funds/count`);
  return z.number({ coerce: true }).parse(res);
});

/**
 * Fetch an array of our most featured funds
 */
export const GetFeaturedFunds = new RequestHandler(
  'GetFeaturedFunds',
  fetch =>
    async (offset: number = 0): Promise<Array<FundListing>> => {
      // TODO: Update once backend accepts offset
      const count = 10;
      const res = await fetch('/v1/funds/featured');
      return z
        .array(fundListingSchema)
        .parse(res)
        .slice(0 * count, (offset + 1) * count);
    },
  {
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/funds/featured` }),
  },
);

/**
 * Fetch an array of our community featured funds
 */
export const GetCommunityFunds = new RequestHandler(
  'GetCommunityFunds',
  fetch =>
    async (count: number = 6, offset?: number): Promise<Array<FundListing>> => {
      const res = await fetch('/v1/funds/community', {
        params: {
          count,
          offset,
        },
      });
      return arraySchemaInvalidsFiltered(fundListingSchema).parse(res);
    },
);

/**
 * Mutation that needs to be called in order to create a new fund on the backend
 */
export const CreateFund = new RequestHandler(
  'CreateFund',
  fetch => async (input: CreateFundInput) => {
    const res = await fetch('/v1/funds', {
      method: 'POST',
      body: input,
    });
    return fundListingSchema.parse(res);
  },
  { isUserSpecificRequest: true, makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/funds` }) },
);

/**
 * Mutation that needs to be called in order to edit a fund on the backend
 */
export const EditFund = new RequestHandler(
  'EditFund',
  fetch => async (id: UUID, settings: FundSettingsInput) => {
    const res = await fetch(`/v1/funds/${id}`, {
      method: 'PUT',
      body: settings,
    });
    return fundListingSchema.parse(res);
  },
  { isUserSpecificRequest: true },
);

/**
 * Fetch a Fund using its id (primary key) or a vanityUrl
 */
export const GetFund = new RequestHandler(
  'GetFund',
  fetch =>
    async (idOrVanity: UUID | string): Promise<FundDetails> => {
      let req: Promise<unknown>;
      if (isUuid(idOrVanity)) {
        req = fetch(`/v1/funds/${idOrVanity}`);
      } else {
        req = fetch(`/v1/funds/vanity/${idOrVanity}`);
      }
      return fundDetailsSchema.parse(await req);
    },
  {
    isUserSpecificRequest: true,
    makeMockEndpoints: ({ baseURL }) => ({
      default: `${baseURL}/v1/funds/:id`,
      vanity: `${baseURL}/v1/funds/vanity/:vanity`,
    }),
  },
);

/**
 * Ping for a fund's existence
 */
export const PingFund = new RequestHandler('PingFund', fetch => async (id: UUID): Promise<boolean> => {
  try {
    const res = await fetch(`/v1/funds/ping/${id}/1849fe0c-3832-4b8f-a387-553738568cd6`);
    return z.boolean({ coerce: true }).parse(res);
  } catch (e) {
    return false;
  }
});

/**
 * Get All funds where the current user is the manager
 */
export const GetUserFunds = new RequestHandler(
  'GetUserFunds',
  fetch => async (): Promise<Array<FundListing>> => {
    const res = await fetch(`/v1/funds/mine`);
    return arraySchemaInvalidsFiltered(fundListingSchema).parse(res);
  },
  {
    isUserSpecificRequest: true,
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/funds/mine` }),
  },
);

/**
 * Get all funds where the current user is an advisor
 */
export const GetUserAdvisedFunds = new RequestHandler(
  'GetUserAdvisedFunds',
  fetch => async (): Promise<Array<FundListing>> => {
    const res = await fetch(`/v1/funds/advising`);
    return arraySchemaInvalidsFiltered(fundListingSchema).parse(res);
  },
  {
    isUserSpecificRequest: true,
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/funds/advising` }),
  },
);

/**
 * Fetch recent activity associated with a Fund
 */
export const GetFundActivity = new RequestHandler(
  'GetFundActivity',
  fetch =>
    async (id: UUID): Promise<Array<Activity>> => {
      const res = await fetch(`/v1/activity/fund/${id}`);
      return arraySchemaInvalidsFiltered(activitySchema).parse(res);
    },
);

/**
 * Fetch all positions associated with a Fund
 */
export const GetFundPositions = new RequestHandler(
  'GetFundPositions',
  fetch =>
    async (id: UUID): Promise<EntityPositionSummary> => {
      const res = await fetch(`/v1/portfolios/summary/fund/${id}`);
      return entityPositionSummarySchema.parse(res);
    },
  {
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/portfolios/summary/fund/:id` }),
  },
);

/**
 * Fetch all funds for the admin dashboard
 */
export const GetAdminFunds = new RequestHandler(
  'GetAdminFunds',
  fetch =>
    async (count?: number, offset?: number, name?: string): Promise<Array<AdminFund>> => {
      const res = await fetch('/v1/funds/all', {
        params: {
          count,
          offset,
          name,
        },
      });
      return z.array(adminFundSchema).parse(res);
    },
);

const allImpactFundDistributionRoundsSchema = z.object({
  current: uuidSchema,
  rounds: arraySchemaInvalidsFiltered(fundDistributionRoundSchema),
});
type AllImpactFundDistributionRounds = z.infer<typeof allImpactFundDistributionRoundsSchema>;
export const GetAllImpactFundDistributionRounds = new RequestHandler(
  'GetAllImpactFundDistributionRounds',
  fetch =>
    async (id: UUID): Promise<AllImpactFundDistributionRounds> => {
      const res = await fetch(`/v1/funds/${id}/distribution-rounds`);
      return allImpactFundDistributionRoundsSchema.parse(res);
    },
  {
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/funds/:id/distribution-rounds` }),
  },
);

export type RoundIdInput = UUID | 'current';

export const GetImpactFundDistributionRoundSummary = new RequestHandler(
  'GetImpactFundDistributionRoundSummary',
  fetch =>
    async (id: UUID, roundId: RoundIdInput): Promise<FundDistributionRoundSummary> => {
      const res = await fetch(`/v1/funds/${id}/distribution-rounds/${roundId}/breakdown`, {
        params: {
          categoryCount: 5,
        },
      });
      return fundDistributionRoundSummarySchema.parse(res);
    },
  {
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/funds/:id/distribution-rounds/:roundId/breakdown` }),
  },
);

export const SearchImpactFundDistributionRecipients = new RequestHandler(
  'SearchImpactFundDistributionRecipients',
  fetch =>
    async (
      id: UUID,
      roundId: RoundIdInput,
      params?: {
        searchTerm?: string;
        nteeMajorCode?: Letter;
        count?: number;
        offset?: number;
      },
    ): Promise<Array<FundDistributionRecipient>> => {
      const res = await fetch(`/v1/funds/${id}/distribution-rounds/${roundId}/recipients`, {
        params,
      });
      return z.array(fundDistributionRecipientSchema).parse(res);
    },
  {
    makeMockEndpoints: ({ baseURL }) => ({
      default: `${baseURL}/v1/funds/:id/distribution-rounds/:roundId/recipients`,
    }),
  },
);

export const GetFundAdvisors = new RequestHandler(
  'GetFundAdvisors',
  fetch =>
    async (id: UUID): Promise<Array<FundAdvisor>> => {
      const res = await fetch(`/v1/funds/${id}/advisors`);
      return z.array(fundAdvisorSchema).parse(res);
    },
);
