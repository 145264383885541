import { Link } from '@chakra-ui/next-js';
import { Flex } from '@chakra-ui/react';

import { routes } from '@endaoment-frontend/routes';
import type { FundListing } from '@endaoment-frontend/types';
import { AllocationIcon, CheckmarkIcon, WalletIcon } from '@endaoment-frontend/ui/icons';
import { ActionButton, Pill } from '@endaoment-frontend/ui/shared';
import { EntityCardWithLabel } from '@endaoment-frontend/ui/smart';

import styles from './CreateFund.module.scss';

export const CreateFundComplete = ({ fund }: { fund?: FundListing }) => {
  return (
    <>
      <EntityCardWithLabel label='New Fund Created' entity={fund ? { type: 'fund', id: fund.id } : undefined} link />
      <Flex justifyContent='stretch' gap='1rem' flexDir='column'>
        {fund ? (
          <>
            <div className={styles['success-pill__container']}>
              <Pill color='green' size='tiny' className={styles['success-pill']}>
                <CheckmarkIcon color='currentColor' /> Fund Created
              </Pill>
            </div>
            <ActionButton
              color='fund'
              as={Link}
              href={routes.app.fund({
                id: fund.id,
                wizardParams: {
                  isFundWizardOpen: false,
                  isTargetAllocationModalOpen: true,
                  targetAllocationFundId: fund.id,
                },
              })}
              text='Set Target Allocation'
              subtext='Set a preferred portfolio mix for this fund'>
              <AllocationIcon color='currentColor' />
            </ActionButton>
            <ActionButton
              color='purple'
              as={Link}
              href={routes.app.fund({
                id: fund.id,
                wizardParams: {
                  isDonationWizardOpen: true,
                  isFundWizardOpen: false,
                  dwRecipient: {
                    type: 'fund',
                    id: fund.id,
                  },
                },
              })}
              text='Add Assets'
              subtext='Donate crypto, stock or cash to this fund'>
              <WalletIcon color='currentColor' />
            </ActionButton>
          </>
        ) : (
          <>Please wait. Awaiting onchain confirmation of new fund...</>
        )}
      </Flex>
    </>
  );
};
