import { useEffect, useReducer } from 'react';
import { useChainId, useEnsAvatar, useEnsName } from 'wagmi';

import { defaults } from '@endaoment-frontend/config';
import { TIME_ONE_HOUR_IN_SECONDS } from '@endaoment-frontend/constants';

const computeEnsChainIdToUse = (chainId: number): number => {
  // Force use Goerli ENS records for local development
  if (chainId === 31337 || chainId === 31338) return 5;
  return chainId;
};

export const useEnsAvatarWithFallback = (args: Parameters<typeof useEnsName>[0]): ReturnType<typeof useEnsAvatar> => {
  const currentChainId = useChainId();
  const [usedChainId, setUsedChainId] = useReducer(
    (_prev: number, next: number) => computeEnsChainIdToUse(next ?? currentChainId),
    computeEnsChainIdToUse(args?.chainId ?? currentChainId),
  );
  const ensNameResponse = useEnsName({
    staleTime: TIME_ONE_HOUR_IN_SECONDS * 1000,
    ...args,
    chainId: usedChainId,
  });
  const ensAvatarResponse = useEnsAvatar({
    staleTime: TIME_ONE_HOUR_IN_SECONDS * 1000,
    ...args,
    chainId: usedChainId,
    enabled: !!ensNameResponse.data,
  });

  const hasError = ensNameResponse.error || ensAvatarResponse.error;
  useEffect(() => {
    if (!hasError) return;

    setUsedChainId(defaults.network.defaultChainId);
  }, [hasError]);

  return ensAvatarResponse;
};
