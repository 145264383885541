import { Modal as ChakraModal, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import clsx from 'clsx';

import { CloseIcon, BackIcon, LogoIcon } from '@endaoment-frontend/ui/icons';

import { Button } from '../button';

import styles from './Modal.module.scss';
import type { ModalProps } from './Modal.types';

export const Modal = ({
  title,
  branded = false,
  showCloseButton = true,
  showBackButton = false,
  children,
  className,
  overlayClassName,
  stickyHeader,
  small,
  onClose,
  onBack,
  ...restProps
}: ModalProps) => {
  const showHeader = title || showCloseButton || showBackButton || branded;
  return (
    <ChakraModal onClose={onClose} size='xl' isCentered {...restProps}>
      <ModalOverlay className={overlayClassName} />
      <ModalContent
        className={clsx(
          styles.modal,
          small && styles['modal--small'],
          stickyHeader && styles['modal--sticky-header'],
          className,
        )}>
        {!!showHeader && (
          <div className={clsx(styles.header, stickyHeader && styles['header--sticky'])}>
            <Button
              className={clsx(styles.button, styles['button--back'], showBackButton && styles['button--show'])}
              onClick={onBack ?? onClose}
              filled
              variation='faded'
              minimal
              float={false}
              title='Go Back Modal'>
              <BackIcon width={8} />
            </Button>

            {!!branded && <LogoIcon className={styles['branded-logo']} />}
            {!branded && !!title && <h2 className={styles.title}>{title}</h2>}

            <Button
              className={clsx(styles.button, styles['button--close'], showCloseButton && styles['button--show'])}
              onClick={onClose}
              filled
              variation='faded'
              minimal
              float={false}
              title='Close'>
              <CloseIcon />
            </Button>
          </div>
        )}

        {!!branded && !!title && <h1 className={styles.title}>{title}</h1>}
        <div className={styles.content}>{children}</div>
      </ModalContent>
    </ChakraModal>
  );
};
