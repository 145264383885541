// Tokens and Values
export const STABLECOIN_DECIMALS = 6 as const;
export const PRICE_IMPACT_WARNING_THRESHOLD = 0.2 as const;

export const LETTERS = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
] as const;

// Transactions
export const QUEUE_LOCALSTORAGE_KEY = 'transaction-list' as const;

// Endaoment Docs
export const TERMS_AND_CONDITIONS_URL = 'https://docs.endaoment.org/governance/terms-and-conditions' as const;
export const PRIVACY_POLICY_URL = 'https://docs.endaoment.org/governance/personal-data-usage-and-storage' as const;
export const NO_DONOR_BENEFIT_URL =
  'https://docs.endaoment.org/governance/fund-agreement#no-donor-benefit-clause' as const;
export const ORGANIZATION_FUNDING_POLICY_URL =
  'https://docs.endaoment.org/governance/mission-values#organization-funding-policy' as const;
export const FUND_AGREEMENT_URL = 'https://docs.endaoment.org/governance/fund-agreement' as const;

export const ORGANIZATION_ADDITION_FORM_URL = 'https://endaoment.typeform.com/new-org' as const;

// Misc.
export const PLACID_FUND_GENERATOR_URL = 'https://api.placid.app/u/mau6sylhu' as const;
export const PLACID_ORG_GENERATOR_URL = 'https://api.placid.app/u/v10zuua1r' as const;

export const INTERCOM_APP_ID = 'd1d9jukk' as const;

// ADMIN TAB EMAILS
// FIXME: this is not used anywhere
// export type AdminTab = 'funds' | 'grants' | 'donations' | 'claims' | 'payouts';

// export const ADMIN_TABS_EMAILS: Readonly<Record<AdminTab, EmailRequestEntry>> = {
//   grants: {
//     emailRequest: 'newGrantAlerts',
//     emailTemplates: [
//       'jcf_grant_receipt',
//       'grant_receipt',
//       'grant_receipt_SI',
//       'grant_receipt_C',
//       'grant_receipt_UC',
//       'grant_receipt_SI_C',
//       'grant_receipt_SI_UC',
//     ],
//   },
//   funds: { emailRequest: 'newFundAlerts', emailTemplates: ['jcf_fund_receipt'] },
//   donations: { emailRequest: 'newDonationAlerts', emailTemplates: ['jcf_gift_receipt', 'direct_donation'] },
//   payouts: {
//     emailRequest: 'adminWithdrawalEmails',
//     emailTemplates: ['jcf_withdrawal_receipt', 'wire_withdrawal_receipt'],
//   },
//   claims: {
//     emailRequest: 'adminClaimEmails',
//     emailTemplates: ['org_deployed_alert', 'jcf_claim_approval', 'jcf_claim_receipt'],
//   },
// };

// export const ADMIN_IGNORE_EMAIL_FIELDS: Readonly<string[]> = ['overrides'];

export const COPY_SHARE_TEXT = 'Copy URL to clipboard' as const;

export const ORG_PAGE_SUBPROJECT_MINI_THRESHOLD = 4 as const;

export const TIME_ONE_MINUTE_IN_SECONDS = 60 as const;
export const TIME_ONE_HOUR_IN_SECONDS = 3600 as const;
export const TIME_ONE_DAY_IN_SECONDS = 86_400 as const;
export const DEBOUNCE_MS = 1250 as const;

export const PORTFOLIO_TYPE_LABELS = {
  AaveUsdc: 'Stablecoin Yield',
  CompoundUsdc: 'Stablecoin Yield',
  YearnUsdc: 'Stablecoin Yield',
  AaveUsdcV3: 'Stablecoin Yield',
  CompoundUsdcV3: 'Stablecoin Yield',
  SingleToken: 'Single Token',
  TPlusN: 'T + N',
} as const;

export const NTEE_CODE_TO_NAME = {
  A: 'Arts, Culture & Humanities',
  B: 'Education',
  C: 'Environment',
  D: 'Animal-Related',
  E: 'Health Care',
  F: 'Mental Health & Crisis Intervention',
  G: 'Voluntary Health Associations & Medical Disciplines',
  H: 'Medical Research	',
  I: 'Crime & Legal-Related',
  J: 'Employment',
  K: 'Food, Agriculture & Nutrition	',
  L: 'Housing & Shelter',
  M: 'Public Safety, Disaster Preparedness & Relief',
  N: 'Recreation & Sports',
  O: 'Youth Development',
  P: 'Human Services',
  Q: 'International, Foreign Affairs & National Security',
  R: 'Civil Rights, Social Action & Advocacy',
  S: 'Community Improvement & Capacity Building',
  T: 'Philanthropy, Voluntarism & Grantmaking Foundations',
  U: 'Science & Technology',
  V: 'Social Science',
  W: 'Public & Societal Benefit',
  X: 'Religion-Related',
  Y: 'Mutual & Membership Benefit',
  Z: 'Unknown / Others',
} as const;

export const NATIVE_TOKEN_PLACEHOLDER_ADDRESS = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee' as const;
export const WALLET_CONNECT_PROJECT_ID = 'ec99ea0b84e190d058c72067c9de3c11';

export const ENDAOMENT_EIN = '84-4661797';

export const DEFAULT_META_TITLE = 'Endaoment';
export const DEFAULT_META_DESCRIPTION =
  "We're a tax-exempt Community Foundation built for decentralized finance and focused on social impact. Open a fund and make giving your new favorite habit.";
export const DEFAULT_META_IMAGE = 'https://storage.googleapis.com/endaoment-static/endaoment-meta-preview.png';

export const AUM_RATE = 316887386n as const;
