import { z } from 'zod';

import { environmentSchema } from './config.types';

const coercableToBooleanSchema = (isTrueByDefault: boolean) =>
  z.coerce
    .string()
    .transform((v): boolean => {
      if (isTrueByDefault) {
        return v !== 'false' && v !== '0';
      }
      return v === 'true' || v === '1';
    })
    .pipe(z.coerce.boolean())
    .catch(() => isTrueByDefault);

const env = environmentSchema.parse(process.env.NEXT_PUBLIC_ENV);
const appName = z.enum(['app', 'admin', 'developers', 'marketing']).optional().parse(process.env.NEXT_PUBLIC_APP_NAME);
const isMaintenanceMode = coercableToBooleanSchema(false).parse(process.env.NEXT_PUBLIC_MAINTENANCE_MODE);
const shouldUseCoingecko = coercableToBooleanSchema(true).parse(process.env.NEXT_PUBLIC_USE_COINGECKO);
const gaMeasurementId = z.string({ coerce: true }).optional().parse(process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID);
const disableNextErrorOverlay = coercableToBooleanSchema(false).parse(
  process.env.NEXT_PUBLIC_DISABLE_NEXT_ERROR_OVERLAY,
);
const disableStaticPathGeneration = coercableToBooleanSchema(false).parse(
  process.env.NEXT_PUBLIC_DISABLE_STATIC_PATH_GENERATION,
);
const enableFundCollaboration = coercableToBooleanSchema(false).parse(
  process.env.NEXT_PUBLIC_ENABLE_FUND_COLLABORATION,
);

/**
 * Parsed environment variables.
 */
export const featureFlags = {
  env,
  appName,
  isMaintenanceMode,
  shouldUseCoingecko,
  gaMeasurementId,
  disableNextErrorOverlay,
  disableStaticPathGeneration,
  enableFundCollaboration,
};
