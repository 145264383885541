import type { SetStateAction } from 'jotai';
import { atom } from 'jotai';

import { QUEUE_LOCALSTORAGE_KEY } from '@endaoment-frontend/constants';
import type { Address } from '@endaoment-frontend/types';
import { localStorageAtom } from '@endaoment-frontend/utils';

import {
  PendingListAddedEvent,
  PendingListRemovedEvent,
  TransactionListAddedEvent,
  TransactionListUpdatedEvent,
} from './transactionListEvents';
import type { StoredTransaction } from './useTransactionList';

// We use a map so that we have O(1) access time and maintain insertion order
export type StoredList = Map<Address, StoredTransaction>;

// We have to convert between arrays and maps since localStorage fails to store maps
const storageAtom = localStorageAtom<Array<[Address, StoredTransaction]>>(QUEUE_LOCALSTORAGE_KEY, []);
export const transactionListAtom = atom(
  (get): StoredList => new Map(get(storageAtom)),
  (get, set, update: SetStateAction<StoredList>) => {
    const computedValue = typeof update === 'function' ? update(new Map(get(storageAtom))) : update;
    const nextValue = Array.from(computedValue.entries());
    set(storageAtom, nextValue);
  },
);

const pendingTransactionsStorageAtom = localStorageAtom<Array<Address>>(`${QUEUE_LOCALSTORAGE_KEY}.pending`, []);
export const pendingTransactionsAtom = atom(get => {
  const list = get(transactionListAtom);
  return get(pendingTransactionsStorageAtom).map(hash => {
    const full = list.get(hash);
    if (!full) throw new Error(`Pending transaction ${hash} not found in transaction list`);
    return full;
  });
});

export const addToListAtom = atom(
  null,
  (_get, set, partialTransaction: Omit<StoredTransaction, 'createdAt' | 'status'>) => {
    const fullTransaction: StoredTransaction = { ...partialTransaction, status: 'pending', createdAt: Date.now() };

    // Dispatching add before actually adding to the list so that we can circumvent any storage issues
    TransactionListAddedEvent.dispatch({ transaction: fullTransaction });
    set(transactionListAtom, l => l.set(fullTransaction.hash, fullTransaction));
    // Initial add is also an update, listeners are responsible for checking the status
    TransactionListUpdatedEvent.dispatch({ transaction: fullTransaction });

    set(pendingTransactionsStorageAtom, l => [...l, fullTransaction.hash]);
    PendingListAddedEvent.dispatch({ hash: fullTransaction.hash, type: fullTransaction.type });
  },
);

export const changeStatusAtom = atom(
  null,
  (get, set, { hash, status }: { hash: Address; status: StoredTransaction['status'] }) => {
    const transaction = get(transactionListAtom).get(hash);

    if (!transaction)
      throw new Error(`Transaction ${hash} not found in transaction list when attempting to change status`);

    set(transactionListAtom, l => l.set(hash, { ...transaction, status }));
    TransactionListUpdatedEvent.dispatch({ transaction: { ...transaction, status } });
  },
);

export const replaceTransactionAtom = atom(
  null,
  (get, set, { oldHash, newHash }: { oldHash: Address; newHash: Address }) => {
    set(removeFromPendingAtom, oldHash);
    set(transactionListAtom, l => {
      const transaction = l.get(oldHash);

      if (!transaction)
        throw new Error(`Transaction ${oldHash} not found in transaction list when attempting to replace`);

      const clone = new Map(l);
      clone.delete(oldHash);
      clone.set(newHash, { ...transaction, hash: newHash });
      return clone;
    });
    set(pendingTransactionsStorageAtom, l => [...l, newHash]);
  },
);

export const removeFromPendingAtom = atom(null, (get, set, hash: Address) => {
  const pending = get(pendingTransactionsAtom);
  if (pending.length === 0) return;

  const transaction = pending.find(t => t.hash === hash);

  if (!transaction)
    throw new Error(`Transaction ${hash} not found in transaction list when attempting to remove from pending`);

  set(pendingTransactionsStorageAtom, l => l.filter(h => h !== hash));
  PendingListRemovedEvent.dispatch({ hash, type: transaction.type });
});

export const incrementFailureAtom = atom(null, (_get, set, hash: Address) => {
  set(transactionListAtom, l => {
    const transaction = l.get(hash);

    if (!transaction)
      throw new Error(`Transaction ${hash} not found in transaction list when attempting to increment failure`);

    return l.set(hash, { ...transaction, failCount: transaction.failCount + 1 });
  });
});
