import { trackEvent } from '@phntms/next-gtm';
import { useQueryStates } from 'nuqs';
import { match } from 'ts-pattern';

import { fundWizardParsers } from '@endaoment-frontend/routes';
import type { UUID } from '@endaoment-frontend/types';

type OpenWizardOptions =
  | {
      initialMode: 'migrate';
      initialDestinationFundId?: UUID;
    }
  | {
      initialMode?: 'create';
    };

export const useFundWizardState = () => {
  const [queryState, setQueryState] = useQueryStates(fundWizardParsers);

  const initialValues = match(queryState.fundWizardMode)
    .with('migrate', mode => ({
      mode,
      initialDestinationFundId: queryState.fundWizardInitial?.initialDestinationFundId ?? undefined,
    }))
    .otherwise(mode => ({
      mode: mode ?? undefined,
    }));

  const handleFundWizardOpenChange = (newVal?: NonNullable<typeof queryState.isFundWizardOpen>) => {
    setQueryState(prev => ({ ...prev, isFundWizardOpen: typeof newVal === 'boolean' ? newVal : null }));
  };
  const handleModeChange = (newVal?: NonNullable<typeof queryState.fundWizardMode>) => {
    setQueryState(prev => ({ ...prev, fundWizardMode: !newVal ? null : newVal }));
  };
  const handleInitialsChange = (newVal?: OpenWizardOptions) => {
    if (newVal?.initialMode && newVal.initialMode === 'migrate') {
      setQueryState(prev => ({ ...prev, fundWizardInitial: newVal }));
      return;
    }
    setQueryState(prev => ({ ...prev, fundWizardInitial: null }));
  };

  const openFundWizard = (options?: OpenWizardOptions) => {
    if (options) {
      handleModeChange(options.initialMode);
      handleInitialsChange(options);

      if (options.initialMode === 'migrate') {
        trackEvent({
          event: 'fw_start_migrate',
        });
      }

      if (options.initialMode === 'create') {
        trackEvent({
          event: 'fw_start_create_fund',
          data: {
            is_migration: false,
          },
        });
      }
    }
    handleFundWizardOpenChange(true);
    trackEvent({
      event: 'fw_open_wizard',
    });
  };

  return {
    isFundWizardOpen: queryState.isFundWizardOpen,
    initialValues,
    openFundWizard,
    /** This is for internal use, do not access outside the fund-wizard lib */
    __querySetters: {
      setIsFundWizardOpen: handleFundWizardOpenChange,
      setMode: handleModeChange,
      setInitials: handleInitialsChange,
    },
  } as const;
};

export const useOpenFundWizard = () => useFundWizardState().openFundWizard;
