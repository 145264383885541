import { setUser } from '@sentry/nextjs';
import { z } from 'zod';

import { RequestHandler } from '@endaoment-frontend/data-fetching';
import type { LoginDTO } from '@endaoment-frontend/types';
import { addressSchema, uuidSchema } from '@endaoment-frontend/types';

export const SignIn = new RequestHandler(
  'SignIn',
  fetch =>
    async (body: Pick<LoginDTO, 'email' | 'signature' | 'signatureDateUtc' | 'walletAddress'>): Promise<boolean> => {
      try {
        await fetch('/v1/auth/signin', {
          method: 'POST',
          body: body satisfies LoginDTO,
        });
        setUser({ username: body.email ?? body.walletAddress });
        return true;
      } catch (e) {
        console.warn(e);
        return false;
      }
    },
  {
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/auth/signin` }),
  },
);

export const SignOut = new RequestHandler(
  'SignOut',
  fetch => async (): Promise<boolean> => {
    try {
      await fetch('/v1/auth/signout', {
        method: 'POST',
      });
      setUser(null);
      return true;
    } catch (e) {
      console.warn(e);
      return false;
    }
  },
  {
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/auth/signout` }),
  },
);

const authInfoSchema = z.object({
  userId: uuidSchema,
  wallet: addressSchema,
  iat: z.number(),
  exp: z.number(),

  // TODO: Remove this optional flag after this is deployed to production for at least 3 days
  hasInformedReferralSource: z.boolean().optional(),
});
export type AuthInfo = z.infer<typeof authInfoSchema>;
export const WhoAmI = new RequestHandler(
  'WhoAmI',
  fetch => async (): Promise<AuthInfo> => {
    const res = await fetch(`/v1/auth/whoami`);
    return authInfoSchema.parse(res);
  },
  {
    isUserSpecificRequest: true,
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/auth/whoami` }),
  },
);
