import clsx from 'clsx';
import type { ReactNode } from 'react';

import { ErrorDisplay } from './ErrorDisplay';
import styles from './Input.module.scss';

export type InputWrapperProps = {
  children: ReactNode;
  name?: string;
  label?: ReactNode;
  leftElements?: ReactNode;
  rightElements?: ReactNode;
  error?: ReactNode;
  className?: string;
  errorClassName?: string;
  plain?: boolean;
};

export const InputWrapper = ({
  children,
  name,
  label,
  leftElements,
  rightElements,
  error,
  className,
  errorClassName,
  plain,
}: InputWrapperProps) => {
  return (
    <div className={clsx(styles.input, className, plain && styles['input--plain'])}>
      {!!label && (
        <div className={styles['label-container']}>
          <label htmlFor={name}>{label}</label>
        </div>
      )}
      <div className={styles['input-container']}>
        {!!leftElements && <span className={clsx(styles.elements, styles['elements--left'])}>{leftElements}</span>}
        {children}
        {!!rightElements && <span className={clsx(styles.elements, styles['elements--right'])}>{rightElements}</span>}
      </div>
      <ErrorDisplay error={error} className={clsx(styles.elements, errorClassName)} />
    </div>
  );
};
