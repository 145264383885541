import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { useAtom } from 'jotai';
import { useEffect, useState, type ReactNode } from 'react';
import { match } from 'ts-pattern';
import { useNetwork } from 'wagmi';

import { Modal } from '@endaoment-frontend/ui/shared';

import { useAuth } from '../useAuth';
import { walletModalOpenAtom } from '../useWalletModal';

import { ChooseSocialView, ChooseTypeView, ChooseWalletView } from './ChooseTypeView';
import { NetworkView } from './NetworkView';
import { ProfileView } from './ProfileView';
import styles from './WalletModal.module.scss';

type WalletModalChoices = 'choose' | 'network' | 'social' | 'wallet';

export const WalletModal = () => {
  const { isConnected } = useAuth();
  const { chain: currentChain, chains: supportedChains } = useNetwork();

  const [isOpen, setIsOpen] = useAtom(walletModalOpenAtom);
  const [choice, setChoice] = useState<WalletModalChoices>('choose');

  const isOnSupportedChain = !!currentChain && supportedChains.some(chain => chain.id === currentChain.id);

  useEffect(() => {
    if (!currentChain) return;
    if (isOnSupportedChain) {
      if (isOpen && choice === 'network') {
        setChoice('choose');
        setIsOpen(false);
      }
      return;
    }

    // Force open modal if user is on unsupported chain
    setChoice('network');
    setIsOpen(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnSupportedChain, isConnected]);

  const handleClose = () => {
    setChoice('choose');
    setIsOpen(false);
  };

  const {
    view,
    onBack,
    onClose,
    className,
  }: { view: ReactNode; onBack?: () => void; onClose?: () => void; className?: string } = match({
    isConnected,
    choice,
  })
    .with({ isConnected: true, choice: 'network' }, () => ({
      view: <NetworkView key='network-view' />,
      onBack: undefined,
      onClose: undefined,
      className: styles['network-view'],
    }))
    .with({ isConnected: true }, () => ({
      view: <ProfileView onClose={handleClose} key='profile-view' />,
      onBack: undefined,
      onClose: handleClose,
    }))
    .with({ isConnected: false, choice: 'choose' }, () => ({
      view: <ChooseTypeView onChoose={setChoice} onClose={handleClose} key='choose-type-view' />,
      onBack: undefined,
      onClose: handleClose,
    }))
    .with({ isConnected: false, choice: 'social' }, () => ({
      view: <ChooseSocialView key='choose-social-view' />,
      onBack: () => setChoice('choose'),
      onClose: handleClose,
    }))
    .otherwise(() => ({
      view: <ChooseWalletView key='choose-wallet-view' />,
      onBack: () => setChoice('choose'),
      onClose: handleClose,
    }));

  return (
    <Modal
      isOpen={isOpen}
      onClose={
        onClose
          ? onClose
          : () => {
              return;
            }
      }
      onBack={onBack}
      size='2xl'
      isCentered
      className={clsx(styles.modal, className)}
      showBackButton={!!onBack}
      showCloseButton={!!onClose}>
      <AnimatePresence mode='wait'>
        <WalletView key={choice}>{view}</WalletView>
      </AnimatePresence>
    </Modal>
  );
};

const WalletView = ({ children }: { children: ReactNode }) => {
  return (
    <motion.div
      data-testid='wallet-view'
      initial='hidden'
      animate='visible'
      exit='hidden'
      variants={{
        hidden: {
          opacity: 0,
        },
        visible: {
          opacity: 1,
        },
      }}
      transition={{ duration: 0.5 }}
      className={styles.view}>
      {children}
    </motion.div>
  );
};
