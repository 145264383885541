import clsx from 'clsx';
import { useEffect, useRef } from 'react';

import styles from './SegmentedControl.module.scss';
import type { SegmentedControlProps } from './SegmentedControl.types';
import { useSegmentedControlSelection } from './useSegmentedControlSelection';

export const SegmentedControl = ({ className, defaultIndex = 0, name, options, onSelect }: SegmentedControlProps) => {
  const { selectedIndex, optionRefs, onOptionChange } = useSegmentedControlSelection(options, defaultIndex, onSelect);

  const ready = useRef(false);

  useEffect(() => {
    ready.current = true;
  }, []);

  return (
    <div className={clsx(styles['segmented-control'], className)}>
      <div
        className={clsx(
          styles['segmented-control__options'],
          ready.current && styles['segmented-control__options--ready'],
        )}>
        {options.map((option, index) => (
          <div
            key={option.value}
            className={clsx(
              styles['segmented-control__option'],
              index === selectedIndex && styles['segmented-control__option--selected'],
            )}
            ref={optionRefs.current[index]}>
            <input
              type='radio'
              value={option.value}
              id={option.label}
              name={name}
              onChange={() => onOptionChange(option, index)}
              checked={index === selectedIndex}
            />
            <p>{option.label}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
