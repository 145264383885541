import { z } from 'zod';

import { RequestHandler } from '@endaoment-frontend/data-fetching';
import {
  type AdminGrant,
  type Grant,
  type Address,
  type UUID,
  bigIntSchema,
  grantSchema,
  adminGrantSchema,
  arraySchemaInvalidsFiltered,
} from '@endaoment-frontend/types';

export const GetTotalGrants = new RequestHandler('GetTotalGrants', fetch => async (): Promise<bigint> => {
  try {
    const res = await fetch('/v1/transfers/grants/total');
    return bigIntSchema.parse(res);
  } catch {
    return 0n;
  }
});

export const GetFundGrants = new RequestHandler(
  'GetFundGrants',
  fetch =>
    async (id: UUID): Promise<Array<Grant>> => {
      const res = await fetch(`/v1/transfers/grants/fund/${id}`);
      return arraySchemaInvalidsFiltered(grantSchema).parse(res);
    },
  {
    makeMockEndpoints: ({ baseURL }) => ({
      default: `${baseURL}/v1/transfers/grants/fund/:id`,
    }),
  },
);

type RegisterGrantArgs = {
  recommender: string;
  purpose: string;
  specialInstructions?: string;
  shareMyEmail: boolean;
  subprojectId?: UUID;
};
/**
 * Callback for when a grant has finished processing on the blockchain
 */
export const RegisterGrant = new RequestHandler(
  'RegisterGrant',
  fetch =>
    async (transactionHash: Address, chainId: number, grantInput: RegisterGrantArgs): Promise<void> => {
      await fetch('/v1/transfers', {
        method: 'POST',
        body: {
          transactionHash,
          chainId,
          grantInput: {
            ...grantInput,
            // Casing is inconsistent between the API and the frontend
            subprojectId: grantInput.subprojectId,
            specialInstructions: grantInput.specialInstructions || undefined,
          },
        },
      });
    },
  { isUserSpecificRequest: true },
);

export const GetAdminGrants = new RequestHandler(
  'GetAdminGrants',
  fetch =>
    async (
      count?: number,
      offset?: number,
      fundSearchTerm?: string,
      orgSearchTerm?: string,
    ): Promise<Array<AdminGrant>> => {
      const res = await fetch('/v1/transfers/all', {
        params: {
          count,
          offset,
          fundSearchTerm,
          orgSearchTerm,
        },
      });
      return z.array(adminGrantSchema).parse(res);
    },
);

export const ApproveGrant = new RequestHandler('ApproveGrant', fetch => async (id: UUID): Promise<Grant> => {
  const res = await fetch(`/v1/transfers/${id}/approve`, {
    method: 'PUT',
  });
  return grantSchema.parse(res);
});
