import type { As } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';

import styles from './Tooltip.module.scss';
import type { TooltipProps } from './Tooltip.types';

const MotionBox = motion(Box);

export const Tooltip = <Tag extends As>({
  content = '',
  direction = 'top',
  children,
  as,
  className,
  innerClassName,
  _devForceShow,
  ...props
}: TooltipProps<Tag>) => {
  const [visible, setVisible] = useState(false);

  return (
    <MotionBox
      as={as ?? 'span'}
      className={clsx(styles['tooltip__wrapper'], className)}
      onHoverStart={() => setVisible(true)}
      onHoverEnd={() => setVisible(false)}
      onTap={() => setVisible(v => !v)}
      {...props}>
      {children}
      <AnimatePresence>
        {!!(visible || _devForceShow) && !!content && (
          <motion.span
            initial='hidden'
            animate='visible'
            exit='hidden'
            variants={{
              hidden: { opacity: 0 },
              visible: { opacity: 1 },
            }}
            transition={{ duration: 0.5 }}
            className={clsx(styles['tooltip__content'], styles[`tooltip--${direction}`], innerClassName)}>
            {content}
          </motion.span>
        )}
      </AnimatePresence>
    </MotionBox>
  );
};
