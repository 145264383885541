import { Button } from '../button';
import { Loader } from '../loader';

import styles from './PlaceholderPrompt.module.scss';
import type { PlaceholderPromptProps } from './PlaceholderPrompt.types';

export const PlaceholderPrompt = ({
  enabledLabel,
  disabledLabel,
  loadingButtonLabel,
  actionButtonLabel,
  status,
  action,
}: PlaceholderPromptProps) => {
  return (
    <div className={styles['placeholder-prompt']}>
      <div className={styles['placeholder-prompt__content']}>
        <h2>
          {status !== 'disabled' && enabledLabel}
          {status === 'disabled' && disabledLabel}
        </h2>

        {status === 'loading' && (
          <Button variation='fund' filled disabled>
            <Loader /> {loadingButtonLabel}
          </Button>
        )}

        {status === 'enabled' && (
          <Button onClick={action} variation='fund' filled>
            {actionButtonLabel}
          </Button>
        )}
      </div>
    </div>
  );
};
