import clsx from 'clsx';

import { BigLoadingIcon, LoadingIcon } from '@endaoment-frontend/ui/icons';

import styles from './Loader.module.scss';
import type { LoaderProps } from './Loader.types';

export const Loader = ({ loading = true, size = 's', white = false, className }: LoaderProps) => {
  return (
    <div
      className={clsx(
        styles['loader'],
        className,
        white && styles['loader--white'],
        loading && styles['loader--loading'],
        size && styles[`loader--${size}`],
      )}
      role='status'>
      {size === 's' && <LoadingIcon />}
      {size === 'l' && <BigLoadingIcon width={50} height={50} strokeWidth={5} />}
    </div>
  );
};
