import { trackEvent } from '@phntms/next-gtm';
import type { SetValues, Values } from 'nuqs';
import { useQueryStates } from 'nuqs';
import { useMemo } from 'react';
import { z } from 'zod';

import { donationWizardParsers } from '@endaoment-frontend/routes';
import type { DonationRecipient, UUID } from '@endaoment-frontend/types';

export const donationWizardModes = z.enum([
  'bank-donation',
  'brokerage-donation',
  'credit-donation',
  'erc-donation',
  'grant',
  'nft-donation',
  'otc-donation',
]);

type OpenWizardOptions = {
  initialMode?: NonNullable<z.infer<typeof donationWizardModes>>;
  initialRecipient?: DonationRecipient;
  initialOriginId?: UUID;
};

type QueryStateValues = Values<typeof donationWizardParsers>;

export const useDonationWizardState = () => {
  const [queryState, setQueryState] = useQueryStates(donationWizardParsers);

  // Clean the query state to only acceptable values
  const state = {
    mode: queryState.dwMode ?? undefined,
    recipient: queryState.dwRecipient ?? undefined,
    amount: queryState.dwAmount,
    includeTaxReceipt: queryState.dwIncludeTaxReceipt,
    token: queryState.ercToken ?? undefined,
    tokenId: queryState.otcTokenId ?? undefined,
    originId: queryState.grantOriginId ?? undefined,
    ticker: queryState.brokerageTicker ?? undefined,
    shares: queryState.brokerageShares,
    pledgeAmount: queryState.creditPledgeAmount,
    // Rebalance is only applicable to fund donations, so we default to false for other types
    isRebalanceRequested: queryState.dwRecipient?.type === 'fund' ? queryState.isRebalanceRequested : false,
  };
  const setters = useMemo(() => makeDonationWizardSetters(setQueryState), [setQueryState]);

  const openDonationWizard = (options?: OpenWizardOptions) => {
    setQueryState({
      isDonationWizardOpen: true,
      dwMode: options?.initialMode ?? null,
      dwRecipient: options?.initialRecipient ?? null,
      grantOriginId: options?.initialOriginId ?? null,
    });

    trackEvent({
      event: 'dw_open_wizard',
    });
  };
  const resetDonationWizard = (resetToTypeSelect = false) => {
    setQueryState(p => ({
      isDonationWizardOpen: resetToTypeSelect ? true : null,
      dwMode: null,
      dwRecipient: resetToTypeSelect ? p.dwRecipient : null,
      grantOriginId: null,
      dwAmount: null,
      brokerageShares: null,
      brokerageTicker: null,
      dwIncludeTaxReceipt: null,
      creditPledgeAmount: null,
      ercToken: null,
      otcTokenId: null,
      isRebalanceRequested: null,
    }));
  };

  return {
    isDonationWizardOpen: queryState.isDonationWizardOpen,
    state,
    openDonationWizard,
    resetDonationWizard,
    /** This is for internal use, do not access outside the donation-wizard lib */
    setters,
  } as const;
};

const makeDonationWizardSetters = (setQueryState: SetValues<typeof donationWizardParsers>) => {
  const setMode = (newVal?: NonNullable<QueryStateValues['dwMode']>) => {
    setQueryState(prev => ({ ...prev, dwMode: !newVal ? null : newVal }));
  };
  const setRecipient = (newVal?: NonNullable<QueryStateValues['dwRecipient']>) => {
    setQueryState(prev => ({ ...prev, dwRecipient: !newVal ? null : newVal }));
  };
  const setAmount = (newVal?: NonNullable<QueryStateValues['dwAmount']>) => {
    setQueryState(prev => ({ ...prev, dwAmount: typeof newVal === 'undefined' ? null : newVal }));
  };
  const setIncludeTaxReceipt = (newVal?: NonNullable<QueryStateValues['dwIncludeTaxReceipt']>) => {
    setQueryState(prev => ({
      ...prev,
      dwIncludeTaxReceipt: typeof newVal === 'undefined' ? null : newVal,
    }));
  };
  const setErcToken = (newVal?: NonNullable<QueryStateValues['ercToken']>) => {
    setQueryState(prev => ({ ...prev, ercToken: !newVal ? null : newVal }));
  };
  const setOtcTokenId = (newVal?: NonNullable<QueryStateValues['otcTokenId']>) => {
    setQueryState(prev => ({ ...prev, otcTokenId: !newVal ? null : newVal }));
  };
  const setGrantOriginId = (newVal?: NonNullable<QueryStateValues['grantOriginId']>) => {
    setQueryState(prev => ({ ...prev, grantOriginId: !newVal ? null : newVal }));
  };
  const setCreditPledgeAmount = (newVal?: NonNullable<QueryStateValues['creditPledgeAmount']>) => {
    setQueryState(prev => ({ ...prev, creditPledgeAmount: typeof newVal === 'undefined' ? null : newVal }));
  };
  const setBrokerageTicker = (newVal?: NonNullable<QueryStateValues['brokerageTicker']>) => {
    setQueryState(prev => ({ ...prev, brokerageTicker: !newVal ? null : newVal }));
  };
  const setBrokerageShares = (newVal?: NonNullable<QueryStateValues['brokerageShares']>) => {
    setQueryState(prev => ({ ...prev, brokerageShares: typeof newVal === 'undefined' ? null : newVal }));
  };
  const setIsRebalanceRequested = (newVal?: NonNullable<QueryStateValues['isRebalanceRequested']>) => {
    setQueryState(prev => ({ ...prev, isRebalanceRequested: typeof newVal === 'undefined' ? null : newVal }));
  };
  return {
    setMode,
    setRecipient,
    setAmount,
    setCreditPledgeAmount,
    setIncludeTaxReceipt,
    setErcToken,
    setOtcTokenId,
    setGrantOriginId,
    setBrokerageTicker,
    setBrokerageShares,
    setIsRebalanceRequested,
  };
};

export const useOpenDonationWizard = () => useDonationWizardState().openDonationWizard;
