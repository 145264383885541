import { Flex } from '@chakra-ui/react';
import clsx from 'clsx';
import { useState } from 'react';
import { P, match } from 'ts-pattern';

import { GetUserFunds, GetPortfolio } from '@endaoment-frontend/api';
import { useOpenFundWizard } from '@endaoment-frontend/fund-wizard';
import type { UUID } from '@endaoment-frontend/types';
import { Input } from '@endaoment-frontend/ui/forms';
import { Button, Card, Loader } from '@endaoment-frontend/ui/shared';

import { FundDetailsCardWithBar } from '../common/FundDetailsCardWithBar';
import { RemoveablePortfolioDetails } from '../common/RemovableDetails';
import wizardStyles from '../PortfolioWizard.module.scss';

export const FundStep = ({
  portfolioId,
  onClose,
  onClearPortfolioSelection,
  onFundSelect,
}: {
  portfolioId?: UUID;
  onClose: () => void;
  onClearPortfolioSelection: () => void;
  onFundSelect: (fundId: UUID) => void;
}) => {
  const { data: portfolio } = GetPortfolio.useQuery([portfolioId as UUID], {
    enabled: !!portfolioId,
  });
  const portfolioDisplay = portfolioId ? (
    <>
      <Card noPadding noShadow>
        <RemoveablePortfolioDetails portfolioId={portfolioId} onRemove={onClearPortfolioSelection} />
      </Card>
      <hr />
    </>
  ) : (
    <></>
  );

  const openFundWizard = useOpenFundWizard();

  const { data: userFunds } = GetUserFunds.useQuery([]);
  const [search, setSearch] = useState('');
  const selectableFunds = userFunds?.filter(fund => {
    // Filter out funds that don't match the portfolio chain
    if (portfolioId && fund.chainId !== portfolio?.chainId) return false;

    // Filter out funds with no money in them
    return fund.lifetimeDonationsUsdc !== 0n;
  });
  const filteredFunds = selectableFunds?.filter(fund => {
    // Filter out funds that don't match the search
    return (
      search === '' ||
      fund.name.toLowerCase().includes(search.toLowerCase()) ||
      fund.description.toLowerCase().includes(search.toLowerCase())
    );
  });

  const handleFundSelect = (fundId: UUID) => () => {
    // Do not allow selecting while portfolio is loading
    if (portfolioId && !portfolio) throw new Error(`Portfolio with ID ${portfolioId} not found in cache`);

    const selectedFund = userFunds?.find(fund => fund.id === fundId);
    if (!selectedFund) throw new Error(`Fund with ID ${fundId} not found in user funds`);

    // Do not allow selecting funds that are on a different chain than the portfolio
    if (portfolio && selectedFund.chainId !== portfolio.chainId)
      throw new Error(`Fund with ID ${fundId} is on a different chain than the portfolio`);

    onFundSelect(fundId);
  };

  const fundsDisplay = match({
    isSearchable: !!selectableFunds && selectableFunds.length > 3,
    filteredFunds,
    selectableFunds,
    userFunds,
  })
    .with({ isSearchable: true, filteredFunds: P.not(P.nullish) }, ({ filteredFunds }) => (
      <>
        <h4>Which fund would you like to allocate from?</h4>
        <div className={wizardStyles['search-container__outer']}>
          <Input
            value={search}
            onChange={v => setSearch(v.currentTarget.value)}
            placeholder='Filter Funds'
            className={wizardStyles['search-container__filter']}
          />
          <div className={clsx(wizardStyles['search-container'], wizardStyles['search-container--with-filter'])}>
            {filteredFunds.map(({ id }) => (
              <FundDetailsCardWithBar key={id} fundId={id} onClick={handleFundSelect(id)} selectable />
            ))}
            {filteredFunds.length === 0 && <h4>No results</h4>}
          </div>
        </div>
      </>
    ))
    .with({ isSearchable: false, selectableFunds: [P.any, ...P.array()] }, ({ selectableFunds }) => (
      <>
        <h4>Which fund would you like to allocate from?</h4>
        <div className={wizardStyles['search-container__outer']}>
          <div className={clsx(wizardStyles['search-container'])}>
            {selectableFunds.map(({ id }) => (
              <FundDetailsCardWithBar key={id} fundId={id} onClick={handleFundSelect(id)} selectable />
            ))}
          </div>
        </div>
      </>
    ))
    .with({ selectableFunds: [], userFunds: P.not(P.nullish) }, ({ userFunds }) => (
      <div className={wizardStyles['search-container__outer']}>
        <div className={clsx(wizardStyles['search-container'])}>
          <div className={wizardStyles['warning']}>
            <h4>{userFunds.length > 0 ? 'You have no funds with a balance' : 'You have no funds yet!'}</h4>
            <p>In order to proceed please choose one of the following:</p>
            <Flex alignItems='center' justifyContent='center' gap={4} marginTop={4}>
              <Button
                size='small'
                filled
                variation='portfolio'
                onClick={() => {
                  onClose();
                  openFundWizard({
                    initialMode: 'create',
                  });
                }}>
                Create New Fund
              </Button>
              {!!portfolioId && (
                <Button size='small' filled variation='portfolio' onClick={onClearPortfolioSelection}>
                  Clear Portfolio Selection
                </Button>
              )}
            </Flex>
          </div>
        </div>
      </div>
    ))
    .otherwise(() => <Loader />);
  return (
    <>
      {portfolioDisplay}
      {fundsDisplay}
    </>
  );
};
