import memoize from 'micro-memoize';

import type { EntityPosition, EntityPositionSummary, FundListing } from '@endaoment-frontend/types';
import { formatNumber } from '@endaoment-frontend/utils';

import type { TargetAllocation } from './types';

export const computeRebalancableUsdc = (fund: Pick<FundListing, 'investedUsdc' | 'usdcBalance'>) => {
  return fund.usdcBalance + fund.investedUsdc;
};

export const getIsEntityPendingAdminRebalance = memoize(({ positions }: Pick<EntityPositionSummary, 'positions'>) => {
  const totalInTransit = positions.reduce((acc, position) => {
    return acc + position.inTransitBuyUsdcAmount + position.inTransitSellUsdcAmount;
  }, 0n);

  return totalInTransit > 0n;
});

/**
 * Compute the percentage of the fund rebalanceable balance that is the given amount
 * @returns a number between 0 and 1 representing the percentage
 */
export const computePercentageOfFundRebalancable = memoize(
  (amount: bigint = 0n, fund: Pick<FundListing, 'investedUsdc' | 'usdcBalance'>, decimals = 5): number => {
    const rebalanceable = computeRebalancableUsdc(fund);

    if (rebalanceable < amount) throw new Error('Amount is greater than rebalanceable balance');
    if (rebalanceable === 0n) return 1;
    return Number((Number(amount) / Number(rebalanceable)).toFixed(decimals));
  },
);

export const formatPercentageOfFundRebalancable = memoize(
  (amount: bigint = 0n, fund: Pick<FundListing, 'investedUsdc' | 'usdcBalance'>): string =>
    `${formatNumber(computePercentageOfFundRebalancable(amount, fund) * 100, { digits: 3, fractionDigits: 2 })}%`,
);

const ALLOWED_DEVIATION_PERCENT = 0.05;
export const computeDeviationFromTarget = memoize(
  (
    // Amount currently allocated to the portfolio
    amount: bigint = 0n,
    // Current state of the fund
    fund: Pick<FundListing, 'investedUsdc' | 'usdcBalance'>,
    // Target allocation for the portfolio
    allocation: Pick<TargetAllocation, 'percentage'>,
    decimals = 5,
  ) => {
    const currentPositionPercent = Number(computePercentageOfFundRebalancable(amount, fund, decimals));
    const deviationPercentFromTarget = Number((allocation.percentage - currentPositionPercent).toFixed(decimals));
    const deviationAmountFromTarget =
      (BigInt(Math.trunc(deviationPercentFromTarget * 100)) * computeRebalancableUsdc(fund)) / 100n;
    const isWithinTarget = Math.abs(deviationPercentFromTarget) <= ALLOWED_DEVIATION_PERCENT;

    return {
      currentPositionPercent,
      deviationPercentFromTarget,
      deviationAmountFromTarget,
      isWithinTarget,
    };
  },
);

export const sortAllocations = memoize(
  <TA extends Pick<TargetAllocation, 'portfolioId'>>(allocations: Array<TA>): Array<TA> => {
    return allocations.sort((a, b) => a.portfolioId.localeCompare(b.portfolioId));
  },
);

export const computeIsNearTargetAllocations = memoize(
  (
    fund: Pick<FundListing, 'investedUsdc' | 'usdcBalance'>,
    positions: Array<Pick<EntityPosition, 'currentMarketValue' | 'portfolio'>>,
    allocations: Array<TargetAllocation>,
  ): boolean => {
    for (const allocation of allocations) {
      const currentPosition = positions.find(p => p.portfolio.id === allocation.portfolioId);
      if (!currentPosition) return false;
      const { isWithinTarget } = computeDeviationFromTarget(currentPosition.currentMarketValue, fund, allocation);
      if (!isWithinTarget) return false;
    }
    return true;
  },
);
