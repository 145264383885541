import type { LegacyRef, MutableRefObject, RefCallback } from 'react';

/** Adopted from https://github.com/gregberge/react-merge-refs/blob/main/src/index.tsx */
export const mergeRefs = <T = unknown>(...refs: Array<LegacyRef<T> | MutableRefObject<T>>): RefCallback<T> => {
  return value => {
    refs.forEach(ref => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref != null) {
        (ref as MutableRefObject<T | null>).current = value;
      }
    });
  };
};

export const retry = async <T>(fn: () => Promise<T>, retries = 3): Promise<T> => {
  try {
    return await fn();
  } catch (e) {
    for (let i = 0; i < retries; i++) {
      // Exponential backoff
      await new Promise(resolve => setTimeout(resolve, 1000 * 2 ** i));

      try {
        return await fn();
      } catch (e) {
        continue;
      }
    }
    throw e;
  }
};
