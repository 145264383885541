import { captureException } from '@sentry/nextjs';
import { waitForTransaction } from '@wagmi/core';
import { useSetAtom } from 'jotai';
import { useMount } from 'react-use';
import { TransactionNotFoundError, WaitForTransactionReceiptTimeoutError } from 'viem';

import { defaults } from '@endaoment-frontend/config';
import type { Address, BlockchainTransactionStatus } from '@endaoment-frontend/types';
import { delay } from '@endaoment-frontend/utils';

import { changeStatusAtom, replaceTransactionAtom } from './transactionListAtoms';
import { useTransactionList, type StoredTransaction } from './useTransactionList';

const transactionTypeToRequiredConfirmations: Record<StoredTransaction['type'], number> = {
  BATCH_DEPLOY_ORG: defaults.confirmations.orgDeployment,
  CREATE_DONATION: defaults.confirmations.donation,
  CREATE_GRANT: defaults.confirmations.transfer,
  DEPLOY_ORG: defaults.confirmations.orgDeployment,
  EDIT_POSITION: defaults.confirmations.portfolioTrade,
  DEPLOY_FUND: defaults.confirmations.fundDeployment,
  REBALANCE_FUND: defaults.confirmations.portfolioTrade,
};

export const TransactionListProvider = () => {
  const { pendingTransactions } = useTransactionList();

  return (
    <>
      {pendingTransactions.map(v => (
        <TransactionWaiter key={v.hash} transaction={v} />
      ))}
    </>
  );
};

/**
 * Waits till transaction is confirmed and updates status with final value
 *
 * Exported only for testing purposes, should not be used outside of this file
 */
export const updateTransactionWithCompletion = async (
  {
    transaction,
    onChangeStatus,
    onReplaceTransaction,
  }: {
    transaction: StoredTransaction;
    onChangeStatus: (arg: { hash: Address; status: BlockchainTransactionStatus }) => void;
    onReplaceTransaction: (arg: { oldHash: Address; newHash: Address }) => void;
  },
  attemptCount = 1,
): Promise<void> => {
  try {
    const receipt = await waitForTransaction({
      hash: transaction.hash,
      chainId: transaction.chainId,
      confirmations: transactionTypeToRequiredConfirmations[transaction.type],
      onReplaced: ({ replacedTransaction, transaction }) => {
        // Replace the transaction in the list with the one that replaced it
        onReplaceTransaction({ oldHash: replacedTransaction.hash, newHash: transaction.hash });
      },
    });
    onChangeStatus({
      hash: receipt.transactionHash,
      status: receipt.status === 'success' ? 'success' : 'error',
    });
  } catch (e) {
    if (!(e instanceof Error)) throw e;
    if (e instanceof WaitForTransactionReceiptTimeoutError || e instanceof TransactionNotFoundError) {
      // Give up on the 3rd attempt
      if (attemptCount > 2) return;

      // Try again in 1 second
      await delay(1000);
      return updateTransactionWithCompletion({ transaction, onChangeStatus, onReplaceTransaction }, attemptCount + 1);
    }

    // Default to transaction failure
    captureException(e, {
      tags: {
        'transaction-failure': true,
      },
      level: 'warning',
      extra: { transaction },
    });
    onChangeStatus({ hash: transaction.hash, status: 'error' });
  }
};
/**
 * Add atoms to update function and call it on mount
 */
const TransactionWaiter = ({ transaction }: { transaction: StoredTransaction }) => {
  const onChangeStatus = useSetAtom(changeStatusAtom);
  const onReplaceTransaction = useSetAtom(replaceTransactionAtom);

  useMount(() => {
    updateTransactionWithCompletion({
      transaction,
      onChangeStatus,
      onReplaceTransaction,
    });
  });

  return <></>;
};
