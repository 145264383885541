import { signMessage } from '@wagmi/core';
import { useCallback, useEffect } from 'react';

import { useAuth, useWalletModal } from '@endaoment-frontend/authentication';
import type { LoginDTO } from '@endaoment-frontend/types';
import { makeValidLoginSignature } from '@endaoment-frontend/utils';

declare global {
  interface Window {
    admin: {
      sign: () => void;
    };
  }
}

export const AdminFunctions = () => {
  const { isSignedIn, authAddress } = useAuth();
  const { showWallet } = useWalletModal();

  const sign = useCallback(async () => {
    if (!isSignedIn) {
      showWallet();
      return;
    }

    if (!authAddress) return;

    const loginInfo = makeValidLoginSignature(authAddress);
    const signedMessage = await signMessage({ message: loginInfo.message });

    const dto: LoginDTO = {
      signature: signedMessage,
      walletAddress: authAddress,
      signatureDateUtc: loginInfo.date,
    };
    console.info(JSON.stringify(dto, undefined, 2));
  }, [authAddress, isSignedIn, showWallet]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.admin = {
        sign,
      };
    }
  }, [sign]);

  return <></>;
};
