import clsx from 'clsx';

import { ApproveIcon, DenyIcon } from '@endaoment-frontend/ui/icons';

import { Button } from '../button';

import styles from './ApproveRejectButton.module.scss';
import type { ApproveRejectButtonProps } from './ApproveRejectButton.types';

export const ApproveRejectButton = ({ className, onApprove, onReject }: ApproveRejectButtonProps) => {
  return (
    <div className={clsx(styles['container'], className)}>
      <Button
        size='small'
        className={clsx(styles['approve-reject-button'], styles['approve-reject-button--approve'])}
        onClick={() => onApprove()}>
        <ApproveIcon />
      </Button>
      <Button
        size='small'
        className={clsx(styles['approve-reject-button'], styles['approve-reject-button--reject'])}
        onClick={() => onReject()}>
        <DenyIcon />
      </Button>
    </div>
  );
};
