import { disconnect } from '@wagmi/core';
import clsx from 'clsx';
import { useState } from 'react';
import { match } from 'ts-pattern';
import type { Chain } from 'wagmi';
import { useNetwork, useSwitchNetwork } from 'wagmi';

import { ChainIcon } from '@endaoment-frontend/ui/icons';
import { Button, Pill } from '@endaoment-frontend/ui/shared';

import styles from './NetworkView.module.scss';

export const NetworkView = () => {
  const { chains } = useNetwork();
  return (
    <>
      <h2 className={styles.title}>Please Switch Network</h2>
      <p className={styles['unsupported-blurb']}>
        You are connected to an unsupported network. Please select a network from the list below or disconnect.
      </p>
      <div className={styles['chain-list']}>
        {chains.map(chain => (
          <SwitchToNetworkButton key={chain.id} chain={chain} />
        ))}
      </div>
      <Button size='medium' variation='purple' filled onClick={disconnect}>
        Disconnect
      </Button>
    </>
  );
};

const SwitchToNetworkButton = ({ chain }: { chain: Chain }) => {
  const { switchNetwork, isLoading } = useSwitchNetwork();
  const [isHovered, setIsHovered] = useState(false);

  const switchText = match({ isHovered, isLoading })
    .with({ isLoading: true }, () => 'Switching...')
    .with({ isHovered: true }, () => 'Switch')
    .otherwise(() => undefined);

  return (
    <Button
      className={clsx(styles['chain'], styles[`chain--${chain.id}`])}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => switchNetwork?.(chain.id)}>
      <div className={styles['chain-icon']}>
        <ChainIcon chainId={chain.id} />
      </div>
      {chain.name}
      {!!switchText && (
        <Pill size='tiny' className={styles['switch']}>
          {switchText}
        </Pill>
      )}
    </Button>
  );
};
