import { Text } from '@chakra-ui/react';
import { trackEvent } from '@phntms/next-gtm';
import { useQueryClient } from '@tanstack/react-query';
import { AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import { match } from 'ts-pattern';

import { GetUserFunds, WhoAmI } from '@endaoment-frontend/api';
import { useDeployAndCreateFund } from '@endaoment-frontend/blockchain-interactions';
import type { CreateFundDetailsInput } from '@endaoment-frontend/types';
import { StepModal } from '@endaoment-frontend/ui/shared';

import CreateFundAdvisor from '../common/CreateFundAdvisor';
import CreateFundDetails from '../common/CreateFundDetails';

import { CreateFundComplete } from './CreateFundComplete';

type FundCreateStep = 'advisor' | 'complete' | 'details';

const useCreateFundFlowState = () => {
  const [step, setStep] = useState<FundCreateStep>('details');
  const [fundDetails, setFundDetails] = useState<CreateFundDetailsInput>();

  return {
    wizard: {
      step,
      fundDetails,
    } as const,
    setStep,
    setFundDetails,
  } as const;
};

export const CreateFundFlow = ({ isForMigration, onClose }: { isForMigration?: boolean; onClose: () => void }) => {
  const queryClient = useQueryClient();
  const { wizard, setStep, setFundDetails } = useCreateFundFlowState();

  const { createFund, fund } = useDeployAndCreateFund({
    onSuccess: () => {
      WhoAmI.invalidateQuery(queryClient);
      GetUserFunds.invalidateQuery(queryClient, []);
    },
  });

  const steps = match({ step: wizard.step, fund })
    .with({ step: 'details' }, () => (
      <StepModal.Step key='details' header='New Fund Details' onClose={onClose} progress={{ current: 1, pages: 2 }}>
        <CreateFundDetails
          initialValues={wizard.fundDetails}
          onSubmit={details => {
            setFundDetails(details);
            setStep('advisor');
          }}
        />
      </StepModal.Step>
    ))
    .with({ step: 'advisor' }, ({ step }) => (
      <StepModal.Step key='advisor' header='Fund Manager Details' onBack={() => setStep('details')} onClose={onClose}>
        <CreateFundAdvisor
          createButtonText='Create Fund'
          onSubmit={advisor => {
            if (!wizard.fundDetails) throw new Error(`Fund details are missing from Create Fund ${step} step`);

            createFund({ fundDetails: wizard.fundDetails, advisor });

            trackEvent({
              event: 'fw_create_fund',
              data: {
                is_migration: isForMigration,
              },
            });

            setStep('complete');
          }}
        />
      </StepModal.Step>
    ))
    .with({ step: 'complete' }, () => (
      <StepModal.Step key='complete' header='Fund Creation' onClose={onClose}>
        <CreateFundComplete fund={fund} />
      </StepModal.Step>
    ))
    .otherwise(wizard => {
      console.error('Unhandled Create Fund Wizard state', wizard);
      return (
        <StepModal.Step key='error' onClose={onClose} header='Error'>
          <Text alignSelf='center'>Something went wrong</Text>
        </StepModal.Step>
      );
    });

  return <AnimatePresence mode='wait'>{steps}</AnimatePresence>;
};
