import type {
  ReadContractConfig,
  WriteContractArgs,
  WriteContractPreparedArgs,
  WriteContractUnpreparedArgs,
} from '@wagmi/core';
import { readContract, writeContract } from '@wagmi/core';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// BatchOrgDeployer
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const batchOrgDeployerABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [{ name: '_orgFundFactory', internalType: 'contract OrgFundFactory', type: 'address' }],
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'caller', internalType: 'address', type: 'address', indexed: true },
      { name: 'entityType', internalType: 'uint8', type: 'uint8', indexed: true },
      { name: 'batchSize', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'EntityBatchDeployed',
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_orgIds', internalType: 'bytes32[]', type: 'bytes32[]' }],
    name: 'batchDeploy',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'orgFundFactory',
    outputs: [{ name: '', internalType: 'contract OrgFundFactory', type: 'address' }],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// ERC20
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const erc20ABI = [
  {
    type: 'event',
    inputs: [
      { name: 'owner', type: 'address', indexed: true },
      { name: 'spender', type: 'address', indexed: true },
      { name: 'value', type: 'uint256', indexed: false },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    inputs: [
      { name: 'from', type: 'address', indexed: true },
      { name: 'to', type: 'address', indexed: true },
      { name: 'value', type: 'uint256', indexed: false },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', type: 'address' },
      { name: 'spender', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', type: 'address' },
      { name: 'amount', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ type: 'uint256' }],
  },
  { stateMutability: 'view', type: 'function', inputs: [], name: 'decimals', outputs: [{ type: 'uint8' }] },
  { stateMutability: 'view', type: 'function', inputs: [], name: 'name', outputs: [{ type: 'string' }] },
  { stateMutability: 'view', type: 'function', inputs: [], name: 'symbol', outputs: [{ type: 'string' }] },
  { stateMutability: 'view', type: 'function', inputs: [], name: 'totalSupply', outputs: [{ type: 'uint256' }] },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'recipient', type: 'address' },
      { name: 'amount', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'sender', type: 'address' },
      { name: 'recipient', type: 'address' },
      { name: 'amount', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', type: 'address' },
      { name: 'addedValue', type: 'uint256' },
    ],
    name: 'increaseAllowance',
    outputs: [{ type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', type: 'address' },
      { name: 'subtractedValue', type: 'uint256' },
    ],
    name: 'decreaseAllowance',
    outputs: [{ type: 'bool' }],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// ERC721
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const erc721ABI = [
  {
    type: 'event',
    inputs: [
      { name: 'owner', type: 'address', indexed: true },
      { name: 'spender', type: 'address', indexed: true },
      { name: 'tokenId', type: 'uint256', indexed: true },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    inputs: [
      { name: 'owner', type: 'address', indexed: true },
      { name: 'operator', type: 'address', indexed: true },
      { name: 'approved', type: 'bool', indexed: false },
    ],
    name: 'ApprovalForAll',
  },
  {
    type: 'event',
    inputs: [
      { name: 'from', type: 'address', indexed: true },
      { name: 'to', type: 'address', indexed: true },
      { name: 'tokenId', type: 'uint256', indexed: true },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'spender', type: 'address' },
      { name: 'tokenId', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'tokenId', type: 'uint256' }],
    name: 'getApproved',
    outputs: [{ type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', type: 'address' },
      { name: 'operator', type: 'address' },
    ],
    name: 'isApprovedForAll',
    outputs: [{ type: 'bool' }],
  },
  { stateMutability: 'view', type: 'function', inputs: [], name: 'name', outputs: [{ type: 'string' }] },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'tokenId', type: 'uint256' }],
    name: 'ownerOf',
    outputs: [{ name: 'owner', type: 'address' }],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'from', type: 'address' },
      { name: 'to', type: 'address' },
      { name: 'tokenId', type: 'uint256' },
    ],
    name: 'safeTransferFrom',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', type: 'address' },
      { name: 'to', type: 'address' },
      { name: 'id', type: 'uint256' },
      { name: 'data', type: 'bytes' },
    ],
    name: 'safeTransferFrom',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'operator', type: 'address' },
      { name: 'approved', type: 'bool' },
    ],
    name: 'setApprovalForAll',
    outputs: [],
  },
  { stateMutability: 'view', type: 'function', inputs: [], name: 'symbol', outputs: [{ type: 'string' }] },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'index', type: 'uint256' }],
    name: 'tokenByIndex',
    outputs: [{ type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', type: 'address' },
      { name: 'index', type: 'uint256' },
    ],
    name: 'tokenByIndex',
    outputs: [{ name: 'tokenId', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'tokenId', type: 'uint256' }],
    name: 'tokenURI',
    outputs: [{ type: 'string' }],
  },
  { stateMutability: 'view', type: 'function', inputs: [], name: 'totalSupply', outputs: [{ type: 'uint256' }] },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'sender', type: 'address' },
      { name: 'recipient', type: 'address' },
      { name: 'tokenId', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Entity
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const entityABI = [
  { type: 'error', inputs: [], name: 'AlreadyInitialized' },
  { type: 'error', inputs: [], name: 'BalanceMismatch' },
  { type: 'error', inputs: [{ name: 'response', internalType: 'bytes', type: 'bytes' }], name: 'CallFailed' },
  { type: 'error', inputs: [], name: 'EntityInactive' },
  { type: 'error', inputs: [], name: 'InsufficientFunds' },
  { type: 'error', inputs: [], name: 'InvalidAction' },
  { type: 'error', inputs: [], name: 'InvalidAuthority' },
  { type: 'error', inputs: [], name: 'PortfolioInactive' },
  { type: 'error', inputs: [], name: 'Reentrancy' },
  { type: 'error', inputs: [], name: 'Unauthorized' },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'entity', internalType: 'address', type: 'address', indexed: true },
      { name: 'newBalance', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'EntityBalanceCorrected',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'entity', internalType: 'address', type: 'address', indexed: true },
      { name: 'amountReceived', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'amountFee', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'EntityBalanceReconciled',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'portfolio', internalType: 'address', type: 'address', indexed: true },
      { name: 'baseTokenDeposited', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'sharesReceived', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'EntityDeposit',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'tokenIn', internalType: 'address', type: 'address', indexed: true },
      { name: 'amountIn', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'amountReceived', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'amountFee', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'EntityDonationReceived',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'sender', internalType: 'address', type: 'address', indexed: true },
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'EntityEthReceived',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'oldManager', internalType: 'address', type: 'address', indexed: true },
      { name: 'newManager', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'EntityManagerSet',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'portfolio', internalType: 'address', type: 'address', indexed: true },
      { name: 'sharesRedeemed', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'baseTokenReceived', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'EntityRedeem',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'amountSent', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'amountFee', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'EntityValuePaidOut',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'amountReceived', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'amountFee', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'EntityValueTransferred',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'ETH_PLACEHOLDER',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'authority',
    outputs: [{ name: '', internalType: 'contract RolesAuthority', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'balance',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'baseToken',
    outputs: [{ name: '', internalType: 'contract ERC20', type: 'address' }],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: '_target', internalType: 'address', type: 'address' },
      { name: '_value', internalType: 'uint256', type: 'uint256' },
      { name: '_data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'callAsEntity',
    outputs: [{ name: '', internalType: 'bytes', type: 'bytes' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_amount', internalType: 'uint256', type: 'uint256' }],
    name: 'donate',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
      { name: '_feeOverride', internalType: 'uint32', type: 'uint32' },
    ],
    name: 'donateWithAdminOverrides',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_amount', internalType: 'uint256', type: 'uint256' }],
    name: 'donateWithOverrides',
    outputs: [],
  },
  {
    stateMutability: 'pure',
    type: 'function',
    inputs: [],
    name: 'entityType',
    outputs: [{ name: '', internalType: 'uint8', type: 'uint8' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'manager',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_to', internalType: 'address', type: 'address' },
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'payout',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_to', internalType: 'address', type: 'address' },
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
      { name: '_feeOverride', internalType: 'uint32', type: 'uint32' },
    ],
    name: 'payoutWithAdminOverrides',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_to', internalType: 'address', type: 'address' },
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'payoutWithOverrides',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_portfolio', internalType: 'contract Portfolio', type: 'address' },
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
      { name: '_data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'portfolioDeposit',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_portfolio', internalType: 'contract Portfolio', type: 'address' },
      { name: '_shares', internalType: 'uint256', type: 'uint256' },
      { name: '_data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'portfolioRedeem',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_transferAmount', internalType: 'uint256', type: 'uint256' }],
    name: 'receiveTransfer',
    outputs: [],
  },
  { stateMutability: 'nonpayable', type: 'function', inputs: [], name: 'reconcileBalance', outputs: [] },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'registry',
    outputs: [{ name: '', internalType: 'contract Registry', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_manager', internalType: 'address', type: 'address' }],
    name: 'setManager',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'specialTarget',
    outputs: [{ name: '', internalType: 'bytes20', type: 'bytes20' }],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: '_swapWrapper', internalType: 'contract ISwapWrapper', type: 'address' },
      { name: '_tokenIn', internalType: 'address', type: 'address' },
      { name: '_amountIn', internalType: 'uint256', type: 'uint256' },
      { name: '_data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'swapAndDonate',
    outputs: [],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: '_swapWrapper', internalType: 'contract ISwapWrapper', type: 'address' },
      { name: '_tokenIn', internalType: 'address', type: 'address' },
      { name: '_amountIn', internalType: 'uint256', type: 'uint256' },
      { name: '_data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'swapAndDonateWithOverrides',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_swapWrapper', internalType: 'contract ISwapWrapper', type: 'address' },
      { name: '_tokenIn', internalType: 'address', type: 'address' },
      { name: '_amountIn', internalType: 'uint256', type: 'uint256' },
      { name: '_data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'swapAndReconcileBalance',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_to', internalType: 'contract Entity', type: 'address' },
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferToEntity',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_to', internalType: 'contract Entity', type: 'address' },
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
      { name: '_feeOverride', internalType: 'uint32', type: 'uint32' },
    ],
    name: 'transferToEntityWithAdminOverrides',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_to', internalType: 'contract Entity', type: 'address' },
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferToEntityWithOverrides',
    outputs: [],
  },
  { stateMutability: 'payable', type: 'receive' },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// EntityMulticall
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const entityMulticallABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [{ name: '_registry', internalType: 'contract Registry', type: 'address' }],
  },
  { type: 'error', inputs: [], name: 'AlreadyInitialized' },
  { type: 'error', inputs: [{ name: 'response', internalType: 'bytes', type: 'bytes' }], name: 'CallFailed' },
  { type: 'error', inputs: [], name: 'EntityInactive' },
  { type: 'error', inputs: [], name: 'InvalidAuthority' },
  { type: 'error', inputs: [], name: 'Unauthorized' },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'authority',
    outputs: [{ name: '', internalType: 'contract RolesAuthority', type: 'address' }],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: '_entity', internalType: 'contract Entity', type: 'address' },
      {
        name: '_calls',
        internalType: 'struct EntityMulticall.Call[]',
        type: 'tuple[]',
        components: [
          { name: 'value', internalType: 'uint256', type: 'uint256' },
          { name: 'data', internalType: 'bytes', type: 'bytes' },
        ],
      },
    ],
    name: 'multicall',
    outputs: [],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: '_entity', internalType: 'contract Entity', type: 'address' },
      {
        name: '_allowances',
        internalType: 'struct EntityMulticall.TokenAllowance[]',
        type: 'tuple[]',
        components: [
          { name: 'token', internalType: 'contract ERC20', type: 'address' },
          { name: 'amount', internalType: 'uint256', type: 'uint256' },
        ],
      },
      {
        name: '_calls',
        internalType: 'struct EntityMulticall.Call[]',
        type: 'tuple[]',
        components: [
          { name: 'value', internalType: 'uint256', type: 'uint256' },
          { name: 'data', internalType: 'bytes', type: 'bytes' },
        ],
      },
    ],
    name: 'multicallWithAllowance',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'registry',
    outputs: [{ name: '', internalType: 'contract Registry', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'specialTarget',
    outputs: [{ name: '', internalType: 'bytes20', type: 'bytes20' }],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Fund
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const fundABI = [
  { type: 'error', inputs: [], name: 'AlreadyInitialized' },
  { type: 'error', inputs: [], name: 'BalanceMismatch' },
  { type: 'error', inputs: [{ name: 'response', internalType: 'bytes', type: 'bytes' }], name: 'CallFailed' },
  { type: 'error', inputs: [], name: 'EntityInactive' },
  { type: 'error', inputs: [], name: 'InsufficientFunds' },
  { type: 'error', inputs: [], name: 'InvalidAction' },
  { type: 'error', inputs: [], name: 'InvalidAuthority' },
  { type: 'error', inputs: [], name: 'PortfolioInactive' },
  { type: 'error', inputs: [], name: 'Reentrancy' },
  { type: 'error', inputs: [], name: 'Unauthorized' },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'entity', internalType: 'address', type: 'address', indexed: true },
      { name: 'newBalance', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'EntityBalanceCorrected',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'entity', internalType: 'address', type: 'address', indexed: true },
      { name: 'amountReceived', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'amountFee', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'EntityBalanceReconciled',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'portfolio', internalType: 'address', type: 'address', indexed: true },
      { name: 'baseTokenDeposited', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'sharesReceived', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'EntityDeposit',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'tokenIn', internalType: 'address', type: 'address', indexed: true },
      { name: 'amountIn', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'amountReceived', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'amountFee', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'EntityDonationReceived',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'sender', internalType: 'address', type: 'address', indexed: true },
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'EntityEthReceived',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'oldManager', internalType: 'address', type: 'address', indexed: true },
      { name: 'newManager', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'EntityManagerSet',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'portfolio', internalType: 'address', type: 'address', indexed: true },
      { name: 'sharesRedeemed', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'baseTokenReceived', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'EntityRedeem',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'amountSent', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'amountFee', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'EntityValuePaidOut',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'amountReceived', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'amountFee', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'EntityValueTransferred',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'ETH_PLACEHOLDER',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'authority',
    outputs: [{ name: '', internalType: 'contract RolesAuthority', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'balance',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'baseToken',
    outputs: [{ name: '', internalType: 'contract ERC20', type: 'address' }],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: '_target', internalType: 'address', type: 'address' },
      { name: '_value', internalType: 'uint256', type: 'uint256' },
      { name: '_data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'callAsEntity',
    outputs: [{ name: '', internalType: 'bytes', type: 'bytes' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_amount', internalType: 'uint256', type: 'uint256' }],
    name: 'donate',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
      { name: '_feeOverride', internalType: 'uint32', type: 'uint32' },
    ],
    name: 'donateWithAdminOverrides',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_amount', internalType: 'uint256', type: 'uint256' }],
    name: 'donateWithOverrides',
    outputs: [],
  },
  {
    stateMutability: 'pure',
    type: 'function',
    inputs: [],
    name: 'entityType',
    outputs: [{ name: '', internalType: 'uint8', type: 'uint8' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_registry', internalType: 'contract Registry', type: 'address' },
      { name: '_manager', internalType: 'address', type: 'address' },
    ],
    name: 'initialize',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'manager',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_to', internalType: 'address', type: 'address' },
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'payout',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_to', internalType: 'address', type: 'address' },
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
      { name: '_feeOverride', internalType: 'uint32', type: 'uint32' },
    ],
    name: 'payoutWithAdminOverrides',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_to', internalType: 'address', type: 'address' },
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'payoutWithOverrides',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_portfolio', internalType: 'contract Portfolio', type: 'address' },
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
      { name: '_data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'portfolioDeposit',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_portfolio', internalType: 'contract Portfolio', type: 'address' },
      { name: '_shares', internalType: 'uint256', type: 'uint256' },
      { name: '_data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'portfolioRedeem',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_transferAmount', internalType: 'uint256', type: 'uint256' }],
    name: 'receiveTransfer',
    outputs: [],
  },
  { stateMutability: 'nonpayable', type: 'function', inputs: [], name: 'reconcileBalance', outputs: [] },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'registry',
    outputs: [{ name: '', internalType: 'contract Registry', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_manager', internalType: 'address', type: 'address' }],
    name: 'setManager',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'specialTarget',
    outputs: [{ name: '', internalType: 'bytes20', type: 'bytes20' }],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: '_swapWrapper', internalType: 'contract ISwapWrapper', type: 'address' },
      { name: '_tokenIn', internalType: 'address', type: 'address' },
      { name: '_amountIn', internalType: 'uint256', type: 'uint256' },
      { name: '_data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'swapAndDonate',
    outputs: [],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: '_swapWrapper', internalType: 'contract ISwapWrapper', type: 'address' },
      { name: '_tokenIn', internalType: 'address', type: 'address' },
      { name: '_amountIn', internalType: 'uint256', type: 'uint256' },
      { name: '_data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'swapAndDonateWithOverrides',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_swapWrapper', internalType: 'contract ISwapWrapper', type: 'address' },
      { name: '_tokenIn', internalType: 'address', type: 'address' },
      { name: '_amountIn', internalType: 'uint256', type: 'uint256' },
      { name: '_data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'swapAndReconcileBalance',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_to', internalType: 'contract Entity', type: 'address' },
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferToEntity',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_to', internalType: 'contract Entity', type: 'address' },
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
      { name: '_feeOverride', internalType: 'uint32', type: 'uint32' },
    ],
    name: 'transferToEntityWithAdminOverrides',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_to', internalType: 'contract Entity', type: 'address' },
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferToEntityWithOverrides',
    outputs: [],
  },
  { stateMutability: 'payable', type: 'receive' },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Org
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const orgABI = [
  { type: 'error', inputs: [], name: 'AlreadyInitialized' },
  { type: 'error', inputs: [], name: 'BalanceMismatch' },
  { type: 'error', inputs: [{ name: 'response', internalType: 'bytes', type: 'bytes' }], name: 'CallFailed' },
  { type: 'error', inputs: [], name: 'EntityInactive' },
  { type: 'error', inputs: [], name: 'InsufficientFunds' },
  { type: 'error', inputs: [], name: 'InvalidAction' },
  { type: 'error', inputs: [], name: 'InvalidAuthority' },
  { type: 'error', inputs: [], name: 'PortfolioInactive' },
  { type: 'error', inputs: [], name: 'Reentrancy' },
  { type: 'error', inputs: [], name: 'Unauthorized' },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'entity', internalType: 'address', type: 'address', indexed: true },
      { name: 'newBalance', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'EntityBalanceCorrected',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'entity', internalType: 'address', type: 'address', indexed: true },
      { name: 'amountReceived', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'amountFee', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'EntityBalanceReconciled',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'portfolio', internalType: 'address', type: 'address', indexed: true },
      { name: 'baseTokenDeposited', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'sharesReceived', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'EntityDeposit',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'tokenIn', internalType: 'address', type: 'address', indexed: true },
      { name: 'amountIn', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'amountReceived', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'amountFee', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'EntityDonationReceived',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'sender', internalType: 'address', type: 'address', indexed: true },
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'EntityEthReceived',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'oldManager', internalType: 'address', type: 'address', indexed: true },
      { name: 'newManager', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'EntityManagerSet',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'portfolio', internalType: 'address', type: 'address', indexed: true },
      { name: 'sharesRedeemed', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'baseTokenReceived', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'EntityRedeem',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'amountSent', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'amountFee', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'EntityValuePaidOut',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'amountReceived', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'amountFee', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'EntityValueTransferred',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'ETH_PLACEHOLDER',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'authority',
    outputs: [{ name: '', internalType: 'contract RolesAuthority', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'balance',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'baseToken',
    outputs: [{ name: '', internalType: 'contract ERC20', type: 'address' }],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: '_target', internalType: 'address', type: 'address' },
      { name: '_value', internalType: 'uint256', type: 'uint256' },
      { name: '_data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'callAsEntity',
    outputs: [{ name: '', internalType: 'bytes', type: 'bytes' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_amount', internalType: 'uint256', type: 'uint256' }],
    name: 'donate',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
      { name: '_feeOverride', internalType: 'uint32', type: 'uint32' },
    ],
    name: 'donateWithAdminOverrides',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_amount', internalType: 'uint256', type: 'uint256' }],
    name: 'donateWithOverrides',
    outputs: [],
  },
  {
    stateMutability: 'pure',
    type: 'function',
    inputs: [],
    name: 'entityType',
    outputs: [{ name: '', internalType: 'uint8', type: 'uint8' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_registry', internalType: 'contract Registry', type: 'address' },
      { name: '_orgId', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'initialize',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'manager',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'orgId',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_to', internalType: 'address', type: 'address' },
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'payout',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_to', internalType: 'address', type: 'address' },
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
      { name: '_feeOverride', internalType: 'uint32', type: 'uint32' },
    ],
    name: 'payoutWithAdminOverrides',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_to', internalType: 'address', type: 'address' },
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'payoutWithOverrides',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_portfolio', internalType: 'contract Portfolio', type: 'address' },
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
      { name: '_data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'portfolioDeposit',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_portfolio', internalType: 'contract Portfolio', type: 'address' },
      { name: '_shares', internalType: 'uint256', type: 'uint256' },
      { name: '_data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'portfolioRedeem',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_transferAmount', internalType: 'uint256', type: 'uint256' }],
    name: 'receiveTransfer',
    outputs: [],
  },
  { stateMutability: 'nonpayable', type: 'function', inputs: [], name: 'reconcileBalance', outputs: [] },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'registry',
    outputs: [{ name: '', internalType: 'contract Registry', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_manager', internalType: 'address', type: 'address' }],
    name: 'setManager',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_orgId', internalType: 'bytes32', type: 'bytes32' }],
    name: 'setOrgId',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'specialTarget',
    outputs: [{ name: '', internalType: 'bytes20', type: 'bytes20' }],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: '_swapWrapper', internalType: 'contract ISwapWrapper', type: 'address' },
      { name: '_tokenIn', internalType: 'address', type: 'address' },
      { name: '_amountIn', internalType: 'uint256', type: 'uint256' },
      { name: '_data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'swapAndDonate',
    outputs: [],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: '_swapWrapper', internalType: 'contract ISwapWrapper', type: 'address' },
      { name: '_tokenIn', internalType: 'address', type: 'address' },
      { name: '_amountIn', internalType: 'uint256', type: 'uint256' },
      { name: '_data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'swapAndDonateWithOverrides',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_swapWrapper', internalType: 'contract ISwapWrapper', type: 'address' },
      { name: '_tokenIn', internalType: 'address', type: 'address' },
      { name: '_amountIn', internalType: 'uint256', type: 'uint256' },
      { name: '_data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'swapAndReconcileBalance',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_to', internalType: 'contract Entity', type: 'address' },
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferToEntity',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_to', internalType: 'contract Entity', type: 'address' },
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
      { name: '_feeOverride', internalType: 'uint32', type: 'uint32' },
    ],
    name: 'transferToEntityWithAdminOverrides',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_to', internalType: 'contract Entity', type: 'address' },
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferToEntityWithOverrides',
    outputs: [],
  },
  { stateMutability: 'payable', type: 'receive' },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// OrgFundFactory
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const orgFundFactoryABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [{ name: '_registry', internalType: 'contract Registry', type: 'address' }],
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'entity', internalType: 'address', type: 'address', indexed: true },
      { name: 'entityType', internalType: 'uint8', type: 'uint8', indexed: true },
      { name: 'entityManager', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'EntityDeployed',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'ETH_PLACEHOLDER',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'baseToken',
    outputs: [{ name: '', internalType: 'contract ERC20', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: '_manager', internalType: 'address', type: 'address' },
      { name: '_salt', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'computeFundAddress',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_orgId', internalType: 'bytes32', type: 'bytes32' }],
    name: 'computeOrgAddress',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_manager', internalType: 'address', type: 'address' },
      { name: '_salt', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'deployFund',
    outputs: [{ name: '_fund', internalType: 'contract Fund', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_manager', internalType: 'address', type: 'address' },
      { name: '_salt', internalType: 'bytes32', type: 'bytes32' },
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'deployFundAndDonate',
    outputs: [{ name: '_fund', internalType: 'contract Fund', type: 'address' }],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: '_manager', internalType: 'address', type: 'address' },
      { name: '_salt', internalType: 'bytes32', type: 'bytes32' },
      { name: '_swapWrapper', internalType: 'contract ISwapWrapper', type: 'address' },
      { name: '_tokenIn', internalType: 'address', type: 'address' },
      { name: '_amountIn', internalType: 'uint256', type: 'uint256' },
      { name: '_data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'deployFundSwapAndDonate',
    outputs: [{ name: '_fund', internalType: 'contract Fund', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_orgId', internalType: 'bytes32', type: 'bytes32' }],
    name: 'deployOrg',
    outputs: [{ name: '_org', internalType: 'contract Org', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_orgId', internalType: 'bytes32', type: 'bytes32' },
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'deployOrgAndDonate',
    outputs: [{ name: '_org', internalType: 'contract Org', type: 'address' }],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: '_orgId', internalType: 'bytes32', type: 'bytes32' },
      { name: '_swapWrapper', internalType: 'contract ISwapWrapper', type: 'address' },
      { name: '_tokenIn', internalType: 'address', type: 'address' },
      { name: '_amountIn', internalType: 'uint256', type: 'uint256' },
      { name: '_data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'deployOrgSwapAndDonate',
    outputs: [{ name: '_org', internalType: 'contract Org', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'fundImplementation',
    outputs: [{ name: '', internalType: 'contract Fund', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'orgImplementation',
    outputs: [{ name: '', internalType: 'contract Org', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'registry',
    outputs: [{ name: '', internalType: 'contract Registry', type: 'address' }],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Portfolio
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const portfolioABI = [
  { type: 'error', inputs: [], name: 'AlreadyInitialized' },
  { type: 'error', inputs: [], name: 'BadCheckCapImplementation' },
  { type: 'error', inputs: [{ name: 'response', internalType: 'bytes', type: 'bytes' }], name: 'CallFailed' },
  { type: 'error', inputs: [], name: 'DepositAfterShutdown' },
  { type: 'error', inputs: [], name: 'DidShutdown' },
  { type: 'error', inputs: [], name: 'ExceedsCap' },
  { type: 'error', inputs: [], name: 'InvalidAuthority' },
  { type: 'error', inputs: [], name: 'InvalidRate' },
  { type: 'error', inputs: [], name: 'InvalidSwapper' },
  { type: 'error', inputs: [], name: 'NotEntity' },
  { type: 'error', inputs: [], name: 'PercentageOver100' },
  { type: 'error', inputs: [], name: 'RoundsToZero' },
  { type: 'error', inputs: [], name: 'Slippage' },
  { type: 'error', inputs: [], name: 'TransferDisallowed' },
  { type: 'error', inputs: [], name: 'Unauthorized' },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'feeAmount', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'timeDelta', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'AumFeesTaken',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'rate', internalType: 'uint256', type: 'uint256', indexed: false }],
    name: 'AumRateSet',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'cap', internalType: 'uint256', type: 'uint256', indexed: false }],
    name: 'CapSet',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'sender', internalType: 'address', type: 'address', indexed: true },
      { name: 'receiver', internalType: 'address', type: 'address', indexed: true },
      { name: 'assets', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'shares', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'depositAmount', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'fee', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Deposit',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'fee', internalType: 'uint256', type: 'uint256', indexed: false }],
    name: 'DepositFeeSet',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'feeTreasury', internalType: 'address', type: 'address', indexed: false }],
    name: 'FeeTreasurySet',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'amount', internalType: 'uint256', type: 'uint256', indexed: false }],
    name: 'FeesTaken',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'sender', internalType: 'address', type: 'address', indexed: true },
      { name: 'receiver', internalType: 'address', type: 'address', indexed: true },
      { name: 'assets', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'shares', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'redeemedAmount', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'fee', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Redeem',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'fee', internalType: 'uint256', type: 'uint256', indexed: false }],
    name: 'RedemptionFeeSet',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'assetAmount', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'baseTokenOut', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Shutdown',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'DOMAIN_SEPARATOR',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'PERMIT_TYPEHASH',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'address', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'pure',
    type: 'function',
    inputs: [
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'asset',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'async',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'aumRate',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'authority',
    outputs: [{ name: '', internalType: 'contract RolesAuthority', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'baseToken',
    outputs: [{ name: '', internalType: 'contract ERC20', type: 'address' }],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: '_target', internalType: 'address', type: 'address' },
      { name: '_value', internalType: 'uint256', type: 'uint256' },
      { name: '_data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'callAsPortfolio',
    outputs: [{ name: '', internalType: 'bytes', type: 'bytes' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'cap',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_receiptAssets', internalType: 'uint256', type: 'uint256' }],
    name: 'convertReceiptAssetsToAssets',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_shares', internalType: 'uint256', type: 'uint256' }],
    name: 'convertToAssets',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_shares', internalType: 'uint256', type: 'uint256' }],
    name: 'convertToAssetsShutdown',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_assets', internalType: 'uint256', type: 'uint256' }],
    name: 'convertToShares',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'decimals',
    outputs: [{ name: '', internalType: 'uint8', type: 'uint8' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_amountBaseToken', internalType: 'uint256', type: 'uint256' },
      { name: '_data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'deposit',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'depositFee',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'didShutdown',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'feeTreasury',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'nonces',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'pure',
    type: 'function',
    inputs: [
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'uint256', type: 'uint256' },
      { name: '', internalType: 'uint256', type: 'uint256' },
      { name: '', internalType: 'uint8', type: 'uint8' },
      { name: '', internalType: 'bytes32', type: 'bytes32' },
      { name: '', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'permit',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'receiptAsset',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_amountShares', internalType: 'uint256', type: 'uint256' },
      { name: '_data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'redeem',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'redemptionFee',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'registry',
    outputs: [{ name: '', internalType: 'contract Registry', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_pct', internalType: 'uint256', type: 'uint256' }],
    name: 'setAumRate',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_amount', internalType: 'uint256', type: 'uint256' }],
    name: 'setCap',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_pct', internalType: 'uint256', type: 'uint256' }],
    name: 'setDepositFee',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_feeTreasury', internalType: 'address', type: 'address' }],
    name: 'setFeeTreasury',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_pct', internalType: 'uint256', type: 'uint256' }],
    name: 'setRedemptionFee',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_data', internalType: 'bytes', type: 'bytes' }],
    name: 'shutdown',
    outputs: [{ name: 'baseTokenOut', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'specialTarget',
    outputs: [{ name: '', internalType: 'bytes20', type: 'bytes20' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'symbol',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  { stateMutability: 'nonpayable', type: 'function', inputs: [], name: 'takeAumFees', outputs: [] },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_amountReceiptAssets', internalType: 'uint256', type: 'uint256' }],
    name: 'takeFees',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'timestampAumFeesTaken',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'totalAssets',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'totalReceiptAssets',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'pure',
    type: 'function',
    inputs: [
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'pure',
    type: 'function',
    inputs: [
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Registry
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const registryABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: '_admin', internalType: 'address', type: 'address' },
      { name: '_treasury', internalType: 'address', type: 'address' },
      { name: '_baseToken', internalType: 'contract ERC20', type: 'address' },
    ],
  },
  { type: 'error', inputs: [], name: 'OwnershipInvalid' },
  { type: 'error', inputs: [], name: 'Unauthorized' },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'user', internalType: 'address', type: 'address', indexed: true },
      { name: 'newAuthority', internalType: 'contract Authority', type: 'address', indexed: true },
    ],
    name: 'AuthorityUpdated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'entityType', internalType: 'uint8', type: 'uint8', indexed: true },
      { name: 'fee', internalType: 'uint32', type: 'uint32', indexed: false },
    ],
    name: 'DefaultDonationFeeSet',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'entityType', internalType: 'uint8', type: 'uint8', indexed: true },
      { name: 'fee', internalType: 'uint32', type: 'uint32', indexed: false },
    ],
    name: 'DefaultPayoutFeeSet',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'fromEntityType', internalType: 'uint8', type: 'uint8', indexed: true },
      { name: 'toEntityType', internalType: 'uint8', type: 'uint8', indexed: true },
      { name: 'fee', internalType: 'uint32', type: 'uint32', indexed: false },
    ],
    name: 'DefaultTransferFeeSet',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'entity', internalType: 'address', type: 'address', indexed: true },
      { name: 'fee', internalType: 'uint32', type: 'uint32', indexed: false },
    ],
    name: 'DonationFeeReceiverOverrideSet',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'entity', internalType: 'address', type: 'address', indexed: true },
      { name: 'isActive', internalType: 'bool', type: 'bool', indexed: false },
    ],
    name: 'EntityStatusSet',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'factory', internalType: 'address', type: 'address', indexed: true },
      { name: 'isApproved', internalType: 'bool', type: 'bool', indexed: false },
    ],
    name: 'FactoryApprovalSet',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'user', internalType: 'address', type: 'address', indexed: true },
      { name: 'newOwner', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'OwnerUpdated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'newOwner', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'OwnershipChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'user', internalType: 'address', type: 'address', indexed: true },
      { name: 'newOwner', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'OwnershipTransferProposed',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'entity', internalType: 'address', type: 'address', indexed: true },
      { name: 'fee', internalType: 'uint32', type: 'uint32', indexed: false },
    ],
    name: 'PayoutFeeOverrideSet',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'portfolio', internalType: 'address', type: 'address', indexed: true },
      { name: 'isActive', internalType: 'bool', type: 'bool', indexed: false },
    ],
    name: 'PortfolioStatusSet',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'target', internalType: 'address', type: 'address', indexed: true },
      { name: 'functionSig', internalType: 'bytes4', type: 'bytes4', indexed: true },
      { name: 'enabled', internalType: 'bool', type: 'bool', indexed: false },
    ],
    name: 'PublicCapabilityUpdated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'uint8', type: 'uint8', indexed: true },
      { name: 'target', internalType: 'address', type: 'address', indexed: true },
      { name: 'functionSig', internalType: 'bytes4', type: 'bytes4', indexed: true },
      { name: 'enabled', internalType: 'bool', type: 'bool', indexed: false },
    ],
    name: 'RoleCapabilityUpdated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'swapWrapper', internalType: 'address', type: 'address', indexed: true },
      { name: 'isSupported', internalType: 'bool', type: 'bool', indexed: false },
    ],
    name: 'SwapWrapperStatusSet',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'fromEntityType', internalType: 'uint8', type: 'uint8', indexed: true },
      { name: 'toEntity', internalType: 'address', type: 'address', indexed: true },
      { name: 'fee', internalType: 'uint32', type: 'uint32', indexed: false },
    ],
    name: 'TransferFeeReceiverOverrideSet',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'fromEntity', internalType: 'address', type: 'address', indexed: true },
      { name: 'toEntityType', internalType: 'uint8', type: 'uint8', indexed: true },
      { name: 'fee', internalType: 'uint32', type: 'uint32', indexed: false },
    ],
    name: 'TransferFeeSenderOverrideSet',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'oldTreasury', internalType: 'address', type: 'address', indexed: false },
      { name: 'newTreasury', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'TreasuryChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'user', internalType: 'address', type: 'address', indexed: true },
      { name: 'role', internalType: 'uint8', type: 'uint8', indexed: true },
      { name: 'enabled', internalType: 'bool', type: 'bool', indexed: false },
    ],
    name: 'UserRoleUpdated',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'authority',
    outputs: [{ name: '', internalType: 'contract Authority', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'baseToken',
    outputs: [{ name: '', internalType: 'contract ERC20', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'user', internalType: 'address', type: 'address' },
      { name: 'target', internalType: 'address', type: 'address' },
      { name: 'functionSig', internalType: 'bytes4', type: 'bytes4' },
    ],
    name: 'canCall',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  { stateMutability: 'nonpayable', type: 'function', inputs: [], name: 'claimOwnership', outputs: [] },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'uint8', type: 'uint8' },
      { name: 'target', internalType: 'address', type: 'address' },
      { name: 'functionSig', internalType: 'bytes4', type: 'bytes4' },
    ],
    name: 'doesRoleHaveCapability',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'user', internalType: 'address', type: 'address' },
      { name: 'role', internalType: 'uint8', type: 'uint8' },
    ],
    name: 'doesUserHaveRole',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_entity', internalType: 'contract Entity', type: 'address' }],
    name: 'getDonationFee',
    outputs: [{ name: '', internalType: 'uint32', type: 'uint32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_entity', internalType: 'contract Entity', type: 'address' }],
    name: 'getDonationFeeWithOverrides',
    outputs: [{ name: '', internalType: 'uint32', type: 'uint32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_entity', internalType: 'contract Entity', type: 'address' }],
    name: 'getPayoutFee',
    outputs: [{ name: '', internalType: 'uint32', type: 'uint32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_entity', internalType: 'contract Entity', type: 'address' }],
    name: 'getPayoutFeeWithOverrides',
    outputs: [{ name: '', internalType: 'uint32', type: 'uint32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'bytes4', type: 'bytes4' },
    ],
    name: 'getRolesWithCapability',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: '_sender', internalType: 'contract Entity', type: 'address' },
      { name: '_receiver', internalType: 'contract Entity', type: 'address' },
    ],
    name: 'getTransferFee',
    outputs: [{ name: '', internalType: 'uint32', type: 'uint32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: '_sender', internalType: 'contract Entity', type: 'address' },
      { name: '_receiver', internalType: 'contract Entity', type: 'address' },
    ],
    name: 'getTransferFeeWithOverrides',
    outputs: [{ name: '', internalType: 'uint32', type: 'uint32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'getUserRoles',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'contract Entity', type: 'address' }],
    name: 'isActiveEntity',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'contract Portfolio', type: 'address' }],
    name: 'isActivePortfolio',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'isApprovedFactory',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'bytes4', type: 'bytes4' },
    ],
    name: 'isCapabilityPublic',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'contract ISwapWrapper', type: 'address' }],
    name: 'isSwapperSupported',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'owner',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'pendingOwner',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'newAuthority', internalType: 'contract Authority', type: 'address' }],
    name: 'setAuthority',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_entityType', internalType: 'uint8', type: 'uint8' },
      { name: '_fee', internalType: 'uint32', type: 'uint32' },
    ],
    name: 'setDefaultDonationFee',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_entityType', internalType: 'uint8', type: 'uint8' },
      { name: '_fee', internalType: 'uint32', type: 'uint32' },
    ],
    name: 'setDefaultPayoutFee',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_fromEntityType', internalType: 'uint8', type: 'uint8' },
      { name: '_toEntityType', internalType: 'uint8', type: 'uint8' },
      { name: '_fee', internalType: 'uint32', type: 'uint32' },
    ],
    name: 'setDefaultTransferFee',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_entity', internalType: 'contract Entity', type: 'address' },
      { name: '_fee', internalType: 'uint32', type: 'uint32' },
    ],
    name: 'setDonationFeeReceiverOverride',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_entity', internalType: 'contract Entity', type: 'address' }],
    name: 'setEntityActive',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_entity', internalType: 'contract Entity', type: 'address' },
      { name: '_isActive', internalType: 'bool', type: 'bool' },
    ],
    name: 'setEntityStatus',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_factory', internalType: 'address', type: 'address' },
      { name: '_isApproved', internalType: 'bool', type: 'bool' },
    ],
    name: 'setFactoryApproval',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'setOwner',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_entity', internalType: 'contract Entity', type: 'address' },
      { name: '_fee', internalType: 'uint32', type: 'uint32' },
    ],
    name: 'setPayoutFeeOverride',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_portfolio', internalType: 'contract Portfolio', type: 'address' },
      { name: '_isActive', internalType: 'bool', type: 'bool' },
    ],
    name: 'setPortfolioStatus',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'target', internalType: 'address', type: 'address' },
      { name: 'functionSig', internalType: 'bytes4', type: 'bytes4' },
      { name: 'enabled', internalType: 'bool', type: 'bool' },
    ],
    name: 'setPublicCapability',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'uint8', type: 'uint8' },
      { name: 'target', internalType: 'address', type: 'address' },
      { name: 'functionSig', internalType: 'bytes4', type: 'bytes4' },
      { name: 'enabled', internalType: 'bool', type: 'bool' },
    ],
    name: 'setRoleCapability',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_swapWrapper', internalType: 'contract ISwapWrapper', type: 'address' },
      { name: '_supported', internalType: 'bool', type: 'bool' },
    ],
    name: 'setSwapWrapperStatus',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_fromEntityType', internalType: 'uint8', type: 'uint8' },
      { name: '_toEntity', internalType: 'contract Entity', type: 'address' },
      { name: '_fee', internalType: 'uint32', type: 'uint32' },
    ],
    name: 'setTransferFeeReceiverOverride',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_fromEntity', internalType: 'contract Entity', type: 'address' },
      { name: '_toEntityType', internalType: 'uint8', type: 'uint8' },
      { name: '_fee', internalType: 'uint32', type: 'uint32' },
    ],
    name: 'setTransferFeeSenderOverride',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_newTreasury', internalType: 'address', type: 'address' }],
    name: 'setTreasury',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'user', internalType: 'address', type: 'address' },
      { name: 'role', internalType: 'uint8', type: 'uint8' },
      { name: 'enabled', internalType: 'bool', type: 'bool' },
    ],
    name: 'setUserRole',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_newOwner', internalType: 'address', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'treasury',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// TransferDeployer
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const transferDeployerABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [{ name: '_orgFundFactory', internalType: 'contract OrgFundFactory', type: 'address' }],
  },
  { type: 'error', inputs: [], name: 'Unauthorized' },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'fund', internalType: 'contract Fund', type: 'address', indexed: false },
      { name: 'source', internalType: 'contract Entity', type: 'address', indexed: false },
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'FundDeployedAndTransferred',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'org', internalType: 'contract Org', type: 'address', indexed: false },
      { name: 'source', internalType: 'contract Entity', type: 'address', indexed: false },
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'OrgDeployedAndTransferred',
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_source', internalType: 'contract Entity', type: 'address' },
      { name: '_manager', internalType: 'address', type: 'address' },
      { name: '_salt', internalType: 'bytes32', type: 'bytes32' },
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'deployFundAndTransfer',
    outputs: [{ name: '_fund', internalType: 'contract Fund', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_source', internalType: 'contract Entity', type: 'address' },
      { name: '_orgId', internalType: 'bytes32', type: 'bytes32' },
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'deployOrgAndTransfer',
    outputs: [{ name: '_org', internalType: 'contract Org', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'orgFundFactory',
    outputs: [{ name: '', internalType: 'contract OrgFundFactory', type: 'address' }],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Core
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link batchOrgDeployerABI}__.
 */
export function readBatchOrgDeployer<
  TAbi extends ReadonlyArray<unknown> = typeof batchOrgDeployerABI,
  TFunctionName extends string = string,
>(config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi'>): ReturnType<typeof readContract<TAbi, TFunctionName>> {
  return readContract({ abi: batchOrgDeployerABI, ...config } as unknown as ReadContractConfig<TAbi, TFunctionName>);
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link batchOrgDeployerABI}__.
 */
export function writeBatchOrgDeployer<TFunctionName extends string>(
  config:
    | Omit<WriteContractPreparedArgs<typeof batchOrgDeployerABI, TFunctionName>, 'abi'>
    | Omit<WriteContractUnpreparedArgs<typeof batchOrgDeployerABI, TFunctionName>, 'abi'>,
) {
  return writeContract({ abi: batchOrgDeployerABI, ...config } as unknown as WriteContractArgs<
    typeof batchOrgDeployerABI,
    TFunctionName
  >);
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link erc20ABI}__.
 */
export function readErc20<TAbi extends ReadonlyArray<unknown> = typeof erc20ABI, TFunctionName extends string = string>(
  config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi'>,
): ReturnType<typeof readContract<TAbi, TFunctionName>> {
  return readContract({ abi: erc20ABI, ...config } as unknown as ReadContractConfig<TAbi, TFunctionName>);
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link erc20ABI}__.
 */
export function writeErc20<TFunctionName extends string>(
  config:
    | Omit<WriteContractPreparedArgs<typeof erc20ABI, TFunctionName>, 'abi'>
    | Omit<WriteContractUnpreparedArgs<typeof erc20ABI, TFunctionName>, 'abi'>,
) {
  return writeContract({ abi: erc20ABI, ...config } as unknown as WriteContractArgs<typeof erc20ABI, TFunctionName>);
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link erc721ABI}__.
 */
export function readErc721<
  TAbi extends ReadonlyArray<unknown> = typeof erc721ABI,
  TFunctionName extends string = string,
>(config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi'>): ReturnType<typeof readContract<TAbi, TFunctionName>> {
  return readContract({ abi: erc721ABI, ...config } as unknown as ReadContractConfig<TAbi, TFunctionName>);
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link erc721ABI}__.
 */
export function writeErc721<TFunctionName extends string>(
  config:
    | Omit<WriteContractPreparedArgs<typeof erc721ABI, TFunctionName>, 'abi'>
    | Omit<WriteContractUnpreparedArgs<typeof erc721ABI, TFunctionName>, 'abi'>,
) {
  return writeContract({ abi: erc721ABI, ...config } as unknown as WriteContractArgs<typeof erc721ABI, TFunctionName>);
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link entityABI}__.
 */
export function readEntity<
  TAbi extends ReadonlyArray<unknown> = typeof entityABI,
  TFunctionName extends string = string,
>(config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi'>): ReturnType<typeof readContract<TAbi, TFunctionName>> {
  return readContract({ abi: entityABI, ...config } as unknown as ReadContractConfig<TAbi, TFunctionName>);
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link entityABI}__.
 */
export function writeEntity<TFunctionName extends string>(
  config:
    | Omit<WriteContractPreparedArgs<typeof entityABI, TFunctionName>, 'abi'>
    | Omit<WriteContractUnpreparedArgs<typeof entityABI, TFunctionName>, 'abi'>,
) {
  return writeContract({ abi: entityABI, ...config } as unknown as WriteContractArgs<typeof entityABI, TFunctionName>);
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link entityMulticallABI}__.
 */
export function readEntityMulticall<
  TAbi extends ReadonlyArray<unknown> = typeof entityMulticallABI,
  TFunctionName extends string = string,
>(config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi'>): ReturnType<typeof readContract<TAbi, TFunctionName>> {
  return readContract({ abi: entityMulticallABI, ...config } as unknown as ReadContractConfig<TAbi, TFunctionName>);
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link entityMulticallABI}__.
 */
export function writeEntityMulticall<TFunctionName extends string>(
  config:
    | Omit<WriteContractPreparedArgs<typeof entityMulticallABI, TFunctionName>, 'abi'>
    | Omit<WriteContractUnpreparedArgs<typeof entityMulticallABI, TFunctionName>, 'abi'>,
) {
  return writeContract({ abi: entityMulticallABI, ...config } as unknown as WriteContractArgs<
    typeof entityMulticallABI,
    TFunctionName
  >);
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link fundABI}__.
 */
export function readFund<TAbi extends ReadonlyArray<unknown> = typeof fundABI, TFunctionName extends string = string>(
  config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi'>,
): ReturnType<typeof readContract<TAbi, TFunctionName>> {
  return readContract({ abi: fundABI, ...config } as unknown as ReadContractConfig<TAbi, TFunctionName>);
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link fundABI}__.
 */
export function writeFund<TFunctionName extends string>(
  config:
    | Omit<WriteContractPreparedArgs<typeof fundABI, TFunctionName>, 'abi'>
    | Omit<WriteContractUnpreparedArgs<typeof fundABI, TFunctionName>, 'abi'>,
) {
  return writeContract({ abi: fundABI, ...config } as unknown as WriteContractArgs<typeof fundABI, TFunctionName>);
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link orgABI}__.
 */
export function readOrg<TAbi extends ReadonlyArray<unknown> = typeof orgABI, TFunctionName extends string = string>(
  config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi'>,
): ReturnType<typeof readContract<TAbi, TFunctionName>> {
  return readContract({ abi: orgABI, ...config } as unknown as ReadContractConfig<TAbi, TFunctionName>);
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link orgABI}__.
 */
export function writeOrg<TFunctionName extends string>(
  config:
    | Omit<WriteContractPreparedArgs<typeof orgABI, TFunctionName>, 'abi'>
    | Omit<WriteContractUnpreparedArgs<typeof orgABI, TFunctionName>, 'abi'>,
) {
  return writeContract({ abi: orgABI, ...config } as unknown as WriteContractArgs<typeof orgABI, TFunctionName>);
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link orgFundFactoryABI}__.
 */
export function readOrgFundFactory<
  TAbi extends ReadonlyArray<unknown> = typeof orgFundFactoryABI,
  TFunctionName extends string = string,
>(config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi'>): ReturnType<typeof readContract<TAbi, TFunctionName>> {
  return readContract({ abi: orgFundFactoryABI, ...config } as unknown as ReadContractConfig<TAbi, TFunctionName>);
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link orgFundFactoryABI}__.
 */
export function writeOrgFundFactory<TFunctionName extends string>(
  config:
    | Omit<WriteContractPreparedArgs<typeof orgFundFactoryABI, TFunctionName>, 'abi'>
    | Omit<WriteContractUnpreparedArgs<typeof orgFundFactoryABI, TFunctionName>, 'abi'>,
) {
  return writeContract({ abi: orgFundFactoryABI, ...config } as unknown as WriteContractArgs<
    typeof orgFundFactoryABI,
    TFunctionName
  >);
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link portfolioABI}__.
 */
export function readPortfolio<
  TAbi extends ReadonlyArray<unknown> = typeof portfolioABI,
  TFunctionName extends string = string,
>(config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi'>): ReturnType<typeof readContract<TAbi, TFunctionName>> {
  return readContract({ abi: portfolioABI, ...config } as unknown as ReadContractConfig<TAbi, TFunctionName>);
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link portfolioABI}__.
 */
export function writePortfolio<TFunctionName extends string>(
  config:
    | Omit<WriteContractPreparedArgs<typeof portfolioABI, TFunctionName>, 'abi'>
    | Omit<WriteContractUnpreparedArgs<typeof portfolioABI, TFunctionName>, 'abi'>,
) {
  return writeContract({ abi: portfolioABI, ...config } as unknown as WriteContractArgs<
    typeof portfolioABI,
    TFunctionName
  >);
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link registryABI}__.
 */
export function readRegistry<
  TAbi extends ReadonlyArray<unknown> = typeof registryABI,
  TFunctionName extends string = string,
>(config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi'>): ReturnType<typeof readContract<TAbi, TFunctionName>> {
  return readContract({ abi: registryABI, ...config } as unknown as ReadContractConfig<TAbi, TFunctionName>);
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link registryABI}__.
 */
export function writeRegistry<TFunctionName extends string>(
  config:
    | Omit<WriteContractPreparedArgs<typeof registryABI, TFunctionName>, 'abi'>
    | Omit<WriteContractUnpreparedArgs<typeof registryABI, TFunctionName>, 'abi'>,
) {
  return writeContract({ abi: registryABI, ...config } as unknown as WriteContractArgs<
    typeof registryABI,
    TFunctionName
  >);
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link transferDeployerABI}__.
 */
export function readTransferDeployer<
  TAbi extends ReadonlyArray<unknown> = typeof transferDeployerABI,
  TFunctionName extends string = string,
>(config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi'>): ReturnType<typeof readContract<TAbi, TFunctionName>> {
  return readContract({ abi: transferDeployerABI, ...config } as unknown as ReadContractConfig<TAbi, TFunctionName>);
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link transferDeployerABI}__.
 */
export function writeTransferDeployer<TFunctionName extends string>(
  config:
    | Omit<WriteContractPreparedArgs<typeof transferDeployerABI, TFunctionName>, 'abi'>
    | Omit<WriteContractUnpreparedArgs<typeof transferDeployerABI, TFunctionName>, 'abi'>,
) {
  return writeContract({ abi: transferDeployerABI, ...config } as unknown as WriteContractArgs<
    typeof transferDeployerABI,
    TFunctionName
  >);
}
