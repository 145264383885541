import type { Org, OrgListing, Subproject } from '@endaoment-frontend/types';
import { ClaimedIcon } from '@endaoment-frontend/ui/icons';

import { Tooltip } from '../tooltip';

import styles from './OrgStatusTooltips.module.scss';

export const OrgStatusTooltips = ({
  orgOrSubproject,
}: {
  orgOrSubproject: Pick<Org | OrgListing | (Subproject & { claimed?: undefined }), 'claimed'>;
}) => {
  return (
    <>
      {orgOrSubproject.claimed ? (
        <Tooltip content='Onboarding complete! This organization has provided a verified payment method.'>
          <ClaimedIcon color='currentColor' className={styles['status--active']} />
        </Tooltip>
      ) : (
        <Tooltip content="Not onboarded! We'll verify a payment method for this organization once it receives a donation.">
          <ClaimedIcon color='currentColor' className={styles['status--inactive']} />
        </Tooltip>
      )}
    </>
  );
};
